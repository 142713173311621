import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController, setChatbotNavClose } from "context";


ChartJS.register(ArcElement, Tooltip, Legend);

const HalfDonutChart = ({ isStorage, percentage, height, width, totalOccupiedSize, allowedStorage }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

    // Determine color based on percentage
    const getColor = () => {
        return isStorage ? '#D81B60' : '#1A73E8';
    };

    const data = {
        labels: [isStorage ? 'Storage Usage' : "Tokens Usage"],
        datasets: [
            {
                data: [percentage, 100 - percentage],
                backgroundColor: [getColor(percentage), '#dddddd'], // Dynamic color for used portion
                hoverBackgroundColor: [getColor(percentage), '#dddddd'],
            },
        ],
    };

    const options = {
        rotation: -90,
        circumference: 180,
        cutout: '50%',
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                  title: function () {
                    return isStorage ? 'Storage Used' : "Tokens Used";
                  },
                  label: function () {
                    return [
                      `${isStorage ? "Storage Used: " : "Tokens Used: "}${totalOccupiedSize} ${isStorage ? "MB" : ""} `,
                      `${isStorage ? "Allocated Storage: " : "Allocated Tokens: "} ${allowedStorage} ${isStorage ? "MB" : ""}`
                    ];
                  },
                  labelColor: function () {
                    return {
                      borderColor: getColor(),
                      backgroundColor: getColor(),
                    };
                  },
                },
                displayColors: true, 
                yAlign: 'bottom',
              },
        },
    };

    return (
        <div style={{ position: 'relative', textAlign: 'center', height: height, width: width }}>
            <Doughnut data={data} options={options} />
            <div style={{ marginTop:"-120px", textAlign: 'center' }}>
                <MDTypography style={{ fontSize: "24px", fontWeight: "800", color: isStorage ? "#D81B60" : "#1A73E8" }}>{percentage}%</MDTypography>
                <MDTypography style={{ fontSize: "10px", fontWeight: "500", color:darkMode ? "white" :"#3B4E6D"}}>
                  {isStorage ? "Storage used" : "Tokens used"}
                </MDTypography>
            </div>
        </div>
    );
};

export default HalfDonutChart;
