/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// Dashboard components
import 'react-loading-skeleton/dist/skeleton.css';
import { Images } from "libraries/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import MDStepper from "components/MDStepper";
import { LLmFunctionConfig } from "../LLmFunctionConfig";
import { LLMFunctionTest } from "../LLMFunctionTest";
import MDTypography from "components/MDTypography";


function CreationFlowWrapper() {

  const location = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const {id, name} = useParams();
  const { themeColor, darkMode } = controller;
  const stepsLLMFunction = ['Configure','Test'];

  const formatString = (input) => {
    return input
        .split('-')              // Split the string by hyphens
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
        .join(' ');              // Join the words with spaces
  }

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
    <DashboardLayout>
    <DashboardNavbar />

    <MDTypography sx={{ fontSize: "20px", fontWeight: "600",paddingLeft:"5px",paddingBottom:"15px" }}>
        {"LLM Function"}
    </MDTypography>
	<Grid container spacing={2} style={{overflowY: "auto", height: "calc(100vh - 150px)" }}>
		<MDStepper 
      steps={stepsLLMFunction} 
      activeStep={location?.state?.step ?? 0}
      >
          { ( location?.state?.step === 0 ) ? <LLmFunctionConfig />
            : ( location?.state?.step === 1 ) ? <LLMFunctionTest />
          : null
          }
		</MDStepper>
	</Grid>  
      
    </DashboardLayout>
    </div>

  );
}

export default CreationFlowWrapper;
