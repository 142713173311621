import React from 'react';
import { FaSpinner } from "react-icons/fa";

export const SpinnerLoader = ({ enhance, adjustment, style, width }) => {
    return (
        <div className={`flex flex-row bg-transparent ${adjustment && 'justify-center items-center'} ${width && 'w-full'}`}>

            <FaSpinner className={`animate-spin ${enhance}`}
                style={style} />
        </div>
    );
};
