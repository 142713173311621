/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";


// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import googleIcon from "assets/images/small-logos/google-icon.svg"
import microsoftIcon from "assets/images/small-logos/microsoft-icon.svg"
import appLogo from "assets/images/logos/Intellixcore.png";
import { TokenService } from "libraries/TokenService";
import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import {useMaterialUIController} from "context";
import { useDispatch, useSelector } from 'react-redux';
import { isLoginAction, isPermissionAction } from "redux/Action";
import { SpinnerLoader } from "examples/Loader/Loader";
import ApiUrls from "services/ApiUrls";

//media queries
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


function Basic() {
  const location = useLocation();
  const navigate = useNavigate();
  const isToaster = new URLSearchParams(location?.search)?.get('logout');

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [loginCredentials, setLoginCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({ email: '', password: '' });
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
  const [isMicrosoftLoginLoading, setIsMicrosoftLoginLoading] = useState(false);

  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const dispatch = useDispatch();
  const stateUser = useSelector((state) => state?.loginReducer?.user?.errorMessege);
  const ErrorType=localStorage.getItem('errorType')
  const theme = useTheme();
  const isMobileScreen = useMediaQuery("(min-width:310px) and (max-width:330px)");
  const isMediumMobileScreen = useMediaQuery("(max-width:376px)");

  useEffect(()=>{
      if(isToaster){
        toast.success("Your session has expired. Kindly log in again")
      }
  },[isToaster]);

  const loginUser = () => {

    const { email, password } = loginCredentials;
    let newErrors = {};

    // if (!CommonMethodsUI.validateEmail(email)) newErrors.email = 'Please enter a valid email address.';
    // if (!password) newErrors.password = 'Please enter valid password.';
    // if (Object.keys(newErrors).length !== 0) setError(newErrors);
    // else {
        setIsLoginLoading(true);

        ApiRequest.login({ userEmail: email, password }, '', (res) => {
          const { isSuccess, data, error } = res;

          if(res?.isSuccess){
            // userEmail: email
            ApiRequest.userPaymentStatus(data?.accessToken,{}, `/${res?.data?.userId}`,(res2)=>{

              ApiRequest.userPermissionStatus(data?.accessToken, {}, '',(res3)=>{
                  if(res3.isSuccess){
                    setIsLoginLoading(false);
                      if (res2?.isSuccess) {
                        // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
                          dispatch(isPermissionAction(res3?.data?.membershipLimits));
                          TokenService.setAccessToken(data?.accessToken);
                          TokenService.setRefreshToken(data?.refreshToken);                
                          dispatch(isLoginAction(data));
                          toast.success(res?.data.message ? res?.data.message : "Logged in successfully");
                          navigate('/dashboard');                
                      } else{
                        toast.error(res2?.error?.message ? res2?.error?.message : "Something went wrong");
                      }
                  }
              });
                
            });
          }else {
            setIsLoginLoading(false);
            toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
          }    
       });
    // }
  }

  useEffect(()=> {
    TokenService.clearTokens(false);
    if(ErrorType === "errors"){
    toast.error(stateUser);
     localStorage.removeItem('errorType')
    }
    if(ErrorType === "microsoftError"){
    toast.error(stateUser);
     localStorage.removeItem('errorType')
    }
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow={themeColor}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          
        >
          <MDBox 
          mt={1}
          >
           <MDTypography variant="button" display="flex" justifyContent="center">
          <img  src={appLogo} alt="" className="h-[40px]"/>
          </MDTypography>
          </MDBox>
          <MDTypography variant="h4" fontWeight="medium" color="white" display="flex" justifyContent="center">
            Sign in
          </MDTypography>
          <MDTypography variant="button" color="white" mt={1} display="flex" justifyContent="center"  
          style={{
          paddingLeft: isMobileScreen ? "20px" : "",
          paddingLeft: isMediumMobileScreen ? "20px" : "",
       
      }}>
              Welcome Back! Please enter details to login
          </MDTypography>
          

         
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>  */}
        </MDBox>
        <MDBox pt={3} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" value={loginCredentials.email} autoComplete="off" 
                        onChange={(e) => {
                            setLoginCredentials({ ...loginCredentials, email: e.target.value });
                            setError({ ...error, email: '' });
                        }} label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                      type={showPassword ? 'text' : 'password'}
                      value={loginCredentials.password} 
                      onKeyDown={(e) => { 
                        if (e.key === "Enter") {
                          loginUser(); 
                        }
                      }}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=> setShowPassword((show) => !show)}
                              onMouseDown={(e)=> e.preventDefault()}
                              style={{ color: darkMode ? "white" : "black" }}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                     
                      onChange={(e) => {
                          setLoginCredentials({ ...loginCredentials, password: e.target.value });
                          setError({ ...error, password: '' });
                      }} label="Password" fullWidth />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton onClick={() => loginUser()} disabled={isLoginLoading} variant="gradient" color={themeColor} fullWidth>
                {isLoginLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} display="flex" gap={2}>
              <Link to={ApiUrls.googleSignupUrl} className="w-full">
              <MDButton disabled={isGoogleLoginLoading} variant="gradient" color={themeColor} fullWidth className={'flex gap-[10px]'} sx={{ padding:"10px" }}>
                {isGoogleLoginLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> :<><img src={googleIcon} alt="" />SIGN IN WITH GOOGLE</>}
              </MDButton>
              </Link>
              <Link to={ApiUrls.microsoftSignupUrl} className="w-full">
              <MDButton disabled={isMicrosoftLoginLoading} variant="gradient" color={themeColor} fullWidth className={'gap-[10px]'} sx={{ padding:"10px" }}>
                {isMicrosoftLoginLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> :<><img src={microsoftIcon} alt=""  className="h-[21px] w-[19px]"/>SIGN IN WITH MICROSOFT</> }
              </MDButton>
              </Link>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="right">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/auth/forgot-password"
                  variant="button"
                  color={themeColor}
                  fontWeight="medium"
                  textGradient
                >
                  Forgot password?
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color={themeColor}
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

