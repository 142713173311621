/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Dashboard components
import React, { useEffect, useState } from "react";
import useScreenWidth from "libraries/ScreenSizeHook";
import { Link } from "react-router-dom";
import { Card, Grid, useMediaQuery } from "@mui/material";
import { useMaterialUIController } from "context";
import { Images } from "libraries/Images";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ApiRequest from "services/ApiRequest";
import { pattrensMenuOptions } from "../estimated-savings/menuOptions";
import ExpandableMenu from "../estimated-savings/ExpandableMenu";
import Methods from "libraries/CommonMethodsUI";


//images
import { patternIcon } from "./PattrenImages";
import { ReactComponent as WorkForceIcon } from "assets/images/flow-icons/workforce-icon.svg"
import customerServiceImg from "assets/images/flow-icons/customer-service.svg"
import officeAdminSupportImg from "assets/images/flow-icons/admin-support.svg"
import salesImg from "assets/images/flow-icons/sales.svg"
import iTSupportImg from "assets/images/flow-icons/it-services.svg"
import customerServiceGreenThemeImg from "assets/images/flow-icons/customer-service-green.svg"
import officeAdminSupportGreenThemeImg from "assets/images/flow-icons/admin-support-green.svg"
import salesGreenThemeImg from "assets/images/flow-icons/sales-green.svg"
import iTSupportGreenThemeImg from "assets/images/flow-icons/it-services-green.svg"


function WorkForcePatterns() {

    const [windowSize] = useScreenWidth();
    const [controller] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const [selectedService, setSelectedService] = useState(pattrensMenuOptions.CustomerService)
    const [data, setData] = useState({
        operationTeams: [""],
        customerServices: [""],
        adminSupport: [""],
        sales: [""],
        iTServices: [""],
        industryName: ""

    });
    const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)')
    const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)')
    const getBackgroundColor = () => {
        switch (themeColor) {
          case "info":
            return "#1E4585";
          case "success":
            return "#5DAA45";
          default:
            return themeColor;
        }
      };


    useEffect(() => {
        getEstimatedValues()
    }, [])

    const getEstimatedValues = () => {

        ApiRequest.estimatedCostValues('', (res) => {
            if (res?.isSuccess) {
                console.log(res?.data)
                const getValues = res?.data?.results?.patternMatrix;
                const getSupportFunctions = res?.data?.results?.patternMatrix?.supportFunctions;
                setData({
                    operationTeams: getValues?.operationalTeam,
                    customerServices: getSupportFunctions?.customerService,
                    adminSupport: getSupportFunctions?.officeAdminSupport,
                    sales: getSupportFunctions?.sales,
                    iTServices: getSupportFunctions?.itServices,
                    industryName: res?.data?.results?.industryName
                });

            } else {
                console.log("error")
            }
        });

    }
    const maxLength = Math.max(
        data.operationTeams?.length || 0,
        data.customerServices?.length || 0,
        data.adminSupport?.length || 0,
        data.sales?.length || 0,
        data.iTServices?.length || 0
    );

    const convertToCSV = (dataSet) => {
        const rows = [];
        const industryName = dataSet.industryName;
        const categories = ["operationTeams", "customerServices", "adminSupport", "sales", "iTServices"];
    
        // Add the combined header row
        rows.push([`Operation Teams :${industryName}`, 'Customer Services', 'Admin Support', 'Sales', 'IT Services'].join(','));
    
        // Determine the maximum number of patterns in any category
        const maxLength = Math.max(
            dataSet.operationTeams?.length || 0,
            dataSet.customerServices?.length || 0,
            dataSet.adminSupport?.length || 0,
            dataSet.sales?.length || 0,
            dataSet.iTServices?.length || 0
        );
    
        // Create rows for each pattern
        for (let i = 0; i < maxLength; i++) {
            const row = [];
            categories.forEach(category => {
                if (i < dataSet[category]?.length) {
                    row.push(dataSet[category][i]?.patternName || '');
                } else {
                    row.push('');
                }
            });
            rows.push(row.join(','));
        }
    
        return rows.join('\n');
    };

    const handleDownloadClick = () => {
         const csvData = convertToCSV(data);
        Methods.downloadCSV(csvData, 'patterns-list.csv');
    };

    return (
        <div style={{
            backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundPosition: "100% 100%",
            overflowY: "auto",
            height: "100vh"
        }}>
            <DashboardLayout>
                <DashboardNavbar />
                <Grid container spacing={2} style={{ paddingTop: "30px", overflowY: "auto", height: "calc(100vh - 110px)" }}>
                    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 10px", width: "100%" }}>
                        <Card style={{ width: "100%" }}>
                            <MDBox pt={1} pb={2} px={1} style={{ minHeight: "50vh" }}>
                                <MDBox>
                                    <div className={`flex justify-between ${isTablet ?"px-[10px]" :isLargeMobile ? "pl-[10px] pr-[21px]" : isSmallMobile ? "pl-[10px] pr-[6px]" : "pl-[10px] pr-[13px]"}`}>
                                        <div className="flex gap-[20px]">
                                            <div className="h-[58px] w-[57px] rounded-[9px] mt-[-26px] flex justify-center items-center" style={{backgroundColor:getBackgroundColor()}}>
                                                <WorkForceIcon />
                                            </div>
                                            <MDTypography sx={{ display: "flex", alignItems: "center", fontSize: isLargeMobile || isSmallMobile || isTablet ? "12px" : "16px", fontWeight: "600" }}>Workforce Strategy</MDTypography>

                                        </div>
                                        <div>
                                            <MDButton size={isLargeMobile || isSmallMobile || isTablet ? "small" : ""} sx={{ fontSize: isLargeMobile || isSmallMobile ? "7px" : isTablet ? "9px" : "12px" }} variant="gradient" color={themeColor} onClick={()=>{
                                                console.log("eeeeee")
                                                handleDownloadClick()
                                            }}> Download CSV File </MDButton>
                                        </div>
                                    </div>
                                </MDBox>
                                <MDBox sx={{ paddingTop: "40px", display: "flex" }} >
                                    <div className={`${isSmalMobileStyling ? "w-[50%]" : (ismdMobileStyling || isLargeMobile) ? "w-[40%]" : isTablet ? "w-[20%]" : "w-[20%]"} ${isSmallMobile ? "pl-[5px]" : "pl-[10px]"}`}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`flex flex-col rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[55px]" : "h-[84px]"} ml-[10px] mr-[10px] -mt-[30px] justify-center `} style={{backgroundColor:getBackgroundColor()}}>
                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "11px" : "20px", fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    Operations teams
                                                </MDTypography>

                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "7px" : isSmallMobile ? "6px" : "12px", fontWeight: "400", display: "flex", alignItems: "center", textAlign: "center", justifyContent:"center" }}>
                                                    {data?.industryName}
                                                </MDTypography>
                                            </div>
                                            <div className="flex flex-col pt-[20px] px-[10px]">
                                                {Array.from({ length: maxLength }).map((_, index) => {
                                                    const isActive = data.operationTeams[index]?.isActive;
                                                    const iconPath = patternIcon[data.operationTeams[index]?.patternId]?.iconPath;
                                                    return (
                                                        <MDBox
                                                            key={index}
                                                            sx={{
                                                                height: "60px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-start",
                                                                borderBottom: index < maxLength - 1 ? "1px solid gray" : "none",
                                                                // opacity: isActive ? 1 : 0.5,
                                                                pointerEvents: isActive ? "auto" : "none"
                                                            }}
                                                        >
                                                            <div className="flex gap-[5px] items-center">
                                                                {iconPath && (
                                                                    <img src={`${darkMode ? `${patternIcon[data.operationTeams[index]?.patternId]?.iconPathDarkMode}` : `${patternIcon[data.operationTeams[index]?.patternId]?.iconPath}` }`} alt="icon" backgroundColor={`${darkMode && "white"}`} style={{width:"15px", height:"15px", opacity:isActive ? 1 : 0.5}} />
                                                                )}

                                                                <MDTypography
                                                                    style={{ color: (darkMode && !isActive) ? "gray" : darkMode ? "white" :!isActive ? "#BDC3C7" : "#1E4183" }}
                                                                    sx={{
                                                                        fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px",
                                                                        fontWeight: "400",
                                                                        paddingLeft: "7px",
                                                                        paddingRight: "7px"
                                                                    }}
                                                                >
                                                                    <Link to={patternIcon[data.operationTeams[index]?.patternId]?.routePath?.replace(':id', data.operationTeams[index]?.patternId)}>
                                                                        {data.operationTeams[index]?.patternName}
                                                                    </Link>
                                                                </MDTypography>
                                                            </div>
                                                        </MDBox>
                                                    );
                                                })}


                                            </div>
                                        </Card>
                                    </div>
                                    <div className={`${ismdMobileStyling ? "w-[60%]" : isLargeMobile ? "w-[67%]" : "w-[80%]"} ${isSmallMobile ? "pl-[5px]" : "pl-[10px]"} `}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[55px]" : "h-[84px]"} ml-[10px] mr-[10px] -mt-[30px] p-[5px] `} style={{backgroundColor:getBackgroundColor()}}>
                                                {!(isSmallMobile || isLargeMobile || isTablet) && (<MDTypography
                                                    style={{ color: "white", fontSize: isMdLoptop ? "17px" : "20px", fontWeight: "600", paddingLeft: "20px", paddingRight: "20px" }}
                                                >
                                                    Support Function
                                                </MDTypography>)}
                                                <div className={`flex ${isSmalMobileStyling ? "pl-[12px] pr-[20px]" : "px-[20px]"} ${isTablet ? "pt-[18px]" : "pt-[10px]"} ${isLargeMobile && "gap-[17px]"} w-full`}>
                                                    {((selectedService === pattrensMenuOptions.CustomerService) || (!isLargeMobile && !isSmallMobile)) && (
                                                        <>

                                                            <div className={`flex gap-[10px] items-center ${isSmalMobileStyling ? "w-[76%]" : ismdMobileStyling ? "w-[80%]" : isLargeMobile ? "w-[67%]" : "w-[25%]"}`} >
                                                                <img src={themeColor === "success" ? customerServiceGreenThemeImg : customerServiceImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : isMdLoptop ? "w-[21px] h-[21px]" : "w-[24px] h-[24px]"} `} />
                                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400", display: "flex" }}>
                                                                    Customer Service

                                                                </MDTypography>
                                                            </div>
                                                            <div className="pt-[1px]">
                                                                <ExpandableMenu options={Object.values(pattrensMenuOptions)} selectedService={selectedService}
                                                                    currentService={pattrensMenuOptions.CustomerService} setSelectedService={setSelectedService} pattren={true} />
                                                            </div>
                                                        </>


                                                    )}
                                                    {((selectedService === pattrensMenuOptions.OfficeAndAdmin) || (!isLargeMobile && !isSmallMobile)) && (
                                                        <>
                                                            <div className={`flex gap-[10px] items-center ${isTablet && "pl-[12px]"} ${isSmalMobileStyling ? "w-[76%]" : ismdMobileStyling ? "w-[80%]" : isLargeMobile ? "w-[67%]" : "w-[25%]"} `} >
                                                                <img src={themeColor === "success" ? officeAdminSupportGreenThemeImg : officeAdminSupportImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : isMdLoptop ? "w-[21px] h-[21px]" : "w-[24px] h-[24px]"} `} />
                                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400" }}>
                                                                    {(isTablet || isLargeMobile || isSmallMobile) ? "Office & Admin" : "Office & Admin Support"}
                                                                </MDTypography>
                                                            </div>
                                                            <div className="pt-[1px]">
                                                                <ExpandableMenu options={Object.values(pattrensMenuOptions)} selectedService={selectedService}
                                                                    currentService={pattrensMenuOptions.OfficeAndAdmin} setSelectedService={setSelectedService} pattren={true} />
                                                            </div>
                                                        </>
                                                    )}

                                                    {((selectedService === pattrensMenuOptions.Sales) || (!isLargeMobile && !isSmallMobile)) && (
                                                        <>
                                                            <div className={`flex gap-[10px] items-center ${isSmalMobileStyling ? "pl-[0px] w-[76%]" : ismdMobileStyling ? "pl-[0px] w-[80%]" : isLargeMobile ? "w-[67%]" : "w-[25%]"} ${isLargeMobile ? "pl-[0px]" : isTablet? "pl-[26px]" : "pl-[20px]"}  `} >

                                                                <img src={themeColor === "success" ? salesGreenThemeImg : salesImg} alt="" className={` ${isLargeMobile && "ml-[4px]"} ${(isLargeMobile || isSmallMobile) && "ml-[2px]"} ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : isMdLoptop ? "w-[21px] h-[21px]" : "w-[24px] h-[24px]"} `} />
                                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400" }}>
                                                                    Sales & Support
                                                                </MDTypography>
                                                            </div>
                                                            <div className="pt-[1px]">
                                                                <ExpandableMenu options={Object.values(pattrensMenuOptions)} selectedService={selectedService}
                                                                    currentService={pattrensMenuOptions.Sales} setSelectedService={setSelectedService} pattren={true} />
                                                            </div>
                                                        </>
                                                    )}
                                                    {((selectedService === pattrensMenuOptions.ItServices) || (!isLargeMobile && !isSmallMobile)) && (
                                                        <>
                                                            <div className={`flex gap-[10px] items-center ${isSmalMobileStyling ? "pl-[0px] w-[76%]" : ismdMobileStyling ? "w-[80%]" : isLargeMobile ? "w-[67%]" : "w-[25%]"} pl-[20px] `} >
                                                                <img src={themeColor === "success" ? iTSupportGreenThemeImg : iTSupportImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : isMdLoptop ? "w-[21px] h-[21px]" : "w-[24px] h-[24px]"} `} />
                                                                <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400" }}>
                                                                    IT Services
                                                                </MDTypography>
                                                            </div>
                                                            <div className="pt-[1px]">
                                                                <ExpandableMenu options={Object.values(pattrensMenuOptions)} selectedService={selectedService}
                                                                    currentService={pattrensMenuOptions.ItServices} setSelectedService={setSelectedService} pattren={true} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                            </div>

                                            <div className={`flex flex-col pt-[20px] px-[20px] `}>

                                            {Array.from({ length: maxLength }).map((_, index) => {
                                                    const isActiveCustomerService = data.customerServices[index]?.isActive;
                                                    const isActiveAdminSupport = data.adminSupport[index]?.isActive;
                                                    const isActiveSales = data.sales[index]?.isActive;
                                                    const isActiveITSupport = data.iTServices[index]?.isActive;
                                                    return (

                                                        <div
                                                            key={index}
                                                            className={`flex items-center`}
                                                            style={{ borderBottom: index < maxLength -1 ? "1px solid gray" : "none", height: "60px", }}
                                                        >

                                                            {/* Customer Service */}
                                                            {((selectedService === pattrensMenuOptions.CustomerService) || (!isLargeMobile && !isSmallMobile)) && (

                                                                <MDBox
                                                                    sx={{
                                                                        height: "60px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                        width: (ismdMobileStyling || isLargeMobile) ? "80%" : "25%",
                                                                        // opacity: isActiveCustomerService ? 1 : 0.5,
                                                                        pointerEvents: isActiveCustomerService ? "auto" : "none",

                                                                    }}
                                                                >
                                                                    <div className={`flex  ${isTablet ? "gap-[10px]" : "gap-[5px]"} pl-[15px] items-center `}>
                                                                        {data.customerServices[index]?.patternName?.length && (
                                                                            <img src={`${darkMode ? `${patternIcon[data.customerServices[index]?.patternId]?.iconPathDarkMode}` : `${patternIcon[data.customerServices[index]?.patternId]?.iconPath}` }`} alt="icon" backgroundColor={`${darkMode && "white"}`} style={{width:"15px", height:"15px", opacity:isActiveCustomerService ? 1 : 0.5}} />
                                                                        )}
                                                                        <MDTypography
                                                                            style={{
                                                                                    color:(darkMode && !isActiveCustomerService) ? "gray" :  darkMode ? "white" :!isActiveCustomerService ? "#BDC3C7" : "#1E4183", display: isTablet ? "flex" : "block",

                                                                            }}
                                                                            sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400"}}
                                                                        >
                                                                            <Link to={patternIcon[data.customerServices[index]?.patternId]?.routePath?.replace(':id', data.customerServices[index]?.patternId)} className="cursor-pointer">{data.customerServices[index]?.patternName}</Link>
                                                                        </MDTypography>
                                                                    </div>
                                                                </MDBox>
                                                            )}

                                                            {/* Customer Service */}

                                                            {/* Office & admin */}
                                                            {((selectedService === pattrensMenuOptions.OfficeAndAdmin) || (!isLargeMobile && !isSmallMobile)) && (
                                                                <MDBox
                                                                    sx={{
                                                                        height: "60px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                        width: (ismdMobileStyling || isLargeMobile) ? "80%" : "25%",
                                                                        // opacity: isActiveAdminSupport ? 1 : 0.5,
                                                                        pointerEvents: isActiveAdminSupport ? "auto" : "none",

                                                                    }}
                                                                >
                                                                    <div className={`flex ${isTablet ? "gap-[10px]" : "gap-[5px]"} pl-[15px] items-center  `}>
                                                                        {data.adminSupport[index]?.patternName?.length && (
                                                                            <img src={`${darkMode ? `${patternIcon[data.adminSupport[index]?.patternId]?.iconPathDarkMode}` : `${patternIcon[data.adminSupport[index]?.patternId]?.iconPath}` }`} alt="icon" backgroundColor={`${darkMode && "white"}`} style={{width:"15px", height:"15px", opacity:isActiveAdminSupport ? 1 : 0.5}} />
                                                                        )}
                                                                        <MDTypography
                                                                            style={{
                                                                                color:(darkMode && !isActiveAdminSupport ) ? "gray" :darkMode ? "white" :!isActiveAdminSupport ? "#BDC3C7" : "#1E4183", display: isTablet ? "flex" : "block",

                                                                            }}
                                                                            sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400"}}
                                                                        >
                                                                            <Link to={patternIcon[data.adminSupport[index]?.patternId]?.routePath?.replace(':id', data.adminSupport[index]?.patternId)} className="cursor-pointer">{data.adminSupport[index]?.patternName}</Link>
                                                                        </MDTypography>
                                                                    </div>
                                                                </MDBox>
                                                            )}
                                                            {/* Office & admin */}

                                                            {/* Sales */}
                                                            {((selectedService === pattrensMenuOptions.Sales) || (!isLargeMobile && !isSmallMobile)) && (
                                                                <MDBox
                                                                    sx={{
                                                                        height: "60px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                        width: (ismdMobileStyling || isLargeMobile) ? "80%" : "25%",
                                                                        // opacity: isActiveSales ? 1 : 0.5,
                                                                        pointerEvents: isActiveSales ? "auto" : "none",

                                                                    }}
                                                                >
                                                                    <div className={` flex ${isTablet ? "gap-[10px]" : "gap-[5px]"} pl-[25px] items-center `}>
                                                                        {data.sales[index]?.patternName?.length && (
                                                                            <img src={`${darkMode ? `${patternIcon[data.sales[index]?.patternId]?.iconPathDarkMode}` : `${patternIcon[data.sales[index]?.patternId]?.iconPath}` }`} alt="icon" backgroundColor={`${darkMode && "white"}`} style={{width:"15px", height:"15px", opacity:isActiveSales ? 1 : 0.5}} />
                                                                        )}
                                                                        <MDTypography
                                                                            style={{
                                                                                color:(darkMode && !isActiveSales ) ? "gray" : darkMode ? "white" :!isActiveSales ? "#BDC3C7" : "#1E4183", display: isTablet ? "flex" : "block",
                                                                            
                                                                            }}
                                                                            sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400" }}
                                                                        >
                                                                            <Link to={patternIcon[data.sales[index]?.patternId]?.routePath?.replace(':id', data.sales[index]?.patternId)} className="cursor-pointer">{data.sales[index]?.patternName}</Link>
                                                                        </MDTypography>
                                                                    </div>
                                                                </MDBox>
                                                            )}
                                                            {/* Sales */}

                                                            {/* IT services */}
                                                            {((selectedService === pattrensMenuOptions.ItServices) || (!isLargeMobile && !isSmallMobile)) && (
                                                                <MDBox
                                                                    sx={{
                                                                        height: "60px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                        textAlign: "flex-start",
                                                                        width: (ismdMobileStyling || isLargeMobile) ? "80%" : "25%",
                                                                        // opacity: isActiveITSupport ? 1 : 0.5,
                                                                        pointerEvents: isActiveITSupport ? "auto" : "none",

                                                                    }}
                                                                >
                                                                    <div className={`flex ${isTablet ? "gap-[10px]" : "gap-[5px]"} pl-[15px] items-center `}>
                                                                        {data.iTServices[index]?.patternName?.length && (
                                                                            <img src={`${darkMode ? `${patternIcon[data.iTServices[index]?.patternId]?.iconPathDarkMode}` : `${patternIcon[data.iTServices[index]?.patternId]?.iconPath}` }`} alt="icon" backgroundColor={`${darkMode && "white"}`} style={{width:"15px", height:"15px", opacity:isActiveITSupport ? 1 : 0.5}} />
                                                                        )}
                                                                        <MDTypography
                                                                            style={{
                                                                                color:(darkMode && !isActiveITSupport ) ? "gray" :darkMode ? "white" :!isActiveITSupport ? "#BDC3C7" : "#1E4183", display: isTablet ? "flex" : "block",
                                                                            }}
                                                                            sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "12px" : "14px", fontWeight: "400"}}
                                                                        >
                                                                            <Link to={patternIcon[data.iTServices[index]?.patternId]?.routePath?.replace(':id', data.iTServices[index]?.patternId)} className="cursor-pointer">{data.iTServices[index]?.patternName}</Link>
                                                                        </MDTypography>
                                                                    </div>
                                                                </MDBox>
                                                            )}
                                                            {/* IT services */}

                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Card>
                                    </div>

                                </MDBox>
                            </MDBox>
                        </Card>
                    </div>
                </Grid>
            </DashboardLayout>
        </div>

    );
};

export default WorkForcePatterns;