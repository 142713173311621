import React, { useEffect, useState } from 'react';
import {useMaterialUIController} from "context";
import styled, { css, createGlobalStyle } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({backDates}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;

  useEffect(()=> {
    backDates({startDate, endDate})
  },[startDate, endDate])

  const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker__input-container input{
    border: ${darkMode ? "1px solid #888D98" : "1px solid lightgrey"} !important;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${darkMode ? "transparent" : "white"};
    color: ${darkMode ? "white" : "black"};
    border-radius: 5px !important;
  }
  
  .react-datepicker__input-container input::placeholder{
    font-size: 13px;
    color: ${darkMode && "#B3B5BC"}
  }


  .darkmode .react-datepicker {
    background-color: #333;
    border-color: #444;
    color: #fff;
  }
  
  .darkmode .react-datepicker__header {
    background-color: #444;
    border-bottom-color: #555;
  }
  
  .darkmode .react-datepicker__day-name, 
  .darkmode .react-datepicker__day, 
  .darkmode .react-datepicker__time-name {
    color: #fff;
  }
  
  .darkmode .react-datepicker__current-month, 
  .darkmode .react-datepicker-time__header, 
  .darkmode .react-datepicker-year-header {
    color: #fff;
  }
  
  .darkmode .react-datepicker__day--selected, 
  .react-datepicker__day--keyboard-selected {
    background-color: #555;
    color: #fff;
  }
  
  .darkmode .react-datepicker__day--disabled {
    color: #666;
  }
`;

  return (
    <div className={`${darkMode ? "darkmode" : ""} flex w-full items-center justify-end flex-wrap`}>
      <div  style={{ marginBottom: "5px" }}>
        {/* <div style={{ fontSize: "15px" }}>Start Date </div> */}
        <DatePicker
          selected={startDate}
          wrapperClassName="date_picker"
          onChange={date => setStartDate(date)}
          selectsStart
          dateFormat="yyyy-MM-dd"
          style={{ background: "transparent" }}
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
      </div>
      <div style={{ marginLeft: "10px",marginBottom: "5px"  }}>
        {/* <div style={{ fontSize: "15px" }}>End Date </div> */}
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          dateFormat="yyyy-MM-dd"
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
      </div>
      <DatePickerWrapperStyles />
    </div>
  );
};

export default DateRangePicker;