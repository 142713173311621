import React,{useEffect, useState} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import 'react-loading-skeleton/dist/skeleton.css';
import { Code } from 'react-content-loader';
import { TokenService } from "libraries/TokenService";
import {useIdleTimer} from 'react-idle-timer'
import { isLoginAction, isPermissionAction } from 'redux/Action';

const RouteWrapper = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch=useDispatch()
    const token = new URLSearchParams(location?.search)?.get('token');
    const accessToken = new URLSearchParams(location?.search)?.get('accessToken');
    const userId = new URLSearchParams(location?.search)?.get('userId');
    const userEmail = new URLSearchParams(location?.search)?.get('userEmail');
    const googleMicrosoftLogin = new URLSearchParams(location?.search)?.get('paymentPlan');
    const errorMessege = new URLSearchParams(location?.search)?.get('error');
    const widgetId = location?.pathname?.split('/')?.pop();

    const onIdle = () => {
        if(!location?.pathname.includes("/chatbot-widget")){
            toast.success("Your session has expired. Kindly log in again")
            TokenService.clearTokens(false);
            navigate("/auth/login");
        }
    }
    
      const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 55 * 60 * 1000, //1 hour idle timeout
      });

      useEffect(()=>{
            console.log("Test Route", location?.pathname)
            console.log("Test Token", localStorage.getItem("authenticationAccessToken"))
      },[location?.pathname, localStorage.getItem("authenticationAccessToken")]);
    
    useEffect(()=>{
        if(location?.pathname.includes("apple-developer-merchantid-domain-association")){
            setIsLoading(false);
            navigate("/auth/login");
        }else if(location?.pathname.includes("chatbot-dev.html")){
            setIsLoading(false);
            navigate("/auth/login");
        }else if(location?.pathname.includes("/chatbot-widget")){
            setIsLoading(false);
            navigate(`/chatbot-widget/${widgetId}`);
        }else if(token && location?.pathname?.includes("/confirm-email")){
            setIsLoading(true);
            ApiRequest.verifyEmail((location?.pathname + location?.search), '', (res) => {
                if (res?.isSuccess) {
                    toast.success(res?.data ? res?.data?.message : "Email verified successfully");

                            ApiRequest.userPermissionStatus(res?.data?.accessToken, {}, '',(res3)=>{
                                if(res3.isSuccess){
                                    TokenService.setAccessToken(res?.data?.accessToken);
                                    TokenService.setUserEmail(res?.data?.userEmail);
                                    TokenService.setRefreshToken(res?.data?.refreshToken);                
                                    dispatch(isLoginAction({ 
                                        accessToken: res?.data?.accessToken, 
                                        userId: res?.data?.userId, 
                                        userEmail: res?.data?.userEmail
                                    }));
                                    // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
                                    dispatch(isPermissionAction(res3?.data?.membershipLimits));
                                    setIsLoading(false)
                                    navigate('/pricing-plans');
                                }else{
                                    toast.error(res3?.error ? res3?.error?.message : "Something went wrong");   
                                }
                            });               
                             
                } else {
                    toast.error(res?.error ? res?.error?.message : "Email not verified");
                    navigate("/auth/resend-confirm-email");
                    if (res?.error?.message?.includes("Invalid or expired token"));
                }
            })
        }else if(token && location?.pathname?.includes("/forget-password")){
            ApiRequest.verifyResetToken({}, location?.search, '', (res) => {
                setIsLoading(false);
                if (res?.isSuccess) {
                    toast.success(res?.data ? res?.data?.message : "Email verified successfully");
                    navigate(`/auth/login/forget-password?token=${token}`);
                } else {
                    toast.error(res?.error ? res?.error?.message : "Invalid or expired token. Please try again");
                    navigate("/auth/forgot-password");
                }
            })
    
        }else if (location?.pathname?.includes("/login/google/authorized")) {
                    
                    ApiRequest.userPermissionStatus(accessToken, {}, '',(res3)=>{
                        if(res3.isSuccess){
                            TokenService.setAccessToken(accessToken);
                            TokenService.setUserEmail(userEmail);
                            TokenService.setLoginType('google')
                            TokenService.setRemoveUpdatePassword('true')
                            dispatch(isLoginAction({ accessToken, userId, userEmail }));
                            // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
                            dispatch(isPermissionAction(res3?.data?.membershipLimits));
                            if(googleMicrosoftLogin){
                                window.location.href = '/pricing-plans';
                            }else{
                                window.location.href = '/dashboard';
                            }
                        }else{
                            toast.error(res3?.error ? res3?.error?.message : "Something went wrong");   
                        }
                    });               
            
        }else if(location?.pathname?.includes("/login/azure/authorized")){
                    
                    ApiRequest.userPermissionStatus(accessToken, {}, '',(res3)=>{
                        if(res3.isSuccess){
                            TokenService.setAccessToken(accessToken);
                            TokenService.setUserEmail(userEmail);
                            TokenService.setMicorosoftLoginType('microsoft')
                            TokenService.setRemoveUpdatePassword('true')
                            dispatch(isLoginAction({ accessToken, userId, userEmail }));
                            // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
                            dispatch(isPermissionAction(res3?.data?.membershipLimits));
                            if(googleMicrosoftLogin){
                                window.location.href = '/pricing-plans';
                            }else{
                                window.location.href = '/dashboard';
                            }
                    }else{
                        toast.error(res3?.error ? res3?.error?.message : "Something went wrong");   
                    }
                     });               
               
        }else if (location?.pathname?.includes("/login/google/unauthorized")) {
            TokenService.setError(errorMessege)
             TokenService.setErrorType("errors")
            dispatch(isLoginAction({errorMessege}))
            window.location.href = '/auth/login';
            
            
        }else if (location?.pathname?.includes("/login/azure/unauthorized")) {
            TokenService.setError(errorMessege)
             TokenService.setMicrosoftErrorType("microsoftError")
            dispatch(isLoginAction({errorMessege}))
            window.location.href = '/auth/login';
            
            
        }else if(location?.pathname?.includes("/authentication/sign-up")){
            navigate("/authentication/sign-up");
            setIsLoading(false);
        }else if(!localStorage.getItem("authenticationAccessToken")){
            navigate("/auth/login");
            setIsLoading(false);
        }else{
            setIsLoading(false);
        }
    },[]);
   
    if(isLoading){
        return (
            // <Grid item xs={4} md={4} lg={4}>
            //     <MDBox style={{ width: "100%", display: "flex" }}>
            //       {[1, 2].map(() => (
            //         <div style={{ width: "250px", marginRight: "32px" }}>
            //           <Code backgroundColor="grey" foregroundColor="darkgrey" />
            //         </div>
            //       ))}
            //     </MDBox> 
            //     </Grid>
            <div className={`flex flex-row bg-transparent justify-center items-center w-full `}>
                <FaSpinner style={{ marginTop: "50px", fontSize: "40px" }} className={`animate-spin`} />
            </div>
        )
    }else{
        return (
            <div>{children}</div>
        );
    }
   
};

export default RouteWrapper;
