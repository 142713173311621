import moment from "moment";

class CommonMethods {
    validateEmail = (value) => {
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        return pattern.test(value);
    };

    validateLength = (value, desiredLength) => {
        const pattern = new RegExp(`^.{${desiredLength},}$`);
        return pattern.test(value);
    };

    validatePassword = (value) => {
        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/;
        return pattern.test(value);
    };

    classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    noDataAvailable = (str) => {
        return str ? str : 'N/A';
    }

    sort = (arr, key, ascending, callbacks) => {
        if (ascending) callbacks(arr.sort((a, b) => a[key] - b[key]));
        else callbacks(arr.sort((a, b) => a[key] - b[key]).reverse());
    }

    removeUnderscore = (str) => {
        return str ? str.split('_').join(' ') : str;
    };

    getfileName = (str) => {
        return str ? str.split("/").pop() : '';
    };

    getEasternTimeZone = (date, fr) => {
        const format = fr ? fr : "MMMM Do YYYY, h:mm:ss a";
        return date ? moment(date).utc().subtract(5, 'hours').format(format) : '';
    };

    answerBoolean = (value) => {
        return value ? 'Yes' : 'No';
    }

    capitalize = (letter) => {
        return letter ? letter.charAt(0).toUpperCase() + letter.slice(1) : '';
    }

    obscureEmail = (email) => {
        const [name, domain] = email.split('@');
        return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
    };

    getDateByFormat = (date, fr) => {
        return moment(date).format(fr);
    };

    convertUTCtoLocalDate = (date) => {
        const utcDate = new Date(date);

        // Get the user's locale (you can retrieve this from your application)
        const userLocale = navigator.language; // This will use the browser's locale

        // Get the user's time zone (you may need to retrieve this from your application or the user's preferences)
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Convert UTC date to user's local date and time
        const userLocalDate = new Intl.DateTimeFormat(userLocale, {
            timeZone: userTimeZone,
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(utcDate);

        return userLocalDate;
    }

    randomColor = () => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
      };

    downloadCSV = (apiResponse, name) => {
        // Convert the API response to CSV format
        const csvData = apiResponse;
        var blob='';

        console.log("File Name", name)

        // Create a Blob containing the CSV data
        if(name?.split('.')?.[1] === 'parquet'){
            blob = new Blob([csvData], { type: 'application/octet-stream' });
        }else if(name?.split('.')?.[1] === 'xlsx'){
            blob = new Blob([csvData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        }else if (name?.split('.')?.[1] === 'txt') {
            blob = new Blob([csvData], { type: 'text/plain' });
        }else{
            blob = new Blob([csvData], { type: 'text/csv' });
        }

        // Create a link element
        const link = document.createElement('a');

        // Set the link's href attribute to the Blob's URL
        link.href = URL.createObjectURL(blob);

        // Set the download attribute to specify the filename
        link.download = name ?? 'data.csv';

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    }
    downloadAudio = (audioData, name) => {
        // Create a Blob containing the audio data
        const blob = new Blob([audioData], { type: 'audio/mpeg' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's href attribute to the Blob's URL
        link.href = URL.createObjectURL(blob);

        // Set the download attribute to specify the filename
        link.download = name ?? 'audio.mp3';

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    }

}

const Methods = new CommonMethods();

export default Methods



