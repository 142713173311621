import { useMediaQuery } from '@mui/material'
import React from 'react'
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from 'context';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';


const MobileOption = ({ children, title, showNextIcons, showPrevIcons, handleMobileInfoNext, handleMobileInfoPrev, isTabb, isEstimatedSaving, isDarkMode }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile=useMediaQuery('(min-width:318px) and (max-width:390px)')
    console.log(showPrevIcons,"prev")
    return (
        <div className="flex">
            {(isLargeMobile || isSmallMobile) && showPrevIcons && <ArrowLeftOutlinedIcon onClick={handleMobileInfoPrev} />}
            <MDTypography  style={{width: isEstimatedSaving ? "70px" : "", color: isEstimatedSaving ? "white" : (darkMode && isEstimatedSaving) || (darkMode && isDarkMode) ? "white" : "black" }} sx={{ fontSize: isMdLoptop ? "12px" : isTablet ? "11px" 
            : isLargeMobile ? "8px" : isSmallMobile ? "7px" : isEstimatedSaving ? "12px" : "14px", fontWeight: "600", height:isEstimatedSaving ? "" : "50px", paddingLeft : isSmallMobile && showPrevIcons ? "4px": isLargeMobile && !showPrevIcons ? "20px" : !showPrevIcons && isSmallMobile ? "20px" : isTabb ? "12px" : ""}}>
                {title}  {(isLargeMobile || isSmallMobile) && (
                <p style={{ fontSize: "5px", fontWeight: "400" }}>
                    (Navigate left/right for more)
                </p>
            )}
            </MDTypography>
            {(isLargeMobile || isSmallMobile) && showNextIcons  && <ArrowRightOutlinedIcon onClick={handleMobileInfoNext} />}</div>
    )
}

export default MobileOption