import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Progressbar from "examples/Progressbar/Progressbar";
import ApiRequest from "services/ApiRequest";
import { Checkbox } from '@mui/material';
import MDDropdown from "components/MDDropdown";
import Typography from '@mui/material/Typography';
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
// import { Paths } from "libraries/Route";

import { useMaterialUIController } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import Constants from "libraries/Constants";
import { CiCirclePlus } from "react-icons/ci";
import { Tag } from "examples/Tag/Tag";
import Modal from "examples/Modal/Modal";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { Images } from "libraries/Images";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { getColorVlue } from 'services/Utils';
import { useSelector } from "react-redux";


function ChatbotProtect(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();
  const [loader, setLoader] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [detectMsg, setDetectMsg] = useState('');
  const [enableCheck, setEnableCheck] = useState(true);
  const [protectValue, setProtectValue] = useState({})
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, name: "" });
  const stateUser = useSelector((state) => state?.loginReducer?.isSqlTool);
  const [protection, setProtection] = useState({
    onFailForInference: location?.state?.protect?.onFailForInference ?? "exception",
    onFailForTraining: location?.state?.protect?.onFailForTraining ?? "exception",
    detectPII: location?.state?.protect?.detectPII ?? { entities: [], protectFlag: { training: false, inference: true } },
    secrets: location?.state?.protect?.secrets ?? { entities: [], protectFlag: { training: false, inference: true } },
    sensitiveTopics: location?.state?.protect?.sensitiveTopics ?? { entities: [], protectFlag: { training: false, inference: true } },
    promptInjection: location?.state?.protect?.promptInjection ?? { isActive: false, training: false, inference: true },
  });

  const navigation = (route, page, step, objectId, configForm, protect) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        protect: protect,
        isEditAgent: location?.state?.isEditAgent,
        isTrained: page === 4 && route === '/train' ? true : false,
      }
    });
  };

  const nameArr = ['exception', 'fix', 'filter'];
  const nameFlagArr = ['Prompt Only', 'Train Only', 'Train & Prompt Both'];
  const entitiesDetectPII = ['EMAIL_ADDRESS', 'DOMAIN_NAME', 'DATE_TIME', 'PERSON', 'PHONE_NUMBER', 'IP_ADDRESS', 'URL', 'LOCATION'];
  const entitiesSecrets = ['ID', 'API_KEY', 'PASSWORD'];

  const fieldsSensitiveData = [
    { id: 1, type: 'dropdown', placeholder: 'Entities', name: 'entities', arr: entitiesDetectPII, mendatory: true, information: "" },
    // { id: 2, type: 'dropdown', placeholder: 'On Fail', name: 'onFail', arr: nameArr, mendatory: true, information: "" },
    { id: 3, type: 'dropdown', placeholder: 'Apply At', name: 'protectFlag', arr: nameFlagArr, mendatory: true, information: "" },
  ];

  const fieldsSecretData = [
    { id: 1, type: 'dropdown', placeholder: 'Entities', name: 'entities', arr: entitiesSecrets, mendatory: true, information: "" },
    // { id: 2, type: 'dropdown', placeholder: 'On Fail', name: 'onFail', arr: nameArr, mendatory: true, information: "" },
    { id: 3, type: 'dropdown', placeholder: 'Apply At', name: 'protectFlag', arr: nameFlagArr, mendatory: true, information: "" },
  ];

  const fieldsGlobalData = [
    { id: 1, type: 'dropdown', placeholder: 'On Fail Inference', name: 'onFailForInference', arr: nameArr, mendatory: true, information: "" },
    { id: 2, type: 'dropdown', placeholder: 'On Fail Training', name: 'onFailForTraining', arr: nameArr, mendatory: true, information: "" },
  ];

  const divider = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div style={{ background: darkMode ? "white" : "#CCCCCC", height: "2px", width: "100%", borderRadius: "50%", margin: "30px 0px 0px 0px" }}></div>
      </div>
    )
  }

  const postSensitiveTopics = () => {
    if (detectMsg?.length) {
      setProtection({
        ...protection, sensitiveTopics: {
          ...protection?.sensitiveTopics,
          // isActive: (detectMsg?.length && protection?.sensitiveTopics?.onFail?.length) ? true : false,
          entities: [...protection?.sensitiveTopics?.entities, detectMsg]
        }
      });
      setDetectMsg('');
    }
  };

  const saveProtection = () => {
    setLoader(true);

    // if (protection?.detectPII?.entities?.length ) {
    //   toast.error("Please select On Fail for anonymizing sensitive data"); setLoader(false);
    // } 
    // // else if (protection?.secrets?.entities?.length ) {
    // //   toast.error("Please select On Fail for identify secrets"); setLoader(false);
    // // } 
    // else if (protection?.sensitiveTopics?.entities?.length) {
    //   toast.error("Please select On Fail for detect sensitive topics"); setLoader(false);
    // } else {
    var updatedProtection = protection;

    if (!protection?.promptInjection?.isActive) {
      updatedProtection = { ...protection, promptInjection: { isActive: false, training: false, inference: true } }
    }

    ApiRequest.saveGuardRailsChatbotAgent(updatedProtection, location?.state?.objectId, (res) => {
      setLoader(false);
      if (res?.isSuccess) {
        if (!stateUser) {
          navigation('/train', 4, 3, res?.data?.agentId, location?.state?.configForm, res?.data?.updatedValues);
        }
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.error?.message);
      }
    })
    // }

  };
  const allAgentsList = () => {
    ApiRequest.agentList(`/${location.state.objectId}`, '', (res) => {
      if (res.isSuccess) {
         setProtectValue({
          detectPII: res.data?.agentsInformation?.[0]?.detectPII?.entities,
          secrets:res.data?.agentsInformation?.[0]?.secrets?.entities,
          sensitiveTopics: res.data?.agentsInformation?.[0]?.sensitiveTopics?.entities,
          promptInjection: res.data?.agentsInformation?.[0]?.promptInjection?.isActive
          
      })      

      }
    })
  }
 
  useEffect(() => {
    allAgentsList()
   
    navigation('/protect', 3, 2, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
    setEnableCheck(protection.promptInjection);

  }, [protection.promptInjection])

  const reconfigBtn = () => {
    navigation('/tools', 2, 1, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
  };
  const ChatXAgent = () => {
    navigation('/infer/llm',9,9,location.state?.objectId , location?.state?.configForm, protection)
  };

  const Icons = (icon) => {
    return (
      <div style={{ width: "30px", marginRight: "15px" }}>
        <MDBox bgColor={themeColor}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
          <MDBox component="img" textAlign="center"
            src={icon}
            alt="Brand" width="70%" />
        </MDBox>
      </div>
    )
  }
  const flagSections = (i) => {
    return (
      <Tooltip uniqueId={`flagSection${i}`}
        isAlignStart={true}
        width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
        text={<>
          <div style={{ textAlign: "center" }} className="text-lg font-semibold">
            Apply At
          </div>
          <div className="flex mb-1">Info: {'On which part of the X-Agent does this guard apply'}</div>
          <div className="flex mb-1">Train Only: {'This guard will only apply on the training data'}</div>
          <div className="flex mb-1">Prompt Only: {'This guard will only apply on the prompts while doing chat'}</div>
          <div className="flex mb-1">Train & Prompt Both: {'This guard will apply on both the prompts and the training data'}</div>
        </>}
        color={getColorVlue(themeColor)} />
    )
  }

  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
      <div
      // style={{
      //   opacity: stateUser ? "0.5" : "",
      //   pointerEvents: stateUser ? "none" : ""
      // }} 
      >
        <div style={{ display: "flex", alignItems: "center", fontSize: "20px", fontWeight: "500", color: darkMode ? "white" : "black" }}>
          Protect (Optional)
        </div>
        <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.anonymize)}

          Choose personal data to protect
          {/* Protect data privacy by anonymizing sensitive data */}

        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap"
            style={{ gap: "20px", marginTop: "15px" }}>
            {fieldsSensitiveData.map(fi => (
              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex" }}>
                {
                  fi.type === "dropdown" ?

                    <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                      nameArr={fi?.arr}
                      isMultiple={fi?.name === "entities"}
                      // defaultChecked={Array.isArray(protectValue) || []}
                      disabledNames={stateUser ? ['Train & Prompt Both', 'Train Only'] : []}
                      backSelect={(value) => {
                        if (fi?.name === "protectFlag") {
                          setProtection({
                            ...protection, detectPII: {
                              ...protection.detectPII,
                              protectFlag: {
                                training: value === "Train Only" || value === "Train & Prompt Both",
                                inference: value === "Prompt Only" || value === "Train & Prompt Both"
                              }
                            }
                          })
                        } else if (fi?.name === "entities") {
                          setProtection({
                            ...protection, detectPII: {
                              ...protection.detectPII,
                                entities: value
                            }
                          })
                        } else {
                          setProtection({
                            ...protection, detectPII: {
                              ...protection.detectPII,
                              onFail: value
                            }
                          })
                        }
                      }}
                      personName={
                        (()=>{
                          let result=[]
                          if(stateUser && fi?.name === "entities"){
                            result= protectValue?.detectPII ||[]                           
                          }
                          if (fi?.name === "protectFlag" && stateUser) {
                          result = ["Prompt Only"];
                      } else if (fi?.name === "entities") {
                          result = protection?.detectPII?.entities ?? "";
                      } else if (fi?.name === "onFail") {
                          result = [protection?.detectPII?.onFail]?.filter(el => el);
                      } else {
                          result = [
                              (protection?.detectPII?.protectFlag?.training && protection?.detectPII?.protectFlag?.inference) ? "Train & Prompt Both" :
                              (protection?.detectPII?.protectFlag?.training && !protection?.detectPII?.protectFlag?.inference) ? "Train Only" :
                              (!protection?.detectPII?.protectFlag?.training && protection?.detectPII?.protectFlag?.inference) ? "Prompt Only" : ""
                          ].filter(el => el);
                      }
                    return result})()
                      }

                    />
                    :
                    <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                      <Checkbox style={{ paddingLeft: "0px" }}
                        color={themeColor}
                        icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                        checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                        checked={protection?.detectPII?.entities?.includes(fi?.name) }
                        // || protectValue.includes(value)
                        onClick={() => {
                          var updatedEntities = protection?.detectPII?.entities.includes(fi?.name) ?
                            protection?.detectPII?.entities?.filter(fl => fl !== fi?.name)
                            : [...protection?.detectPII?.entities, fi?.name];
                          setProtection({
                            ...protection, detectPII: {
                              ...protection?.detectPII,
                              entities: updatedEntities
                            }
                          })
                        }
                        } />

                      <span style={{ color: darkMode ? "white" : "black" }}>{fi.placeholder}</span>
                    </div>
                }
                {fi.type === "dropdown" && fi?.name === "onFail" &&
                  <Tooltip uniqueId={'OnfailAnonymize'}
                    width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                    text={<>
                      <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                        On Fail
                      </div>
                      <div className="flex mb-1">Exception: {'Raises an error if any sensitive entity is found in the data. This means the process halts and alerts the user about the presence of sensitive information'}</div>
                      <div className="flex mb-1">Fix: {'Replaces the actual value of the sensitive entity with its tag. For example, an email address like abc@intellixcore.com would be replaced with <EMAIL_ADDRESS>. This allows the model to train on the data without exposing sensitive information'}</div>
                      <div className="flex mb-1">Filter: {'Leaves the sensitive entities unchanged in the training data. This is useful when the training data is already anonymized or when the user intends to handle anonymization separately from training'}</div>
                    </>}
                    color={getColorVlue(themeColor)}
                    isAlignStart={true}
                  />
                }
                {fi.type === "dropdown" && fi.name === "entities" &&
                  <Tooltip uniqueId={'Anonymize'}
                    isAlignStart={true}
                    width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                    text={<>
                      <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                        Entities
                      </div>
                      <div className="flex mb-1">Email Address: {'Detects email addresses. For example, abc@intellixcore.com'}</div>
                      <div className="flex mb-1">Domain Name: {'Recognizes domain names, like "example.com"'}</div>
                      <div className="flex mb-1">Date Time: {'Recognizes dates and times'}</div>
                      <div className="flex mb-1">Person: {'Identifies names of people'}</div>
                      <div className="flex mb-1">Phone Number: {'Recognizes phone numbers'}</div>
                      <div className="flex mb-1">IP Address: {'Identifies IP addresses'}</div>
                      <div className="flex mb-1">URL: {'Detects web URLs'}</div>
                      <div className="flex mb-1">Location: {'Identifies locations, such as cities or countries'}</div>

                    </>}
                    color={getColorVlue(themeColor)} />
                }
                {fi.type === "dropdown" && fi?.name === "protectFlag" && flagSections(1)}
              </MDBox>
            ))}
          </div>
        </div>

        {/* Protect Sensitive Data CLOSE */}

        {divider()}

        {/* Protect Secret Data OPEN */}
        <div>
          <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
            {Icons(Images.secret)}
            Choose secrets to protect
          </div>
          <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
            <div className="w-[100%] flex flex-row flex-wrap" style={{ gap: "20px", marginTop: "15px" }}>
              {fieldsSecretData.map(fi => (
                <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex" }}>
                  {
                    fi.type === "dropdown" ?
                      <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                        nameArr={fi?.arr}
                        isMultiple={fi?.name === "entities"}
                        disabledNames={stateUser ? ['Train & Prompt Both', 'Train Only'] : []}
                        backSelect={(value) => {
                          if (fi?.name === "protectFlag") {
                            setProtection({
                              ...protection, secrets: {
                                ...protection.secrets,
                                protectFlag: {
                                  training: value === "Train Only" || value === "Train & Prompt Both",
                                  inference: value === "Prompt Only" || value === "Train & Prompt Both"
                                }
                              }
                            })
                          } else if (fi?.name === "entities") {
                            setProtection({
                              ...protection, secrets: {
                                ...protection.secrets,
                                entities: value
                              }
                            })
                          } else {
                            setProtection({
                              ...protection, secrets: {
                                ...protection?.secrets,
                                onFail: value
                              }
                            })
                          }
                        }
                        }

                        personName={
                          (()=>{
                            let result=[]
                            if(stateUser &&fi?.name === "entities"){
                              result= protectValue?.secrets ||[]
                              
                            }
                            if (fi?.name === "protectFlag" && stateUser) {
                            result = ["Prompt Only"];
                        } else if (fi?.name === "entities") {
                            result = protection?.secrets?.entities ?? "";
                        } else if (fi?.name === "onFail") {
                            result = [protection?.secrets?.onFail]?.filter(el => el);
                        } else {
                            result = [
                                (protection?.secrets?.protectFlag?.training && protection?.secrets?.protectFlag?.inference) ? "Train & Prompt Both" :
                                (protection?.secrets?.protectFlag?.training && !protection?.secrets?.protectFlag?.inference) ? "Train Only" :
                                (!protection?.secrets?.protectFlag?.training && protection?.secrets?.protectFlag?.inference) ? "Prompt Only" : ""
                            ].filter(el => el);
                        }
                      return result})()
                        }
                        

                      />
                      :
                      <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ paddingLeft: "0px" }}
                          color={themeColor}
                          icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                          checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                          checked={protection?.secrets?.entities?.includes(fi?.name)}
                          onClick={() => {
                            var updatedEntities = protection?.secrets?.entities?.includes(fi?.name) ?
                              protection?.secrets.entities?.filter(fl => fl !== fi?.name)
                              : [...protection?.secrets?.entities, fi?.name];
                            setProtection({
                              ...protection, secrets: {
                                ...protection?.secrets,
                                entities: updatedEntities
                              }
                            })
                          }}
                        />
                        <span style={{ color: darkMode ? "white" : "black" }}>{fi.placeholder}</span>
                      </div>
                  }

                  {fi.type === "dropdown" && fi?.name === "entities" &&
                    <Tooltip uniqueId={'Secrets'}
                      isAlignStart={true}
                      width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                      text={<>
                        <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                          Entities
                        </div>
                        <div className="flex mb-1">User ID: {'Recognizes user IDs, typically used for identifying users in systems'}</div>
                        <div className="flex mb-1">API Keys: {'Detects API keys, which are used for accessing services or resources'}</div>
                        <div className="flex mb-1">User Password: {'Identifies user passwords, used for authentication'}</div>
                      </>}
                      color={getColorVlue(themeColor)} />
                  }
                  {fi.type === "dropdown" && fi?.name === "onFail" &&
                    <Tooltip uniqueId={'OnfailSecret'}
                      width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                      text={<>
                        <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                          On Fail
                        </div>
                        <div className="flex mb-1">Exception: {'Raises an error if any sensitive entity is found in the data. his means the process halts and alerts the user about the presence of sensitive information'}</div>
                        <div className="flex mb-1">Fix: {'Replaces the actual value of the sensitive entity with its tag. For example, a user password like "password123" would be replaced with <USER_PASSWORD>. This allows training without revealing sensitive information'}</div>
                        <div className="flex mb-1">Filter: {'Leaves the sensitive entities unchanged in the training data. This option is useful when the training data is already anonymized or when the user intends to handle anonymization separately from training'}</div>
                      </>}
                      isAlignStart={true}
                      color={getColorVlue(themeColor)} />
                  }
                  {fi.type === "dropdown" && fi?.name === "protectFlag" && flagSections(2)}
                </MDBox>
              ))}
            </div>
          </div>
        </div>
        {/* Protect Secret Data CLOSE */}

        {divider()}

        {/* Detect Sensitive Data OPEN */}
        <div>
          <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
            {Icons(Images.sensitiveTopic)}
            Choose sensitive topics to filter
          </div>
          <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
            <div className="w-[100%] flex flex-row flex-wrap" style={{ gap: "20px" }}>
              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex", alignItems: "center" }}>
                <div style={{ width: "210px" }} className=" mt-2 relative">
                  <textarea
                    value={detectMsg}
                    onChange={(e) => setDetectMsg(e?.target?.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) { e.preventDefault(); postSensitiveTopics() }
                    }}
                    className="truncateOneLine"
                    placeholder="Add Keyword e.g Politics"
                    style={{
                      color: darkMode ? "white" : Constants.ccBlue,
                    background: !darkMode ? "white" : "transparent", resize: 'none', paddingRight: "30px",
                    width: '100%', borderRadius: "5px", 
                    borderColor: "lightgray",
                    height: "43px",maxHeight: "150px",
                    }}
                  />
                  <CiCirclePlus onClick={() => postSensitiveTopics()}
                    style={{ color: darkMode ? "white" : Constants.ccBlue }}
                    className={`absolute h-6 w-6 top-2 right-2 cursor-pointer`} />
                </div>
                <Tooltip uniqueId={'Sensitive'}
                  //  isAlignStart={true}
                  width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                  text={<>
                    <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                      Entities
                    </div>
                    <div className="flex mb-1">{'Recognizes sensitive topics, such as politics, hate speech, or religion'}</div>
                  </>}
                  color={getColorVlue(themeColor)} />
              </MDBox>

              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex", alignItems: "center" }}>
                <MDDropdown isSmall={false} dropdownName={"Apply At"}
                  nameArr={nameFlagArr} isMultiple={false}
                  disabledNames={stateUser ? ['Train & Prompt Both', 'Train Only'] : []}
                  backSelect={(value) =>
                    setProtection({
                      ...protection, sensitiveTopics: {
                        ...protection.sensitiveTopics,
                        protectFlag: {
                          training: value === "Train Only" || value === "Train & Prompt Both",
                          inference: value === "Prompt Only" || value === "Train & Prompt Both"
                        }
                      }
                    })
                  }
                  personName={(()=>{
                    let personName=[]
                    
                    if (stateUser) {
                      personName = ["Prompt Only"];
                    } else {
                      if (protection?.sensitiveTopics?.protectFlag?.training && protection?.sensitiveTopics?.protectFlag?.inference) {
                        personName = ["Train & Prompt Both"];
                      } else if (protection?.sensitiveTopics?.protectFlag?.training && !protection?.sensitiveTopics?.protectFlag?.inference) {
                        personName = ["Train Only"];
                      } else if (!protection?.sensitiveTopics?.protectFlag?.training && protection?.sensitiveTopics?.protectFlag?.inference) {
                        personName = ["Prompt Only"];
                      } else {
                        personName = [];
                      }
                    }
                    personName = personName.filter(el => el);   
                    return personName                 
                  })()} />

                {flagSections(3)}
              </MDBox>
            </div>

            {protection?.sensitiveTopics?.entities?.length ? protection?.sensitiveTopics?.entities?.map((afl) => (
              <Tag
                name={afl}
                onClick={(e) => console.log("")}
                isDelete={true}
                onDelete={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, name: afl }) }}
                className="mt-6"
                isLogo={false}
              />
            )) : null}
          </div>
        </div>
        {/* Detect Sensitive Data CLOSE */}

        {divider()}
      </div>

      {/* Prevent prompt injection OPEN */}
      <div>
        <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 15px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.promptInjection)}
          Prevent prompt injection while inferencing
        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap" style={{ gap: "20px" }}>
            <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                <Checkbox style={{ paddingLeft: "0px" }} color={themeColor}
                  icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                  checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                  checked={protection.promptInjection?.isActive}
                  onClick={() =>
                    setProtection({ ...protection, promptInjection: { ...protection?.promptInjection, isActive: !protection.promptInjection?.isActive } })
                  }
                  onChange={(e) => setEnableCheck(e.target.value)}
                />
                <span style={{ color: darkMode ? "white" : "black" }}>{"Enable"}</span>
              </div>

              <Tooltip uniqueId={'PromptInjection'} width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                text={<>
                  <div style={{ textAlign: "center" }} className="text-lg font-semibold">Prompt Injection</div>
                  <div className="flex mb-1">{'Identifies whether a prompt has been injected into the input data at inference time'}</div>
                </>}
                color={getColorVlue(themeColor)} />
            </MDBox>


            <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex", alignItems: "center" }}>
              <MDDropdown isSmall={false} dropdownName={"Apply At"}
                nameArr={nameFlagArr} isMultiple={false}
                backSelect={(value) =>
                  setProtection({
                    ...protection, promptInjection: {
                      ...protection?.promptInjection,
                      training: value === "Train Only" || value === "Train & Prompt Both",
                      inference: value === "Prompt Only" || value === "Train & Prompt Both"
                    }
                  })
                }
                personName={

                  [(protection?.promptInjection?.training && protection?.promptInjection?.inference) ? "Train & Prompt Both"
                    : (protection?.promptInjection?.training && !protection?.promptInjection?.inference) ? "Train Only"
                      : (!protection?.promptInjection?.training && protection?.promptInjection?.inference) ? "Prompt Only" : ""]?.filter(el => el)} />

              {flagSections(3)}
            </MDBox>
          </div>
        </div>
      </div>
      {/* Prevent prompt injection CLOSE */}

      {divider()}

      {/* Global On Fail Dropdowns OPEN */}
      <div
      // style={{ opacity: stateUser ? "0.5" : "",
      //   pointerEvents: stateUser ? "none" : "" }}
      >
        <div style={{
          display: "flex", alignItems: "center", fontSize: "14px",
          fontWeight: "450", margin: "30px 0px 15px 0px", color: darkMode ? "white" : "black"
        }}>
          {Icons(Images.global)}

          On validation failure: Make a choice
          {/* Protect data privacy by anonymizing sensitive data */}

        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap"
            style={{ gap: "20px", marginTop: "15px" }}>
            {fieldsGlobalData.map(fi => (
              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex" }}>
                {fi?.name !== "onFailForTraining" || !stateUser ? (
                  <MDDropdown
                    isSmall={false}
                    dropdownName={fi?.placeholder}
                    nameArr={fi?.arr}
                    isMultiple={false}
                    backSelect={(value) => {
                      if (fi?.name === "onFailForInference") {
                        setProtection({ ...protection, onFailForInference: value })
                      } else {
                        setProtection({ ...protection, onFailForTraining: value })
                      }
                    }}
                    personName={
                      fi?.name === "onFailForInference" ? [protection?.onFailForInference]?.filter(el => el)
                        : fi?.name === "onFailForTraining" ? [protection?.onFailForTraining]?.filter(el => el)
                          : [""]
                    }
                  />
                ) : null}
                {fi.type === "dropdown" && fi?.name === "onFailForInference" && (
                  <Tooltip
                    uniqueId={'onFailForInference'}
                    width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                    text={<>
                      <div style={{ textAlign: "center" }} className="text-lg font-semibold">
                        On Fail
                      </div>
                      <div className="flex mb-1">Exception: {'Raises an error if any sensitive entity is found in the data. This means the process halts and alerts the user about the presence of sensitive information'}</div>
                      <div className="flex mb-1">Fix: {'Replaces the actual value of the sensitive entity with its tag. For example, an email address like abc@intellixcore.com would be replaced with <EMAIL_ADDRESS>. This allows the model to train on the data without exposing sensitive information'}</div>
                      <div className="flex mb-1">Filter: {'Leaves the sensitive entities unchanged in the training data. This is useful when the training data is already anonymized or when the user intends to handle anonymization separately from training'}</div>
                    </>}
                    color={getColorVlue(themeColor)}
                    isAlignStart={true}
                  />
                )}
              </MDBox>
            ))}
          </div>
        </div>
      </div>

      {/* Global On Fail Dropdowns CLOSE */}

      <MDBox mt={4} mb={1} style={{ display: "flex", width: "100%", gap: "20px", justifyContent: "center" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={loader}
          onClick={() => reconfigBtn()} variant="gradient" color={themeColor} fullWidth>
          Previous
        </MDButton>
        {stateUser === true && (
          <MDButton
            style={{ width: "max-content", minWidth: "140px" }}
            disabled={loader}
            onClick={() => {
              saveProtection()
              ChatXAgent();
            }}
            variant="gradient"
            color={themeColor}
            fullWidth
          >
            {loader ? (
              <SpinnerLoader adjustment={true} enhance="text-white text-xs" />
            ) : (
              enableCheck ? "Chatbot" : "Skip"
            )}
          </MDButton>
        )
        }
        {stateUser === false && (
          <>
            <MDButton style={{ width: "max-content", minWidth: "140px" }}
              disabled={loader}
              onClick={() => { saveProtection() }} variant="gradient" color={themeColor} fullWidth>
              {loader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'SAVE & NEXT'}
            </MDButton>
          </>
        )
        }

      </MDBox>

      {modalDelOpen?.flag &&
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, name: "" })}>
          <div style={{ textAlign: "center" }}
            className="flex flex-row w-full justify-center items-center text-xl mb-4" >
            Are you sure you want to delete this topic? Deleted topic will be lost
          </div >

          <div className="mt-6 flex flex-row w-full justify-center items-center  text-xl font-semibold">


            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                onClick={() => setModalDelOpen({ flag: false, name: "" })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                onClick={() => {
                  setProtection({
                    ...protection, sensitiveTopics: {
                      ...protection?.sensitiveTopics,
                      entities: protection?.sensitiveTopics?.entities?.filter(fl => fl !== modalDelOpen?.name)
                    }
                  })
                  // setDetectMsgArr(detectMsgArr?.filter(fl => fl !== modalDelOpen?.name));
                  setModalDelOpen({ flag: false, name: "" })
                }}
                variant="gradient" color={themeColor} fullWidth>
                Yes
              </MDButton>
            </MDBox>
          </div>
        </Modal>
      }
    </div>
  );
};

export default ChatbotProtect;

