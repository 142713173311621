import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ApiRequest from "services/ApiRequest";
import { Checkbox, Grid, OutlinedInput,IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import MDDropdown from "components/MDDropdown";
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import AddBoxIcon from '@mui/icons-material/AddBox';

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
// import { Paths } from "libraries/Route";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useMaterialUIController } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { getColorVlue } from 'services/Utils';
import { validateAndProcessFile, getFileType,validateFileTypes } from "services/Utils";
//actions
import { isSqlToolsAction } from "redux/Action"

//images
import sqlDatabaseIcon from "assets/images/flow-icons/sql-icon.svg"
import MDTypography from "components/MDTypography";
import { Tag } from "examples/Tag/Tag";
import { TabUpload } from "examples/TabUpload/TabUpload";
import Modal from "examples/Modal/Modal";


function ChatbotTool() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();
  const [loader, setLoader] = useState(false);
  const [windowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [sqlLoader, setSqlLoader] = useState(false)
  const [sqlformData, setSqlFormData] = useState({})
  const [modelName, setModelName] = useState("")
  const dispatch = useDispatch()
  const isSqlTools = useSelector((state) => state?.loginReducer?.isSqlTool);
  const isLargeLoptop = useMediaQuery('(min-width: 1300px) and (max-width: 1500px)');
  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1199px)');
  const isloptop = useMediaQuery('(min-width: 1200px) and (max-width: 1295px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
  const [promptQuery, setPromptQuery] = useState([{ promptValue: "", sqlQuery: "" }]);
  const [sslFile, setSslFile] = useState(null)
  const [sslFilename, setSslFilename] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('')
  const [allowFileDeleted, setAllowFileDeleted] = useState(false)
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [databaseType, setDatabaseType] = useState('')
  const [inputValues, setInputValues] = useState({
    userId: "",
    dataBaseName: "",
    password: "",
    dataBasePort: 0,
    dataBaseServerUrl: "",

  });
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, document: {} });
  const defaultPrompt = `You are an agent designed to interact with a SQL database.
Given an input question, create a syntactically correct {dialect} query to run, then look at the results of the query and return the answer.
Unless the user specifies a specific number of examples they wish to obtain, always limit your query to at most {top_k} results.
You can order the results by a relevant column to return the most interesting examples in the database.
Never query for all the columns from a specific table, only ask for the relevant columns given the question.
You have access to tools for interacting with the database.
Only use the given tools. Only use the information returned by the tools to construct your final answer.
You MUST double check your query before executing it. If you get an error while executing a query, rewrite the query and try again.
DO NOT make any DML statements (INSERT, UPDATE, DELETE, DROP etc.) to the database.
If the question does not seem related to the database, just return "I don't know" as the answer.
Here are some examples of user inputs and their corresponding SQL queries`;

  const [promptMessage, setPromptMessage] = useState(defaultPrompt);
  const [showPassword, setShowPassword] = useState(false);
  const navigation = (route, page, step, objectId, configForm, isSqlTool) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location?.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isSqlTool: isSqlTool,
        protect: location.state.protect,
        isEditAgent: location?.state?.isEditAgent,
        isTrained: page === 4 && route === '/train' ? true : false,
      }
    });
  };



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const fields = [
    { id: 1, type: 'dropdown', placeholder: 'Database type', name: 'databaseType', arr: dropdownOptions?.map(option => option?.displayName) || [], mendatory: true, information: "There are two types of database Microsoft SQL Server 2022 and MySQL 8.0" },
    { id: 2, type: 'text', placeholder: 'Database Name', name: "dataBaseName", mendatory: true, information: "Name of the database." },
    { id: 3, type: 'text', placeholder: 'User ID', name: "userId", mendatory: true, information: " User ID which has permissions to connect to the database. NOTE: Please ensure that minimum rights are given to this user, as LLMs can be unstable and may try to execute unexpected or harmful queries. Read only permission are strongly recommended." },
    { id: 4, type: 'password', placeholder: 'Password', name: "password", mendatory: true, information: "Password for the user to connect to the database." },
    { id: 5, type: 'text', placeholder: 'Database Port', name: "dataBasePort", mendatory: true, information: "Port number at which the database runs on." },
    { id: 6, type: 'text', placeholder: 'Database Server URL', name: "dataBaseServerUrl", mendatory: true, information: "URL of the database server." },

  ]

  const fewShotPrompt = promptQuery?.map(query => ({
    input: query?.promptValue,
    query: query?.sqlQuery
  }))

  const sqldatabaseTool = () => {
    const convertToFormData = (data) => {
      const formData = new FormData();
      Object?.keys(data)?.forEach(key => {
        if (data[key] !== null && data[key] !== undefined) {
          formData?.append(key, data[key]);
        }
      });
      return formData;
    };

    const postData = {
      sqlTool: isSqlTools,
      databaseUserID: inputValues?.userId,
      databaseUserPassword: inputValues?.password,
      databaseName: inputValues?.dataBaseName,
      databaseServerURL: inputValues?.dataBaseServerUrl,
      databasePort: inputValues?.dataBasePort,
      sqlDefaultPrompt: promptMessage,
      databaseType: selectedDatabase,
      fewShotExamples: JSON?.stringify(fewShotPrompt),
      sslCertificate: sslFile
    };

    if (!isSqlTools) {
      dispatch(isSqlToolsAction(false));
      navigation('/protect', 3, 2, location?.state?.objectId, location?.state?.configForm, location?.state?.protect);
    } else {
      const emptyFields = fields.filter(field => inputValues[field.name] === "");
      if (emptyFields.length === fields.length) {
        toast.error("Please fill the all required fields");
        return;
      } else if (emptyFields.length > 0) {
        const firstEmptyField = emptyFields[0];
        toast.error(`Please fill in the ${firstEmptyField.placeholder} field`);
        return;
      }
      const undefinedFields = Object?.entries(postData)?.filter(([key, value]) => value === undefined);

      if (undefinedFields.length > 0) {
        const firstUndefinedField = undefinedFields[0];
        toast.error(`Please fill in the ${firstUndefinedField[0]} field`);
        return;
      }
      if (modelName === "gpt-3.5-turbo-instruct") {
        toast.error("gpt-3.5-turbo-instruct is not compatible with the SQL Tool. Please select a different LLM to continue");
        return;
      }
      if (!promptMessage.includes("{dialect}") && !promptMessage.includes("{top_k}")) {
        toast.error("Please do not remove the {dialect} and {top_k} variables from the system prompt");
        return;
      }
      const invalidPromptQuery = promptQuery.some(query =>
        (query.promptValue && !query.sqlQuery) || (!query.promptValue && query.sqlQuery)
      );

      if (invalidPromptQuery) {
        toast.error("Please fill in both Prompt Input Prompt and SQL Query fields or leave both empty.");
        return;
      }

      setSqlLoader(true);

      const formData = convertToFormData(postData);
      if (sslFile) {
        formData.append('sslCertificate', sslFile);
      }

      ApiRequest.sqldatabaseTool(formData, { id: location?.state?.objectId }, (res) => {
        if (res.isSuccess) {
          toast.success(res.data?.message ? res?.data?.message : "Urls fetched successfully");
          setSqlLoader(false);
          dispatch(isSqlToolsAction(true));
          navigation('/protect', 3, 2, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
        } else {
          toast.error(res.error?.message ? res?.error?.message : "Urls fetch failed");
          setSqlLoader(false);
        }
      });
    }

  }

  const setDatabaseName = (res) => {
    const agentdb = res.data?.agentsInformation?.[0];
        const ModelName = res.data?.agentsInformation?.[0]?.configuration?.modelName;
        setModelName(ModelName)
        setSqlFormData({
          userId: agentdb?.databaseUserID,
          dataBaseName: agentdb?.databaseName,
          dataBasePort: agentdb?.databasePort,
          dataBaseServerUrl: agentdb?.databaseServerURL,
        })
        setSslFilename(agentdb?.secretFileName);
        setAllowFileDeleted(agentdb?.sslSecretId)
        const fewShotExamples = agentdb?.fewShotExamples;
        const preloadedPromptQuery = fewShotExamples.map(example => ({
          promptValue: example.input,
          sqlQuery: example.query,
        }));
        setPromptMessage(agentdb?.sqlDefaultPrompt)
        setPromptQuery(preloadedPromptQuery)
        setSelectedDatabase(agentdb?.databaseType)
      }


  const getPreloadedValues = async () => {
     ApiRequest.agentList(`/${location?.state?.objectId}`, '', (res1) => {
      if (res1.isSuccess) {
        
        ApiRequest.getSqlToolDropDownValues("", (res) => {
          if (res.isSuccess) {
            setDropdownOptions(res?.data?.databases?.map(db => ({
              displayName: db?.databaseType?.displayName,
              databaseName: db?.databaseType?.databaseName,
              defaultPort: db?.databaseType?.defaultPort
            })));
            const dropdownvalue = res?.data?.databases?.map(db => ({
              displayName: db?.databaseType?.displayName,
              databaseName: db?.databaseType?.databaseName,
              defaultPort: db?.databaseType?.defaultPort
            }))
            const selectedOption = dropdownvalue?.find(option => option?.databaseName === res1?.data?.agentsInformation?.[0]?.databaseType);
            const displayName = selectedOption ? selectedOption.displayName : '';
            setSelectedDropdownValue(displayName);
          } else {
            toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
          }
        });
        setDatabaseName(res1)

    }
  })
  }
  useEffect(() => {
      getPreloadedValues()
  }, []);

  useEffect(() => {
    if (sqlformData?.userId !== undefined && sqlformData?.userId !== null && sqlformData?.userId !== "" && sqlformData.databasePort !== 0) {
      setInputValues(sqlformData)
      dispatch(isSqlToolsAction(true))
    } else {
      dispatch(isSqlToolsAction(false))
    }
  }, [sqlformData])
  const handleCheckBox = () => {
    if (!isSqlTools && modelName === "gpt-3.5-turbo-instruct") {
      toast.error("gpt-3.5-turbo-instruct is not compatible with the SQL Tool. Please select a different LLM to continue");
      return;
    }
    dispatch(isSqlToolsAction(!isSqlTools))
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e?.target;
    const updatedPromptQueries = [...promptQuery];
    updatedPromptQueries[index][name] = value;
    setPromptQuery(updatedPromptQueries);
  };
  const addUrlInput = () => {
    setPromptQuery([...promptQuery, { promptValue: "", sqlQuery: "" }]);
  };
  const deleteUrlInput = (index) => {
    const updatedPromptQueries = promptQuery?.filter((_, i) => i !== index);
    setPromptQuery(updatedPromptQueries);
  };
  const uploadedFile = (acceptedFiles) => {
    if (sslFile) {
      toast.error('Please delete the existing SSL certificate before uploading a new one.');
      return;
    }
    
    const uploadfile = acceptedFiles?.[0];
    // console.log(uploadfile, "uploadfile");

    const supportedFormats = ['pem']
    if(!validateFileTypes(supportedFormats,uploadfile)){
      toast.error('Unexpected File Type. We only support SSL certificates.');
      return;
    }
    
    setSslFile(uploadfile);
    setSslFilename(uploadfile?.name);
    toast.success(`${uploadfile?.name} uploaded successfully.`);
  };
  

  const deleteDocument = () => {
    if (!allowFileDeleted) {
      setModalDelOpen({ flag: false, document: !sslFile })
      setSslFile(null)
      setSslFilename(null)
      return;
    }
    ApiRequest.sqlDeleteDocumnet(`/${location?.state?.objectId}`, (res) => {
      if (res.isSuccess) {
        setModalDelOpen({ flag: false, document: !sslFile })
        setSslFile(null)
        setSslFilename(null)
        toast.success(res?.data?.message ? res?.data?.message : "Query successful.");
      } else {
        toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
        setModalDelOpen({ flag: false, document: !sslFile })
      }
    });
  };

  const handleDropdownChange = (selectedValue) => {
    setSelectedDropdownValue(selectedValue)
    const selectedDb = dropdownOptions?.find(option => option?.displayName === selectedValue);

    if (selectedDb) {
      setSelectedDatabase(selectedDb?.databaseName);
      setInputValues(prevValues => ({
        ...prevValues,
        dataBasePort: selectedDb?.defaultPort
      }));
    }
  };
  const deleteAllInputs = () => {
    setPromptQuery([]);
  };
  console.log(selectedDropdownValue,"dropdownValue")

  const inputStyled = `${isSmallMobile ? "w-[190px]" : isLargeMobile ? "w-[296px]" : isMdLoptop ? "w-[330px]" : isTablet ? "w-[221px]" : "min-w-[390px]"} 
    rounded-md h-[25px] w-full mt-[20px] text-[#000000] placeholder-gray-300 
    ${darkMode ? 'bg-transparent placeholder:placeholder-gray-300 text-white focus:border-[#ffff]' 
    : 'border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-500'}`;

  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
      <div>
        <div className={`flex items-center text-[20px] font-semibold pl-[20px] ${darkMode ? "text-white" : "text-black"}`}>
          Tools (Optional)
        </div>
        <div className="flex items-center mt-[10px]">
          <div className="flex gap-[10px] items-center ">
            <div className="h-[34px] w-[34px] rounded-full ml-[20px] flex items-center justify-center" style={{ background: getColorVlue(themeColor) }}><img src={sqlDatabaseIcon} alt="" /></div>
            <Typography style={{ color: darkMode ? "white" : "black" }} sx={{ fontWeight: "600", fontSize: "14px" }}>SQL</Typography>

          </div>
          <Tooltip text="This tool can be used to ask questions in natural language from your database.
           Supported databases include: Microsoft SQL Server & MySQL 8.0 " uniqueId="sqlDatabase"
            color={getColorVlue(themeColor)}
          >
          </Tooltip>
        </div>
        <div className="flex items-center gap-[3px] pt-[20px] pl-[63px]">
          <Checkbox style={{ padding: "0px" }} color={themeColor}
            icon={<MdCheckBoxOutlineBlank style={{ color: darkMode ? "white" : "black" }} />}
            checkedIcon={darkMode ? <MdOutlineCheckBox style={{ color: "white" }} /> : <MdCheckBox />}
            checked={isSqlTools}
            onChange={() => handleCheckBox()}
          />
          <span style={{ fontSize: "14px", color: darkMode ? "white" : "black" }}>{"Enable"}</span>
        </div>
        <div className={` min-h-[300px] mt-[30px]`}>

          {isSqlTools && (

            <div className="w-[100%] flex" style={{ paddingLeft: "20px", paddingTop: "20px", gap: "20px", padding: "5px" }}>
              <Grid container className={`flex ${(isLargeMobile || isSmallMobile) ? "gap-[20px]" : isTablet ? "gap-[90px]" : isloptop ? "gap-[134px]" : "gap-[140px]"}`}>
                <Grid item lg={5} md={5} sm={12} xs={12} sx={{ marginTop: "5px" }}>
                  <div className={`w-[100%] flex flex-col flex-wrap ${isTablet ? "gap-[50px]" : "gap-[30px]"}`}>
                    {fields?.map(fi => (
                      <MDBox className="sqlTool" style={{ width: windowSize.innerWidth <= 600 ? "100%" : "", outline: "none", display: "flex", alignItems: "center" }}>
                        {
                          fi.type === "dropdown" ?

                            <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                              nameArr={fi?.arr?.length ? fi?.arr : ["No Option"]}
                              isMultiple={false}
                              isLarge={true}
                              backSelect={(value) => handleDropdownChange(value)}
                            personName={fi?.name === "databaseType" ? [selectedDropdownValue] : []}
                            />
                            :
                            <MDInput
                              id={fi.name}
                              type={fi.name === 'password' && showPassword ? 'text' : fi.type}
                              value={inputValues[fi.name]}
                              onChange={(e) => {
                                setInputValues({ ...inputValues, [fi.name]: e?.target?.value });
                              }}
                              label={fi.placeholder}
                              InputLabelProps={{
                                paddingLeft: "10px !important"
                              }}
                              InputProps={{
                                endAdornment: fi.type === 'password' ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                    style={{marginTop:"-17px"}}
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <Visibility style={{width:"17px",height:"17px",color:darkMode? "white" : ""}}/> : <VisibilityOff style={{width:"17px",height:"17px", color:darkMode? "white" : ""}}/>}
                                    </IconButton>
                                  </InputAdornment>
                                ) : null,
                              }}
                              variant="standard" fullWidth />
                        }

                        {<Tooltip uniqueId={fi.name} text={fi?.information} color={getColorVlue(themeColor)} />}

                      </MDBox>
                    ))}

                    {
                      selectedDropdownValue === 'MySQL 8.0' && (
                        <div>
                          <div className="flex">
                           <MDTypography sx={{ fontSize: "14px", }} style={{ color: darkMode ? "white" : "black" }}>SSL Certificate File</MDTypography>
                           {<Tooltip uniqueId={"SSlCertificate"} text={"SSL pem file for securely connecting to the database. If the file is not provided, the connection to the database will be insecure."} color={getColorVlue(themeColor)} />}
                           </div>
                          <div className={`${isMdLoptop ? "h-[160px]" : isTablet ? "h-[270px]" : ""} border-2 border-dashed flex justify-center flex-col rounded-xl mr-[25px] pb-[25px] mt-[10px]`}>
                            <div>
                              <TabUpload supportedFileTypes = "SSL" isSingleFile={true} uploadedFile={uploadedFile} isSql={true} isWebToolTrain={true} />
                            </div>
                            {sslFilename ? (
                              <>
                                <Tag
                                  key={sslFilename}
                                  name={sslFilename}
                                  onClick={() => console.log("")}
                                  isDelete={true}
                                  onDelete={(e) => {
                                    e.stopPropagation();
                                    setModalDelOpen({ flag: true, document: sslFile });
                                  }}
                                  className="mt-6"
                                  isLogo={true}
                                  isWebToolTrain={true}
                                />
                              </>
                            ) : null}
                          </div>

                        </div>
                      )
                    }
                  </div>
                </Grid>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <MDBox>
                    <div className="flex mt-[10px] pl-[7px]">
                      <MDTypography sx={{ fontSize: "14px", }} style={{ color: darkMode ? "white" : "black" }}>System Prompt</MDTypography>
                      {<Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} />}
                    </div>
                    <OutlinedInput placeholder="Write prompt here" sx={{
                      width: "100%",
                      minHeight: "70px",
                      borderRadius: "10px", '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CCCCCC',
                        borderWidth: '2px',
                      },
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                      resize: 'none',
                      fontSize: '12px',
                      color: darkMode ? "white" : "black",
                      marginTop: "20px"
                    }} multiline
                      value={promptMessage} onChange={(e) => setPromptMessage(e?.target?.value)}
                    />
                    <div className={`pl-[7px] ${isLargeLoptop ? "mt-[30px]" : "mt-[20px]"}`}>
                      <MDTypography style={{ fontSize: "12px", color: darkMode ? "white" : "black" }}>
                        <MDTypography style={{ fontSize: "12px", color: darkMode ? "white" : "black" }}>
                          WARNING!
                        </MDTypography>
                      </MDTypography>
                      <div className={`flex flex-col ${isLargeLoptop ? "gap-[15px]" : "gap-[10px]"}`}>
                        <MDTypography style={{ fontSize: "10px", color: darkMode ? "white" : "black", marginTop: "10px" }}>
                          Please do not remove the <span className="text-red-500">{"{dialect}"}</span> and <span className="text-red-500">{"{top_k}"}</span> variables from the system prompt, if you choose to edit the default system prompt.
                        </MDTypography>
                        <MDTypography style={{ fontSize: "10px", color: darkMode ? "white" : "black" }}>
                          Protect settings will not be applicable on training of the agent with the SQL Tool enabled
                        </MDTypography>
                        <MDTypography style={{ fontSize: "10px", color: darkMode ? "white" : "black" }}>
                          User feedback mechanism will not be applicable with the SQL Tool enabled
                        </MDTypography>
                      </div>
                    </div>
                  </MDBox>
                </Grid>
              </Grid>

            </div>
          )}
        </div>
        {
          isSqlTools && (
            <MDBox sx={{ marginTop: "30px" }}>
              <Grid lg={12}>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <MDTypography sx={{ fontSize: isSmallMobile ? "9px" : "14px", fontWeight: "400" }} style={{ color: darkMode ? "white" : "black" }}>
                      Example Prompt & SQL Query (Optional)
                    </MDTypography>
                    <Tooltip uniqueId={"sqlprompt&query"} text={`A set of sample natural language prompts and equivalent SQL query pairs that can be used to improve the model's responses, directly tailored to your database schema and tables.`} color={getColorVlue(themeColor)} />
                  </div>
                  <div className="flex items-center" style={{marginTop:"-4px"}}>
                    <MDButton size="small" sx={{
                      color: darkMode ? "#ec1313 !important" : "#FF0000!important", fontSize: "10px", padding: "7px !important",
                      display: "flex", gap: "4px", padding: "7px !important", fontSize: "10px !important", backgroundColor: darkMode ? "transparent !important" : "", marginTop:"4px"
                    }}
                      onClick={deleteAllInputs}>Delete All</MDButton>
                    <AddBoxIcon color={themeColor} className=" mt-[2px] pl-[5px] cursor-pointer flex justify-center items-center" fontSize="medium" onClick={() => addUrlInput()} />
                  </div>
                </div>
                {promptQuery?.map((query, index) => (
                  <MDBox key={index} className={`${(isLargeMobile || isSmallMobile) && "flex flex-wrap"}`} sx={{ display: "flex", gap: (isLargeMobile || isSmallMobile) ? "20px" : isMdLoptop ? "138px" : "120px", marginTop: "10px" }}>
                    <Grid item lg={6} md={5}>
                      <input
                        name="promptValue"
                        className={inputStyled}
                        style={{ borderColor: darkMode ? "white" : "#000000",color:darkMode ? "white" : "#000000"  }}
                        value={query?.promptValue || ""}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Input Prompt"
                      />
                    </Grid>
                    <Grid item lg={6} md={5} sx={{ display: 'flex', gap: '20px' }}>
                      <input
                        name="sqlQuery"
                        className={inputStyled}
                        style={{ borderColor: darkMode ? "white" : "#000000", color:darkMode ? "white" : "#000000"  }}
                        value={query?.sqlQuery || ""}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Sql Query"
                      />
                      <Icon
                        sx={{ cursor: "pointer", color: darkMode ? "white !important" : "", marginTop: "20px" }}
                        fontSize="small"
                        onClick={() => deleteUrlInput(index)}
                      >
                        delete
                      </Icon>
                    </Grid>
                  </MDBox>
                ))}
              </Grid>
            </MDBox>

          )
        }
        {modalDelOpen?.flag && (
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: sslFile })}>
            <div style={{ textAlign: "center" }} className="flex flex-row w-full justify-center items-center text-xl font-semibold mb-4">
              Are you sure you want to delete "{sslFilename}"? Deleted document will be lost
            </div>

            <div className="mt-6 flex flex-row w-full justify-center items-center text-xl font-semibold" style={{ gap: "20px" }}>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: !sslFile })} variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={deleteDocument} variant="gradient" color={themeColor} fullWidth>
                Yes
              </MDButton>
            </div>
          </Modal>
        )}



      </div>
      <MDBox mt={4} mb={1} style={{ display: "flex", width: "100%", gap: "20px", justifyContent: "center", flexWrap: isSmallMobile ? "" : "wrap" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={loader}
          onClick={() => reconfigBtn()} variant="gradient" color={themeColor} fullWidth>
          Previous
        </MDButton>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={loader}
          onClick={() => sqldatabaseTool()} variant="gradient" color={themeColor} fullWidth>
          {sqlLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
        </MDButton>
      </MDBox>
    </div>
  );
};

export default ChatbotTool;

