/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Divider from "@mui/material/Divider";
import ApiRequest from "services/ApiRequest";
import { ReactComponent as Free } from "assets/images/billing-icons/Free.svg";
import { ReactComponent as Basic } from "assets/images/billing-icons/Basic.svg";
import { ReactComponent as Premium } from "assets/images/billing-icons/Premium.svg";
import { ReactComponent as Enterprise } from "assets/images/billing-icons/Enterprise.svg";
import { Images } from "libraries/Images";
import { FaPoundSign } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerLoader } from "examples/Loader/Loader";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { RxDotFilled } from "react-icons/rx";
import Methods from "libraries/CommonMethodsUI";
import Modal from "examples/Modal/Modal";



// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useMaterialUIController } from "context";
import ApiUrls from "services/ApiUrls";
import axios from "axios"
import { FaCheck } from "react-icons/fa";
import { getColorVlue } from 'services/Utils';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import ContactSupport from "layouts/authentication/contact-us/contact";
import { TokenService } from "libraries/TokenService";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import useScreenWidth from "libraries/ScreenSizeHook";
import { isLoginAction, isPermissionAction } from 'redux/Action';
import ActivePlan from "assets/images/flow-icons/active-plan.svg";
import Constants from "libraries/Constants";


// =======================  MAIN SCREEN
function BillingPlans() {
  const [controller] = useMaterialUIController();
  const theme = useTheme();
  const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 376px)');
  const isMDMobile = useMediaQuery('(min-width: 400px) and (max-width: 476px)');
  const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const location=useLocation()
  const [membership, setMembership] = useState();
  const [billingLoader, setBillingLoader] = useState(false);
  const permissionUser = useSelector((state) => state?.loginReducer?.permission);
  const [permissionPlan, setPermissionPlan] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);
  const [isDowngraded, setIsDowngraded] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const stateUser = useSelector((state) => state.loginReducer);
  const userEmail = TokenService.getUserEmail('userEmail');
  const [windowSize, getWindowSize] = useScreenWidth();
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, planName: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [clickedPlan, setClickedPlan] = useState(false);
  const dispatch=useDispatch()

  // currentPlanType

  const entrpizeServices = [
    "Premium plan features", "All available models", "All available patterns",
    "Unlimited storage", "Unlimited compute", "Dedicated SAAS option", "On Premise option"
  ]

  useEffect(()=> {
    permissionBilling();
    billingInfo();
    if(location?.state?.pricingPlanUpdate===true){
      setOpenContact(true)
    }
  },[]);

  const billingInfo = () => {
    ApiRequest.billingPlans('', '', (res) => {
      if(res?.data?.plans?.length){
        setMembership(res?.data?.plans?.map(pl => {
          return {
            name: pl?.planType,
            icon: "",
            finalPrice: pl?.finalPrice,
            priceId: pl?.priceId,
            services: [`${pl?.agents} agents`, `${pl?.chatWidgets} chat widgets`,
                      `${pl?.llmFunctions} LLM functions`, `${Methods.formatNumber(pl?.weightedTokens)} tokens`,
                      `${pl?.allowedStorage} GB storage`, `${pl?.websiteURLs} links to train on`,
                      `${pl?.strategicWorkflow ? "Workflow Strategy" : ""}`,
                    ],
            // models: pl?.patterns?.map(ps => ps),
            patterns: pl?.patterns?.map(ps => ps )
          }
        }))
      }
    });
  };
  const permissionBilling = () => {
    ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '',(res3)=>{
      if(res3.isSuccess){
        setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
        setIsCancelled(res3?.data?.membershipLimits?.isMembershipCanceled);
        setIsDowngraded(res3?.data?.membershipLimits?.isMembershipDowngraded);
        // localStorage.setItem("membershipPlan",JSON.stringify({ permission: res3?.data?.membershipLimits}));
        dispatch(isPermissionAction(res3?.data?.membershipLimits));
      }
    });
  };
  const getStarted = (plan, type) => {
    // if(plan?.name === "Free"){
    //   ApiRequest.subscribeBilling({planType: "Free", userEmail: stateUser?.user?.userEmail || userEmail }, '', (res) => {
    //     if(res?.isSuccess){
    //       ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '',(res3)=>{
    //         if(res3.isSuccess){
    //           toast.success(res?.data?.message ? res?.data?.message : "Free Enterprise plan activated");
    //           setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
    //           localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
    //           // dispatch(isPermissionAction(res3?.data?.membershipLimits));
    //         }
    //       });
    //     }else{
    //       toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
    //     }
    //   });
    // }
    // else{
      setBillingLoader(true); setClickedPlan(plan?.name);
      if(type === "Downgrade"){
        ApiRequest.downgradeBilling({priceId: plan?.priceId, planType: plan?.name}, `${stateUser?.user?.userId}`, (res) => {
          setBillingLoader(false); setClickedPlan("");
          if(res?.isSuccess){
            ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '',(res3)=>{
              if(res3.isSuccess){
                toast.success(res?.data?.message ? res?.data?.message : "Free Enterprise plan activated");
                setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
                // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
                dispatch(isPermissionAction(res3?.data?.membershipLimits));
              }else{
                toast.error(res3?.error?.message ? res3?.error?.message : "Something went wrong");
              }
            });
          }else{
            toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
          }
        });
      }else{
        ApiRequest.checkoutBilling({priceId: plan?.priceId, userEmail: stateUser?.user?.userEmail || userEmail }, '', (res) => {
          setBillingLoader(false); setClickedPlan("");
          if(res?.isSuccess){
            // toast.success(res?.data?.message ? res?.data?.message : `${plan?.name} plan activated`);
            window.location.replace(res?.data?.URL);
          }else{
            toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
          }
        });
      }  
    // }
  };

  const deleteModal = () => {
    setDelLoader(true);
    ApiRequest.cancelBilling('', `${stateUser?.user?.userId}`, (res) => {
      setDelLoader(false);
      if(res?.isSuccess){
        ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '',(res3)=>{
          if(res3.isSuccess){
            toast.success(res?.data?.message ? res?.data?.message : "Free Enterprise plan activated");
            setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
            setIsCancelled(res3?.data?.membershipLimits?.isMembershipCanceled);
            setIsDowngraded(res3?.data?.membershipLimits?.isMembershipDowngraded);
            
            // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
            setModalDelOpen({ flag: false, planName: '' });
            dispatch(isPermissionAction(res3?.data?.membershipLimits));
          }else{
            setModalDelOpen({ flag: false, planName: '' });
            toast.error(res3?.error?.message ? res3?.error?.message : "Something went wrong");
          }
        });
      }else{
        setModalDelOpen({ flag: false, planName: '' });
        toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
      }
    });
  }

  console.log("location",location)
  

  return (
    <div  style={{
          backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundPosition: "100% 100%",
          overflowY: "auto",
          height: "100vh"
        }}>

      <DashboardLayout>
        <DashboardNavbar />

        <MDBox style={{ overflowY: "auto", height: "calc(100vh - 130px)" }}>
        <Grid item xs={12} style={{ padding: "0px 5px", display: "flex", width: "100%", justifyContent: "space-between"}}>
          <MDTypography sx={{ color: darkMode ? "white" : getColorVlue(themeColor), 
            fontSize: windowSize.innerWidth <= 700 ? "20px" : "30px", fontWeight: "600" }}>
            Pricing Plans
          </MDTypography>
        <MDButton
                component="a"
                onClick={()=>{navigate("/usage-history")}}
                rel="noreferrer"
                variant="gradient"
                color={themeColor}
                style={{ height: "40px", marginBottom: "10px" }}
            >
              Usage Details
          </MDButton>
        </Grid >
          <Grid py={3}> 
            <div className="flex flex-row w-full flex-wrap justify-center">
              {membership?.length ? membership?.map((pl, indx) => (
                <Card sx={{ margin: "18px 10px", paddingBottom: "80px", 
                    width: windowSize.innerWidth <= 700 ? "100%" :
                    windowSize.innerWidth <= 1250 ? "43%" : "22%", position: "relative", 
                    border:pl?.name === permissionPlan && `2px solid ${getColorVlue(themeColor)}`}}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    px={2}
                    variant="gradient"
                    sx={{ backgroundColor: getColorVlue(themeColor), height: "62px", display: "flex", justifyContent: "center", alignItems: "center",   }}
                    borderRadius="lg"
                    coloredShadow={themeColor}
                  >
                    <div className="flex justify-center items-center gap-[10px]">
                      <MDTypography variant="h6" color="white"
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {indx === 0 && <Free fill={getColorVlue(themeColor)} style={{ width: "25px", height: "30px" }} />}
                        {indx === 1 && <Basic fill={getColorVlue(themeColor)} style={{ width: "25px", height: "30px" }} />}
                        {indx === 2 && <Premium fill={getColorVlue(themeColor)} style={{ width: "25px", height: "30px" }} />}
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "24px", fontWeight: "600" }} style={{ color: "white" }}>{pl?.name}</MDTypography>
                      {pl?.name === permissionPlan && (
                        <div>
                          <FaCircleCheck style={{ fontSize: "21px", color: "white"}} />
                          {/* <img src={ActivePlan} alt="active-plan" className="w-[21px] h-[21px]" /> */}
                        </div>
                      )}
                    </div>
                  </MDBox>

                  <MDBox pt={1} sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <span style={{ color: darkMode && "white", fontSize: "48px", fontWeight: "bold" }}>{pl?.finalPrice}</span>
                    <FaPoundSign style={{ color: darkMode && "white", margin: "0px 0px 23px 5px", fontSize: "16px" }} />
                    <span style={{ fontSize: "16px", color: darkMode ? "white" : "#9D9D9D", marginBottom: "16px" }}>/Month</span>
                  </MDBox>
                  <MDBox pt={1} sx={{ paddingRight: "20px", fontSize: "12px" }}>
                    {pl?.services?.map(sr => (
                      <div>
                        {sr?.length ?
                          <div className="flex flex-row items-center ml-4" >
                            <FaCheck style={{ marginRight: "10px", color: darkMode ? "white" : "black" }} />
                            <span style={{ color: darkMode && "white", fontSize: "12px" }}>{sr}</span>
                          </div>
                          : null}
                      </div>
                    ))}
                    <Divider />

                    <div className="ml-4" style={{ color: darkMode && "white", marginBottom: "10px", fontSize: "20px", fontWeight: 600 }}>
                      Patterns
                    </div>
                    {pl?.patterns?.map((sr, ind) => (
                    <div>
                    <div className="flex flex-row flex-wrap w-full items-center ml-4" >
                       <FaCheck style={{ marginRight:"10px", color: darkMode ? "white" : "black"  }} />
                       <span style={{ color: darkMode && "white", fontSize: "12px" }}>{sr?.patternName}</span>
                       {sr?.patternName !== "LLM" && sr?.availableModels?.length ?
                       <div className="ml-2">
                        <Tooltip uniqueId={`billing-${ind}-${indx}`}
                          color={getColorVlue(themeColor)}
                          isBilling
                          text={<>
                            <div style={{ textAlign: "center", fontSize: "15px" }} className="font-semibold">
                                  {'Available Models'}
                              </div>
                              <div>
                                {sr?.availableModels?.map((am) => (
                                   <div className="flex flex-row w-full items-center">
                                    <RxDotFilled  style={{ marginRight:"5px", color: darkMode && "white" }} />
                                    {am?.displayName}
                                    </div>
                                ))}
                              </div>
                            </>} />
                       </div>
                       : null }
                    </div>
                    {sr?.patternName === "LLM" &&
                    <div style={{ marginLeft: "37px" }}>
                      <div className="flex flex-row flex-wrap w-full items-center">
                      {sr?.availableModels?.length ? sr?.availableModels?.map((am, i) => (
                            <div style={{ color: darkMode && "white",fontSize: "10px"  }}  
                                 className="flex flex-row items-center">
                            {/* <RxDotFilled  style={{ marginRight:"5px"}} /> */}
                            {am?.displayName} 
                            {sr?.availableModels?.length !== (i+1) ? 
                            <span className="mx-1">|</span> : null}
                            </div>
                      )) :  null}
                      </div>
                    </div>
                    }
                  </div>
                ))}
                  </MDBox>

                  {/* Button */}
                  {pl?.name !== "Free" &&
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MDBox pb={2} px={2} style={{ position: "absolute", bottom: "0px" }}>
                      <MDButton
                        component="a"
                        onClick={() => pl?.name === permissionPlan ? setModalDelOpen({flag: true, planName: pl}) : getStarted(pl, Constants.planChange(permissionPlan, pl?.name))}
                        rel="noreferrer"
                        variant="gradient"
                        style={{ pointerEvents: (pl?.name === permissionPlan && isCancelled || pl?.name === permissionPlan && isDowngraded) && "none",
                                width: "140px", color: "white", 
                                backgroundColor: (pl?.name === permissionPlan && isCancelled || pl?.name === permissionPlan && isDowngraded) ? "darkgrey" : getColorVlue(themeColor) }}
                        disabled={(pl?.name === permissionPlan && isCancelled || pl?.name === permissionPlan && isDowngraded)}
                        // color={themeColor}
                        fullWidth
                      >
                        {/* billingLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> :  */}
                        {
                        (pl?.name === permissionPlan && isCancelled) ? "Cancelled" : 
                        (pl?.name === permissionPlan && isDowngraded) ? "Downgraded" : 
                        pl?.name === permissionPlan && !isCancelled ? "Cancel" :  
                        (billingLoader && pl?.name === clickedPlan) ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 
                        Constants.planChange(permissionPlan, pl?.name)}
                      </MDButton>
                    </MDBox>
                  </div>
                  }
                </Card>
              )) : null}


              {/* ================  Static Card */}
              {membership?.length ?
                <Card sx={{ margin: "18px 10px", paddingBottom: "80px", 
                      width: windowSize.innerWidth <= 700 ? "96%" : windowSize.innerWidth <= 1250 ? "43%" : "22%",
                      border:"Enterprise" === permissionPlan && `2px solid ${getColorVlue(themeColor)}`, 
                      position: "relative" }}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    px={2}
                    variant="gradient"
                    sx={{ backgroundColor: getColorVlue(themeColor), height: "62px", display: "flex", justifyContent: "center", alignItems: "center",   }}
                    borderRadius="lg"
                    coloredShadow={themeColor}
                  >
                    <div className="flex justify-center items-center gap-[10px]">  
                    <MDTypography variant="h6" color="white"
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Enterprise fill={getColorVlue(themeColor)} style={{ width: "20px", height: "20px" }} />
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "24px", fontWeight: "600" }} style={{ color: "white" }}>{"Enterprise"}</MDTypography>
                      </div>
                  </MDBox>

                  <MDBox pt={1} sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <span style={{ color: darkMode && "white", fontSize: "48px", fontWeight: "bold" }}>{"TBA"}</span>
                    <FaPoundSign style={{ color: darkMode && "white", margin: "0px 0px 23px 5px", fontSize: "16px" }} />
                    <span style={{ fontSize: "16px", color: darkMode ? "white" : "#9D9D9D", marginBottom: "16px" }}>/Month</span>
                  </MDBox>
                  <MDBox pt={1} sx={{ paddingRight: "20px", fontSize: "12px" }}>
                    {entrpizeServices?.map(sr => (
                      <div>
                        <div className="flex flex-row items-center ml-6" >
                          <FaCheck style={{ marginRight: "10px", color: darkMode ? "white" : "black" }} />
                          <span style={{ color: darkMode && "white", fontSize: "12px" }}>{sr}</span>
                        </div>
                      </div>
                    ))}
                  </MDBox>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MDBox pb={2} px={2} style={{ position: "absolute", bottom: "0px" }}>
                      <MDButton
                        component="a"
                        onClick={() => setOpenContact(true)}
                        rel="noreferrer"
                        variant="gradient"
                        style={{ pointerEvents: (("Enterprise" === permissionPlan && isCancelled) || ("Enterprise" === permissionPlan && isDowngraded)) && "none",
                                width: "140px", color: "white", 
                                backgroundColor: (("Enterprise" === permissionPlan && isCancelled) || ("Enterprise" === permissionPlan && isDowngraded)) ? "darkgrey" : getColorVlue(themeColor) }}
                        disabled={(("Enterprise" === permissionPlan && isCancelled) || ("Enterprise" === permissionPlan && isDowngraded))}
                        // color={themeColor}
                        fullWidth
                      >
                        {
                        "Enterprise" === permissionPlan && isCancelled ? "Cancelled" : 
                        "Enterprise" === permissionPlan && isDowngraded ? "Downgraded" : 
                        "Enterprise" === permissionPlan && !isCancelled ? "Cancel" : 
                        (billingLoader && "Enterprise" === clickedPlan) ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 
                        Constants.planChange(permissionPlan, "Enterprise")}
                      </MDButton>
                    </MDBox>
                  </div>
                </Card>
                : null}
            </div>
          </Grid>

        </MDBox>
      </DashboardLayout>



      {openContact && <ContactSupport flag={openContact} backFlag={(fl) => setOpenContact(fl)} />}

      {modalDelOpen?.flag &&
            <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, planName: '' })}>
              <div className="flex flex-row w-full justify-center text-xl mb-4" >
              Are you sure you want to cancel your current subscription? You can continue using your current plan’s resources until it expires
              </div>

              <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "140px"}} disabled={false} 
                          onClick={() => setModalDelOpen({ flag: false, planName: '' })}
                      variant="gradient" color={"error"} fullWidth>
                        No
            </MDButton>

            <MDButton style={{width: "140px"}} disabled={delLoader} 
              onClick={() => deleteModal()}
              variant="gradient" color={themeColor} fullWidth>
                {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </MDBox>
            </Modal>
          }
    </div>
  );
}

export default BillingPlans;