import { TokenService } from "libraries/TokenService";
import axios from "axios";
import ApiUrls from "./ApiUrls";

class Service {
  async getRequest(url, data) {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      params: data,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async getParamTokenRequest(url, data, token) {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async getRequestWithoutToken(url, data) {
    return axios.get(url, {
      headers: {
        // Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      params: data,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async postRequest(url, body, param, isForm) {
    var formData = new FormData();

    if (isForm) {
      return axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
        data: formData,
        params: param,
        validateStatus: (status) =>
          status === 440
            ? TokenService.clearTokens(true)
            : status >= 200 && status <= 429,
      });
    }

    return axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      params: param,
      validateStatus: (status) => status >= 200 && status <= 429,
    });
  }



  async postRequestImage(url, body, param) {
    var formData = new FormData();

    return axios.post(url, body, {
      headers: {
        // Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      responseType: "arraybuffer",
      data: formData,
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async postRequestStream(url, body, param) {
    var formData = new FormData();

    return axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      responseType: "arraybuffer",
      data: formData,
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  };

  async postRequestStreamWithJSON(url, body, param) {
    var formData = new FormData();

    return axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      responseType: "arraybuffer",
      data: formData,
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  };

  async postRequestStreamWithoutFormData(url, body, param) {
    var formData = new FormData();

    return axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      responseType: "arraybuffer",
      data: formData,
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async postRequestStreamWithoutFormData(url, body, param) {
    var formData = new FormData();

    return axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      responseType: "arraybuffer",
      data: formData,
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async putRequest(url, body, param, isForm) {
    return axios.put(url, body, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      params: param,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async patchRequest(url, id, body) {
    return axios.patch(`${url}${id}/`, body, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }

  async uploadFileToGCP(url, headers, body) {
    return axios.put(url, body, {
      headers: headers,
      validateStatus: (status) =>
        status === 440
          ? TokenService.clearTokens(true)
          : status >= 200 && status <= 429,
    });
  }
  async postBlobTextToSpeech(url, body, param) {
    try {
      // Create FormData object
      var formData = new FormData();

      // Perform the POST request
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
        responseType: 'blob', // Expect the response as a Blob
        params: param,
        data: formData,
        validateStatus: (status) =>
          status === 440
            ? TokenService.clearTokens(true)
            : status >= 200 && status <= 429,
      });

      // If response is OK, return the Blob
      if (response.status === 200 || response.status === 201) {
        return response;
      } else {
        // If there's an error, attempt to convert the Blob into text (error message)
        const errorBlob = response.data;
        const errorText = await errorBlob.text(); // Convert Blob to text
        const errorJson = JSON.parse(errorText);  // Parse text as JSON
        const errorMessage = errorJson.message;   // Extract the message field
        throw new Error(errorMessage);
      }
    } catch (error) {
      throw error; // Forward the error to be handled elsewhere
    }
  }
}

const ApiService = new Service();

export default ApiService;
