import llmIcon from "assets/images/pattren-icon/llm.svg"
import perdictiveIcon from "assets/images/pattren-icon/perdictive.svg"
import personalizationIcon from "assets/images/pattren-icon/personalization.svg"
import recommendation from "assets/images/pattren-icon/recommendation.svg"
import emotionRecognitionIcon from "assets/images/pattren-icon/emotion-recognition.svg"
import naturalLanguageUnderstandingIcon from "assets/images/pattren-icon/natural-language-understanding.svg"
import behavioralAnalyticsIcon from "assets/images/pattren-icon/behavioral-analytics.svg"
import processAutomationIcon from "assets/images/pattren-icon/process-automation.svg"
import featureExtractionLabellingIcon from "assets/images/pattren-icon/feature-extraction-labelling.svg"
import virtualAssistantIcon from "assets/images/pattren-icon/virtual-assistant.svg"
import customerSegmentationIcon from "assets/images/pattren-icon/customer-segmentation.svg"
import anomalyDetectionIcon from "assets/images/pattren-icon/anomaly-detection.svg"
import reinforcementLearningIcon from "assets/images/pattren-icon/reinforcement-learning.svg"
import blockchainIntegrationIcon from "assets/images/pattren-icon/blockchain-integration.svg"
import augmentedRealityIcon from "assets/images/pattren-icon/augmented-reality.svg"
import fraudDetectionIcon from "assets/images/pattren-icon/fraud-detection.svg"
import imageToTextIcon from "assets/images/pattren-icon/image-to-text.svg"
import computerVisionIcon from "assets/images/pattren-icon/computer-vision.png"
import llmDarkModeIcon from "assets/images/pattren-icon/llm-dark.png"
import perdictiveDarkModeIcon from "assets/images/pattren-icon/predictive-dark .png"
import personalizationDarkModeIcon from "assets/images/pattren-icon/personalisation-dark.png"
import recommendationDarkMode from "assets/images/pattren-icon/recommendation-dark.png"
import emotionRecognitionDarkModeIcon from "assets/images/pattren-icon/emotion-recognation-dark.png"
import naturalLanguageUnderstandingDarkModeIcon from "assets/images/pattren-icon/natural-language-understanding-dark.png"
import behavioralAnalyticsDarkModeIcon from "assets/images/pattren-icon/behavioural-analytic-dark.png"
import processAutomationDarkModeIcon from "assets/images/pattren-icon/process-automation-dark.png"
import featureExtractionLabellingDarkModeIcon from "assets/images/pattren-icon/featur-extraction-dark.png"
import virtualAssistantDarkModeIcon from "assets/images/pattren-icon/virtual-assitant-dark.png"
import customerSegmentationDarkModeIcon from "assets/images/pattren-icon/customer-segmentation-dark.png"
import anomalyDetectionDarkModeIcon from "assets/images/pattren-icon/anomoly-detection-dark.png"
import reinforcementLearningDarkModeIcon from "assets/images/pattren-icon/reinforcement-learning-dark.png"
import blockchainIntegrationDarkModeIcon from "assets/images/pattren-icon/blockchain-integration-dark.png"
import augmentedRealityDarkModeIcon from "assets/images/pattren-icon/augmented-reality-dark.png"
import fraudDetectionDarkModeIcon from "assets/images/pattren-icon/fraud-detection-dark.png"
import imageToTextDarkModeIcon from "assets/images/pattren-icon/Image-to-text-dark.png"
import computerVisionDarkModeIcon from "assets/images/pattren-icon/computer-vision-dark.png"
import automatedContentGenerationIcon from "assets/images/pattren-icon/automated-content-generation.png"
import automatedContentGenerationDarkModeIcon from "assets/images/pattren-icon/automated-content-generation-dark.png"


export const patternIcon = {
    '655f34ac4158543391a3e97c': {
        iconPath:llmIcon ,
        iconPathDarkMode:llmDarkModeIcon ,
        routePath:"/patterns/:id/llm"
    },

    '655f38634158543391a3e97e': {
        iconPath: perdictiveIcon,
        iconPathDarkMode:perdictiveDarkModeIcon ,
        routePath:"/patterns/:id/predictive-analysis"
    },

    '65846d65e7131f66b7b70fdc': {
        iconPath: personalizationIcon,
        iconPathDarkMode:personalizationDarkModeIcon ,
    },

    '655f39a04158543391a3e980': {
        iconPath: recommendation,
        iconPathDarkMode:recommendationDarkMode ,
    },

    '658d886f9542b03c86a0fdf8': {
        iconPath: emotionRecognitionIcon,
        iconPathDarkMode:emotionRecognitionDarkModeIcon ,
    },

    '65847450e7131f66b7b70fde': {
        iconPath: naturalLanguageUnderstandingIcon,
        iconPathDarkMode:naturalLanguageUnderstandingDarkModeIcon ,
    },

    '658ca0e904366dc4fc4fa598': {
        iconPath: behavioralAnalyticsIcon,
        iconPathDarkMode:behavioralAnalyticsDarkModeIcon ,
    },

    '658c842f04366dc4fc4fa587': {
        iconPath: processAutomationIcon,
        iconPathDarkMode:processAutomationDarkModeIcon ,
    },

    '658d94089542b03c86a0fe00': {
        iconPath: naturalLanguageUnderstandingIcon,
        iconPathDarkMode:naturalLanguageUnderstandingDarkModeIcon ,
    },
    
    '65796ff4a4d1bccc6fd5e14c': {
        iconPath: featureExtractionLabellingIcon,
        iconPathDarkMode:featureExtractionLabellingDarkModeIcon ,
        routePath:"/patterns/:id/feature-extraction"

    },
    '658c90d204366dc4fc4fa596': {
        iconPath: virtualAssistantIcon,
        iconPathDarkMode:virtualAssistantDarkModeIcon ,
    },
    '658d86349542b03c86a0fdf6': {
        iconPath: automatedContentGenerationIcon,
        iconPathDarkMode:automatedContentGenerationDarkModeIcon ,
    },
    '658c8ed904366dc4fc4fa591': {
        iconPath: customerSegmentationIcon,
        iconPathDarkMode:customerSegmentationDarkModeIcon ,
    },
    '65846e74e7131f66b7b70fdd': {
        iconPath: anomalyDetectionIcon,
        iconPathDarkMode:anomalyDetectionDarkModeIcon ,
    },
    '658ca4fc04366dc4fc4fa59a': {
        iconPath: reinforcementLearningIcon,
        iconPathDarkMode:reinforcementLearningDarkModeIcon ,
    },
    '658d925f9542b03c86a0fdfe': {
        iconPath: blockchainIntegrationIcon,
        iconPathDarkMode:blockchainIntegrationDarkModeIcon ,
    },
    '658d8db99542b03c86a0fdfc': {
        iconPath: augmentedRealityIcon,
        iconPathDarkMode:augmentedRealityDarkModeIcon ,
    },
    '658ca7aa04366dc4fc4fa59c': {
        iconPath: fraudDetectionIcon,
        iconPathDarkMode:fraudDetectionDarkModeIcon ,
    },
    '658c8c0c04366dc4fc4fa590': {
        iconPath: imageToTextIcon,
        iconPathDarkMode:imageToTextDarkModeIcon ,
    },
    '658475dfe7131f66b7b70fe2' :{
        iconPath:computerVisionIcon,
        iconPathDarkMode:computerVisionDarkModeIcon ,
    }
}