/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import {  useMaterialUIController } from "context";

function SimpleStatisticsCard({ color, title, count, percentage, icon, isIcon, patternName, IconHeight, IconWidth }) {
  const [controller] = useMaterialUIController();
  const { darkMode  } = controller;
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pb={1.5} pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={IconWidth}
          height={IconHeight}
          style={{ minWidth: IconWidth, minHeight: IconHeight }}
          mt={-3}
        >
            <img className="white-colored-image" style={{ width: "30px", height: "30px" }} src={icon} />
        
            
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} px={2}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
      {
        percentage &&
        <>
         
          <Divider />
          <MDBox pb={2} px={2}>
            <MDTypography component="p" variant="button" color="text" display="flex">
              <MDTypography
                component="span"
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.amount}
              </MDTypography>
              <div style={{ textAlign: "justify", color: darkMode ? "white" : "black" }}>
                  The <span style={{ fontWeight: "bold" }}>{patternName}</span>
              &nbsp;{percentage.label}
              </div>
            </MDTypography>
          </MDBox>
        </>
      }
    
    </Card>
  );
}

// Setting default values for the props of SimpleStatisticsCard
SimpleStatisticsCard.defaultProps = {
  color: "info",
};

// Typechecking props for the SimpleStatisticsCard
SimpleStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default SimpleStatisticsCard;
