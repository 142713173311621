/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Dashboard components
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScreenWidth from "libraries/ScreenSizeHook";
import { Card, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { useMaterialUIController } from "context";
import { Images } from "libraries/Images";
import { NumericFormat } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';

import MDBox from "components/MDBox";
import Modal from "examples/Modal/Modal";
import MDInput from "components/MDInput";
import MDDropdown from "components/MDDropdown";
import { SpinnerLoader } from "examples/Loader/Loader";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';
import ApiRequest from "services/ApiRequest";
import { useSelector } from "react-redux";

import {
  fields, businessOperations, fteHeadCount, loadedEmploymentCost,
  numberOfEmployee, averageEmployeeCost, numberOfContractors, contractorCost,
  outsourcingContractorType, outsourcingType, fteEquivalentOutsourcing
} from "./workForceConstants"

//images
import { ReactComponent as WorkForceIcon } from "assets/images/flow-icons/workforce-icon.svg"
import MDTypography from "components/MDTypography";
import MobileOption from "./mobileOptions";
import { FaCheck } from "react-icons/fa";

function WorkForceStrategy() {
  const navigate = useNavigate()
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false });
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState({ currentEvaluation: 0, netProfit: 0, peRatio: 0 });
  const [businessImpactInputValue, setBusinessImpactInputValue] = useState("")
  const [windowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [employeesType, setEmployeesType] = useState([])
  const [industryType, setIndustryType] = useState([])
  const [selectedEmployeeType, setSelectedEmployeeType] = useState("")
  const [fteHeadCountValue, setFteHeadCountValue] = useState({})
  const [avgEmployeeCost, setAvgEmployeeCost] = useState({})
  const [employees, setEmployees] = useState({})
  const [employeesAvgCost, setEmployeesAvgCost] = useState({})
  const [numberOfContractor, setNumberOfContractor] = useState({})
  const [contractorsCost, setContractorsCost] = useState({})
  const [numberOfOutSourcing, SetNumberOfOutSourcing] = useState({})
  const [outSourcingContractCost, setOutSourcingContractCost] = useState({})
  const [fteEquivalent, setFteEquivalent] = useState({})
  const [businessOperationValue, setBusinessOperationValue] = useState({})
  const [businessAvgCostValue, setBusinessAvgCostValue] = useState({})
  const [implementationStyleValue, setImplementationStyleValue] = useState("")
  const [SelectedImplementationStyleValue, setSelectedImplementationStyleValue] = useState("")
  const [industryName, setIndustryName] = useState("")
  const [submittedData, setSubmittedData] = useState(false)
  const permissionUser = useSelector((state) => state?.loginReducer?.permission?.strategicWorkflowPermission?.permission === 1);
  // const permissionUser = JSON?.parse(localStorage.getItem("membershipPlan"))?.permission?.strategicWorkflowPermission?.permission === 1
  const [modalPermission, setModalPermission] = useState({ flag: permissionUser })
  const [mobileToggleInfo, setMobileToggleInfo] = useState({ current: 0, pages: 0 })
  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
  const isLargeScreen = useMediaQuery('(min-width: 1301px) and (max-width: 2560px)')
  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1E4585";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };
  const UpgradeFeature = [
    { title: 'Uncover Hidden Savings', description: 'Discover cost-cutting opportunities in crucial areas.' },
    { title: 'See the Impact', description: 'Visualize the transformative effects of strategic changes.' },
    { title: 'Get Actionable Recommendations', description: 'Optimize your workforce with expert advice.' },
    { title: 'Detailed Reports', description: 'Access comprehensive insights and propel your growth to new heights.' },
  ];
  const fieldDropdown = [
    { id: 1, type: 'dropdown', placeholder: 'Choose Industry', name: 'chooseIndustry', arr: industryType?.map(item => item?.industryName), mendatory: true },
    { id: 2, type: 'dropdown', placeholder: 'Select employee type', name: 'selectEmployeeType', arr: employeesType, mendatory: true },
  ]

  const implementationStyleDropdown = [
    { id: 1, type: 'dropdown', placeholder: 'Choose Implementation Style', name: 'chooseImplementationStyle', arr: implementationStyleValue, mendatory: true },
  ]
  console.log(submittedData, "====>submitted")

  const getDropdownValues = () => {

    ApiRequest.workForceStrategyvalues('', (res) => {
      if (res?.isSuccess) {
        setEmployeesType(res?.data?.allDefaultValues?.employeeType)
        setIndustryType(res?.data?.allDefaultValues?.defaultValues)
        setImplementationStyleValue(res?.data?.allDefaultValues?.implementationStyle)


      } else {
        console.log("error")
      }
    });

  }

  const handleMobileInfoPages = employeeType => {

    if (employeeType === "Employees") {
      setMobileToggleInfo({ ...mobileToggleInfo, pages: 2, current: 1 })

    } else if (employeeType === "Contractors") {
      setMobileToggleInfo({ ...mobileToggleInfo, pages: 2, current: 1 })

    } else if (employeeType === "Outsourcing") {
      setMobileToggleInfo({ ...mobileToggleInfo, pages: 3, current: 1 })

    }
  }

  const handleMobileInfoPrev = () => {
    setMobileToggleInfo(prev => ({ ...mobileToggleInfo, current: prev.current - 1 }))
  }
  const handleMobileInfoNext = () => {
    setMobileToggleInfo(prev => ({ ...mobileToggleInfo, current: prev.current + 1 }))
  }
  useEffect(() => {
    getDropdownValues()
    getWorkForceList()


  }, [])
  useEffect(() => {
    updateBusinessOperationValue()
    updateBusinessAvgCostValue()
  }, [employeesAvgCost, fteHeadCountValue, numberOfContractor, fteEquivalent, avgEmployeeCost, contractorsCost, numberOfOutSourcing, outSourcingContractCost])

  const getWorkForceList = () => {

    ApiRequest.getWorkForceStartegyData('', (res) => {
      if (res.isSuccess) {
        if (res.data?.planInformation?.currentValuation) {
          setSubmittedData(true)
        } else {
          setSubmittedData(false)
        }
        const data = res?.data?.planInformation
        setInputValues({
          currentEvaluation: data?.currentValuation,
          netProfit: data?.netProfit,
          peRatio: data?.peRatio,

        })
        setBusinessImpactInputValue(data?.impactPercentage);
        setSelectedEmployeeType(data?.employeeType?.[0]);
        setIndustryName(data?.industryName);
        setSelectedImplementationStyleValue(data?.implementationStyle)

        const fteHeadCountVal = {
          emp1: data.salesFte,
          emp2: data.operationsFte,
          emp3: data.customerServiceFte,
          emp4: data.financeFte,
          emp5: data.hrFte,
          emp6: data.procurementFte,
          emp7: data.itFte,
        };
        setFteHeadCountValue(fteHeadCountVal);

        const avgCostFields = {
          avgemp1: data.salesAvgFte,
          avgemp2: data.operationsAvgFte,
          avgemp3: data.customerServiceAvgFte,
          avgemp4: data.financeAvgFte,
          avgemp5: data.hrAvgFte,
          avgemp6: data.procurementAvgFte,
          avgemp7: data.itAvgFte,
        };

        setAvgEmployeeCost(avgCostFields);

        const employeesFields = {
          employee1: data.salesEmployees,
          employee2: data.operationsEmployees,
          employee3: data.customerServiceEmployees,
          employee4: data.financeEmployees,
          employee5: data.hrEmployees,
          employee6: data.procurementEmployees,
          employee7: data.itEmployees,
        };

        setEmployees(employeesFields)

        const employeesCostFields = {
          employeeAvgCost1: data.salesEmployeesAvg,
          employeeAvgCost2: data.operationsEmployeesAvg,
          employeeAvgCost3: data.customerServiceEmployeesAvg,
          employeeAvgCost4: data.financeEmployeesAvg,
          employeeAvgCost5: data.hrEmployeesAvg,
          employeeAvgCost6: data.procurementEmployeesAvg,
          employeeAvgCost7: data.itEmployeesAvg,
        };

        setEmployeesAvgCost(employeesCostFields)

        const contractorFields = {
          contractor1: data.salesContractors,
          contractor2: data.operationsContractors,
          contractor3: data.customerServiceContractors,
          contractor4: data.financeContractors,
          contractor5: data.hrContractors,
          contractor6: data.procurementContractors,
          contractor7: data.itContractors,
        };
        setNumberOfContractor(contractorFields)
        const contractorCostFields = {
          contractorcost1: data.salesContractorCosts,
          contractorcost2: data.operationsContractorCosts,
          contractorcost3: data.customerServiceContractorCosts,
          contractorcost4: data.financeContractorCosts,
          contractorcost5: data.hrContractorCosts,
          contractorcost6: data.procurementContractorCosts,
          contractorcost7: data.itContractorCosts,
        };

        setContractorsCost(contractorCostFields)

        const fteEquivalentOutsourcingFields = {
          fteEquivalent1: data.salesFteEquivalent,
          fteEquivalent2: data.operationsFteEquivalent,
          fteEquivalent3: data.customerServiceFteEquivalent,
          fteEquivalent4: data.financeFteEquivalent,
          fteEquivalent5: data.hrFteEquivalent,
          fteEquivalent6: data.procurementFteEquivalent,
          fteEquivalent7: data.itFteEquivalent,
        };
        setFteEquivalent(fteEquivalentOutsourcingFields)

        const outsourcingContractorTypeFields = {
          outSourcingcContractor1: data.salesOutsourcingCosts,
          outSourcingcContractor2: data.operationsOutsourcingCosts,
          outSourcingcContractor3: data.customerServiceOutsourcingCosts,
          outSourcingcContractor4: data.financeOutsourcingCosts,
          outSourcingcContractor5: data.hrOutsourcingCosts,
          outSourcingcContractor6: data.procurementOutsourcingCosts,
          outSourcingcContractor7: data.itOutsourcingCosts,
        };
        setOutSourcingContractCost(outsourcingContractorTypeFields)

        const outsourcingTypeFields = {
          outSourcing1: data.salesOutsourcing,
          outSourcing2: data.operationsOutsourcing,
          outSourcing3: data.customerServiceOutsourcing,
          outSourcing4: data.financeOutsourcing,
          outSourcing5: data.hrOutsourcing,
          outSourcing6: data.procurementOutsourcing,
          outSourcing7: data.itOutsourcing,
        };
        SetNumberOfOutSourcing(outsourcingTypeFields)

        handleMobileInfoPages(data?.employeeType?.[0])



      } else {
        setSubmittedData(false)
      }
    })

  }

  const updateBusinessOperationValue = () => {
    const updatedValues = {};
    fteHeadCount?.forEach((item) => {
      const totalValue = (Number(employees[item?.employeeName]) || 0) + (Number(numberOfContractor[item?.contractorName]) || 0) + (Number(fteEquivalent[item?.fteEquivalentName]) || 0)
      updatedValues[item.businessOperationsName] = totalValue;
    });
    setBusinessOperationValue(updatedValues);
  };
  const updateBusinessAvgCostValue = () => {
    const updatedValues = {};
    loadedEmploymentCost?.forEach((item) => {
      const totalValue = Math.round((((Number(employeesAvgCost[item?.employeeAvgName]) || 0) * (Number(employees[item?.employeeName]) || 0)) + ((Number(contractorsCost[item?.contractorCostName]) || 0) * (Number(numberOfContractor[item?.contractorNameValue]) || 0)) + ((((Number(outSourcingContractCost[item?.outSourcingCostName]) || 0) / (Number(fteEquivalent[item?.fteEquivalentName] || 0)) || 1)) * (Number(fteEquivalent[item?.fteEquivalentName]) || 0))) / ((Number(employees[item?.employeeName]) || 0) + (Number(numberOfContractor[item?.contractorNameValue]) || 0) + (Number(fteEquivalent[item?.fteEquivalentName]) || 0) || 0) || 0)

      updatedValues[item.businessEmploymentCostName] = totalValue;

    });
    setBusinessAvgCostValue(updatedValues);
  };

  const postData = {
    currentValuation: inputValues?.currentEvaluation,
    netProfit: inputValues?.netProfit,
    peRatio: inputValues.peRatio,
    industryName: industryName,
    impactPercentage: businessImpactInputValue,
    employeeType: [selectedEmployeeType],
    implementationStyle: SelectedImplementationStyleValue,
    salesFte: businessOperationValue?.salesFte,
    salesAvgFte: businessAvgCostValue?.salesAvgFte,
    operationsFte: businessOperationValue?.operationsFte,
    operationsAvgFte: businessAvgCostValue?.operationsAvgFte,
    customerServiceFte: businessOperationValue?.customerServiceFte,
    customerServiceAvgFte: businessAvgCostValue?.customerServiceAvgFte,
    financeFte: businessOperationValue?.financeFte,
    financeAvgFte: businessAvgCostValue?.financeAvgFte,
    hrFte: businessOperationValue?.hrFte,
    hrAvgFte: businessAvgCostValue?.hrAvgFte,
    procurementFte: businessOperationValue?.procurementFte,
    procurementAvgFte: businessAvgCostValue?.procurementAvgFte,
    itFte: businessOperationValue?.itFte,
    itAvgFte: businessAvgCostValue?.itAvgFte,
    ///////Employeees
    salesEmployees: parseInt(employees?.employee1) || 0,
    salesEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost1) || 0,
    operationsEmployees: parseInt(employees?.employee2) || 0,
    operationsEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost2) || 0,
    customerServiceEmployees: parseInt(employees?.employee3) || 0,
    customerServiceEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost3) || 0,
    financeEmployees: parseInt(employees?.employee4) || 0,
    financeEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost4) || 0,
    hrEmployees: parseInt(employees?.employee5) || 0,
    hrEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost5) || 0,
    procurementEmployees: parseInt(employees?.employee6) || 0,
    procurementEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost6) || 0,
    itEmployees: parseInt(employees?.employee7) || 0,
    itEmployeesAvg: parseInt(employeesAvgCost?.employeeAvgCost7) || 0,
    ////Contractor
    salesContractors: parseInt(numberOfContractor?.contractor1) || 0,
    salesContractorCosts: parseInt(contractorsCost?.contractorcost1) || 0,
    operationsContractors: parseInt(numberOfContractor?.contractor2) || 0,
    operationsContractorCosts: parseInt(contractorsCost?.contractorcost2) || 0,
    customerServiceContractors: parseInt(numberOfContractor?.contractor3) || 0,
    customerServiceContractorCosts: parseInt(contractorsCost?.contractorcost3) || 0,
    financeContractors: parseInt(numberOfContractor?.contractor4) || 0,
    financeContractorCosts: parseInt(contractorsCost?.contractorcost4) || 0,
    hrContractors: parseInt(numberOfContractor?.contractor5) || 0,
    hrContractorCosts: parseInt(contractorsCost?.contractorcost5) || 0,
    procurementContractors: parseInt(numberOfContractor?.contractor6) || 0,
    procurementContractorCosts: parseInt(contractorsCost?.contractorcost6) || 0,
    itContractors: parseInt(numberOfContractor?.contractor7) || 0,
    itContractorCosts: parseInt(contractorsCost?.contractorcost7) || 0,
    //for outSourcing
    salesFteEquivalent: parseInt(fteEquivalent?.fteEquivalent1) || 0,
    salesOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor1 / fteEquivalent?.fteEquivalent1) || 0,
    salesOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor1) || 0,
    operationsFteEquivalent: parseInt(fteEquivalent?.fteEquivalent2) || 0,
    operationsOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor2 / fteEquivalent?.fteEquivalent2) || 0,
    operationsOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor2) || 0,
    customerServiceFteEquivalent: parseInt(fteEquivalent?.fteEquivalent3) || 0,
    customerServiceOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor3 / fteEquivalent?.fteEquivalent3) || 0,
    customerServiceOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor3) || 0,
    financeFteEquivalent: parseInt(fteEquivalent?.fteEquivalent4) || 0,
    financeOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor4 / fteEquivalent?.fteEquivalent4) || 0,
    financeOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor4) || 0,
    hrFteEquivalent: parseInt(fteEquivalent?.fteEquivalent5) || 0,
    hrOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor5 / fteEquivalent?.fteEquivalent5) || 0,
    hrOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor5) || 0,
    procurementFteEquivalent: parseInt(fteEquivalent?.fteEquivalent6) || 0,
    procurementOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor6 / fteEquivalent?.fteEquivalent6) || 0,
    procurementOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor6) || 0,
    itFteEquivalent: parseInt(fteEquivalent?.fteEquivalent7) || 0,
    itOutsourcing: parseFloat(outSourcingContractCost?.outSourcingcContractor7 / fteEquivalent?.fteEquivalent7) || 0,
    itOutsourcingCosts: parseInt(outSourcingContractCost?.outSourcingcContractor7) || 0,


  }

  const postworkforcedata = () => {

    setIsLoading(true)
    ApiRequest.workForceStrategy(postData, (res) => {
      if (res.isSuccess) {
        toast.success(res.data?.message ? res?.data?.message : "Urls fetched successfully");
        setSubmittedData(true)
        setIsLoading(false)
        navigate('/estimated-savings')
      } else {
        toast.error(res.error?.message ? res?.error?.message : "Urls fetch failed")
        setIsLoading(false)
      }
    });
  }

  const updateWorkforceData = () => {
    setIsLoading(true)
    ApiRequest.updateWorkForceStrategy(postData, (res) => {
      if (res.isSuccess) {
        toast.success(res.data?.message ? res?.data?.message : "Urls fetched successfully");
        setIsLoading(false)
        navigate('/estimated-savings')
      } else {
        toast.error(res.error?.message ? res?.error?.message : "Urls fetch failed")
        setIsLoading(false)
      }
    });
  }
  const handleWorkForceStrategyData = () => {

    const emptyFields = [];
    if (!inputValues.currentEvaluation || inputValues.currentEvaluation <= 0) {
      emptyFields.push("Current Evaluation");
    }
    if (!inputValues.netProfit || inputValues.netProfit <= 0) {
      emptyFields.push("Net Profit");
    }
    if (!inputValues.peRatio || inputValues.peRatio <= 0) {
      emptyFields.push("PE Ratio");
    }

    if (emptyFields.length > 0) {
      toast.error(`Please fill the following fields and value greater than 0: ${emptyFields.join(", ")}`);
      return;
    }

    if ((!industryName || industryName.length === 0)) {
      toast.error("Please Select Industry Name");
      return;
    }
    if (!selectedEmployeeType) {
      toast.error("Please Select Employee Type");
      return;
    }
    if (!businessOperationValue?.operationsFte || !businessAvgCostValue?.operationsAvgFte) {
      toast.error("Please enter information for Operations field.")
      return;
    }
    if (!SelectedImplementationStyleValue) {
      toast.error("Please Select implementation Type");
      return;
    }

    if (submittedData === false) {
      postworkforcedata()

    } else {
      updateWorkforceData()
    }

  }

  const clearFormData = () => {
    setInputValues({
      currentEvaluation: "",
      netProfit: "",
      peRatio: ""
    });
    setFteHeadCountValue({})
    setAvgEmployeeCost({})
    setNumberOfContractor({})
    setContractorsCost({})
    SetNumberOfOutSourcing({})
    setOutSourcingContractCost({})
    setFteEquivalent({})
    setEmployees({})
    setEmployeesAvgCost({})
    setBusinessOperationValue({})
    setBusinessAvgCostValue("")
    setSelectedEmployeeType("")
    setIndustryName([])
    setSelectedImplementationStyleValue("")
    setBusinessImpactInputValue("")
  };
  const handleInputChange = (value, field) => {
    setInputValues((prevValues) => ({ ...prevValues, [field]: parseFloat(value.value) }));
  };

  const handleEmployeeInputChange = (value, field) => {
    setEmployees((prevEmployees) => ({ ...prevEmployees, [field]: parseFloat(value.value) }));
  };

  const handleEmployeeAvgCostChange = (value, field) => {
    setEmployeesAvgCost((prevAvgCost) => ({ ...prevAvgCost, [field]: parseFloat(value.value) }));
  };

  const handleFteHeadcountValue = (value, field) => {
    setFteHeadCountValue((prevFteHeadCount) => ({ ...prevFteHeadCount, [field]: parseFloat(value.value) }));
  };

  const handleLoadedEmploymentCostValue = (value, field) => {
    setAvgEmployeeCost((prevAvgEmployeeCost) => ({ ...prevAvgEmployeeCost, [field]: parseFloat(value.value) }));
  };

  const handleContractorValueChange = (value, field) => {
    setNumberOfContractor((prevNumOfContractor) => ({ ...prevNumOfContractor, [field]: parseFloat(value.value) }));
  }
  const handleContractorCostValueChange = (value, field) => {
    setContractorsCost((prevContractorsCost) => ({ ...prevContractorsCost, [field]: parseFloat(value.value) }));
  }
  const handleFteEquivalentValueChange = (value, field) => {
    setFteEquivalent((prevFteEquivalent) => ({ ...prevFteEquivalent, [field]: parseFloat(value.value) }));
  }
  const handleOutSourcingCostValueChange = (value, field) => {
    setOutSourcingContractCost((prevOutSourcingContractCost) => ({ ...prevOutSourcingContractCost, [field]: parseFloat(value.value) }));
  }
  const handleNumberOfOutSourcingChange = (value, field) => {
    SetNumberOfOutSourcing((prevNumberOfOutSourcing) => ({ ...prevNumberOfOutSourcing, [field]: parseFloat(value.value) }));
  }

  const handleBusinessImpactChange = (values) => {
    setBusinessImpactInputValue(values?.floatValue);
  };


  console.log("permissionUser", permissionUser)

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={2} style={{ paddingTop: "30px", overflowY: "auto", height: "calc(100vh - 110px)" }}>
          <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 10px", width: "100%" }}>
            <Card style={{ width: isSmallMobile ? "99%" : isMdLoptop ? "98%" : "96%" }}>
              <MDBox pt={1} pb={3} px={2} style={{ minHeight: "50vh" }}>
                <MDBox className="flex">
                  <div className="flex gap-[20px]">
                    <div className={`h-[58px] w-[57px] rounded-[9px] mt-[-26px] flex justify-center items-center`} style={{ backgroundColor: getBackgroundColor() }}>
                      <WorkForceIcon />
                    </div>
                    <MDTypography sx={{ fontSize: (isLargeMobile || isSmallMobile) ? "12px" : "20px", fontWeight: "600" }}>Workforce Strategy</MDTypography>
                  </div>
                </MDBox>
                <MDBox className={`${isSmallMobile ? "pt-[10px]" : "pt-[30px] px-[25px]"} `}>
                  <div className="flex items-center">
                    <MDTypography style={{ color: darkMode ? "white" : "black" }} sx={{ fontSize: (isLargeMobile || isSmallMobile) ? "12px" : "14px", fontWeight: "600" }}>Financial  Information*</MDTypography>
                    <Tooltip sx={{ marginTop: "1px" }} text="Enter current valuation, net profit and the price-to-earning ratio of your current business process."
                      uniqueId="financialInformation"
                      color={getColorVlue(themeColor)}
                    >
                    </Tooltip>
                  </div>
                  <div className={`flex ${isSmallMobile ? "gap-[19px]" : "gap-[50px]"} pt-[25px]`}>
                    {fields?.map(fi => (
                      <MDBox className="financial-input" style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%", outline: "none", display: "flex", alignItems: "center" }}>
                        <NumericFormat
                          value={inputValues[fi?.name]}
                          thousandSeparator={true}
                          onValueChange={(values) => handleInputChange(values, fi?.name)}
                          customInput={MDInput}
                          inputProps={{
                            style: {
                              fontSize: (isLargeMobile || isSmallMobile) ? "9px" : ""
                            }
                          }}
                          label={fi.placeholder}
                          InputLabelProps={{
                            paddingLeft: "10px !important",
                            fontSize: "12px",
                            style: {
                              fontSize: isLargeMobile || isSmallMobile ? "8px" : ""
                            }
                          }}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    ))}
                  </div>
                  <hr className="mt-[30px] text-[#CCCCCC]" />
                  <div className="flex items-center pt-[25px]">
                    <MDTypography style={{ color: darkMode ? "white" : "black" }} sx={{ fontSize: (isLargeMobile || isSmallMobile) ? "12px" : "14px", fontWeight: "600" }}>Industry*</MDTypography>
                    <Tooltip sx={{ marginTop: "1px" }} text="Choose the industry your business process is based on."
                      uniqueId="industryInfo"
                      color={getColorVlue(themeColor)}
                    >
                    </Tooltip>
                  </div>
                  <div className={`pt-[30px] flex ${isMdLoptop ? "gap-[300px]" : isLargeMobile ? "flex-wrap gap-[30px]" : isSmallMobile ? "flex-wrap gap-[30px]" : "gap-[250px]"}`}>
                    {fieldDropdown?.map(fi => (
                      <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%", outline: "none", display: "flex" }}>
                        {fi?.type === "dropdown" ? (
                          <MDDropdown
                            dropdownName={fi?.placeholder}
                            nameArr={fi?.arr?.length ? fi?.arr : ["No Option"]}
                            isMultiple={false}
                            backSelect={(value) => {
                              if (fi?.name === "chooseIndustry") {
                                const selectedIndustry = value;
                                setBusinessImpactInputValue(industryType.find(type => type?.industryName === value)?.impactPercentage)
                                setIndustryName(selectedIndustry)
                              }
                              if (fi?.name === 'selectEmployeeType') {

                                setSelectedEmployeeType(value)

                              }
                              handleMobileInfoPages(value)
                            }}
                            isExtraLarge={fi?.name === "chooseIndustry"}
                            isLarge={fi?.name === "selectEmployeeType"}
                            isTab={fi?.name === "chooseIndustry"}
                            personName={fi?.name === "selectEmployeeType" ? [selectedEmployeeType] : [industryName]}
                            ismdMobile={fi?.name === "chooseIndustry" || fi?.name === "selectEmployeeType"}

                          />
                        ) : null}
                      </MDBox>

                    ))}
                  </div>

                  <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%", outline: "none", display: "flex", alignItems: "center", paddingTop: "25px" }}>

                    <NumericFormat
                      value={businessImpactInputValue}
                      id={"businessImpact"}
                      type={"text"}
                      thousandSeparator={true}
                      onValueChange={handleBusinessImpactChange}
                      customInput={MDInput}
                      inputProps={{
                        style: {
                          fontSize: (isLargeMobile || isSmallMobile) ? "9px" : ""
                        }
                      }}
                      label={"Business Impact*"}
                      InputLabelProps={{
                        paddingLeft: "10px !important",
                        fontSize: "12px",
                        style: {
                          fontSize: isLargeMobile || isSmallMobile ? "8px" : ""
                        }
                      }}
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <hr className="mt-[15px] text-[#CCCCCC]" />
                  <MDBox className="flex pt-[20px]">
                    <MDBox className={`${isLargeMobile ? "w-[28%]" : "w-[22%]"}`}>
                      <div className="flex">
                        <MDTypography style={{ color: darkMode ? "white" : '#1C3A7C', }} sx={{ fontSize: isMdLoptop ? "12px" : isTablet ? "10px" : isLargeMobile ? "8px" : isSmallMobile ? "7px" : "14px", fontWeight: "600", height: "50px" }}>
                          Business Operations
                        </MDTypography>
                        <Tooltip text="Enter the full-time equivalent headcount of your employees categorized on the basis of basic business operations."
                          uniqueId="businessOperation"
                          color={getColorVlue(themeColor)}
                          isWorkForce={true}
                        >
                        </Tooltip>
                      </div>
                      <div className={`flex flex-col gap-[5px] ${(isLargeMobile || isSmallMobile) && "pt-[20px]"}`}>
                        {businessOperations?.map((item, index) => (
                          <MDTypography style={{ color: darkMode ? "white" : "black" }} key={index} sx={{ fontSize: isSmallMobile ? "6px" : isLargeMobile ? "9px" : isTablet ? "12px" : "14px", fontWeight: isSmallMobile ? "500" : "400", height: "22px", display: "flex", alignItems: "center" }}>
                            {item}
                          </MDTypography>
                        ))}
                      </div>
                    </MDBox>
                    <div className={`w-[18%] flex flex-col ${isMdLoptop ? "pl-[7px]" : ""} ${isLargeMobile ? "w-[26%] pl-[20px]" : ""} ${isSmallMobile ? "w-[27%] pl-[20px]" : ""} `}>
                      <MDTypography style={{ color: darkMode ? "white" : "#1C3A7C" }} sx={{ fontSize: isMdLoptop ? "12px" : isTablet ? "10px" : isLargeMobile ? "8px" : isSmallMobile ? "7px" : "14px", fontWeight: "600", height: "50px", textAlign: isTablet ? "center" : "" }}>
                        Total FTE Headcount
                      </MDTypography>
                      <div className={`flex flex-col gap-[5px] opacity-50 ${(isLargeMobile || isSmallMobile) && "pt-[20px]"}`}>
                        {fteHeadCount?.map((item) => {

                          return (
                            <NumericFormat
                              className="workForce"
                              value={(Number(employees[item?.employeeName]) || 0) + (Number(numberOfContractor[item?.contractorName]) || 0) + (Number(fteEquivalent[item?.fteEquivalentName]) || 0)}
                              id={item?.name}
                              type={item?.type}
                              thousandSeparator={true}
                              onValueChange={(values) => handleFteHeadcountValue(values, item?.name)}
                              customInput={MDInput}
                              disabled
                              sx={{
                                "& fieldset": { border: 'none' },
                              }}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: "center", },

                                }
                              }}

                            />
                          )

                        })}
                      </div>
                    </div>
                    <div className={`${isMdLoptop ? "w-[24%] pl-[10px] " : isTablet ? "w-[21%]" : "w-[19%] "} ${isLargeMobile && "w-[23%]"} ${isSmallMobile && "w-[25%]"} flex flex-col`}>
                      <MDTypography style={{ color: darkMode ? "white" : "#1C3A7C" }} sx={{ fontSize: isMdLoptop ? "12px" : isTablet ? "10px" : isLargeMobile ? "8px" : isSmallMobile ? "7px" : "14px", fontWeight: "600", height: "50px" }}>
                        {(isLargeMobile && isSmallMobile) ? "Weighted average FTE employment cost" : "Weighted average FTE employment cost"}
                      </MDTypography>
                      <div className={`flex flex-col gap-[5px] opacity-50 ${(isLargeMobile || isSmallMobile) && "pt-[20px]"}`} >
                        {loadedEmploymentCost?.map((item) => (
                          <NumericFormat
                            className="workForce"
                            value={Math.round((((Number(employeesAvgCost[item?.employeeAvgName]) || 0) * (Number(employees[item?.employeeName]) || 0)) + ((Number(contractorsCost[item?.contractorCostName]) || 0) * (Number(numberOfContractor[item?.contractorNameValue]) || 0)) + ((((Number(outSourcingContractCost[item?.outSourcingCostName]) || 0) / (Number(fteEquivalent[item?.fteEquivalentName] || 0)) || 1)) * (Number(fteEquivalent[item?.fteEquivalentName]) || 0))) / ((Number(employees[item?.employeeName]) || 0) + (Number(numberOfContractor[item?.contractorNameValue]) || 0) + (Number(fteEquivalent[item?.fteEquivalentName]) || 0) || 0) || 0)}
                            id={item?.name}
                            type={item?.type}
                            thousandSeparator={true}
                            onValueChange={(values) => handleLoadedEmploymentCostValue(values, item?.name)}
                            customInput={MDInput}
                            disabled
                            sx={{
                              "& fieldset": { border: 'none' },
                            }}
                            InputProps={{
                              inputProps: {
                                style: { textAlign: "center", },

                              }
                            }}

                          />

                        ))}
                      </div>
                    </div>
                    {(!isLargeMobile && !isSmallMobile) && (
                      <div className={`${isTablet ? "w-[10px]" : "w-[40px] "} border-l-2`}></div>
                    )}

                    {selectedEmployeeType === "Employees" && (
                      <>
                        {(isLargeMobile && mobileToggleInfo.current == 1 || isSmallMobile && mobileToggleInfo.current == 1 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`w-[18%] flex flex-col ${isMdLoptop ? "pl-[13px]" : ""} ${isLargeMobile && "w-[20%]"} ${isSmallMobile && "w-[21%]"}`}>
                          <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title="Number of Employees" showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages} isDarkMode={true}></MobileOption>
                          <div className={`flex flex-col gap-[5px] ${isLargeMobile && "pl-[20px] pt-[20px]"} ${isSmallMobile && "pl-[18px] pt-[20px]"} `}>
                            {numberOfEmployee?.map((item) => (

                              <NumericFormat
                                className="workForce"
                                value={Number(employees[item?.name]) || 0}
                                id={item?.name}
                                type={item?.type}
                                thousandSeparator={true}
                                onValueChange={(values) => handleEmployeeInputChange(values, item?.name)}
                                customInput={MDInput}
                              />
                            ))}

                          </div>
                        </div>)}
                        {
                          (isLargeMobile && mobileToggleInfo.current == 2 || isSmallMobile && mobileToggleInfo.current == 2 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`${isMdLoptop ? "w-[20%] pl-[16px]" : "w-[18%]"} ${isSmallMobile && "w-[21%]"} flex flex-col`}>
                            <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title="Average Employee Cost*" isDarkMode={true} showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages}></MobileOption>
                            <div className={`flex flex-col gap-[5px] ${(isLargeMobile || isSmallMobile) && "pt-[20px]"} ${!(mobileToggleInfo?.current < mobileToggleInfo?.pages) && "pl-[20px]"} `}>
                              {averageEmployeeCost?.map((item) => (
                                <NumericFormat
                                  className="workForce"
                                  value={Number(employeesAvgCost[item?.name]) || 0}
                                  id={item?.name}
                                  type={item?.type}
                                  thousandSeparator={true}
                                  onValueChange={(values) => handleEmployeeAvgCostChange(values, item?.name)}
                                  customInput={MDInput}
                                />
                              ))}

                            </div>
                          </div>)
                        }
                      </>
                    )}

                    {selectedEmployeeType === "Contractors" && (
                      <>
                        {(isLargeMobile && mobileToggleInfo.current == 1 || isSmallMobile && mobileToggleInfo.current == 1 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`w-[18%] flex flex-col ${isMdLoptop ? "pl-[13px]" : ""} ${isLargeMobile && "w-[20%]"}`}>
                          <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title=" Number of Contractors" showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages} isDarkMode={true}></MobileOption>
                          <div className={`flex flex-col gap-[5px] ${isLargeMobile && "pl-[20px] pt-[20px]"} ${isSmallMobile && "pl-[18px] pt-[20px]"} `}>
                            {numberOfContractors?.map((item) => (
                              <NumericFormat
                                className="workForce"
                                value={Number(numberOfContractor[item?.name]) || 0}
                                id={item?.name}
                                type={item?.type}
                                thousandSeparator={true}
                                onValueChange={(values) => handleContractorValueChange(values, item?.name)}
                                customInput={MDInput}
                              />
                            ))}
                          </div>
                        </div>
                        )}

                        {
                          (isLargeMobile && mobileToggleInfo.current == 2 || isSmallMobile && mobileToggleInfo.current == 2 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`${isMdLoptop ? "w-[20%] pl-[16px]" : "w-[18%]"} flex flex-col`}>
                            <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title="Contractors Cost PA*" showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages} isDarkMode={true}></MobileOption>
                            <div className={`flex flex-col gap-[5px] ${(isLargeMobile || isSmallMobile) && "pt-[20px]"} ${!(mobileToggleInfo?.current < mobileToggleInfo?.pages) && "pl-[20px]"}  `}>
                              {contractorCost?.map((item) => (
                                <NumericFormat
                                  className="workForce"
                                  value={Number(contractorsCost[item?.name]) || 0}
                                  id={item?.name}
                                  type={item?.type}
                                  thousandSeparator={true}
                                  onValueChange={(values) => handleContractorCostValueChange(values, item?.name)}
                                  customInput={MDInput}
                                />
                              ))}

                            </div>
                          </div>
                          )}
                      </>
                    )}
                    {selectedEmployeeType === "Outsourcing" && (
                      <>
                        {(isLargeMobile && mobileToggleInfo.current == 1 || isSmallMobile && mobileToggleInfo.current == 1 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`w-[17%] flex flex-col ${isMdLoptop ? "pl-[13px]" : ""} ${isLargeMobile && "w-[20%]"}`}>
                          <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title=" FTE Equivalent" showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages} isDarkMode={true}></MobileOption>
                          <div className={`flex flex-col gap-[5px] ${isLargeMobile && "pl-[20px]"} ${isSmallMobile && "pl-[18px]"} `}>
                            {fteEquivalentOutsourcing?.map((item) => (
                              <NumericFormat
                                className="workForce"
                                value={fteEquivalent[item?.name] || ""}
                                id={item?.name}
                                type={item?.type}
                                thousandSeparator={true}
                                onValueChange={(values) => handleFteEquivalentValueChange(values, item?.name)}
                                customInput={MDInput}
                              />
                            ))}

                          </div>
                        </div>
                        )}

                        {(isLargeMobile && mobileToggleInfo.current == 2 || isSmallMobile && mobileToggleInfo.current == 2 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`w-[18%] flex flex-col ${isMdLoptop ? "pl-[13px]" : ""} ${isLargeMobile && "w-[20%]"}`}>
                          <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title="Outsourcing Cost PA*" showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={true} isDarkMode={true}></MobileOption>
                          <div className={`flex flex-col gap-[5px] ${isMdLoptop && "pl-[12px]"} ${isLargeMobile ? "pl-[20px] pt-[20px]" : ""} ${isSmallMobile && "pl-[18px] pt-[20px]"}`}>
                            {outsourcingContractorType?.map((item) => (
                              <NumericFormat
                                className="workForce"
                                value={outSourcingContractCost[item?.name] || ""}
                                id={item?.name}
                                type={item?.type}
                                thousandSeparator={true}
                                onValueChange={(values) => handleOutSourcingCostValueChange(values, item?.name)}
                                customInput={MDInput}
                              />
                            ))}

                          </div>
                        </div>
                        )}

                        {(isLargeMobile && mobileToggleInfo.current == 3 || isSmallMobile && mobileToggleInfo.current == 3 || (isTablet || isMdLoptop || isLargeScreen)) && (<div className={`w-[18%] flex flex-col ${isMdLoptop ? "pl-[13px]" : ""} ${isLargeMobile && "w-[20%]"} ${isSmallMobile && "w-[24%]"}`}>
                          <MobileOption handleMobileInfoPrev={handleMobileInfoPrev} handleMobileInfoNext={handleMobileInfoNext} title={`${isLargeMobile || isSmallMobile ? "Outsourcing FTE" : "Outsourcing"}`} showNextIcons={mobileToggleInfo?.current < mobileToggleInfo?.pages} showPrevIcons={mobileToggleInfo?.current >= mobileToggleInfo?.pages} isTabb={true} isDarkMode={true}></MobileOption>

                          <div className={`flex flex-col gap-[5px] opacity-50  ${isLargeMobile ? "pl-[20px] pt-[20px]" : ""} ${isSmallMobile && "pl-[15px] pt-[20px]"}`}>
                            {outsourcingType?.map((item) => (
                              <NumericFormat
                                className="workForce"
                                value={((Number(outSourcingContractCost[item?.outsourcingCostName]) || 0) / (Number(fteEquivalent[item?.fteEquivalentOutsourceName] || 0)) || 1)}
                                id={item?.name}
                                type={item?.type}
                                thousandSeparator={true}
                                onValueChange={(values) => handleNumberOfOutSourcingChange(values, item?.name)}
                                customInput={MDInput}
                                disabled
                                sx={{
                                  "& fieldset": { border: 'none' },
                                }}
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "center" },
                                  }
                                }}
                              />
                            ))}

                          </div>
                        </div>
                        )}
                      </>
                    )}

                  </MDBox>
                  <hr className="mt-[20px] text-[#CCCCCC]" />
                  <MDBox>
                    <div className="flex pt-[15px]">
                      <MDTypography style={{ color: darkMode ? "white" : "black" }} sx={{ fontSize: (isLargeMobile || isSmallMobile) ? "12px" : "14px", fontWeight: "600" }}>Implementation Style*</MDTypography>
                      <Tooltip sx={{ marginTop: "1px" }} text="Choose one out of the three implementation styles to measure an estimate of the savings on that basis."
                        uniqueId="implementationStyle"
                        color={getColorVlue(themeColor)}
                      >
                      </Tooltip>
                    </div>
                    <div className={`flex justify-between ${isLargeMobile ? "flex-wrap" : isMdLoptop ? "flex-wrap" : isSmallMobile ? "flex-wrap" : ""}`}>
                      <div className="pt-[30px]">
                        {implementationStyleDropdown?.map(fi => (
                          <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%", outline: "none", display: "flex" }}>
                            {fi?.type === "dropdown" ? (
                              <MDDropdown
                                dropdownName={fi?.placeholder}
                                nameArr={fi?.arr || ["No Option"]}
                                isMultiple={false}
                                backSelect={(value) => {
                                  if (fi?.name === 'chooseImplementationStyle') {
                                    setSelectedImplementationStyleValue(value)
                                  }
                                }}
                                isLarge={true}
                                personName={fi?.name === "chooseImplementationStyle" ? [SelectedImplementationStyleValue] : ""}
                                ismdMobile={true}
                              />
                            ) : null}
                          </MDBox>
                        ))}

                      </div>
                      <div className={`pt-[30px] flex ${isSmallMobile ? "gap-[10px]" : "gap-[30px]"} `}>
                        <MDButton style={{ width: "max-content", minWidth: "125px", fontsize: (isLargeMobile || isSmallMobile) ? "10px" : "13px" }} variant="gradient" color={themeColor} fullWidth onClick={() => setModalDelOpen({ flag: true })}>
                          Clear Form
                        </MDButton>
                        <MDButton onClick={() => {
                          handleWorkForceStrategyData()

                        }} style={{ width: "max-content", minWidth: "125px", fontSize: (isLargeMobile || isSmallMobile) ? "10px" : "13px" }} variant="gradient" color={themeColor} fullWidth>
                          {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Submit'}
                        </MDButton>
                      </div>

                      {modalDelOpen?.flag &&
                        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl })}>
                          <div style={{ textAlign: "center" }}
                            className="flex flex-row w-full justify-center items-center text-xl mb-4" >
                            Are you sure you want to delete all data
                          </div >

                          <div className="mt-6 flex flex-row w-full justify-center items-center  text-xl font-semibold">


                            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                                onClick={() => setModalDelOpen({ flag: false })}
                                variant="gradient" color={"error"} fullWidth>
                                No
                              </MDButton>

                              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                                onClick={() => {
                                  clearFormData()
                                  setModalDelOpen({ flag: false })
                                  getDropdownValues()
                                }}
                                variant="gradient" color={themeColor} fullWidth>
                                Yes
                              </MDButton>
                            </MDBox>
                          </div>
                        </Modal>
                      }
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </div>
        </Grid>


        {modalPermission?.flag &&
          <Modal open={modalPermission?.flag} setOpen={(fl) => setModalPermission({ flag: true })}>
            <MDBox className = {` ${isSmallMobile ? "pb-[15px]" : "px-[15px] pb-[15px]"}`}>
              <div className="flex justify-end">
                <CloseIcon fontSize="medium" className="cursor-pointer" style={{color: darkMode ? "white" : "#000000" }} onClick={() => navigate('/dashboard')} />
              </div>
              <div className="flex flex-row w-full justify-between items-center text-xl font-semibold mt-5 mb-4" >
                <div className="flex gap-[10px] items-center">
                  <div className={`${isSmallMobile ? "h-[58px] w-[97px]" :isLargeMobile ? "h-[58px] w-[90px]": "h-[58px] w-[57px]"} rounded-[9px] mt-[-26px] flex justify-center items-center`} style={{ backgroundColor: getBackgroundColor() }}>
                    <WorkForceIcon />
                  </div>
                  <MDTypography style={{ fontSize:(isSmallMobile || isLargeMobile) ? "13px": "24px", fontWeight: "600", color: darkMode ? "white" : "#000000" }} >
                    Upgrade to access Business Planning (PREMIUIM)
                  </MDTypography>
                </div>
                <MDButton style={{ width: "max-content", minWidth: windowSize.innerWidth <= 600 ? "100px" : "140px" }}
                  disabled={false} onClick={() => navigate('/pricing-plans')} variant="gradient"
                  color={themeColor} fullWidth>
                  Upgrade
                </MDButton>
              </div>
              <MDTypography className="my-4" style={{ marginTop: "30px", color: darkMode ? "white" : "#000000", fontSize: "16px", fontWeight: "700" }}>
                Unlock Your full potential with our upgraded features:
              </MDTypography>

              <div className="mt-[20px]">
                {UpgradeFeature.map((item, index) => (
                  <div key={index} className="flex flex-row w-full items-center mt-2 gap-[10px]" style={{ fontSize: "14px", color: darkMode ? "white" : "black" }}>
                    <FaCheck style={{ color: "green", height: "14px", width: "14px" }} />
                    <MDTypography style={{ fontSize: "16px", fontWeight: "400", color: darkMode ? "white" : "#1E4081" }}>
                      <span style={{ fontSize: "16px", fontWeight: 700, color: darkMode ? "white" : "#1E4081" }}>{item.title}:</span> {item.description}
                    </MDTypography>
                  </div>
                ))}
              </div>
              <div className="mt-4" style={{ marginTop: "20px", color: darkMode ? "white" : "#000000", fontSize: "16px", fontWeight: "700" }}>
                Upgrade now and take the first step towards unparalleled success!
              </div>

            </MDBox>
          </Modal>
        }
      </DashboardLayout>
    </div>

  );
};

export default WorkForceStrategy;