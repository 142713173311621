/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React,{ useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import { Images } from "libraries/Images";

import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {useMaterialUIController} from "context";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { SpinnerLoader } from "examples/Loader/Loader";

import ApiUrls from "services/ApiUrls";
import axios from "axios"
import { FaCheck } from "react-icons/fa";
import { getColorVlue } from 'services/Utils';


function ContactUs() {
    const [controller] = useMaterialUIController();
    const theme = useTheme();
    const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 376px)');
    const isMDMobile = useMediaQuery('(min-width: 400px) and (max-width: 476px)');
    const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
    const {  themeColor, darkMode } = controller;
    const navigate = useNavigate();
    const [isContactLoading, setIsContactLoading] = useState(false);

    const [contactCredentials, setContactCredentials] = useState(
      { name: '', email: '', phoneNumber: '', description: '' });

    const fields = [
      { id: 1, type: 'text', placeholder: 'Full Name*', name: 'name' },
      { id: 2, type: 'text', placeholder: 'Email*', name: 'email' },
      { id: 3, type: 'text', placeholder: 'Mobile/Phone No.', name: 'phoneNumber' },
    ];
    

    const contact = () => {
        const { name, email, phoneNumber, description } = contactCredentials;
        const data = { name, email, phoneNumber, description};
        if(!name ){
          toast.error("Please enter your full name")
        }
        else if(!email ){
          toast.error("Please enter your email address")
        }
        else {
            setIsContactLoading(true);
            ApiRequest.contactUs(data, '', (res) => {
              toast.success(res?.data?.message ? res?.data?.message : "Email sent successfully");
                console.log("Res Contact",res)

                if(res?.isSuccess){
                  ApiRequest.subscribeBilling({planType: "Free", userEmail: contactCredentials?.email}, '', (res) => {
                    toast.success(res?.data?.message ? res?.data?.message : "Free Enterprise plan activated");
                    console.log("Res Subscribe",res)
                  });
                }else{
                  toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
                  setIsContactLoading(false);
                }

            })
        };
    };

    console.log("contactCredentials",contactCredentials)

 
  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
<DashboardLayout>
      <DashboardNavbar />
      
      <Grid lg={12} md={6} xs={12}className="flex justify-center w-full mt-8">
      <Card>
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow={themeColor}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          
        >
        {/* <MDTypography variant="h4" fontWeight="medium" color="white" display="flex" justifyContent="center">
          Contact Us
        </MDTypography> */}

       </MDBox>

       {/* Static Card */}

          {/* Input Fields */}

          <MDBox pt={3} pb={3} px={3}>
          <MDBox component="form" role="form">

            {fields?.map(fl => (
              <MDBox mb={2}>
              <MDInput  variant="standard" type={fl?.type} 
                        value={contactCredentials[fl.name]} 
                        autoComplete="off" 
                        onChange={(e) => {
                          setContactCredentials({
                            ...contactCredentials,
                            [fl.name]: e.target.value,
                          });
                        }}
                        label={fl?.placeholder} fullWidth />
            </MDBox>
            ))}

            <MDBox sx={{ marginTop: "20px" }}>
              <textarea
                    // ref={textareaRef}
                    value={contactCredentials['description']}
                    // disabled={loader}
                    placeholder="Tell us more about your requirement"
                    onChange={(e) => {
                      setContactCredentials({
                        ...contactCredentials,
                        description: e.target.value,
                      });
                    }}
                    style={{
                      color: darkMode ? "white" : "black",
                      borderColor: darkMode ? "white" : "lightgrey",
                      background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight: "30px",
                      width: '100%', borderRadius: "10px",
                      height: "200px",maxHeight: "200px",
                    }}
                  />
           </MDBox>
           </MDBox>       
          </MDBox>

           {/* Buttons */}

            <div>
            <MDBox sx={{ display: "flex", justifyContent: "center" }} mb={1}>
            <MDButton mr={4} onClick={() => navigate("/pricing-plans")} disabled={false} variant="gradient" 
                      color={themeColor} sx={{width: "120px", marginRight: "20px" }}>
                {"cancel"}
              </MDButton>
              <MDButton onClick={() => contact()} disabled={false} variant="gradient" 
                        color={themeColor} sx={{width: "120px" }}>
                {isContactLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "submit"}
              </MDButton>
            </MDBox>
            </div> 
       </Card>
       </Grid>

       </DashboardLayout>
       
       </div>
  );
}

export default ContactUs;

