/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import Modal from "examples/Modal/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {useMaterialUIController} from "context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';
import useScreenWidth from "libraries/ScreenSizeHook";

import { useDispatch, useSelector } from 'react-redux';


const ChangePassword = ({flag, backFlag}) => {

  const [passCredential, setPassCredential] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const {currentPassword, newPassword, confirmPassword} = passCredential;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [windowSize, getWindowSize] = useScreenWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const stateUser = useSelector((state) => state.loginReducer);

  const saveChangePassword = () => {
    if(!newPassword || !confirmPassword || !currentPassword){
      toast.error("Please fill all required fields")
    }
    else if(newPassword !== confirmPassword){
      toast.error("New Password and Confirm New Password do not match");
    }else{
      ApiRequest.updateNewPassword({currentPassword, newPassword }, stateUser?.user?.userId , '', (res) => {
        const { isSuccess, data, error } = res;
  
        if (isSuccess) {
            toast.success(data.message);
            backFlag(false);
        } else {
            toast.error(error.message);
        }
      });
    }
    
  }

  const hideShowEye = (pass, count) => {
    return (
      <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=> {
                if(count === 1) setShowPassword((show) => !show)
                if(count === 2) setShowPassword2((show) => !show)
                if(count === 3) setShowPassword3((show) => !show)
              }}
              style={{ color: darkMode ? "white" : "black" }}
              onMouseDown={(e)=> e.preventDefault()}
              edge="end"
            >
              {pass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
    )
  }

  const fields = [
    {
      placeholder: "Current Password", name: "currentPassword",
      isActive: true,
      val: currentPassword, type: showPassword ? 'text' : 'password', isDropdown: false,
      information: "Unique name for the model.",
      mendatory: true,
      endorse: { endAdornment: hideShowEye(showPassword, 1) }
    },
    {
      placeholder: "New Password", name: "newPassword",
      isActive: true,
      val: newPassword, type: showPassword2 ? 'text' : 'password', isDropdown: false,
      information: "Unique name for the model.",
      mendatory: true,
      endorse: { endAdornment: hideShowEye(showPassword2, 2) }
    },
    {
      placeholder: "Confirm New Password", name: "confirmPassword",
      isActive: true,
      val: confirmPassword, type: showPassword3 ? 'text' : 'password', isDropdown: false,
      information: "Unique name for the model.",
      mendatory: true,
      endorse: {endAdornment: hideShowEye(showPassword3, 3)}
    },
  ];

  return (
      <Modal open={flag} setOpen={(fl) => backFlag(fl)}>
            <div>
            <div className="flex flex-row w-full justify-between items-centertext-xl font-semibold mb-4" >
              <div> Change Password </div>

              <MDButton style={{width: "max-content", minWidth: "140px"}}
                disabled={false} onClick={()=> saveChangePassword()} variant="gradient" 
                      color={themeColor} fullWidth>
                  Save
              </MDButton>
             
            </div>

            <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "30px 0px"}}>
        	{fields?.filter((fl) => fl?.isActive).map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "45%", outline: "none",display: "flex", alignItems: "center" }}>
					<MDInput   
                // style={{outline: "none", border: "none"}}
                id={fi.name} 
                type={fi.type}
                value={passCredential[fi.name]} 
                onChange={(e) => {
                      setPassCredential({ ...passCredential, [fi.name]: e.target.value });
                }}
                InputProps={fi?.endorse}
                autoComplete="off"
                label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                variant="standard" fullWidth />
					
          {/* {
            fi?.information &&
           <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>
          } */}
					</MDBox>
            ))}
            </div>
          </div>
      </Modal>    
  );
}

export default ChangePassword;
