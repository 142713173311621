import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IoMdSend } from "react-icons/io";
import { useSelector } from "react-redux"
import { SpinnerLoader } from "examples/Loader/Loader";

import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";
import ApiUrls from "services/ApiUrls";
import { useMaterialUIController } from "context";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Import a syntax highlighting style
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const ChatWidget = () => {
  const [sendChat, setSendChat] = useState([]);
  const [chat, setChat] = useState([]);
  const [streamData, setStreamData] = useState();
  const [writeMessage, setWriteMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [isCopy, setIsCopy] = useState(0);
  const [streamIndex, setStreamIndex] = useState();
  const [stylingLoader, setStylingLoader] = useState(true);
  const stateUser = useSelector((state) => state.agentReducer);
  const textareaRef = useRef();
  const parentRef = useRef();
  const [lines, setLines] = useState(0);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [noScroll, setNoScroll] = useState(false);
  const [widgetError, setWidgetError] = useState("");
  const [sessionId, setSessionId] = useState(Constants.makeId(32));
  const navigate = useNavigate();
  const location = useLocation();
  const widgetId = location?.pathname?.split('/')?.pop();
  const [chatIcon, setChatIcon]  = useState();
  const [profileIcon, setProfileIcon]  = useState();
  var JSONPrettyMon = require('react-json-pretty/dist/monikai');

  const [widgetDesign, setWidgetDesign] = useState(
    {
      msgPlaceHolder: '', footer: '', displayName: '',
      headerColor: '#1C3C7D', 
      msgColor: '#1C3C7D', 
      chatIconColor: '#1C3C7D', 
      bgColor: '#ffff', 
      alignChatIcon: "right"
    });
  const [displayMsg, setDisplayMsg] = useState("");

  useEffect(() => {
    chatbotWork(true); 
    getImageIcons('profile');
  }, []);

  const getImageIcons = (type) =>{
    ApiRequest.readWidgetIcons({imageType: type}, widgetId, (resVal)=> {

      if(resVal?.blob){
        if(type === "chat"){
          setChatIcon(URL.createObjectURL(resVal?.blob));
        }else{
          setProfileIcon(URL.createObjectURL(resVal?.blob));
        }
      }else{
        if(type === "chat")setChatIcon(""); 
        else setProfileIcon("")
      }
    });
  };

  const chatbotWork = (isExit) => {
    const data = {
      // agentId: widgetId,
      sessionId,
      parentUrl: (window.location != window.parent.location) ? document.referrer: document.location.href,
      question: isExit ? "exit" : writeMessage
    }

    if (isExit || (writeMessage.replace(/\s+/g, '').length !== 0 && !loader)) {
      setLoader(true);

      fetchData(data, isExit)
    }
  };
  
  const fetchData = async (data, isExit) => {
    setLoader(true); setLines(17);

    ApiRequest.editWidget('', widgetId, (res2) => {
      setStylingLoader(false)
       if(res2?.isSuccess){
        setDisplayMsg(res2?.data?.widget?.displayMsg);
        setWidgetDesign(res2?.data?.widget?.styling ?? widgetDesign);
        ApiRequest.exposeChatbotAgent(data, `${ApiUrls.chatbotDeployedUrl}/chatbot-agent/chatwidget/${widgetId}`, async (res) => {
          
          console.log("res Chat Widget", res?.error?.message)
          if(!res?.isSuccess){
            setWidgetError(res?.error?.message ?? "Your chat widget is inactive. Kindly activate your widget and refresh the page to use it")
            setLoader(false);
          }else{
            const reader = res?.data?.body?.getReader();
            setStreamData(''); setWidgetError("")
      
            let receivedData = '';
            while (true) {
              const { done, value } = await reader.read();
              if (done) { break; }
              // Process the chunk of data (e.g., convert to text and accumulate)
              const text = new TextDecoder().decode(value);
              receivedData += text;
              // Update the state with the received data
              if (!isExit) {
                setNoScroll(false);
                setStreamData(receivedData?.split(`"[{*}]"`)?.[0]);

                setStreamIndex(chat?.length ? chat.length : 0);
              }
              setLoader(false);
            }
      
            // Set Question and Answer in Chatbot Stream
            if (!isExit) {
               setChat([...chat,
              {
                answer: receivedData?.split(`"[{*}]"`)?.[0],
                question: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON?.parse(receivedData?.split(`"[{*}]"`)?.[1])?.question,
              }]);
            }
            setLoader(true);
            setTimeout(()=>setLoader(false), 1500);
            setStreamData(""); 
            setSendChat([]);
      
            // Close the reader when done (optional)
            reader.releaseLock();
            setLoader(false);
          }
          
        });
       }
    });
    
  };

  const sendText = () => {   
    if (writeMessage) {
      chatbotWork(false); setNoScroll(false)
      setSendChat([...sendChat, writeMessage]);
      // smoothScroll();
      setWriteMessage('');
    }
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      const parentElement = elementRef.current?.closest('.scroll-actual');
      if (parentElement) {
        elementRef.current.scrollIntoView()
      }
    });
    return <div ref={elementRef} />;
  }; 


  // Auto Scroll Test
  
  useEffect(() => {
    // Scroll to the bottom whenever items change
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  }, [sendChat, chat, streamData]);

  useEffect(() => {
    setNoScroll(true);
    if (textareaRef.current) {
      const computedStyle = window.getComputedStyle(textareaRef.current);
      const lht = parseInt(computedStyle.lineHeight, 10);
      const newLines = textareaRef.current.scrollHeight / lht;
      setLines(Math.floor(newLines) * 17);
    }
  }, [writeMessage]);

  useEffect(() => {
    // Configure marked to use highlight.js for code highlighting
    marked.setOptions({
      breaks: true,
      gfm: true,
      smartLists: true, // Better list formatting
      highlight: function(code, lang) {
        const validLanguage = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(validLanguage, code).value;
        // return `
        //   <div style="position: relative;">
        //     <pre style="background: #f8f9fa; border: 1px solid #ddd; padding: 10px; border-radius: 4px; overflow: auto;"><code class="hljs ${validLanguage}" style="display: block; padding: 10px;">${highlighted}</code></pre>
        //     <button class="copy-btn" style="position: absolute; top: 5px; right: 5px; padding: 5px; font-size: 12px; cursor: pointer; background: #007bff; color: white; border: none; border-radius: 3px;">Copy</button>
        //   </div>`;
      }
    });
  }, []);

  // Helper function to create unique keys
  const createKey = (preIndex, codeIndex) => `pre-${preIndex}-code-${codeIndex}`;

  const handleCopyClick = (e, index, answerIndex) => {
    const codeContent = e.target.nextElementSibling.innerText;
    navigator.clipboard.writeText(codeContent).then(() => {
      console.log("copy To Clipboard")
      // setIsCopy(index+answerIndex);
      // toast.success("Code has been copied successfully")
    });

    // setTimeout(()=> setIsCopy(0),1000  );
  };

  // Custom render function to add copy buttons to code blocks
  const renderCodeBlocks = (value, answerIndex) => {
      const htmlContent = getMarkdownText(value).__html;
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const preTags = doc.querySelectorAll('pre');

      // preTags.forEach((preTag, preIndex, index) => {

      //   const codeTag = preTag.querySelector('code');
      //   if (codeTag) {
      //     const button = document.createElement('div');
      //     // isCopy === index+answerIndex ? 'Copied' : 
      //     button.innerText = 'Copy';
      //     button.className = `copy-button${preIndex}${answerIndex}`;
          
      //     button.onclick = () => {
      //       navigator.clipboard.writeText(codeTag.innerText);
      //     };

          
      //     const divQuery = document.querySelector(`.copy-button${preIndex}${answerIndex}`);
      //     if(divQuery){
      //       const handleClick = function(e) {
      //         handleCopyClick(e, preIndex, answerIndex);
      //         // toast.success("Code has been copied successfully");
      //         divQuery.removeEventListener('click', handleClick);
      //       };
      //       divQuery.addEventListener('click', handleClick);
      //     }
      //     // button.addEventListener('click', handleCopyClick);
      //     preTag.insertBefore(button, codeTag);
          
      //   }
      // });
  
      return { __html: doc.body.innerHTML };
  };


  const getMarkdownText = (value) => {
    const rawMarkup = marked(value ?? markdown, { breaks: true, gfm: true });
    return { __html: DOMPurify.sanitize(rawMarkup) }; // Sanitize the HTML
  };

  const resetChat = () => {
    setLoader(true); setChat([]);
    setTimeout(()=>{
      setLoader(false);
    },1000)
  };

  function extractWrappedString(str) {
    // Copy Text
    navigator.clipboard.writeText(str).then(() => {
      toast.success("Code has been copied successfully")
    });

  };

  let programming_languages = Constants.languages.map(Constants.escapeRegExp);
  let pattern = new RegExp(`\\b(${programming_languages?.join('|')})\\b`, 'i');

  return (
    <div style={{ borderRadius: "10px" }}>
    {stylingLoader ? 
      <SpinnerLoader style={{ color: darkMode ? "white" : themeColor, paddingBottom: "30px" }} enhance={'text-2xl'} adjustment={true} />
      : widgetError?.length ? 
      <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>{widgetError}</div>
      :
      <div style={{overflowY: "auto", height: "100%", position: "relative", scrollBehavior: "smooth", backgroundColor: widgetDesign?.bgColor }}
          ref={parentRef}     
      >

      <div style={{ position: "fixed",zIndex: 9999, top: 0, right: 0, left: 0, borderRadius: "5px 5px 0px 0px",padding: "10px",color: "white",
           backgroundColor: widgetDesign?.headerColor, display: "flex",justifyContent: "space-between",alignItems: "center" }}>
          <div className="flex flex-row items-center w-full justify-between">
              <div  className="flex flex-row items-center">
              <img
                    style={{ width: "20px", height: "20px" }}
                    src={profileIcon ? profileIcon : "https://storage.googleapis.com/intellixcore-frontend-develop/uploads/IntellixcoreWidgetAssets/default-icons/chatWidgetDefaultProfileIcon.png"} />

              <span style={{ marginLeft: "10px" }}>
                  {widgetDesign?.displayName}
              </span>
              </div>

              <div style={{ marginRight: "32px" }}>
                <div onClick={()=> resetChat()}>
                  <i  className="fa fa-retweet refresh-icon cursor-pointer"></i>
                </div>
              </div>
          </div>
          {/* <i className="fas fa-window-close cross-icon"></i> */}
       </div> 
        
        <div>
          <Grid container xs="12" className="relative pt-12"  
                style={{ height: "100vh" }}
                >
                <Grid xs="12" sm="12" md={"12"} lg={"12"} style={{ paddingBottom: "80px" }} className="px-2">
                {/* <Layout themeColor={themeColor} style={{paddingTop: "20px"}} layoutTitle={`LLM X-Agent - "Test"`}> */}
                  <div className="scroll-actual" 
                        
                        style={{ height: "100%", overflowY: 'auto', padding: "20px", scrollBehavior: 'smooth' }}>
                    <div className="mt-4 " style={{ position: "relative" }}>
                      <div className="flex flex-row justify-between">
                        <div className="w-full flex flex-col justify-between">
                          <div className="flex flex-col ">
                            <>
                            {(displayMsg?.length) ? 
                              <>
                              <div className="flex justify-start mb-4">
                                  <div style={{ maxWidth: "90%", fontSize: "16px" }} className=" py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                    <div>
                                        {displayMsg}
                                    </div>
                                  </div>
                                </div>
                              </>
                            : null}

                            {chat?.map((ch, answerIndex) => (
                              <div style={{ fontSize: "16px" }}>
                                <div className="flex justify-end mb-4">
                                  <MDBox style={{ maxWidth: "90%",whiteSpace: "pre-wrap",color: "white", backgroundColor: widgetDesign['msgColor']  }} 
                                         className=" py-3 px-4 rounded-bl-lg rounded-tl-lg rounded-tr-xl">
                                    {ch?.question}
                                  </MDBox>
                                </div>
                                <div className="flex justify-start mb-4">
                                  <div style={{ maxWidth: "90%",  }} className=" py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                    <div>

                                    {ch?.answer?.includes('```') ?
                                    <>
                                    {ch?.answer.split('```').map((part, index) => {
                                      if (index % 2 === 0) {
                                        return <p key={index}>{part}</p>;
                                      } else {
                                        return (
                                          <div class="markdown-preview">
                                          <pre>
                                          <button className="copy-button"  
                                                  onClick={(e) => {extractWrappedString(part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), ''))?.trimStart()}}>
                                                    Copy
                                          </button>

                                          <code key={index}>
                                            <SyntaxHighlighter language={part.match(pattern)?.[0]} style={docco}>  
                                              {part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), '')?.trimStart()}
                                            </SyntaxHighlighter>
                                          </code>
                                          </pre>
                                          </div>
                                        )
                                      }
                                    })}
                                    </>
                                    : <div
                                        className="markdown-preview"
                                        dangerouslySetInnerHTML={renderCodeBlocks(ch?.answer, answerIndex)}
                                      />}
                                     
                                          {/* <div className="markdown-preview"
                                            dangerouslySetInnerHTML={renderCodeBlocks(ch?.answer, answerIndex)}
                                        /> */}
                                          
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {sendChat?.[0]?.replace(/\s+/g, '').length !== 0 ? sendChat?.map((sc) => (
                              <div style={{ fontSize: "16px" }} className="flex justify-end mb-4">
                                <div style={{ maxWidth: "90%", whiteSpace: "pre-wrap", color: "white", backgroundColor: widgetDesign['msgColor'] }} 
                                className="py-3 px-4 rounded-bl-lg rounded-tl-lg rounded-tr-lg text-white">
                                  {sc}
                                  </div>
                              </div>
                            )) : null}

                            


                            {streamData?.length ?
                              <div style={{ fontSize: "16px" }} className="flex justify-start mb-4">
                                <div style={{ maxWidth: "90%" }} className="py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                  <div>
                                    {/* {streamData?.split("AI:")?.[streamData?.split("AI:").length - 1]?.split('\n')?.map((c, i) => ( */}
                                      {/* <div> */}
                                        <div className="markdown-preview"
                                            dangerouslySetInnerHTML={renderCodeBlocks(streamData, streamIndex)}
                                        />
                                       {/* {i > 1 && <br />} */}
                                      {/* </div> */}
                                    {/* ))} */}
                                  </div>
                                </div>
                              </div>
                              : null
                            }
                            </>                            

                            {loader &&
                              <SpinnerLoader style={{ color: "black" }} enhance={'text-2xl'} adjustment={true} />
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {!noScroll &&
                    <AlwaysScrollToBottom /> 
                    } */}
                    {/* <div id="bottom-element"></div> */}
                  </div>
                {/* </Layout> */}

                  
                </Grid>
          </Grid>

          <div style={{ position: "fixed",bottom: 0, right: 0, left: 0, background: "white" }}>            
              <div className=" mt-2 relative">
                  <div style={{ padding: "0px 10px" }}>
                  <textarea
                    // ref={textareaRef}
                    value={writeMessage}
                    disabled={loader}
                    onChange={(e) => setWriteMessage(e.target.value)}
                    onKeyDown={(e) => { 
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Prevent the default action to avoid a new line being inserted
                        sendText(); // Call your sendText function
                      }
                    }}
                    placeholder={widgetDesign['msgPlaceHolder']} 
                    style={{
                      color: darkMode ? "white" : Constants.ccBlue,
                      borderColor: darkMode ? "white" : Constants.ccBlue,
                      background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight: "30px",
                      width: '100%', borderRadius: "10px",
                      height: lines === 17 ? '43px' : `${32 + lines}px`,
                      maxHeight: "100px",
                    }}
                  />
                  </div>

                  {/* <div onClick={() => !loader && sendText()} className={`${!loader && 'cursor-pointer'}`}>
                    <i  style={{ color: darkMode ? "white" : "#202940" }}
                        className={`fa fa-paper-plane absolute h-6 w-6 top-2 right-2`}>
                    </i>
                    </div> */}
                  <IoMdSend
                    onClick={() => !loader && sendText()}
                    style={{ color: darkMode ? "white" : "#202940", top: "8px", right: "16px" }}
                    className={`absolute h-6 w-6 ${!loader && 'cursor-pointer'}`} 
                    />
            </div>   

            <div>
                {/* <div className="flex justify-center mt-4" style={{ color:"#1C3C7D", fontWeight: "600"  }}>                  
                </div> */}
                <div className="flex justify-center" style={{ fontSize: "12px" }}>
                {widgetDesign['footer']?.length ? `${widgetDesign['footer']} -` : ''} Powered by <span style={{ fontWeight: "500", marginLeft: "5px" }}>
                  {"INTELLIXCORE"}
                    </span>
                </div>
            </div> 
          </div>
        </div> 


            {/* Alert */}
            {/* {isCopy > 0 ? toast.success("Code has been copied successfully") : null} */}
      </div>
    }
    </div>   
  );
};

export default ChatWidget;
