/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import {useMaterialUIController} from "context";

import {ReactComponent as EmailIcon} from "assets/images/intellixcore/Email.svg"
import {ReactComponent as EmailWhiteIcon} from "assets/images/intellixcore/EmailWhite.svg"

function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [controller] = useMaterialUIController();
  const [page, setPage] = useState(1);
  const { darkMode, themeColor } = controller;


  const sendEmail = () => {
    if(!email){
      toast.error("Please enter email address")
    }else{
      ApiRequest.forgotPassword({ userEmail: email }, '', (res) => {
        const { isSuccess, data, error } = res;
  
        if (isSuccess) {
            toast.success(data.message);
            setPage(2);
            // navigate('/auth/login');
        } else {
            toast.error(error.message);
        }
      });   
    }
    
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow={themeColor}
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              {page === 1 ?  'Forget Password' : 'Email Sent'}
          </MDTypography>

            {page === 1 &&
              <MDTypography display="block" variant="button" color="white" my={1}>
                <div>Please enter your email address below.</div>
                <div>We'll send you a link to reset your password</div>
              </MDTypography>
            }
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {page === 1 ? 
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput onChange={(e)=> setEmail(e.target.value)} 
              autoComplete="off" value={email}
              type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color={themeColor} onClick={()=> sendEmail()} fullWidth>
                Send
              </MDButton>
            </MDBox>

            <MDBox mt={1} mb={1} textAlign="right">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color={themeColor}
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          : 
          <div style={{ fontSize: "16px", color: darkMode ? "white" : "black" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
              { darkMode ? <EmailWhiteIcon style={{ width: "80px" }}  /> : <EmailIcon style={{ width: "80px" }} />}
            </div>
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                    A password reset link has been sent to the email address provided. 
              </div>
              <div style={{ textAlign: "center" }}>
                    Please check your inbox and follow the instructions
              </div>
            </div>
          }
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgetPassword;
