import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Constants from 'libraries/Constants';
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMaterialUIController } from "context";
import { Checkbox } from '@mui/material';
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { BackspaceOutlined } from '@mui/icons-material';
import { FaLock } from "react-icons/fa";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      cursor: "pointer",
      // '&:hover': {
      //   cursor: "pointer"
      // },
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({ isBgWhite, dropdownName, nameArr, isMultiple, isNoSelection, backSelect, personName, isSmall, disabledNames, backendArr, isTab, isLarge, isExtraLarge, ismdMobile, isllmFunction, llmFunctionTbalet,isTextToSpeech }) {

  const theme = useTheme();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const isMdLoptop = useMediaQuery('(min-width: 600px) and (max-width: 800px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    // For single select, toggle the selected value
    if (personName?.[0] === selectedValue) {
      backSelect(''); // Deselect if the same value is clicked
    } else {
      backSelect(selectedValue); // Select the new value
    }
    
  };

  console.log("yyy backendArr", backendArr)

  return (
    <div style={{ minHeight: "45px", background: isBgWhite && "white", borderRadius: isBgWhite && "10px" }}>
      <FormControl sx={{
        width: isSmallMobile ? (ismdMobile ? "260px" : isllmFunction ? "131px" : 210) : isTablet ? (isTab ? "370px" : llmFunctionTbalet ? "150px" : 210) : isMdLoptop ? (isSmall ? 150 : isLarge ? "230px" : 210) : (isLargeMobile && isllmFunction) ? "150px" :((isLargeMobile || isSmallMobile) && isTextToSpeech) ? "200px": (isSmallMobile && isllmFunction) ? "100px" : isLargeMobile ? "300px" : isSmall ? 150
          : isLarge ? "270px" : isExtraLarge ? "470px" : 210,
        height: "100%",
      }}>
        <InputLabel id="demo-multiple-chip-label" className='text-[14px]' style={{ fontSize: isTablet ? "12px" : isLargeMobile ? "10px" : ismdMobile && isSmallMobile ? "9px" : "" }}>
          {dropdownName}
        </InputLabel>
        <Select
          className='text-[14px]'
          style={{ minHeight: "45px", fontSize: isTablet ? "10px" : isLargeMobile ? "8px" : ismdMobile && isSmallMobile ? "7px" : "", }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-name"
          multiple={isMultiple}
          value={isNoSelection ? '' : isMultiple ? personName
            : Constants.allDropdownLabels(personName?.[0], true)?.length ? [Constants.allDropdownLabels(personName?.[0], true)]
              : Constants.allDynamicDropdownLabels(backendArr, personName?.[0], true)?.length ? [Constants.allDynamicDropdownLabels(backendArr, personName?.[0], true)]
                : personName}
          IconComponent={() => <KeyboardArrowDownIcon style={{ width: "30px", color: darkMode ? "white" : "black" }} />}
          onChange={(e) => isMultiple ? backSelect(e?.target?.value) : handleSelectChange(e)}
          input={<OutlinedInput label={dropdownName} />}
          renderValue={(selected) => (
            <>
              {isMultiple ?
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin: "5px" }}>
                  {selected?.length ? selected?.map((value) => (
                    <Chip color={themeColor} style={{ color: "white", fontSize: "10px" }}
                      onMouseDown={(e) => { e.stopPropagation(); }}
                      size={"small"}
                      onDelete={(e) => { backSelect(selected?.filter(fl => fl !== value)) }}
                      key={value}
                      label={Constants.allDropdownLabels(value, true)?.length ? Constants.allDropdownLabels(value, true)
                        : Constants.allDynamicDropdownLabels(backendArr, value, true)?.length ? Constants.allDynamicDropdownLabels(backendArr, value, true)
                          : value}
                    />
                  )) : ""}
                </Box> : Constants.allDropdownLabels(selected, true)?.length ? Constants.allDropdownLabels(selected, true)
                  : Constants.allDynamicDropdownLabels(backendArr, selected, true)?.length ? Constants.allDynamicDropdownLabels(backendArr, selected, true)
                    : selected}
            </>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                cursor: "pointer",
                overflowX: "auto",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: isSmall ? 175 : 240,
              },
            }
          }}
        >
          {nameArr?.length ? nameArr.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
              sx={{ fontSize: isTablet ? "10px" : isLargeMobile ? "8px" : ismdMobile && isSmallMobile ? "7px" : "" }}
              disabled={disabledNames?.includes(name)}
            >
              {isMultiple &&
                <Checkbox
                  color={themeColor}
                  icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                  checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                  checked={personName?.indexOf(name) > -1} />}
              {/* || defaultChecked?.includes && defaultChecked?.includes(name) */}
              {
                Constants.allDropdownLabels(name, true)?.length ? Constants.allDropdownLabels(name, true)
                  : Constants.allDynamicDropdownLabels(backendArr, name, true)?.length ? Constants.allDynamicDropdownLabels(backendArr, name, true)
                    // : backendArr?.isLock ? `${name} ${<FaLock style={{ color: darkMode ? "white" : "black" }} />}`
                    : name
              }
            </MenuItem>
          )) : 'No record found'}
        </Select>
      </FormControl>
    </div>
  );
}
