import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { useMediaQuery } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import { FaEdit } from "react-icons/fa";
import { MdDelete, MdOutlineSettingsSuggest } from "react-icons/md";
import { BsFileEarmarkCodeFill } from "react-icons/bs";
import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SpinnerLoader } from "examples/Loader/Loader";
import { setChatbotHistoryUrl } from "context";
import ActivePublish from "assets/images/flow-icons/active.png"
import Deactivepublish from "assets/images/flow-icons/deActive.png"
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


export default function LLMFunctionTablePattren({ columns, widthhandle }) {
  const location = useLocation()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [functionList, setFunctionList] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, agentName: '', id: '' });
  const [modalInfoOpen, setModalInfoOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const { llmFunctionsConfigForm } = location?.state || {};
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')

  const navigation = (route, page, step, objectId, llmFunctionsConfigForm, llmFunctionIds) => {
    navigate(`/llm-function/llm-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        objectId: objectId,
        page: page, step: step,
        llmFunctionsConfigForm: llmFunctionsConfigForm,
        llmFunctionTest: location.state?.llmFunctionTest,
        isEditAgent: true,
        llmFunctionIds: llmFunctionIds
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setChatbotHistoryUrl(dispatch, '')
    listingOfLlmFunction()
  }, []);

  const listingOfLlmFunction = () => {
    setIsLoader(true);
    ApiRequest.listingOfLlmFunction('', (res) => {
      var agentArr = [];
      agentArr = res.data?.functionAgents?.map(pl => pl?.functionDetails?.map(ps => {
        return {
          id: ps?._id, llmFunctionName: ps?.functionName, agentName: pl?.agentName,
          agentId: pl?.agentId, flag: ps?.flag
        }
      }))?.flatMap(item => item)
      if (res.isSuccess) {
        setFunctionList(agentArr?.length ? agentArr : []);
      } else {
        setFunctionList([]);
      }
      setIsLoader(false);
    });
  }

  const deleteModal = () => {
    setDelLoader(true);

    ApiRequest.deleteLLMFunction("", { id: modalDelOpen?.id }, (res) => {
      console.log(modalDelOpen?.id, "model id after")
      setModalDelOpen({ flag: false, agentName: '', id: '' }); setDelLoader(false);
      listingOfLlmFunction();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const editAgent = (e, agentId, llmFunctionIds) => {
    console.log(llmFunctionIds, "==>")
    e.stopPropagation();
    navigation('/config', 1, 0, llmFunctionIds);
  }

  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1D3E80";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };

  const getTooltipBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  };
  return (
    <>
      <div style={{ height: "45px", width: '100%', display: 'flex', padding: '12px 16px', backgroundColor: getBackgroundColor(), borderRadius: '8px', alignItems: 'center' }}>
        <div
          style={{ border: "none",width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white" }}
        >
          LLM Function

        </div>
        <div
          style={{border: "none", width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isLargeMobile ? "" : isTablet ? "-4px" : "29px" }}
        >
          X-Agent
        </div>
        {windowSize.innerWidth > 600 && <>
          <div
            style={{ width: "20%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "32px" : "47px" }}
          >
            Published
          </div>
          <div
            style={{ width: "10%", fontWeight: "600", fontSize: "16px", color: "white", paddingLeft: isTablet && "8px", marginLeft: isTablet ? "0px" : "21px" }}
          >
            Action
          </div>
        </>}
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>

        <TableContainer>
          <Table sx={{ marginTop: "15px" }}>

            {/* Body List */}
            <TableBody>

              {/* Body List  */}
              {isLoader ?
                <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                  {[1, 2, 3].map(() => (
                    <div className="w-56 mr-8">
                      <Code backgroundColor="grey" foregroundColor="darkgrey" />
                    </div>
                  ))}
                </div>
                :
                functionList?.length ?
                  <>
                    {functionList
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, i) => {
                        return (
                          <>
                            <TableRow tabIndex={-1} style={{ backgroundColor: !darkMode ? i % 2 === 0 ? "white" : "#F1F1F1" : ""}}>
                              <TableCell key={i} align={"left"}
                                style={{ paddingLeft: isSmallMobile ? "4px" : "", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "35%" }}>
                                {
                                  windowSize.innerWidth <= 600 &&
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => { setOpen(!open); setCollapseRow(i + 1) }}
                                  >
                                    {(open && (collapseRow === (i + 1))) ?
                                      <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                      : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                  </IconButton>
                                }
                                {row?.llmFunctionName}
                              </TableCell>
                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "35%", paddingLeft: isSmallMobile ? "12px" : "0px" }}>
                                {row?.agentName}
                              </TableCell>
                              {windowSize.innerWidth > 600 &&
                                <>
                                  <TableCell key={i} align={"left"}
                                    style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: "20%", paddingLeft: "28px" }}>
                                    {row?.flag === 1 ?
                                      <>
                                        <img src={ActivePublish} alt='publish' className='h-[18px] w-[18px]' /> </>
                                      : <><img src={Deactivepublish} alt='Unpublish' className='h-[18px] w-[18px]' />
                                      </>
                                    }
                                  </TableCell>

                                  <TableCell key={i} align={"left"} style={{ paddingLeft: "0px" }}>
                                    <div className="flex flex-row" key={i} >
                                      <Tooltip title="Reconfigure LLM Function" arrow
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <FaEdit
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => editAgent(e, row?.agentId, row?.id)}
                                            className=" cursor-pointer"
                                          />
                                        </div>
                                      </Tooltip>
                                      <Tooltip title="Delete LLM Function" arrow

                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <MdDelete
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, id: row?.id }) }}
                                            className={`ml-4  cursor-pointer`}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </>
                              }

                            </TableRow>

                            {/* Extended Row */}
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Table>
                                      <MDBox component="thead">
                                        <TableRow >
                                          <TableCell
                                            align={"left"}
                                            style={{ color: darkMode ? "white" : "black", width: "50%", fontWeight: "bold", paddingLeft: isLargeMobile ? "0px" : isSmallMobile ? "4px" : "" }}
                                          >
                                            Status
                                          </TableCell>
                                          <TableCell
                                            // key={column.id}
                                            align={"left"}
                                            style={{ color: darkMode ? "white" : "black", fontWeight: "bold", paddingLeft: isLargeMobile ? "0px" : "" }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </MDBox>
                                      <TableBody >
                                        <>
                                          <TableRow tabIndex={-1}>
                                            <TableCell key={i} align={"left"}
                                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: "20%", paddingLeft:isSmallMobile ? "13px" :isLargeMobile? "10px": "0px" }}>
                                              {row?.flag === 1 ?
                                                <>
                                                  <img src={ActivePublish} alt='publish' className='h-[18px] w-[18px]' /> </>
                                                : <><img src={Deactivepublish} alt='Unpublish' className='h-[18px] w-[18px]' />
                                                </>
                                              }
                                            </TableCell>

                                            <TableCell key={i} align={"center"} style={{ paddingLeft: isLargeMobile && "0px" }}>
                                              <div className={`flex flex-row`} key={i} >
                                                <Tooltip title="Reconfigure LLM Function" arrow
                                                  slotProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "white",
                                                        backgroundColor: getTooltipBackgroundColor(),
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                        '& .MuiTooltip-arrow': {
                                                          color: getTooltipBackgroundColor(),
                                                        },
                                                      },
                                                    },
                                                  }}
                                                >
                                                  <div>
                                                    <FaEdit
                                                      style={{ color: darkMode ? "white" : "black" }}
                                                      onClick={(e) => editAgent(e, row?.agentId, row?.id)}
                                                      className="cursor-pointer"
                                                    />
                                                  </div>
                                                </Tooltip>
                                                <Tooltip title="Delete LLM Function" arrow

                                                  slotProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "white",
                                                        backgroundColor: getTooltipBackgroundColor(),
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                        '& .MuiTooltip-arrow': {
                                                          color: getTooltipBackgroundColor(),
                                                        },
                                                      },
                                                    },
                                                  }}
                                                >
                                                  <div>
                                                    <MdDelete
                                                      style={{ color: darkMode ? "white" : "black" }}
                                                      onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, id: row?.id }) }}
                                                      className={`ml-4  cursor-pointer`}
                                                    />
                                                  </div>
                                                </Tooltip>
                                              </div>

                                            </TableCell>
                                          </TableRow>
                                        </>

                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </>
                  : <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No LLM Function found</div>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          style={{ color: darkMode ? "white" : "black" }}
          count={functionList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {modalDelOpen?.flag &&
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, agentName: '', id: '' })}>
            <div className="flex flex-row w-full justify-center items-center text-xl mb-4" >
              Are you sure you want to delete? Deleted LLM Function will be lost
            </div >

            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "140px" }} disabled={false}
                onClick={() => setModalDelOpen({ flag: false, agentName: '', id: '' })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "140px" }} disabled={delLoader}
                onClick={() => deleteModal()}
                variant="gradient" color={themeColor} fullWidth>
                {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
              </MDButton>
            </MDBox>
          </Modal>
        }
      </Paper>
    </>
  );
}
