  /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Tooltip } from "examples/Tooltip/Tooltip";


// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getColorVlue } from "services/Utils";

import {useMaterialUIController} from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { SpinnerLoader } from "examples/Loader/Loader";
import { Images } from "libraries/Images";
import OutlinedInput from '@mui/material/OutlinedInput';


function PredictiveAnalysisConfig() {
  const navigate = useNavigate()
  const location = useLocation()  
  const {id, name} = useParams()

  const { configForm } = location?.state || {};
  const {  agentName, description } = configForm || {};

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const [config, setConfig] = useState(
    { agentName: agentName ?? "",  description: description ?? '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();


  const fields = [
    { id: 1, type: 'text', placeholder: 'Agent Name', name: 'agentName', mendatory: true, information: "Name of the X-Agent you want to create. Note that the X-Agent name must be unique." },
    { id: 2, type: 'textarea', placeholder: 'Agent Description*', name: 'description', mendatory: true, information: "Describes the agent's purpose or functionality." },
  ];

  const isValidate = () => {
    let validate = true;
    fields.map((field, _) => {
        if((field?.type === "text" || field?.type === "textarea")&& field?.mendatory && config[`${field?.name}`] === ""){
            validate = false
        }
    })
    return validate
  }

  const navigation = (route, page, step, objectId, configForm) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, location?.state?.configForm)
  ),[]);

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, 
    {...config, patternId: id})
  ),[config]);

  const Icons = (icon) => {
    return (
        <div style={{ width: "30px", marginRight: "15px" }}>
            <MDBox bgColor={themeColor}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                <MDBox component="img" textAlign="center"
                    src={icon}
                    alt="Brand" width="70%"
                    style={{ height: "14px", width: "14px" }}
                />
            </MDBox>
        </div>
    )
}


  const saveConfig = (flg) => {
      if(isValidate()){
        if(flg){setIsLoading(true)}
        else{setIsLoading2(true)}
        ApiRequest.configPredictiveAgent({...config, patternId: id}, location?.state?.objectId, (res) => {
            if(flg) setIsLoading(false);
            if (res.isSuccess) {
              if (flg) {
                navigation('/config', 1, 0, res?.data?.agent?._id, location?.state?.configForm);
              } else {
                setIsLoading(false);setIsLoading2(false);
                navigation('/model', 3, 1, res?.data?.agent?._id, location?.state?.configForm);

                //  Deployment Predictive
                // if (location?.state?.deployedURL) {
                //   setIsLoading(false);setIsLoading2(false);
                //   navigation('/deployment', 2, 1, res?.data?.agent?._id, location?.state?.configForm);
                // } else {
                //   toast.success("Predictive analysis agent deployment starting soon");
                //   ApiRequest.deployPredictiveAgent('', res?.data?.agent?._id, (res2) => {
                //     setIsLoading2(false);
                //     if (res2.isSuccess) {
                //       navigation('/deployment', 2, 1, res?.data?.agent?._id, location?.state?.configForm);
                //     } else {
                //       toast.error(res2?.error?.message ? res2?.error?.message : "Deployment Failed");
                //       navigation('/config', 1, 0, res?.data?.agent?._id, location?.state?.configForm);
                //     }
                //   });
                // }
              }
              toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
            } else {
              setIsLoading(false);setIsLoading2(false);
              toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
        });
      }
      else{
        toast.error("Please fill all marked fields!");
      }
  };

  return (
      <div className="flex flex-col" style={{height: "calc(100%)", justifyContent: "space-between"}}>
        <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "30px"}}>
        	{fields.map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "80%", outline: "none",display: "flex", alignItems: "center" }}>
					{fi?.type === "textarea" ? 
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                      {Icons(Images.promptLlmPattern)}
                      {fi?.placeholder}
                  </div>
                <OutlinedInput 
                      placeholder={`Write ${fi?.placeholder} here`}
                      sx={{
                        width: "100%",
                        minHeight: "70px", 
                        borderRadius: "10px", '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CCCCCC',
                            borderWidth: '2px',
                        },
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        resize: 'none'     
                      }} 
                      multiline 
                      value={config[fi.name]} 
                      onChange={(e) => {
                        setConfig({ ...config, [fi.name]: e.target.value });
                        }}
                      />
                      </div>
                :
						<MDInput   
                // style={{outline: "none", border: "none"}}
                id={fi.name} 
                type={fi.type}
                value={config[fi.name]} 
                onChange={(e) => {
                setConfig({ ...config, [fi.name]: e.target.value });
                // setError({ ...error, [fi.name]: '' });
                }}
                label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                variant="standard" fullWidth />
              }
                <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>

					</MDBox>
            ))}
        </div>
        

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(true)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
            </MDButton>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(false)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
            </MDButton>
        </MDBox>
      </div>
  );
}

export default PredictiveAnalysisConfig;
