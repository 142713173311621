/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import Modal from "examples/Modal/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {useMaterialUIController} from "context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';
import useScreenWidth from "libraries/ScreenSizeHook";

import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from "react-icons/fa";
import { IoIosClose , IoIosMail } from 'react-icons/io';


const ContactSupport = ({flag, backFlag}) => {

    const [controller] = useMaterialUIController();
    const {  themeColor, darkMode } = controller;
    const navigate = useNavigate();
    const [windowSize, getWindowSize] = useScreenWidth();
    const [isContactLoading, setIsContactLoading] = useState(false);

    const [contactCredentials, setContactCredentials] = useState(
      { name: '', email: '', phoneNumber: '', description: '' });

    const fields = [
      { id: 1, type: 'text', placeholder: 'Full Name*', name: 'name' },
      { id: 2, type: 'text', placeholder: 'Email*', name: 'email' },
      { id: 3, type: 'text', placeholder: 'Mobile/Phone No.', name: 'phoneNumber' },
    ];
    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const contactRegex = /^\+[1-9]\d{1,14}$/;

    const contact = () => {
        const { name, email, phoneNumber, description } = contactCredentials;
        const data = { name, email, phoneNumber, description};
        
        if(!name ){toast.error("Please enter your full name")}
        else if(!emailRegex.test(email)){toast.error("Please enter your email")}
        else if(!contactRegex.test(phoneNumber)){toast.error("Please enter contact number with country code e.g +44")}
        else if(!email ){toast.error("Please enter your email address")}
        else {
            setIsContactLoading(true);
            ApiRequest.contactUs(data, '', (res) => {
              toast.success(res?.data?.message ? res?.data?.message : "Email sent successfully");

                if(res?.isSuccess){
                  ApiRequest.subscribeBilling({planType: "Free", userEmail: contactCredentials?.email}, '', (res) => {
                    // toast.success(res?.data?.message ? res?.data?.message : "Free Enterprise plan activated");
                    backFlag(false);
                  });
                }else{
                  toast.error(res?.error?.message ? res?.error?.message : "Something went wrong");
                  setIsContactLoading(false);
                }
            })
        };
    };

    const contactTicks = [
      'Premium Plan features',
      'All available models',
      'All available patterns',
      'Unlimited Storage',
      'Unlimited compute',
      'Dedicated SAAS option',
      'On Premise option',
    ]

  return (
      <Modal isNoPadding open={flag}  setOpen={(fl) => backFlag(fl)}>
            
            <div className="flex flex-row w-full">
                  {windowSize.innerWidth > 600 &&
                  <MDBox bgColor={themeColor} sx={{ width:  "40%",  padding:"25px 25px 25px 35px" }}>
                      <div style={{ color: "white", fontSize: "24px", marginTop: "70px",marginBottom: "24px", fontWeight: "500" }}>
                      Unlock the full power of Intellixcore AI and sign up to a custom Enterprise plan
                      </div>

                      {contactTicks.map(cm => (
                        <div className="flex flex-row w-full items-center mt-2" style={{ fontSize: "12px",color: "white" }}>
                        <FaCheck style={{ marginRight:"7px" }} />
                        {cm}
                      </div>
                      ))}
                      
                      <div className="mt-6" style={{ color: "white", fontStyle: "italic", fontSize: "16px" }}>
                          Contact Us Now
                      </div>
                  </MDBox>
                  }

                  <div style={{ position: "relative", width: windowSize.innerWidth > 600 ? "60%" : "100%", padding: "20px" }}>
                      <IoIosClose 
                          onClick={(e) => {
                            backFlag(false)}}
                          style={{ position: "absolute", right: "5px", top:"5px",
                           color:  darkMode ? "white" : "black", fontSize: "30px", marginBottom: "10px" }}
                          className={`font-semibold cursor-pointer`}
                      />
                    
                    <div className="flex flex-row w-full items-center text-xl font-semibold mt-6 mb-4" >
                      
                     <MDBox sx={{ borderRadius: "50%", padding: "8px" }}  bgColor={themeColor}>
                     <IoIosMail
                          style={{ color: "white", fontSize: "30px" }}
                          className={`font-semibold cursor-pointer`}
                      />
                      </MDBox> 
                    
                      <MDBox sx={{ color: darkMode && "white" }} color={!darkMode && themeColor} className="ml-4"> Contact Us </MDBox>

                    
     
                    </div>

                    <div className="w-[100%]" 
                      style={{gap: "20px", justifyContent: "space-between"}}>
                  
                    {fields?.map(fl => (
                      <MDBox mb={2}>
                      <MDInput  variant="standard" type={fl?.type} 
                                value={contactCredentials[fl.name]} 
                                autoComplete="off" 
                                onChange={(e) => {
                                  setContactCredentials({
                                    ...contactCredentials,
                                    [fl.name]: e.target.value,
                                  });
                                }}
                                label={fl?.placeholder} fullWidth />
                     </MDBox>
                    ))}

                    <MDBox sx={{ marginTop: "20px" }}>
                      <textarea
                            // ref={textareaRef}
                            value={contactCredentials['description']}
                            // disabled={loader}
                            placeholder="Tell us more about your requirement"
                            onChange={(e) => {
                              setContactCredentials({
                                ...contactCredentials,
                                description: e.target.value,
                              });
                            }}
                            style={{
                              color: darkMode ? "white" : "black",
                              borderColor: darkMode ? "white" : "lightgrey",
                              background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight: "30px",
                              width: '100%', borderRadius: "10px",
                              height: "200px",maxHeight: "200px",
                            }}
                          />
              
                    </MDBox>
                    </div>


                    {/* Save */}
                     
                     <div className="flex flex-row justify-end">      
                    <MDButton style={{width: "max-content", minWidth: "140px"}}
                        disabled={false} onClick={()=> contact()} variant="gradient" 
                              color={themeColor} fullWidth>
                          Submit
                      </MDButton>
                      </div>
                  </div>
            </div>
          
      </Modal>    
  );
}

export default ContactSupport;
