import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoMdSend } from "react-icons/io";
import { useSelector } from "react-redux"
// import Modal from "Components/Modal/Modal";
import JSONPretty from 'react-json-pretty';
import { SpinnerLoader } from "examples/Loader/Loader";
import MDButton from "components/MDButton";
import { Toggle } from "examples/Toggle/Toggle";
import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";
import { useMaterialUIController, setChatbotInitial, setChatbotHistoryUrl } from "context";
import { Card, Grid } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Images } from "libraries/Images";
import Modal from "examples/Modal/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useScreenWidth from "libraries/ScreenSizeHook";
import { MdOutlineChat } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
// import javascript from 'highlight.js/lib/languages/javascript';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Import a syntax highlighting style

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Layout = ({layoutTitle, children, style, className, themeColor}) => {
  return(
    <Card>
    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
      <MDBox
        variant="gradient"
        bgColor={themeColor}
        color={"white"}
        coloredShadow={themeColor}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        // width="4rem"
        pl={2} pr={2}
        height="4rem"
        mt={-5}
      >
        <MDTypography style={{ color: "white" }} variant="h6">
            {layoutTitle}
        </MDTypography>
          
      </MDBox>
    </MDBox>  
    <MDBox lineHeight={1.25} px={2} py={1} className={className} style={{paddingBottom: "30px", ...style}}>
          {children}              
      </MDBox>
  </Card>
  )
}

const ChatXAgent = ({chatDetail}) => {
  const [sendChat, setSendChat] = useState([]);
  const [chat, setChat] = useState([]);
  const [prevChat, setPrevChat] = useState([]);
  const [feedbackPrevChat, setFeedbackPrevChat] = useState([]);
  const [streamData, setStreamData] = useState();
  const [writeMessage, setWriteMessage] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [prevChatLoader, setPrevChatLoader] = useState(false);
  const [exitLoader, setExitLoader] = useState(false);
  const [checkFeedback, setCheckFeedback] = useState(true);
  const stateUser = useSelector((state) => state?.loginReducer?.isSqlTool);
  const textareaRef = useRef();
  const [lines, setLines] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [sessionId, setSessionId] = useState(Constants.makeId(32));
  const [modalDelOpen, setModalDelOpen] = useState({flag: false, messageId: ""});
  const [enabled, setEnabled] = useState(false);
  var JSONPrettyMon = require('react-json-pretty/dist/monikai');
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor, chatbotInitial } = controller;
  const {id, name, chatId} = useParams();
  const [isOpenFeedback, setIsOpenFeedback] = useState('');
  const [noScroll, setNoScroll] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const [debuggerChat, setDebuggerChat] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [formattedContent, setFormattedContent] = useState(null);
  const [markdown, setMarkdown] = useState('');
  const [copied, setCopied] = useState(false);
  const [streamIndex, setStreamIndex] = useState();
 
  useEffect(() => {
    setChatbotHistoryUrl(dispatch, `${location?.state?.deployedURL}/chatbot-agent/listpreviouschat/${location?.state?.objectId}`)
    setDebuggerChat([]);
    
    if(chatId === undefined) {
      chatbotWork(true);
      setChatbotInitial(dispatch, false);
      oldChatPreview(false);
    }else{
      setNoScroll(false);
      oldChatPreview(true);
    }
    
  }, [chatId]);

  const oldChatPreview = (isReload) => {
    if(chatId){ 
      setWriteMessage('');     
      if(isReload) setPrevChatLoader(true);
      ApiRequest.viewChatbotHistoryConversation({conversationId: chatId},`${location?.state?.deployedURL}/chatbot-agent/viewconversation/${location?.state?.objectId}`, (res) => {
        setStreamData(''); setChat([]); setSendChat([]); setPrevChat([]); setIsOpenFeedback(''); setFeedbackMessage(''); 
        setCheckFeedback(true);
        setPrevChatLoader(false);

        setFeedbackPrevChat(res?.data?.[0]?.conversation);
        setPrevChat(res?.data?.[0]?.conversation);
        setSessionId(res?.data?.[0]?.sessionId);
      });
    }else{
      setPrevChat([]) 
    }
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const AlwaysScrollToBottomDebug = () => {
    const elementRefDebug = useRef();
    useEffect(() => elementRefDebug.current.scrollIntoView());
    return <div ref={elementRefDebug} />;
  };


  const chatbotWork = (isExit) => {
    var sessionUpdatedId = "";
    if(isExit){
      sessionUpdatedId =  Constants.makeId(32);      
      setSessionId(sessionUpdatedId);
    }

    const data = {
      agentId: location?.state?.objectId,
      sessionId: sessionUpdatedId?.length ? sessionUpdatedId : sessionId,
      question: isExit ? "exit" : writeMessage
    }

    if (isExit || (writeMessage.replace(/\s+/g, '').length !== 0 && !loader)) {
      setLoader(true);

      fetchData(data, isExit)
    }
  };

  const fetchData = async (data, isExit) => {
    setLoader(true);
    setLines(17);
    ApiRequest.chatChatbotAgent(data, `${location?.state?.deployedURL}/chatbot-agent/inference/${location?.state?.objectId}`, async (res) => {
      if(!res?.isSuccess){
        toast.error(res?.error?.message ?? "Something went wrong")
        setLoader(false);
      }else{
        const reader = res?.data?.body?.getReader();
        setStreamData('');
  
        let receivedData = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) { break; }
          // Process the chunk of data (e.g., convert to text and accumulate)
          const text = new TextDecoder().decode(value);
          receivedData += text;
          // Update the state with the received data
          if (!isExit) {
            setStreamData(receivedData?.split(`"[{*}]"`)?.[0]);
            setStreamIndex(chat?.length ? chat.length : 0);
          }
          setLoader(false);
        }
  
        // Set Question and Answer in Chatbot Stream
        if(!chatbotInitial && !isExit && !chatId && receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.chat_history) setChatbotInitial(dispatch, true);
  
        if (!isExit) {
           setChat([...chat,
          {
            answer: receivedData?.split(`"[{*}]"`)?.[0],
            question: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON?.parse(receivedData?.split(`"[{*}]"`)?.[1])?.question,
          }]);
  
          setDebuggerChat([...debuggerChat,
            {
              debug: {
                chat_history: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.chat_history,
                openai_prompt: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.openai_prompt,
                question: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.question,
                source_documents: receivedData?.split(`"[{*}]"`)?.[1]?.length && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.source_documents,
              }
            }]);
        }
        setLoader(true);
        setTimeout(()=>setLoader(false), 1500);
        setStreamData(""); setSendChat([]);
  
        // Close the reader when done (optional)
        reader.releaseLock();
  
        if(!isExit && JSON.parse(receivedData?.split(`"[{*}]"`)?.[1])?.chat_history?.length){
          oldChatPreview(false); 
        }
        setLoader(false);
      }
   
    });
  };

  const sendText = () => {
    
    if (writeMessage) {
      chatbotWork(false); setNoScroll(false)
      setSendChat([...sendChat, writeMessage]);
      setWriteMessage('');
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      const computedStyle = window.getComputedStyle(textareaRef.current);
      const lht = parseInt(computedStyle.lineHeight, 10);
      const newLines = textareaRef.current.scrollHeight / lht;
      setLines(Math.floor(newLines) * 17);
    }
  }, [writeMessage]);
  

  const exitSession = () => {
    setChatbotInitial(dispatch, false);
    navigate(`/patterns/${id}/${name}/agent-creation/config`,{ state: {...location?.state, page: 1, step: 0, isDeploying: false}});
  }

  const spilittingHuman = (c) => {
    return <span>
      {c?.split('Human:')?.map((chuman, iHuman) => (
        <span>
          {(iHuman !== 0) ? <>
            <br />
            <span className="font-bold">Human:</span>
            {chuman}
          </> : chuman}
        </span>
      ))}
    </span>
  }

  const splittingAI = (cc) => {
    return <span>
      {cc?.split('AI:')?.filter((fs) => fs?.trim())?.map((c, i) => (
        <span>
          {(i !== 0) ?
            <>
              <br />
              <span className="font-bold">AI:</span>
              {spilittingHuman(c)}
            </>
            : spilittingHuman(c)}
        </span>
      ))}
    </span>
  };

  const feedbackChatbot = (messageId, isUpdate) => {
    const data = {
      messageId: messageId,
      // agentId: location?.state?.objectId,
      conversationId: chatId,
      feedback: feedbackMessage
    }
    
    if(!feedbackMessage?.length){
      toast.error("Kindly enter your feedback")
    }else{
      ApiRequest.feedbackChatbotAgent(data, `${location?.state?.deployedURL}/chatbot-agent/feedback/${location?.state?.objectId}`, isUpdate,
      (res) => {
        if (res?.isSuccess) {
          toast.success(res?.data?.message);
          oldChatPreview(false);
      } else {
          toast.error(res?.error?.message);
      }
      });
    }
  };

  const feedbackDelChatbot = () => {
    const data = {
      messageId: modalDelOpen?.messageId,
      conversationId: chatId,
    }

    ApiRequest.deleteChatbotConversation(data, `${location?.state?.deployedURL}/chatbot-agent/deletefeedback/${location?.state?.objectId}`, (res) => {
      setModalDelOpen({flag: false, messageId: ""});
      if (res?.isSuccess) {
        toast.success(res?.data?.message);
        oldChatPreview(false);
    } else {
        toast.error(res?.error?.message);
    }
    });
  };

  const debugWindow = () => {
    return(
      <Grid style={{ marginBottom: windowSize.innerWidth <= 768 ? "50px" : "0px"  }} 
                        xs="12" sm="12" md={enabled ? "5" : "12"} lg={enabled ? "5" : "12"}
                         className="px-2">
                    <Layout themeColor={themeColor} layoutTitle={`Debugger`} style={{paddingTop: "20px"}}>
                        <div style={{ position: "relative", height: `${`50vh`}`, overflowY: 'auto', scrollBehavior: 'smooth' }}>
                            <div className="flex flex-row justify-between">

                            <div className="w-full flex flex-col justify-between">
                                <div className="flex flex-col ">


                                {debuggerChat?.map((ch) => (
                                    <div>
                                    <div className="flex justify-start mb-4">
                                        <div className=" py-3 px-4 bg-gray-100 text-sm rounded-lg text-black" style={{ overflowX: 'auto' }}>
                                        <div>
                                            {/*========= Chat History ======= */}
                                            <div className="mt-2">
                                            <span className="font-bold">
                                                Chat History:-
                                            </span>
                                            {ch?.debug?.chat_history?.length ?
                                                <div>
                                                <JSONPretty id="json-pretty" theme={JSONPrettyMon} data={ch?.debug?.chat_history}></JSONPretty>
                                                </div>
                                                : null
                                            }
                                            </div>

                                            {/*========= Question ======= */}
                                            <div className=" mt-2">
                                            <span className="font-bold">
                                                Question:-
                                            </span>
                                            {ch?.debug?.question?.length ?
                                                <div>{ch?.debug?.question}</div>
                                                : null
                                            }
                                            </div>

                                            {/*========= Source Documents ======= */}
                                            <div className=" mt-2">
                                            <span className="font-bold">
                                                OpenAI Prompt:-
                                            </span>
                                            {ch?.debug?.openai_prompt?.length ? ch?.debug?.openai_prompt?.map((cc) => (
                                                <div>
                                                {cc?.split('System:')?.map((c, i) => (
                                                    <span>
                                                    {(i !== 0) ?
                                                        <>
                                                        <br />
                                                        <span className="font-bold">System:</span>
                                                        {splittingAI(c)}
                                                        </>
                                                        : splittingAI(c)}
                                                    </span>
                                                ))}
                                                </div>
                                            )) : null}
                                            </div>

                                            {/*========= Source Documents ======= */}
                                            <div className=" mt-2">
                                            <span className="font-bold">
                                                Source Documents:-
                                            </span>
                                            {ch?.debug?.source_documents?.length ?
                                                <div>
                                                <JSONPretty id="json-pretty" theme={JSONPrettyMon}
                                                    data={ch?.debug?.source_documents}></JSONPretty>
                                                </div>
                                                : null
                                            }
                                            </div>

                                            {/*========= Divider ======= */}
                                            {/* {ch?.debug?.length !== index + 1 ?
                                                <div className="my-4">
                                                {`<==================>`}
                                                </div> : null */}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}

                                </div>
                            </div>
                            </div>
                        
                        <AlwaysScrollToBottomDebug />
                        </div>
                    </Layout>
        </Grid>
    )
  };

  // useEffect(() => {
  //   // Configure marked to use highlight.js for code highlighting
  //   marked.setOptions({
  //     breaks: true,
  //     gfm: true,
  //     smartLists: true, // Better list formatting
  //     highlight: function(code, lang) {
  //       const validLanguage = hljs.getLanguage(lang) ? lang : 'plaintext';
  //       return hljs.highlight(validLanguage, code).value;
  //     }
  //   });
  // }, []);

  // Custom render function to add copy buttons to code blocks
  const renderCodeBlocks = (value, answerIndex) => {
      const htmlContent = getMarkdownText(value).__html;
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');  
      return { __html: doc.body.innerHTML };
  };


  const getMarkdownText = (value) => {
    const rawMarkup = marked(value ?? markdown, { 
      breaks: true, 
      gfm: true,
      smartLists: true, // Better list formatting
     });
    return { __html: DOMPurify.sanitize(rawMarkup) }; // Sanitize the HTML
  };

  function extractWrappedString(str) {
    // Copy Text
    navigator.clipboard.writeText(str).then(() => {
      toast.success("Code has been copied successfully")
    });

  };

  let programming_languages = Constants.languages.map(Constants.escapeRegExp);
  let pattern = new RegExp(`\\b(${programming_languages?.join('|')})\\b`, 'i');
  
  return (
    <div style={{
        backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
        backgroundPosition: "100% 100%",
        overflowY: "auto",
        height: "100vh"
      }}>
      <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2} style={{paddingTop: "30px", overflowY: "auto", height: "calc(100vh - 110px)" }}>
          <Grid container xs="12" style={{ padding: "0px 16px"}} className="relative" >
            {/* <BiArrowBack
              onClick={() => setModalOpen(true)}
              className="text-gray-500 font-bold bg-white p-1 w-6 h-6 cursor-pointer rounded-full border-2 border-primary-blue"
            /> */}

            <Grid container xs="12" className="flex flex-row w-full justify-between items-center text-primary-blue text-xl font-semibold mb-4" >
              <div></div >

              <div className="flex flex-row justify-end">
                {/* {!prevChat?.length && */}
                <div className="mr-4 flex flex-row items-center">
                  <div style={{ color: darkMode ? "white" : "black"}} className=" text-sm mr-4">Debugger</div>
                  <Toggle enabled={enabled} setEnabled={(fl) => setEnabled(fl)} />
                </div>
                {/* } */}
                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                          onClick={()=> {setModalOpen(true)}} variant="gradient" color={themeColor} fullWidth>
                    {"Modify X-Agent"}
                </MDButton>
              </div>
            </Grid>

            <Grid container xs="12" className="mt-8">
               {enabled && windowSize.innerWidth < 768 && debugWindow()}

                <Grid xs="12" sm="12" md={enabled ? "7" : "12"} lg={enabled ? "7" : "12"} className="px-2">
                <Layout themeColor={themeColor} style={{paddingTop: "20px"}} layoutTitle={`LLM X-Agent - "${location?.state?.configForm?.agentName}"`}>

                  <div style={{ height: "50vh", overflowY: 'auto', scrollBehavior: 'smooth' }}>
                    <div className="mt-4 " style={{ position: "relative" }}>
                      <div className="flex flex-row justify-between">
                        <div className="w-full flex flex-col justify-between">
                          <div className="flex flex-col ">
                           {/* ==================== Previous Chat System */}

                          {prevChatLoader ? <SpinnerLoader style={{ color: darkMode ? "white" : themeColor }} enhance={'text-2xl'} adjustment={true} />
                             : prevChat.length ? prevChat?.map((sc, answerIndex) => (
                              <div>
                              {sc?.human !== "exit" && sc?.human &&
                              <div style={{ fontSize: "16px" }} className="flex justify-end mb-4">
                                <MDBox style={{ maxWidth: "90%", whiteSpace: "pre-wrap" }} 
                                 coloredShadow={themeColor}  color={"white"} bgColor={themeColor}
                                className="py-3 px-4 rounded-bl-lg rounded-tl-lg rounded-tr-lg text-white">
                                  {sc?.human}
                                </MDBox>
                              </div>}
                              {sc?.agent && 
                              <div style={{ fontSize: "16px" }} className=" mb-4">
                                <div style={{ display: "flex", justifyContent: "start" }}>
                                <div style={{ maxWidth: "90%", position: "relative" }} className="py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                  {/* {sc?.agent} */}

                                    {sc?.agent?.includes('```') ?
                                    <>
                                    {sc.agent.split('```').map((part, index) => {
                                      if (index % 2 === 0) {
                                        return <p key={index}>{part}</p>;
                                      } else {
                                        return (
                                          <div class="markdown-preview">
                                          <pre>
                                          <button className="copy-button"  
                                                  onClick={(e) => {extractWrappedString(part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), ''))?.trimStart()}  }>
                                                    Copy
                                          </button>

                                          <code key={index}>
                                            <SyntaxHighlighter language={part.match(pattern)?.[0]} style={docco}>  
                                              {part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), '')?.trimStart()}
                                            </SyntaxHighlighter>
                                          </code>
                                          </pre>
                                          </div>
                                        )
                                      }
                                    })}
                                    </>
                                    : <div
                                        className="markdown-preview"
                                        dangerouslySetInnerHTML={renderCodeBlocks(sc?.agent, answerIndex)}
                                      />}
                                    
                                    
                                    {/* {formattedContent && <div dangerouslySetInnerHTML={{ __html: formattedContent }} />} */}
                                </div>
                                </div>

                                  <div>
                                    {!stateUser &&
                                      <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }} 
                                        className="cursor-pointer rounded-md pt-2"
                                        onClick={()=> {setIsOpenFeedback(isOpenFeedback === sc?.messageId ? "" : sc?.messageId);  setNoScroll(true)}}
                                      >
                                      <MdOutlineChat
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => console.log("")}
                                          className="mr-2 cursor-pointer"
                                        />
                                    <div style={{ color: darkMode ? "white" : "black" }}> Feedback</div>

                                        {sc?.feedback &&
                                        <FaRegCheckCircle 
                                          style={{ color: darkMode ? "lightgreen" : "green" }}
                                          onClick={(e) => console.log("")}
                                          className="ml-2 cursor-pointer"
                                        />
                                        }
                                      </div> 
                                      }
                                      
                                      {/* Send Feedback */}
                                      {isOpenFeedback === sc?.messageId &&
                                        <div style={{ width: windowSize.innerWidth <= 600 ? "240px" : "400px" }}>
                                      <textarea
                                          // ref={textareaRef}
                                          value={feedbackMessage?.length ? feedbackMessage : (checkFeedback && sc?.feedback?.length) ? sc?.feedback : feedbackMessage}
                                          // disabled={loader}
                                          onChange={(e) => { 
                                            if(!e.target.value) {setFeedbackMessage("")}
                                            else {
                                              setFeedbackMessage((checkFeedback && sc?.feedback?.length) ? sc?.feedback : e.target.value); 
                                            }
                                            setCheckFeedback(false)} 
                                          }
                                          onKeyDown={(e) => { 
                                              if (e.key === "Enter" && !e.shiftKey) {
                                              e.preventDefault(); 
                                              // feedbackChatbot(sc?.messageId, sc?.feedback);
                                            }
                                          }}
                                          style={{
                                            color: darkMode ? "white" : Constants.ccBlue,
                                            borderColor: darkMode ? "white" : Constants.ccBlue,
                                            background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight: "30px",
                                            width: '100%', borderRadius: "10px",
                                            height: "100px",maxHeight: "150px",
                                          }}
                                        />

                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                          {sc?.feedback &&
                                          <MDButton style={{width: "100px", marginRight: "10px"}}  disabled={false} 
                                                        onClick={() => setModalDelOpen({flag: true, messageId: sc?.messageId})}
                                                        variant="gradient" color={"error"} fullWidth>
                                                        Delete
                                          </MDButton>
                                          }
                                          <MDButton style={{width: "100px"}}  disabled={!feedbackMessage?.length} 
                                                      onClick={() => {setCheckFeedback(true); feedbackChatbot(sc?.messageId, sc?.feedback)}}
                                                      variant="gradient" color={"info"} fullWidth>
                                                     {sc?.feedback ? "Update" : "Save"} 
                                          </MDButton>
                                        </div>
                                        
                                       </div>
                                      }
                                      
                                  </div>
                              </div>}
                              </div>
                            )) : null}



                            {/* ==================== New Chat System */}

                            <>

                           {chat?.map((ch, answerIndex) => (
                              <div style={{ fontSize: "16px" }}>
                                <div className="flex justify-end mb-4">
                                  <MDBox style={{ maxWidth: "90%",whiteSpace: "pre-wrap" }} coloredShadow={themeColor}  color={"white"} bgColor={themeColor} className=" py-3 px-4 rounded-bl-lg rounded-tl-lg rounded-tr-xl">
                                    {ch?.question}
                                  </MDBox>
                                </div>
                                <div className="flex justify-start mb-4">
                                  <div style={{ maxWidth: "90%",  }} className=" py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                    <div>
                                     
                                    {ch?.answer?.includes('```') ?
                                    <>
                                    {ch?.answer.split('```').map((part, index) => {
                                      if (index % 2 === 0) {
                                        return <p key={index}>{part}</p>;
                                      } else {
                                        return (
                                          <div class="markdown-preview">
                                          <pre>
                                          <button className="copy-button"  
                                                  onClick={(e) => {extractWrappedString(part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), ''))?.trimStart() }  }>
                                                    Copy
                                          </button>

                                          <code key={index}>
                                            <SyntaxHighlighter language={part.match(pattern)?.[0]} style={docco}>  
                                              {part.replace(new RegExp(`\\b${part.match(pattern)?.[0]}\\b`, 'gi'), '')?.trimStart()}
                                            </SyntaxHighlighter>
                                          </code>
                                          </pre>
                                          </div>
                                        )
                                      }
                                    })}
                                    </>
                                    : <div
                                        className="markdown-preview"
                                        dangerouslySetInnerHTML={renderCodeBlocks(ch?.answer, answerIndex)}
                                      />}

                                            {/* <div
                                                className="markdown-preview"
                                                dangerouslySetInnerHTML={renderCodeBlocks(ch?.answer, answerIndex)}
                                              /> */}
                                          
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {sendChat?.[0]?.replace(/\s+/g, '').length !== 0 ? sendChat?.map((sc) => (
                              <div style={{ fontSize: "16px" }} className="flex justify-end mb-4">
                                <MDBox style={{ maxWidth: "90%", whiteSpace: "pre-wrap" }} 
                                coloredShadow={themeColor}  color={"white"} bgColor={themeColor}
                                className="py-3 px-4 rounded-bl-lg rounded-tl-lg rounded-tr-lg text-white">
                                  {sc}
                                  </MDBox>
                              </div>
                            )) : null}

                            {streamData?.length ?
                              <div style={{ fontSize: "16px" }} className="flex justify-start mb-4">
                                <div style={{ maxWidth: "90%" }} className="py-3 px-4 bg-gray-100 rounded-br-lg rounded-tr-lg rounded-tl-lg text-black">
                                  <div>
                                    {/* {streamData?.split("AI:")?.[streamData?.split("AI:").length - 1]?.split('\n')?.map((c, i) => ( */}
                                      {/* <div> */}
                                         <div
                                          className="markdown-preview"
                                          dangerouslySetInnerHTML={renderCodeBlocks(streamData, streamIndex)}
                                        />
                                        {/* {c} */}

                                        {/* {i > 1 && <br />} */}
                                      {/* </div> */}
                                    {/* ))} */}
                                  </div>
                                </div>
                              </div>
                              : null
                            }
                            </>                            

                            {loader &&
                              <SpinnerLoader style={{ color: darkMode ? "white" : themeColor }} enhance={'text-2xl'} adjustment={true} />
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    {!noScroll &&
                    <AlwaysScrollToBottom /> 
                    }
                  </div>
                </Layout>

                  <div className=" mt-2 relative">
                    <textarea
                      ref={textareaRef}
                      value={writeMessage}
                      disabled={loader}
                      onChange={(e) => setWriteMessage(e.target.value)}
                      onKeyDown={(e) => { 
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Prevent the default action to avoid a new line being inserted
                          sendText(); // Call your sendText function
                        }
                      }}
                      style={{
                       color: darkMode ? "white" : Constants.ccBlue,
                       borderColor: darkMode ? "white" : Constants.ccBlue,
                       background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight: "30px",
                       width: '100%', borderRadius: "10px",
                       height: lines === 17 ? '43px' : `${32 + lines}px`,
                       maxHeight: "100px",
                      }}
                    />
                    <IoMdSend
                      onClick={() => !loader && sendText()}
                      style={{ color: darkMode ? "white" : "black" }}
                      className={`absolute h-6 w-6 top-2 right-2 ${!loader && 'cursor-pointer'}`} />
                  </div>
                </Grid>

                {/* ========== Debug Window  ================ */}

                {enabled && windowSize.innerWidth >= 768 && debugWindow()}
            </Grid>
          </Grid>


          {
          modalOpen &&
            <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
              <div className="flex flex-row w-full justify-center items-center mb-4" >
                Are you sure you want to exit session? The chat context will be lost
              </div >

              <div className="mt-6 flex flex-row w-full justify-center items-center font-semibold" style={{gap: "20px"}}>
                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {setModalOpen(false)}} variant="gradient" color={"error"} fullWidth>
                    {"No"}
                </MDButton>
                {/* cc-bg-registration */}

                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {exitSession()}} variant="gradient" color={themeColor} fullWidth>
                    {exitLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
                </MDButton>
              </div>
            </Modal>
          }

        {
          modalDelOpen?.flag &&
            <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({flag: fl, messageId: ""})}>
              <div className="flex flex-row w-full justify-center items-center mb-4" >
                Are you sure you want to delete feedback?
              </div >

              <div className="mt-6 flex flex-row w-full justify-center items-center  font-semibold" style={{gap: "20px"}}>
                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {setModalDelOpen({flag: false, messageId: ""})}} variant="gradient" color={"error"} fullWidth>
                    {"No"}
                </MDButton>
                {/* cc-bg-registration */}

                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                    onClick={()=> {feedbackDelChatbot()}} variant="gradient" color={themeColor} fullWidth>
                    {exitLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
                </MDButton>
              </div>
            </Modal>
          }
        </Grid> 
      {/* </div> */}
      
        </DashboardLayout>
    </div>
  );
};

export default ChatXAgent;
