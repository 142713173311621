/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDTable from "components/MDTable/MDTableXAgents";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Images } from "libraries/Images";
import { useMaterialUIController } from "context";
import { ChatbotBottomWidget } from "examples/ChatbotBottomWidget/ChatbotBottomWidget";

function XAgents() {
  // const [windowSize, getWindowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const { themeColor, darkMode } = controller;

  const columns = [
    { id: 1, name: "XAgent" },
    { id: 2, name: "Pattern" },
    { id: 3, name: "Status" },
    { id: 4, name: "Actions" }
  ];

  const widthhandle = ["35", "35", "20", "10"];


  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>
        <DashboardNavbar />

        <MDTypography sx={{ fontSize: "20px", fontWeight: "600",paddingLeft:"5px",paddingBottom:"15px" }}>
                X-Agent
        </MDTypography>
        <MDBox  pt={3} pb={3} style={{ overflowY: "auto", height: "calc(100vh - 175px)" }}>
          <Grid container spacing={6} >
            <Grid item xs={12}>
              
              <Card>
                <MDBox>
                  <MDTable columns={columns} widthhandle={widthhandle} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>


      {/* Widget Component */}
      {/* <ChatbotBottomWidget /> */}

    </div>

  );
}

export default XAgents;
