// export const menuOptions=['Customer Service','Office & Admin','Sales','IT Services']

export const menuOptions={
    CustomerService:'Customer Service',
    OfficeAndAdmin:'Office & Admin',
    Sales:'Sales',
    ItServices:'IT Services',
    SavingsSteadyState : "Savings Steady State",
    Saving1Year:"Savings Year 1",
    Saving2Year:"Savings Year 2",
    Saving3Year:"Savings Year 3"
}

export const pattrensMenuOptions={
    CustomerService:'Customer Service',
    OfficeAndAdmin:'Office & Admin',
    Sales:'Sales',
    ItServices:'IT Services',
}