/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Images } from "libraries/Images";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import DashboardStatisticsCard from "examples/Cards/StatisticsCards/DashboardStatisticsCard";
import { List } from "react-content-loader";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import PieChart from "examples/Charts/PieChart";
import HalfDonutChart from "examples/Charts/PieChart/dashboardPiePercentageChart/PieChartWithPercentage";
import { getColorVlue } from 'services/Utils';
import StorageIcon from "assets/images/flow-icons/storage-icon.svg"
import { useDispatch, useSelector } from "react-redux";
import { TokenService } from "libraries/TokenService";
import { isLoginAction, isPermissionAction } from 'redux/Action';


function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [dashboardStats, setDashboardStats] = useState({
    noOfRequests: [],
    agentsByStatus: { labels: [], data: [] },
    agentsByPattern: { labels: [], data: [] }
  });
  const [storage, setStorage] = useState({ allowedStorage: "", totalOccupiedSize: "" })
  const [storagePercentage, setStoragePercentage] = useState()
  const [storageMbValue, setStorageMbValue] = useState({ allowedStorage: "", totalOccupiedSize: "" })

  const [tokens, setTokens] = useState({ allowedStorage: "", totalOccupiedSize: "" })
  const [tokensPercentage, setTokensPercentage] = useState()

  const [isLoader, setIsLoader] = useState(true);
  const [isStorageLoader, setIsStorageLoader] = useState(true);
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const LoginType = localStorage.getItem('loginType');
  const [patternsList, setPatternsList] = useState();
  const permissionUser = useSelector((state) => state?.loginReducer?.permission);
  const isUpgradePlan = new URLSearchParams(location?.search)?.get('isSubscriptionPlanChanged');
  const [permissionPlan, setPermissionPlan] = useState();
  const [planName,setPlanName] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    allPatternsList();
    if (LoginType === 'google') {
      toast.success('User Login successfully');
      localStorage.removeItem('loginType')
    }
    if (LoginType === 'microsoft') {
      toast.success('User Login successfully');
      localStorage.removeItem('loginType')
    }

    
    // if(isUpgradePlan){
    //   setTimeout(()=> {
    //     permissionBilling();
    //     getStorageDetail();
    //   },[3000]);
    // }else{
      permissionBilling();
      getStorageDetail();
    // }
  }, []);

  const permissionBilling = () => {
    ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '',(res3)=>{
      if(res3.isSuccess){
        setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
        // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));
        
        dispatch(isPermissionAction(res3?.data?.membershipLimits));
      }
    });
  };

  const allPatternsList = () => {
    ApiRequest.patternList("?page=1", "", (res) => {
      allAgentsList();
      if (res.isSuccess) {
        setPatternsList(res.data?.patternsList);
      } else {
        setPatternsList([]);
      }
    });
  };

  
  const getStorageDetail = () => {
    ApiRequest.storageUsage("", (res) => {
      if (res.isSuccess) {
        const storageValue = res?.data?.membershipLimits?.storageLimits;
        const allowedStorageGB = storageValue?.allowedStorage;
        const totalOccupiedSizeGB = storageValue?.totalStorage;
        const allowedStorageMB = allowedStorageGB * 1024;
        const totalOccupiedSizeMB = totalOccupiedSizeGB * 1024;

        const tokensValue = res?.data?.membershipLimits?.tokenLimits;
        const allowedTokens = tokensValue?.allowedTokens;
        const totalOccupiedTokensSize = tokensValue?.totalTokens;
        setStorage({
          allowedStorage: allowedStorageGB,
          totalOccupiedSize: totalOccupiedSizeGB
        });
        setStorageMbValue({
          allowedStorage: allowedStorageMB,
          totalOccupiedSize: totalOccupiedSizeMB
        })
        const percentage = (totalOccupiedSizeGB / allowedStorageGB) * 100;
        setStoragePercentage(percentage);

        setTokens({
          allowedStorage: allowedTokens,
          totalOccupiedSize: totalOccupiedTokensSize
        });

        const tokensPercentage = (totalOccupiedTokensSize / allowedTokens ) * 100;
        setTokensPercentage(tokensPercentage);

        setPlanName(res?.data?.membershipLimits?.currentPlanType)
        // toast.success(res?.data?.message ? res?.data?.message : "Query successful.");
      } else {
        toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
      }
    });
  };

  const allAgentsList = () => {
    setIsLoader(true)
    ApiRequest.dashboardStats('', '', (res) => {
      if (res.isSuccess) {
        const { agentsByNumberOfRequests, agentsByPattern, agentsByStatus } = res.data
        const { patternAPIRequests } = agentsByNumberOfRequests[0]
        const { statusCounts } = agentsByStatus[0]
        const { patternCounts } = agentsByPattern[0]
        let agentsStatus = [`Configured`, `Deployed`, `Deploying`, `Ready`, `Modified`, `Training`]
        let agentsPattern = [`LLM`, `Predictive Analysis`, `Feature Extraction`, `Recommendation System`, `Image to Text`, `Speech to Text`, `Text to Speech`]

        // patternAPIRequests.map((noReq, _) =>  {
        //   if(noReq?.patternName?.toLowerCase() === "llm"){
        //     noOfRequests = {...noOfRequests, chatbot: noReq?.totalAPIRequests}
        //   }
        //   else if(noReq?.patternName === "Feature Extraction"){
        //     noOfRequests = {...noOfRequests, FE: noReq?.totalAPIRequests}
        //   }
        //   else{
        //     noOfRequests = {...noOfRequests, PE: noReq?.totalAPIRequests}
        //   }

        // })
        setDashboardStats({
          noOfRequests: patternAPIRequests,
          agentsByStatus: {
            labels: [`Configured`, `Deployed`, `Deploying`, `Ready`, `Modified`, `Training`],
            data: agentsStatus.map((item, _) => {
              let hasStatus = statusCounts.filter((_item, _) => { return (_item.status?.toLowerCase() === item?.toLowerCase()) })
              if (hasStatus.length > 0) {
                return hasStatus[0]?.count
              }
              else {
                return 0
              }
            })
          },
          agentsByPattern: {
            labels: [`LLM`, `Predicitve Analysis`, `Feature Extraction`, `Recommendation System`, `Image to Text`, `Speech to Text`, `Text to Speech`],
            data: agentsPattern.map((item, _) => {
              let hasStatus = patternCounts.filter((_item, _) => { return (_item.patternName?.toLowerCase() === item?.toLowerCase()) })
              if (hasStatus.length > 0) {
                return hasStatus[0]?.count
              }
              else {
                return 0
              }
            })
          }
        });

        setIsLoader(false)
      } else {
        setDashboardStats([]);
        setIsLoader(false)
      }
    });
  };
  // const options = {
  //   plugins: {
  //     legend: {
  //       labels: {
  //         color: 'green', // Change this to the desired color
  //       },
  //     },
  //   },
  // };

  const DATA_COUNT = 5;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      // height: "100vh"
    }}>
      <DashboardLayout>
        <DashboardNavbar />
        {/* overflowY: "auto", height: "calc(100vh - 110px)" */}
        <MDTypography sx={{margin: "20px 0px 0px 10px", fontSize:"20px",fontWeight:"600", color:darkMode ? "white" : "#3B4E6D"}}>Dashboard ({planName} Plan)</MDTypography>
        <MDBox py={3} style={{ overflowY: "auto", height: "calc(100vh - 130px)" }}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} >
            <div style={{ padding: "10px 10px 20px 10px" }}>
              {
                isLoader
                  ?
                  <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                  :
                  <MDBox style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                    <MDTypography>No of requests </MDTypography>
                    <MDBox style={{ paddingTop: dashboardStats?.noOfRequests?.length && "15px", 
                    // overflowY: "auto", height: "calc(100vh - 270px)"
                     }}>
                      {dashboardStats?.noOfRequests?.length ?
                        dashboardStats?.noOfRequests?.map(ds => (
                          <DashboardStatisticsCard
                            color={themeColor}
                            icon={patternsList?.length ? patternsList?.filter(ps => ps?._id === ds?.patternId)?.[0]?.logoUrl : ""}
                            title={ds?.patternName}
                            count={ds?.totalAPIRequests ?? 0}
                            isForDashboard
                            percentage={{
                              color: "success",
                              // amount: "+1%",
                              label: "xAgent Name",
                            }}
                          />
                        )) : <Card>
                          <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                            <div style={{ color: "red", fontSize: "16px" }} >No record found</div>
                          </MDBox>
                        </Card>
                      }
                    </MDBox>
                  </MDBox>

              }
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} >
            <MDBox sx={{ marginTop: "19px" }}>
              {isLoader
                ?
                <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                :
                <>
                  <MDTypography>Storage Usage</MDTypography>
                  <MDBox sx={{ marginTop: "46px" }}>
                    <Card style={{ padding: "0px 20px" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "58px", width: "57px", borderRadius: "9px", background: getColorVlue(themeColor), marginTop: "-20px", }}><img src={StorageIcon} alt={'storage'} /> </div>
                      <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                        <HalfDonutChart isStorage={true} percentage={(storagePercentage)?.toFixed(2)} height={300} width={300}
                          allowedStorage={storageMbValue.allowedStorage} totalOccupiedSize={storageMbValue.totalOccupiedSize} />
                      </MDBox>
                    </Card>
                  </MDBox>
                </>

              }

            </MDBox>
            <div style={{ padding: "20px 10px 20px 10px" }}>
              {
                isLoader
                  ?
                  <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                  :
                  <MDBox style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                    <MDTypography>Agents by status</MDTypography>
                    <MDBox>
                      {dashboardStats?.agentsByStatus?.data?.length ?
                        <PieChart
                          // title="XYZ"
                          height={"22rem"}
                          // description="description"
                          chart={{
                            labels: dashboardStats?.agentsByStatus?.labels.map((label, index) => { return `${label} (${dashboardStats?.agentsByStatus?.data[index]})` }),
                            // options:options,
                            datasets:
                            {
                              label: 'Agents by status',
                              data: dashboardStats?.agentsByStatus?.data,
                              // #1E4585
                              backgroundColors: ["#f94449", "black", "royalblue", "#43a047", "#c30010", "rgb(123, 128, 154)"]
                            }
                          }}
                        />
                        : <Card>
                          <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                            <div style={{ color: "red", fontSize: "16px" }} >No record found</div>
                          </MDBox>
                        </Card>
                      }
                    </MDBox>
                  </MDBox>
              }
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4} >
            <MDBox sx={{ marginTop: "19px" }}>
              {isLoader
                ?
                <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                :
                <>
                  <MDTypography>Tokens Usage</MDTypography>
                  <MDBox sx={{ marginTop: "46px" }}>
                    <Card style={{ padding: "0px 20px" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "58px", width: "57px", borderRadius: "9px", background: getColorVlue(themeColor), marginTop: "-20px", }}><img src={StorageIcon} alt={'storage'} /> </div>
                      <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                        <HalfDonutChart percentage={(tokensPercentage)?.toFixed(2)} height={300} width={300}
                          allowedStorage={tokens.allowedStorage} totalOccupiedSize={tokens.totalOccupiedSize} />
                      </MDBox>
                    </Card>
                  </MDBox>
                </>

              }

            </MDBox>
            <div style={{ padding: "20px 10px 20px 10px", }}>
              {
                isLoader
                  ?
                  <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                  :
                  <MDBox style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                    <MDTypography>Agents by pattern</MDTypography>
                    <MDBox>
                      {dashboardStats?.agentsByPattern?.data?.length ?
                        <PieChart
                          // title="XYZ"
                          height={"22rem"}
                          // description="description"
                          chart={{
                            labels: dashboardStats?.agentsByPattern?.labels.map((label, index) => { return `${label} (${dashboardStats?.agentsByPattern?.data[index]})` }),
                            datasets:
                            {
                              label: 'Agents by pattern',
                              data: dashboardStats?.agentsByPattern?.data,
                              backgroundColors: ["#1E4585", "rgb(233, 30, 99)", "brown", "purple", "green"]
                            }
                          }}
                        />
                        : <Card>
                        <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                          <div style={{ color: "red", fontSize: "16px" }} >No record found</div>
                        </MDBox>
                      </Card>
                      }
                    </MDBox>
                  </MDBox>
              }
            </div>
          </Grid>
        </Grid>

        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}

export default Dashboard;
