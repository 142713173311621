  /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {useMaterialUIController} from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { SpinnerLoader } from "examples/Loader/Loader";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { validateAndProcessFile } from "services/Utils";
import Methods from "libraries/CommonMethodsUI";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa";
import Constants from "libraries/Constants";
import MDDropdown from "components/MDDropdown";
import { FaCopyright } from "react-icons/fa6";

// import CustomSelect from "components/CustomSelect/CustomSelect"; 


function ImageToTextInference() {
  const navigate = useNavigate()
  const location = useLocation()  
  const {id, name} = useParams()

  const { configForm } = location?.state || {};
  const {  agentName, description, modelType, pageSegmentation, modelName, openAIKey, systemPrompt, temperature, maxTokens } = configForm || {};
  const [uploadedFile, setUploadedFile] = useState([]);
  const [inferResult, setInferResult] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
   
  const navigation = (route, page, step, objectId, configForm) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };

  useEffect(()=>(
    navigation('/inference', 3, 1, location?.state?.objectId, location?.state?.configForm)
  ),[]);

  const uploadedContent = (fl) => {
    let supportedFormats = {};

    if(location?.state?.configForm?.modelType === "ocr"){
       supportedFormats = {
        'application/pdf': 'PDF'
      };
    }else{
       supportedFormats = {
        'image/png': 'PNG',
        'image/jpeg': 'JPEG',
        // 'image/svg+xml': 'SVG',
        'image/webp': 'WebP',
        'image/gif': 'GIF'
      };
    }


    const afile = fl?.[0];

    const validation = validateAndProcessFile(afile, supportedFormats)
    if(validation === true && fl){
        setIsLoading(true);

        ApiRequest.inferenceImageToTextAgent({file: afile}, 
          `${`${location?.state?.deployedURL}/image-to-text-agent/inference`}/${location?.state?.objectId}`,(res)=>{
            if(res?.isSuccess){
              setInferResult(location?.state?.configForm?.modelType === "ocr" 
                ? Object.values(res?.data?.Result)
                : res?.data?.Result)
                toast.success("File have been saved");
                setUploadedFile(fl);
            }else{
              setInferResult("")
              toast.error(res?.error?.message ?? "Something went wrong");
              setUploadedFile([]);
            }
            setIsLoading(false);
        })
    }else{
        toast.error(`${validation}`)      
    }
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location.state?.objectId, location?.state?.configForm);
  };

  const inferenceDownload = () => {
    if(!uploadedFile?.length){
      reconfigBtn()
    }else{
      setUploadedFile([])
    }
  };

  return (
      <div className="flex flex-col" style={{height: "calc(100%)", justifyContent: "space-between"}}>

        {isLoading ? 
        <div style={{ marginTop: "40px" }}>
        <SpinnerLoader adjustment={true} enhance={`${darkMode ? "text-white" : "text-black"} text-2xl`} /> 
        </div>
        : !uploadedFile?.length ?
          <div>
            <TabUpload supportedFileTypes={`${location?.state?.configForm?.modelType === "ocr" ? "PDF" : "PNG, JPEG, WebP, GIF"} `} isSingleFile={true} 
            uploadedFile={(fl) => uploadedContent(fl)} loader={false} />
          </div>
          : <div>
            <MDBox mt={4} mb={1} style={{display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "end"}}>
            
              <CopyToClipboard text={inferResult} onCopy={()=>  toast.success("Text has been copied!")}>
                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                          onClick={(e) => {e?.stopPropagation()}}
                          variant="gradient" color={themeColor} fullWidth>
                  {"Copy"}
              </MDButton>
              </CopyToClipboard>

            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                      onClick={()=> Methods.downloadCSV(inferResult, "data.txt")} variant="gradient" color={themeColor} fullWidth>
                {"Download File"}
            </MDButton>
        </MDBox>
          <div style={{fontSize: "14px", padding: "20px", 
                border: darkMode ? "1px dotted white" : "1px dotted black" , borderRadius:"7px", 
                color: darkMode && "white" ,
                height: "250px", marginTop: "15px", textAlign: "justify", overflowY: "auto" }}>
            {inferResult}

            </div>
            </div>}

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading} 
                      onClick={()=> inferenceDownload()} variant="gradient" color={themeColor} fullWidth>
                {"Previous"}
            </MDButton>
        </MDBox>
      </div>
  );
}

export default ImageToTextInference;
