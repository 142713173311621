import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MDDropdown from "components/MDDropdown";
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import OutlinedInput from '@mui/material/OutlinedInput';
// import { Paths } from "libraries/Route";

import { useMaterialUIController, setLlmFunctionId } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "examples/Modal/Modal";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { getColorVlue } from 'services/Utils';
import MDInput from "components/MDInput";
import ApiRequest from "services/ApiRequest";

//images
import { Images } from "libraries/Images";
import AddIcon from '@mui/icons-material/Add';
import MDTypography from "components/MDTypography";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const LLmFunctionConfig = () => {
    const [windowSize, getWindowSize] = useScreenWidth();
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor, llmFunctionId } = controller;
    const navigate = useNavigate()
    const location = useLocation();
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [xAgentName, setXAgentName] = useState([]);
    const [xAgentList, setXAgentList] = useState([]);
    const [attributeDropdownTypes, setAttributeDropdownTypes] = useState([]);
    const [promptMessage, setPromptMessage] = useState("")
    const [apikeyName, setApiKeyName] = useState([])
    const [apiIdList, setApiIdList] = useState([])
    const { llmFunctionsConfigForm } = location?.state || {};
    const { llmFunctionsName, agentId, key } = llmFunctionsConfigForm || {};
    const llmId = location?.state?.objectId
    const [flg, setFlg] = useState()
    const [llmFunction, setLLMFunction] = useState(
        {
            llmFunctionsName: llmFunctionsName ?? "",
            agentId: agentId ?? '',
            key: key ?? ''
        });
    const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)')
    const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)')
    const isMac = useMediaQuery('(min-width:1700px) and (max-width:1950px)')

    const fieldsAttributeData = [
        {
            id: "attribute1", type: 'text', value: '', placeholder: 'Write attribute name here', name: 'attributeName', mendatory: true, information: " Variables or values passed into a function to customize its operation."
        },
        {
            id: "type1", type: 'dropdown', value: '', placeholder: 'Attribute Type*', name: 'attributeType', arr: attributeDropdownTypes.length ? attributeDropdownTypes.map(type => type.toLowerCase()) : [''], mendatory: true, information: "Input attribute for which the user will pass values."
        },
    ];
    const fieldsConfigData = [
        {
            id: 1, type: 'text', placeholder: 'LLM Function Name', name: 'llmFunctionsName', mendatory: true, information: "The name given to a specific function within the Language Model."
        },
        {
            id: 2, type: 'dropdown', placeholder: 'Select X-Agent*', name: 'agentId', arr: xAgentName?.length ? xAgentName : [''], mendatory: true, information: "One Agent can have multiple llm function. Please Choose X-agent."
        },
        {
            id: 3, type: 'dropdown', placeholder: 'Select ApiKey*', name: 'key', arr: apikeyName?.length ? apikeyName : [''], mendatory: true, information: "A unique identifier used to authenticate requests for usage."
        },
    ];

    const fieldsOutputAttributeData = [
        {
            id: "outputAttribute1", type: 'text', value: '', placeholder: 'Write attribute name here', name: 'outputAttributeName', mendatory: true, information: "The results or data returned by a function."
        },
        {
            id: "outputTypeAttribute1", type: 'dropdown', value: '', placeholder: 'Attribute Type*', name: 'outputAttributeType', arr: attributeDropdownTypes.length ? attributeDropdownTypes.map(type => type.toLowerCase()) : [''], mendatory: true, information: "Output attribute that will be returned by OpenAI."
        },
    ];
    const [attributes, setAttributes] = useState(fieldsAttributeData)
    const [attributesOutPutParameter, setAttributesOutPutParameter] = useState(fieldsOutputAttributeData)

    const indexAttribute = attributes.map((fi, index) => {
        return index
    })
    const outputIndexAttribute = attributesOutPutParameter.map((fi, index) => {
        return index
    })
    let functionId = ""
    const getObjectIdFromLocalStorage = () => {
        return localStorage.getItem('functionId');
    };
    const navigation = (route, page, step, objectId, llmFunctionsConfigForm) => {
        navigate(`/llm-function/llm-creation${route}`, {
            state: {
                previousPath: location.state?.previousPath ?? '',
                objectId: objectId,
                page: page, step: step,
                llmFunctionsConfigForm: llmFunctionsConfigForm,
                llmFunctionTest: location.state?.llmFunctionTest,
                functionId: functionId,
                llmId: llmId,
                isEditAgent: location.state?.isEditAgent,
                flag: flg,
                isSave: isSaved,
            }
        });
    };
    useEffect(() => {
        listReadyAgents()
        getApiKeys()
        listingOfLlmFunction()
        functionId = getObjectIdFromLocalStorage()
        if (llmId) {
            getLLMFunctionDetail(llmId);
        } else if (functionId) {
            getLLMFunctionDetail(functionId);
        }

    }, [attributeDropdownTypes])
    useEffect(() => {
        getAttributeValue()
    }, [])
    const isActivePublish = location?.state?.isSaves
    const fId = location?.state?.fid


    ///{inputAttributehandling open}////////////

    const addAttribute = () => {
        setAttributes(prev => [...prev, ...fieldsAttributeData.map(attribute => ({ ...attribute, name: `${attribute.type === 'dropdown' ? `attributeType` : `attributeName`}`, id: `${attribute.type === 'dropdown' ? `type${attributes.length}` : `attribute${attributes.length}`}` }))])
    }

    function removeElements(dataArray, id) {
        const index = dataArray.findIndex(item => item.id === id);
        if (index === -1) {
            return dataArray;
        }
        const newDataArray = [...dataArray];
        if (index > 0) {
            newDataArray.splice(index - 1, 2);
        } else {
            newDataArray.splice(index, 1);
        }

        return newDataArray;
    }
    const onDeleteAttribute = (id) => {
        let result = removeElements(attributes, id)
        setAttributes(result)
    };
    const onOutputDeleteAttribute = (id) => {
        let result = removeElements(attributesOutPutParameter, id)
        setAttributesOutPutParameter(result)
    };

    const onChangeAttribute = (e) => {
        const { id, value } = e?.target;
        setAttributes(prevAttributes => {
            const newAttributes = [...prevAttributes];
            const index = newAttributes.findIndex(attribute => attribute.id === id);
            if (index !== -1) {
                newAttributes[index] = { ...newAttributes[index], value };
            }
            return newAttributes;
        });
    };
    const handleDropdownChangeAttribute = (id, value) => {
        setAttributes(prevAttributes => {
            const newAttributes = [...prevAttributes];
            const index = newAttributes.findIndex(attribute => attribute.id === id);
            if (index !== -1) {
                newAttributes[index] = { ...newAttributes[index], value };
            }
            return newAttributes;
        });
    };

    ///{inputAttributehandling closed}/////////////

    // {///////   outpthandling   open ///////}
    const addAttributeOutputParameter = () => {
        setAttributesOutPutParameter(prev => [...prev, ...fieldsOutputAttributeData.map(attribute => ({ ...attribute, name: `${attribute.type === 'dropdown' ? `outputAttributeType` : `outputAttributeName`}`, id: `${attribute.type === 'dropdown' ? `outputTypeAttribute${attributesOutPutParameter.length}` : `outputAttribute${attributesOutPutParameter.length}`}` }))])
    }
    const onChangeOutputAttribute = (e) => {
        const { id, value } = e?.target;
        setAttributesOutPutParameter(prevAttributes => {
            const newAttributes = [...prevAttributes];
            const index = newAttributes.findIndex(attribute => attribute.id === id);
            if (index !== -1) {
                newAttributes[index] = { ...newAttributes[index], value };
            }
            return newAttributes;
        });
    };
    const handleOutputDropDownAttribute = (id, value) => {
        setAttributesOutPutParameter(prevAttributes => {
            const newAttributes = [...prevAttributes];
            const index = newAttributes.findIndex(attribute => attribute.id === id);
            if (index !== -1) {
                newAttributes[index] = { ...newAttributes[index], value };
            }
            return newAttributes;
        });
    };
    // {///////   outpthandling closed  ///////}
    const listReadyAgents = () => {
        ApiRequest.listReadyAgents('', '655f34ac4158543391a3e97c?excludeFlag=true', (res) => {
            if (res?.isSuccess) {
                const name = res?.data?.chatbotAgents?.map(pl => { return pl?.agentId });
                setXAgentName(name);
                setXAgentList(res?.data?.chatbotAgents?.map(pl => { return { title: pl?.agentName, apiName: pl?.agentId } }))
            } else {
                setXAgentName([]); setXAgentList([]);
            }
        });
    };

    const getAttributeValue = () => {
        ApiRequest.AttributeDefaultValues('', (res) => {
            if (res?.isSuccess) {
                const name = res?.data?.chatbotAgents?.map(pl => { return pl?.agentId });
                const types = Object.keys(res.data);
                setAttributeDropdownTypes(types);
                setAttributes(prev => prev.map(attribute => ({ ...attribute, arr: types })))
                setAttributesOutPutParameter(prev => prev.map(attribute => ({ ...attribute, arr: types })))

            } else {
                setAttributeDropdownTypes([]);
            }
        });
    }
    const inputAttributes = attributes.reduce((acc, attr, index, arr) => {
        if (index % 2 === 0) {
            acc.push({ name: attr.value, type: arr[index + 1]?.value });
        }
        return acc;
    }, []);

    const outputAttribute = attributesOutPutParameter.reduce((acc, attr, index, arr) => {
        if (index % 2 === 0) {
            acc.push({ name: attr.value, type: arr[index + 1]?.value });
        }
        return acc;
    }, []);
    const inputAttributesValid = inputAttributes.every(attr => attr.name && attr.type);
    const outputAttributesValid = outputAttribute.every(attr => attr.name && attr.type);
    const handleDataLLMFunction = (navigateAfterSave = false) => {
        if (!llmFunction.llmFunctionsName || !llmFunction.agentId || !llmFunction.key || !inputAttributesValid || !promptMessage || !outputAttributesValid) {
            toast.error("Please fill required fields")
            return;
        }
        // if (!inputAttributesValid) {
        //     toast.error("please fill input parameter")
        //     return;
        // }
        // if (!promptMessage) {
        //     toast.error("please fill prompt")
        //     return;
        // }
        // if (!outputAttributesValid) {
        //     toast.error("please fill output parameter")
        //     return;
        // }
        // if (misMatches.length > 0) {
        //     toast.error(misMatches.join(', '));
        //     return;
        // }

        if (!(getObjectIdFromLocalStorage() || llmId)) {
            saveConfig(navigateAfterSave)

        } else {

            updateSaveConfig(navigateAfterSave)
        }
    }
    const saveConfig = (navigateAfterSave) => {
        const payload = {
            agentId: llmFunction.agentId,
            apiId: llmFunction.key,
            functionName: llmFunction.llmFunctionsName,
            inputAttribute: inputAttributes,
            prompt: promptMessage,
            outputAttribute: outputAttribute
        };
        ApiRequest.createLlmFunction(payload, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
                setLlmFunctionId(dispatch, res?.data?.objectId);
                localStorage.setItem('functionId', res?.data?.objectId)
                setIsSaved(true)
                if (navigateAfterSave) {
                    navigation('/test', 2, 1, location?.state?.objectId, location?.state?.llmFunctionsConfigForm, location?.state?.functionId, isSaved)
                }

            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
        });

    };
    const updateSaveConfig = (navigateAfterSave) => {
        const payload = {
            agentId: llmFunction.agentId,
            apiId: llmFunction.key,
            functionName: llmFunction.llmFunctionsName,
            inputAttribute: inputAttributes,
            prompt: promptMessage,
            outputAttribute: outputAttribute
        };
        const id = llmId || getObjectIdFromLocalStorage()
        ApiRequest.updateLlmFunction(payload, { id }, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
                setIsSaved(true)
                if (navigateAfterSave) {
                    navigation('/test', 2, 1, location?.state?.objectId, location?.state?.llmFunctionsConfigForm, location?.state?.functionId, isSaved)
                }
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
        });

    };
    const getllmdetails = async (id) => {
        let data = {}
        await ApiRequest.llmFunctionDetail({ id }, (res) => {
            if (res.isSuccess) {
                data = res.data?.['LLM Function']
            }
        })
        return data;
    }
    const getLLMFunctionDetail = async (id) => {

        const llmDetails = await getllmdetails(id)
        setLLMFunction({
            llmFunctionsName: llmDetails?.functionName,
            agentId: llmDetails?.agentId,
            key: llmDetails?.apiId
        });
        setPromptMessage(llmDetails.prompt);
        const updatedInputAttributes = [];
        llmDetails?.inputAttribute?.forEach((attr, index) => {
            updatedInputAttributes.push({
                id: `attribute${index + 1}`,
                type: 'text',
                value: attr.name,
                placeholder: 'Write attribute name here',
                name: 'attributeName',
                mendatory: true,
                information: "Input parameters that will be passed to OpenAI."
            });
            updatedInputAttributes.push({
                id: `type${index + 1}`,
                type: 'dropdown',
                value: attr.type,
                placeholder: 'Attribute Type*',
                name: 'attributeType',
                arr: attributeDropdownTypes.length ? attributeDropdownTypes.map(type => type.toLowerCase()) : [''],
                mendatory: true,
                information: "Input attribute for which the user will pass values."
            });
        });

        setAttributes(updatedInputAttributes);
        // Update output attributes
        const updatedOutputAttributes = [];
        llmDetails.outputAttribute?.forEach((attr, index) => {
            updatedOutputAttributes.push({
                id: `outputAttribute${index + 1}`,
                type: 'text',
                value: attr.name,
                placeholder: 'Write attribute name here',
                name: 'outputAttributeName',
                mendatory: true,
                information: "Output parameters that OpenAI will return."
            });
            updatedOutputAttributes.push({
                id: `outputTypeAttribute${index + 1}`,
                type: 'dropdown',
                value: attr.type,
                placeholder: 'Attribute Type*',
                name: 'outputAttributeType',
                arr: attributeDropdownTypes.length ? attributeDropdownTypes.map(type => type.toLowerCase()) : [''],
                mendatory: true,
                information: "Output attribute that will be returned by OpenAI."
            });
        });
        setAttributesOutPutParameter(updatedOutputAttributes);
        // console.log("flag=>",llmDetails?.flag)
        setFlg(llmDetails?.flag)
    };
    const llmFunctionPublish = () => {
        const selectedId = getObjectIdFromLocalStorage() || llmId;
        const payload = {
            flag: flg === 0 ? 1 : 0
        };
        ApiRequest.llmFunctionPublish({ id: selectedId }, payload, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
                setFlg(flg === 0 ? 1 : 0)
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
        });

    };
    const getApiKeys = () => {
        ApiRequest.allApiKeys('', (res) => {
            if (res?.isSuccess) {
                const apiKeys = res?.data?.apiKeys;
                const apiIds = apiKeys.map(apiKey => { return apiKey._id });
                setApiKeyName(apiIds);
                setApiIdList(apiKeys?.map(pl => { return { title: pl?.name, apiName: pl?._id } }))
            } else {
                setApiKeyName([]); setApiIdList([])
            }
        });
    }
    const listingOfLlmFunction = () => {
        ApiRequest.listingOfLlmFunction('', (res) => {
            if (res?.isSuccess) {
                console.log(res?.data)

            } else {
                console.log('')
            }
        });
    }
    const Icons = (icon) => {
        return (
            <div style={{ width: "30px", marginRight: "15px" }}>
                <MDBox bgColor={themeColor}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                    <MDBox component="img" textAlign="center"
                        src={icon}
                        alt="Brand" width="70%"
                        style={{ height: "14px", width: "14px" }}
                    />
                </MDBox>
            </div>
        )
    }
    return (
        <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
            {/* Config Data OPEN */}
            <div className={`border-b ${darkMode ? "border-white" : "border-[#CCCCCC]"} pb-[30px]`}>
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.configLlmPattren)}

                    Configure*
                    {<Tooltip uniqueId={"Configid"} text={`Configuration details required to create LLM Function.`} color={getColorVlue(themeColor)} />}
                </div>
                <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
                    <div className="w-[100%] flex flex-row flex-wrap"
                        style={{ gap: isMdLoptop ? "26px" : "30px", marginTop: "15px" }}>
                        {fieldsConfigData.map(fi => (
                            <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "", outline: "none", display: "flex" }}>
                                {
                                    fi.type === "dropdown" ?
                                        <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                                            nameArr={fi?.arr}
                                            isMultiple={false}
                                            backendArr={fi?.name === "agentId"
                                                ? xAgentList : apiIdList
                                            }
                                            backSelect={(value) => {
                                                if (fi?.name === "agentId") {
                                                    setLLMFunction({ ...llmFunction, agentId: value })
                                                } else {
                                                    setLLMFunction({ ...llmFunction, key: value })
                                                }
                                            }}
                                            personName={fi?.name === "agentId" ? [llmFunction?.agentId]
                                                : [llmFunction?.key]}
                                            llmFunctionTbalet={true}
                                        />
                                        :
                                        <MDInput
                                            style={{ width: isTablet ? "140px" : isSmalMobileStyling ? "213px" : ismdMobileStyling ? "213px" : isMdLoptop ? "263px" : "350px" }}
                                            id={fi.name}
                                            type={fi.type}
                                            value={llmFunction[fi.name]}
                                            onChange={(e) => {
                                                setLLMFunction({ ...llmFunction, [fi.name]: e.target.value });
                                            }}
                                            label={`${fi.placeholder}${fi.mendatory ? "*" : ""}`}
                                            variant="standard"
                                        />
                                }

                                {<Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />}

                            </MDBox>
                        ))}
                    </div>
                </div>
            </div>

            {/* Config Data CLOSE */}

            {/* Attribute Name OPEN */}
            <div className={`border-b ${darkMode ? "border-white" : "border-[#CCCCCC]"} pb-[30px]`}>
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.inputParameterLlmPattern)}

                    Input Parameter*
                    {<Tooltip uniqueId={"inputParameter"} text={`Variables or values passed into a function to customize its operation`} color={getColorVlue(themeColor)} />}
                </div>
                <div style={{ display: "flex", marginLeft: windowSize.innerWidth > 600 && "43px" }}>
                    <div className={`${(isSmalMobileStyling && indexAttribute.length === 2) ? "w-[77%]"
                        : isSmalMobileStyling ? "w-[88%]"
                            : (ismdMobileStyling && outputIndexAttribute.length === 2) ? "w-[72%]"
                                : ismdMobileStyling ? "w-[73%]"
                                    : (isLargeMobile && indexAttribute.length === 2) ? "w-[66%]"
                                        : isLargeMobile ? "w-[80%]"
                                            : (isMac && indexAttribute.length === 2) ? "w-[46%]"
                                                : isMac ? "w-[56%]"
                                                    : indexAttribute.length === 2 ? "w-[69%]"
                                                        : isTablet ? "w-[90%]"
                                                            : 'w-[82%]'} flex flex-row flex-wrap`}
                        style={{ gap:isMac? "25px":"30px" }}>

                        {attributes.map((fi, index) => {
                            const values = fi?.arr?.map((value) => value?.toLowerCase())
                            return (
                                <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "", outline: "none", display: "flex" }}>
                                    {
                                        fi.type === "dropdown" ?
                                            <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                                                nameArr={values}
                                                isMultiple={false}
                                                backSelect={(value) => handleDropdownChangeAttribute(fi?.id, value)}
                                                personName={fi?.name === "attributeType" ? [fi?.value] : []}
                                                isllmFunction={true}
                                                llmFunctionTbalet={true}
                                            />
                                            :
                                            <MDInput
                                                style={{ width: isTablet ? "140px" : isMdLoptop ? "263px" : "350px" }}
                                                id={fi.id}
                                                type={fi.type}
                                                value={fi.value}
                                                onChange={onChangeAttribute}
                                                label={`${fi.placeholder}${fi.mendatory ? "*" : ""}`}
                                                variant="standard" />
                                    }
                                    {<Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />}
                                    {(index > 1 && index % 2 !== 0) && <div className={`flex items-center gap-[15px] ${isMdLoptop ? "w-[15%]" : "w-[13%]"}  cursor-pointer ${isSmalMobileStyling ? "ml-[1px]" : ismdMobileStyling ? "ml-[8px]" : "ml-[30px]"}`}
                                        style={{ marginBottom: isTablet ? "4px" : isSmallMobile ? "10px" : isLargeMobile ? "7px" : "", marginTop: 'auto', padding: isLargeMobile ? "0px" : isSmallMobile ? "0px" : '7px 0' }}
                                        onClick={() => {
                                            console.log(fi, "iddonClick")
                                            onDeleteAttribute(fi?.id)
                                        }}>
                                        <div className={`flex ${(isTablet || isSmallMobile) ? "h-[25px] w-[25px]" : "h-[33px] w-[33px]"} rounded-[50%]`} style={{ background: "red" }}>
                                            <DeleteOutlineOutlinedIcon className={`text-white ${isSmallMobile ? "ml-[2px] mr-[2px] mb-[7px] mt-[3px]" : isTablet ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : "ml-[6px] mr-[6px] mb-[7px] mt-[6px]"} `} />
                                        </div>
                                        <MDTypography style={{ fontSize: isTablet ? "11px" : "14px", fontWeight: "400" }}>{!(isLargeMobile || isSmallMobile) ? "Delete" : ""}</MDTypography>
                                    </div>}
                                </MDBox>
                            )
                        })}
                    </div>
                    <div className={`flex items-center gap-[15px] ${isMdLoptop ? "w-[15%]" : "w-[13%]"} cursor-pointer`} style={{ marginBottom: isSmallMobile ? "10px" : isLargeMobile ? "7px" : "", marginTop: 'auto', padding: isLargeMobile ? "0px" : isSmallMobile ? "0px" : '7px 0' }} onClick={() => {
                        addAttribute()
                    }}>
                        <div className={`flex ${(isTablet || isSmallMobile) ? "h-[25px] w-[25px]" : "h-[33px] w-[33px]"} rounded-[50%] ${isMac? "ml-[25px]" : ""}`} style={{ background: getColorVlue(themeColor) }}>
                            <AddIcon className={`text-white ${isSmallMobile ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : isTablet ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : "ml-[7px] mr-[7px] mb-[7px] mt-[6px]"} `} />
                        </div>
                        <MDTypography style={{ fontSize: isTablet ? "11px" : "14px", fontWeight: "400" }}>{!(isLargeMobile || isSmallMobile) ? "Add New" : ""}</MDTypography>
                    </div>
                </div>
            </div>
            {/* AttributeName */}

            {/* prompt */}

            <div className={`border-b ${darkMode ? "border-white" : "border-[#CCCCCC]"} pb-[30px]`}>
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.promptLlmPattern)}

                    Prompt*
                    {<Tooltip uniqueId={"propmtid"} text={`Input that guides the model’s behaviour, providing context or setting the tone for generating responses.`} color={getColorVlue(themeColor)} />}
                </div>
                <div style={{ display: "flex", marginLeft: windowSize.innerWidth > 600 && "43px" }}>
                    <div className="w-[100%] flex flex-row flex-wrap"
                        style={{ gap: "100px", marginTop: "15px" }}>

                        <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "", outline: "none", display: "flex" }}>
                            <OutlinedInput placeholder="Write prompt here" sx={{
                                width: isTablet ? "553px" : isMdLoptop ? "795px" : "895px",
                                minHeight: "70px",
                                borderRadius: "10px", '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#CCCCCC',
                                    borderWidth: '2px',
                                },
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                resize: 'none'

                            }} multiline value={promptMessage} onChange={(e) => setPromptMessage(e?.target?.value)} />
                        </MDBox>
                    </div>

                </div>
            </div>

            {/* prompt */}

            <div>
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.outputParameterLlmPattern)}

                    Output Parameter*
                    {<Tooltip uniqueId={"outputParameter"} text={`The results or data returned by a function`} color={getColorVlue(themeColor)} />}
                </div>
                <div style={{ display: "flex", marginLeft: windowSize.innerWidth > 600 && "43px" }}>
                    <div className={`${(isSmalMobileStyling && outputIndexAttribute.length === 2) ? "w-[77%]" : isSmalMobileStyling ? "w-[88%]" : (ismdMobileStyling && outputIndexAttribute.length === 2) ? "w-[69%]" : ismdMobileStyling ? "w-[73%]" : (isLargeMobile && outputIndexAttribute.length === 2) ? "w-[66%]" : isLargeMobile ? "w-[80%]" :(isMac && outputIndexAttribute.length === 2) ? "w-[46%]"
                                                : isMac ? "w-[56%]": outputIndexAttribute.length === 2 ? "w-[69%]" : isTablet ? "w-[90%]" : (outputIndexAttribute.length === 2 && isLargeMobile) ? "w-[80%]" : 'w-[82%]'} flex flex-row flex-wrap`}
                        style={{ gap:isMac?"25px": "30px", marginTop: "15px" }}>
                        {attributesOutPutParameter.map((fi, index) => {
                            const values = fi?.arr?.map((value) => value?.toLowerCase())
                            return (
                                <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "", outline: "none", display: "flex" }}>
                                    {
                                        fi.type === "dropdown" ?
                                            <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                                                nameArr={values}
                                                isMultiple={false}
                                                backSelect={(value) => handleOutputDropDownAttribute(fi.id, value)}
                                                personName={fi?.name === "outputAttributeType" ? [fi?.value] : []}
                                                isllmFunction={true}
                                                llmFunctionTbalet={true}
                                            />
                                            :
                                            <MDInput
                                                style={{ width: isTablet ? "140px" : isMdLoptop ? "263px" : "350px" }}
                                                id={fi.id}
                                                type={fi.type}
                                                value={fi?.value}
                                                onChange={onChangeOutputAttribute}
                                                label={`${fi.placeholder}${fi.mendatory ? "*" : ""}`}
                                                variant="standard" />
                                    }
                                    {<Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />}
                                    {(index > 1 && index % 2 !== 0) && <div className={`flex items-center gap-[15px] ${isMdLoptop ? "w-[15%]" : "w-[13%]"}  cursor-pointer ${isSmalMobileStyling ? "ml-[1px]" : ismdMobileStyling ? "ml-[8px]" : "ml-[30px]"}`}
                                        style={{ marginBottom: isTablet ? "4px" : isSmallMobile ? "10px" : isLargeMobile ? "7px" : "", marginTop: 'auto', padding: isLargeMobile ? "0px" : isSmallMobile ? "0px" : '7px 0' }}
                                        onClick={() => {
                                            console.log(fi, "iddonClick")
                                            onOutputDeleteAttribute(fi?.id)
                                        }}>
                                        <div className={`flex ${(isTablet || isSmallMobile) ? "h-[25px] w-[25px]" : "h-[33px] w-[33px]"} rounded-[50%]`} style={{ background: "red" }}>
                                            <DeleteOutlineOutlinedIcon className={`text-white ${isSmallMobile ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : isTablet ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : "ml-[7px] mr-[7px] mb-[7px] mt-[6px]"} `} />
                                        </div>
                                        <MDTypography style={{ fontSize: "14px", fontWeight: "400", color: "red" }}>{!(isLargeMobile || isSmallMobile) ? "Delete" : ""}</MDTypography>
                                    </div>}
                                </MDBox>
                            )
                        })}


                    </div>
                    <div className={`flex items-center gap-[15px] ${isMdLoptop ? "w-[15%]" : "w-[13%]"} cursor-pointer`} style={{ marginBottom: isSmallMobile ? "10px" : isLargeMobile ? "7px" : "", marginTop: 'auto', padding: isLargeMobile ? "0px" : isSmallMobile ? "0px" : '7px 0' }} onClick={() => {
                        addAttributeOutputParameter()
                    }}>
                        <div className={`flex ${(isTablet || isSmallMobile) ? "h-[25px] w-[25px]" : "h-[33px] w-[33px]"} rounded-[50%] ${isMac? "ml-[25px]" : ""}`} style={{ background: getColorVlue(themeColor) }}>
                            <AddIcon className={`text-white ${isSmallMobile ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : isTablet ? "ml-[3px] mr-[7px] mb-[7px] mt-[3px]" : "ml-[7px] mr-[7px] mb-[7px] mt-[6px]"} `} />
                        </div>
                        <MDTypography style={{ fontSize: isTablet ? "11px" : "14px", fontWeight: "400" }}>{!(isLargeMobile || isSmallMobile) ? "Add New" : ""}</MDTypography>
                    </div>
                </div>
            </div>

            {/* outputParameter */}

            <MDBox mt={4} mb={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
                <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={isLoading || isLoading2}
                    onClick={() => {
                        handleDataLLMFunction(false)

                    }} variant="gradient" color={themeColor} fullWidth>
                    {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
                </MDButton>
                <MDButton style={{ width: "max-content", minWidth: "140px" }}
                    onClick={() => {
                        handleDataLLMFunction(true)
                    }} variant="gradient" color={themeColor} fullWidth>
                    {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
                </MDButton>
                <MDButton style={{ width: "max-content", minWidth: "140px", opacity: !llmId && !isSaved && !isActivePublish ? "0.5" : "", pointerEvents: !llmId && !isSaved && !isActivePublish ? "none" : "" }}
                    disabled={!llmId && !isSaved && !isActivePublish}
                    onClick={() => {
                        llmFunctionPublish()
                    }} variant="gradient" color={themeColor} fullWidth>
                    {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : (flg === 1 ? 'Unpublish' : 'Publish')}
                </MDButton>
            </MDBox>

        </div>
    )
}