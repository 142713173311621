import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMaterialUIController } from "context";
import { useMediaQuery } from '@mui/material';

const Modal = ({ open, setOpen, children, isNoPadding, isSmall, isMedium, isApikey }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" style={{ zIndex: 99 }} className="relative" onClose={() => {setOpen(false)}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div style={{ zIndex: 99 }} className="fixed inset-0 overflow-y-auto">
                    <div className={`flex ${ (isLargeMobile || isSmallMobile) && isApikey ? "min-h-[83%]" : "min-h-full"} items-end justify-center ${!isNoPadding && "p-4"} text-center sm:items-center sm:p-0`}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                style={{ background: darkMode ? "#202940" : "white", color: !darkMode ? "black" : "white" }}
                                className={`relative transform overflow-hidden ${!isNoPadding && "px-4 py-4"} rounded-lg  bg-white
                                            text-left shadow-xl transition-all sm:my-8 sm:w-full ${isSmall ? 'sm:max-w-lg' : isMedium ? 'sm:max-w-2xl' : 'sm:max-w-4xl'}`}>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Modal;
