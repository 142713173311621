import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import {  useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';

import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import {useMaterialUIController} from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Methods from "libraries/CommonMethodsUI";
import { SpinnerLoader } from "examples/Loader/Loader";
import { useMediaQuery } from '@mui/material';


export default function UsageHistoryTable({backPage, paymentsList, isLoader}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [controller, dispatch] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const [actualPaymentsList, setActualPaymentsList] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setActualPaymentsList(paymentsList ?? [])
  }, [paymentsList]);

  useEffect(() => {
    setPage(0);
  }, [backPage]);


  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1D3E80";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };

  return (
    <div >
      {/* style={{ padding: '0px 10px' }} */}
        <div style={{ height: "45px", width: '100%', display: 'flex', backgroundColor: getBackgroundColor(), borderRadius: '8px', alignItems: 'center' }}>
              {/* {windowSize.innerWidth <= 600 && <TableCell  align={"left"} style={{ width: "30px" }}></TableCell>} */}
                  <TableCell
                    align={"left"}
                    style={{ border: "none", width: windowSize.innerWidth > 600 ? "20%" : "50%",  fontWeight: "600", fontSize: "16px", color: "white" }}
                  >
                    Agent
                  </TableCell>
                  <TableCell
                    align={"left"}
                    style={{ border: "none", width: windowSize.innerWidth > 600 ? "15%" : "50%",  fontWeight: "600", fontSize: "16px", color: "white" }}
                  >
                    Input Tokens
                  </TableCell>
                  
                  {windowSize.innerWidth > 600 && <>
                    <TableCell
                    align={"left"}
                    style={{ border: "none",  width: windowSize.innerWidth > 600 ? "15%" : "50%", fontWeight: "600", fontSize: "16px", color: "white" }}
                  >
                    Output Tokens
                  </TableCell>
                  <TableCell
                    align={"left"}
                    style={{border: "none",  width: windowSize.innerWidth > 600 ? "15%" : "50%", fontWeight: "600", fontSize: "16px", color: "white"  }}
                  >
                    Consumed Tokens
                  </TableCell> 
                  <TableCell
                    align={"left"}
                    style={{border: "none",  width: windowSize.innerWidth > 600 ? "20%" : "50%", fontWeight: "600", fontSize: "16px", color: "white"  }}
                  >
                    Pattern
                  </TableCell>    
                  <TableCell
                    align={"left"}
                    style={{border: "none",  width: windowSize.innerWidth > 600 ? "15%" : "50%", fontWeight: "600", fontSize: "16px", color: "white"  }}
                  >
                    Date / Time
                  </TableCell>              
                  </>}        
        </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
      <TableContainer >
      {/* sx={{ maxHeight: 440 }} */}
        <Table >

          <TableBody>
            {/* Header */}

            {/* Body List  */}
            {isLoader ?
                  <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                    {[1, 2, 3].map(() => (
                      <div className="w-56 mr-8">
                        <Code backgroundColor="grey" foregroundColor="darkgrey" />
                      </div>
                    ))}
                  </div>
                  :
             actualPaymentsList?.length ? 
            <>
            { actualPaymentsList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, i) => {
                return (
                  <>
                  <TableRow tabIndex={-1} style={{ backgroundColor: !darkMode ? i % 2 === 0 ? "white" : "#F1F1F1" : "" }}>
                      <TableCell key={i} align={"left"} 
                        style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "20%" }}>
                          {
                            windowSize.innerWidth <= 600 &&
                             <IconButton
                             aria-label="expand row"
                             size="small"
                             onClick={() => {setOpen(!open); setCollapseRow(i+1)}}
                           >
                             {(open && (collapseRow === (i+1))) ? 
                                 <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} /> 
                                 : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                           </IconButton>
                          }
                          {row?.agentName}
                      </TableCell>
                      <TableCell key={i} align={"left"} 
                      style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "15%" }}>
                          {Methods.formatNumber(row?.inputTokensUsed)}
                      </TableCell>
                     
                      {windowSize.innerWidth > 600 && 
                      <>
                      <TableCell key={i} align={"left"} style={{color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "15%" }}>
                          {Methods.formatNumber(row?.outputTokensUsed)}   
                      </TableCell>

                      <TableCell key={i} align={"left"} 
                      style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "15%" }}>
                          {Methods.formatNumber(row?.totalTokensUsed)}
                      </TableCell>

                      <TableCell key={i} align={"left"} 
                      style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "20%" }}>
                          {row?.patternName}
                      </TableCell>

                      <TableCell key={i} align={"left"} 
                      style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "15%" }}>
                          {Constants.formatDashDates(row?.timestamp, true)}
                          {console.log("Format Dash dates", Constants.formatDashDates(row?.timestamp, false))}
                      </TableCell>
                      
                      </>
                      }
                      
                  </TableRow>

                  {/* Extended Row */}
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={open && (collapseRow === i+1)} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                        <Table>
                            <MDBox component="thead">
                                <TableRow >
                                    <TableCell
                                      // key={column.id}
                                      align={"left"}
                                      style={{ width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                    >
                                      Output Tokens
                                    </TableCell> 
                                    <TableCell
                                      // key={column.id}
                                      align={"left"}
                                      style={{ width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                    >
                                      Consumed Tokens
                                    </TableCell>                                
                                </TableRow>
                            </MDBox>
                            <TableBody >
                                  <>
                                  <TableRow tabIndex={-1}>
                                    <TableCell style={{border: "none", display: "flex", color:  darkMode ? "white" : "black" }} key={i}>
                                        {Methods.formatNumber(row?.outputTokensUsed)}
                                    </TableCell>
                                    <TableCell key={i} style={{border: "none",  color: darkMode ? "white" : "black" }}>
                                    {Methods.formatNumber(row?.totalTokensUsed)}  
                                    </TableCell>
                                  </TableRow>
                                  </>
                                  
                            </TableBody>
                          </Table>
                          <Table>
                            <MDBox component="thead">
                                <TableRow >
                                    <TableCell
                                      // key={column.id}
                                      align={"left"}
                                      style={{ width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                    >
                                      Pattern
                                    </TableCell> 
                                    <TableCell
                                      // key={column.id}
                                      align={"left"}
                                      style={{ width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                    >
                                       Date/Time
                                    </TableCell>                                
                                </TableRow>
                            </MDBox>
                            <TableBody >
                                  <>
                                  <TableRow tabIndex={-1}>
                                    <TableCell style={{border: "none", display: "flex", color: darkMode ? "white" : "black" }} key={i} >
                                    {row?.patternName}    
                                    </TableCell>
                                    <TableCell key={i} style={{border: "none",  color: darkMode ? "white" : "black" }}>
                                    {Constants.formatDashDates(row?.timestamp, true)}
                                    </TableCell>
                                  </TableRow>
                                  </>
                                  
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  </>
                );
              })}
            </>
             : <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No usage history found</div>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        style={{ color: darkMode ? "white" : "black" }}
        count={actualPaymentsList?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

        
    </Paper>
    </div>
  );
}
