export const fields = [
  { id: 1, type: 'number', placeholder: 'Enter Current Valuation', name: "currentEvaluation", mendatory: true },
  { id: 2, type: 'number', placeholder: 'Net Profit', name: "netProfit", mendatory: true },
  { id: 3, type: 'number', placeholder: 'PE Ratio', name: "peRatio", mendatory: true },
]

export const businessOperations = ['Sales', 'Operations*', 'Customer Services', 'Finance', 'HR', 'Procurement', 'IT']

export const fteHeadCount = [
  { id: 1, type: 'text', name: "emp1", employeeName:"employee1", contractorName: "contractor1", fteEquivalentName: "fteEquivalent1", businessOperationsName: "salesFte" },
  { id: 2, type: 'text', name: "emp2", employeeName:"employee2", contractorName: "contractor2", fteEquivalentName: "fteEquivalent2", businessOperationsName: "operationsFte" },
  { id: 3, type: 'text', name: "emp3", employeeName:"employee3", contractorName: "contractor3", fteEquivalentName: "fteEquivalent3", businessOperationsName: "customerServiceFte" },
  { id: 4, type: 'text', name: "emp4", employeeName:"employee4", contractorName: "contractor4", fteEquivalentName: "fteEquivalent4", businessOperationsName: "financeFte" },
  { id: 5, type: 'text', name: "emp5", employeeName:"employee5", contractorName: "contractor5", fteEquivalentName: "fteEquivalent5", businessOperationsName: "hrFte" },
  { id: 6, type: 'text', name: "emp6", employeeName:"employee6", contractorName: "contractor6", fteEquivalentName: "fteEquivalent6", businessOperationsName: "procurementFte" },
  { id: 7, type: 'text', name: "emp7", employeeName:"employee7", contractorName: "contractor7", fteEquivalentName: "fteEquivalent7", businessOperationsName: "itFte" },
]
export const loadedEmploymentCost = [
  { id: 1, type: 'text', name: "avgemp1", employeeName:"employee1", employeeAvgName:"employeeAvgCost1", contractorCostName: "contractorcost1", businessEmploymentCostName: "salesAvgFte", fteHeadCountName: "emp1", contractorNameValue: "contractor1", fteOutSourceName:"outSourcing1", fteEquivalentName:"fteEquivalent1", outSourcingCostName: "outSourcingcContractor1" },
  { id: 2, type: 'text', name: "avgemp2", employeeName:"employee2", employeeAvgName:"employeeAvgCost2", contractorCostName: "contractorcost2", businessEmploymentCostName: "operationsAvgFte", fteHeadCountName: "emp2", contractorNameValue: "contractor2", fteOutSourceName:"outSourcing2", fteEquivalentName:"fteEquivalent2", outSourcingCostName: "outSourcingcContractor2" },
  { id: 3, type: 'text', name: "avgemp3", employeeName:"employee3", employeeAvgName:"employeeAvgCost3", contractorCostName: "contractorcost3", businessEmploymentCostName: "customerServiceAvgFte", fteHeadCountName: "emp3", contractorNameValue: "contractor3", fteOutSourceName:"outSourcing3", fteEquivalentName:"fteEquivalent3", outSourcingCostName: "outSourcingcContractor3" },
  { id: 4, type: 'text', name: "avgemp4", employeeName:"employee4", employeeAvgName:"employeeAvgCost4", contractorCostName: "contractorcost4", businessEmploymentCostName: "financeAvgFte", fteHeadCountName: "emp4", contractorNameValue: "contractor4", fteOutSourceName:"outSourcing4", fteEquivalentName:"fteEquivalent4", outSourcingCostName: "outSourcingcContractor4" },
  { id: 5, type: 'text', name: "avgemp5", employeeName:"employee5", employeeAvgName:"employeeAvgCost5", contractorCostName: "contractorcost5", businessEmploymentCostName: "hrAvgFte", fteHeadCountName: "emp5", contractorNameValue: "contractor5", fteOutSourceName:"outSourcing5", fteEquivalentName:"fteEquivalent5", outSourcingCostName: "outSourcingcContractor5" },
  { id: 6, type: 'text', name: "avgemp6", employeeName:"employee6", employeeAvgName:"employeeAvgCost6", contractorCostName: "contractorcost6", businessEmploymentCostName: "procurementAvgFte", fteHeadCountName: "emp6", contractorNameValue: "contractor6", fteOutSourceName:"outSourcing6", fteEquivalentName:"fteEquivalent6", outSourcingCostName: "outSourcingcContractor6" },
  { id: 7, type: 'text', name: "avgemp7", employeeName:"employee7", employeeAvgName:"employeeAvgCost7", contractorCostName: "contractorcost7", businessEmploymentCostName: "itAvgFte", fteHeadCountName: "emp7", contractorNameValue: "contractor7", fteOutSourceName:"outSourcing7", fteEquivalentName:"fteEquivalent7", outSourcingCostName: "outSourcingcContractor7" },
]
export const numberOfEmployee = [
  { id: 1, type: 'text', name: "employee1" },
  { id: 2, type: 'text', name: "employee2" },
  { id: 3, type: 'text', name: "employee3" },
  { id: 4, type: 'text', name: "employee4" },
  { id: 5, type: 'text', name: "employee5" },
  { id: 6, type: 'text', name: "employee6" },
  { id: 7, type: 'text', name: "employee7" },
]
export const averageEmployeeCost = [
  { id: 1, type: 'text', name: "employeeAvgCost1" },
  { id: 2, type: 'text', name: "employeeAvgCost2" },
  { id: 3, type: 'text', name: "employeeAvgCost3" },
  { id: 4, type: 'text', name: "employeeAvgCost4" },
  { id: 5, type: 'text', name: "employeeAvgCost5" },
  { id: 6, type: 'text', name: "employeeAvgCost6" },
  { id: 7, type: 'text', name: "employeeAvgCost7" },
]
export const numberOfContractors = [
  { id: 1, type: 'text', name: "contractor1", businessOperationsName: "salesContractors" },
  { id: 2, type: 'text', name: "contractor2", businessOperationsName: "operationsContractors" },
  { id: 3, type: 'text', name: "contractor3",  businessOperationsName: "customerServiceContractors" },
  { id: 4, type: 'text', name: "contractor4", businessOperationsName: "financeContractors" },
  { id: 5, type: 'text', name: "contractor5", businessOperationsName: "hrContractors"  },
  { id: 6, type: 'text', name: "contractor6",businessOperationsName: "procurementContractors"  },
  { id: 7, type: 'text', name: "contractor7", businessOperationsName: "itContractors"  },
]
export const contractorCost = [
  { id: 1, type: 'text', name: "contractorcost1", businessEmploymentCostName: "salesAvgFte", },
  { id: 2, type: 'text', name: "contractorcost2", businessEmploymentCostName: "operationsAvgFte",  },
  { id: 3, type: 'text', name: "contractorcost3", businessEmploymentCostName: "customerServiceAvgFte",  },
  { id: 4, type: 'text', name: "contractorcost4", businessEmploymentCostName: "financeAvgFte",  },
  { id: 5, type: 'text', name: "contractorcost5", businessEmploymentCostName: "hrAvgFte",  },
  { id: 6, type: 'text', name: "contractorcost6", businessEmploymentCostName: "procurementAvgFte",  },
  { id: 6, type: 'text', name: "contractorcost7", businessEmploymentCostName: "itAvgFte",  },
]
export const outsourcingType = [
  { id: 1, type: 'text', name: "outSourcing1", fteEquivalentOutsourceName: "fteEquivalent1", outsourcingCostName: "outSourcingcContractor1" },
  { id: 2, type: 'text', name: "outSourcing2", fteEquivalentOutsourceName: "fteEquivalent2", outsourcingCostName: "outSourcingcContractor2" },
  { id: 3, type: 'text', name: "outSourcing3", fteEquivalentOutsourceName: "fteEquivalent3", outsourcingCostName: "outSourcingcContractor3" },
  { id: 4, type: 'text', name: "outSourcin4", fteEquivalentOutsourceName: "fteEquivalent4", outsourcingCostName: "outSourcingcContractor4" },
  { id: 5, type: 'text', name: "outSourcing5", fteEquivalentOutsourceName: "fteEquivalent5", outsourcingCostName: "outSourcingcContractor5" },
  { id: 6, type: 'text', name: "outSourcing6", fteEquivalentOutsourceName: "fteEquivalent6", outsourcingCostName: "outSourcingcContractor6" },
  { id: 6, type: 'text', name: "outSourcing7", fteEquivalentOutsourceName: "fteEquivalent7", outsourcingCostName: "outSourcingcContractor7" },
]
export const outsourcingContractorType = [
  { id: 1, type: 'text', name: "outSourcingcContractor1" },
  { id: 2, type: 'text', name: "outSourcingcContractor2" },
  { id: 3, type: 'text', name: "outSourcingcContractor3" },
  { id: 4, type: 'text', name: "outSourcingcContractor4" },
  { id: 5, type: 'text', name: "outSourcingcContractor5" },
  { id: 6, type: 'text', name: "outSourcingcContractor6" },
  { id: 6, type: 'text', name: "outSourcingcContractor7" },
]
export const fteEquivalentOutsourcing = [
  { id: 1, type: 'text', name: "fteEquivalent1", outsourcingName: "outSourcing1" },
  { id: 2, type: 'text', name: "fteEquivalent2", outsourcingName: "outSourcing2" },
  { id: 3, type: 'text', name: "fteEquivalent3", outsourcingName: "outSourcing3" },
  { id: 4, type: 'text', name: "fteEquivalent4", outsourcingName: "outSourcing4" },
  { id: 5, type: 'text', name: "fteEquivalent5", outsourcingName: "outSourcing5" },
  { id: 6, type: 'text', name: "fteEquivalent6", outsourcingName: "outSourcing6" },
  { id: 6, type: 'text', name: "fteEquivalent7", outsourcingName: "outSourcing7" },
]
