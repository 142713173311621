import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MDDropdown from "components/MDDropdown";
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Input } from '@mui/material';

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { Paths } from "libraries/Route";

import { useMaterialUIController } from "context";
import { FormControl } from "@mui/material";
import useScreenWidth from "libraries/ScreenSizeHook";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "examples/Modal/Modal";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { getColorVlue } from 'services/Utils';
import MDInput from "components/MDInput";
import ApiRequest from "services/ApiRequest";
import CloseIcon from '@mui/icons-material/Close';

//images
import { Images } from "libraries/Images";
import MDTypography from "components/MDTypography";

export const LLMFunctionTest = () => {
    const [windowSize, getWindowSize] = useScreenWidth();
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const navigate = useNavigate()
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [attributeNames, setAttributeNames] = useState([]);
    const [inputAttribute, setInputAttribute] = useState([]);
    const [responseLlmInfer, setResponseLlmInfer] = useState({})
    const [isLoadingTest, setISLoadingTest] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [flg, setFlg] = useState()
    const [accessDetail, setAccessDetail] = useState({ AuthenticationHeader: "", Note: "", Method: "", Url: "", RequestPayload: "", ResponsePayload: "" })
    const { llmFunctionTest } = location?.state || {};
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const [saves, setSaves] = useState(true)
    const id = localStorage.getItem('functionId')
    const navigation = (route, page, step, llmFunctionTest) => {
        navigate(`/llm-function/llm-creation${route}`, {
            state: {
                previousPath: location.state?.previousPath ?? '',
                objectId: location?.state?.objectId,
                page: page, step: step,
                llmFunctionTest: llmFunctionTest,
                llmFunctionsConfigForm: location.state?.llmFunctionsConfigForm,
                llmId: location?.state?.llmId,
                isEditAgent: location.state?.isEditAgent,
                isSaves: saves,
                fid: id

            }
        });
    };
    const functionId = location?.state?.objectId;

    useEffect(() => {
        getLLMFunctionInfer()
        getdetails()
    }, [functionId, id])

    const getdetails = () => {
        if (functionId) {
            getLLMFunctionDetail(functionId);
        } else if (id) {
            getLLMFunctionDetail(id);
        }
    }

    const getLLMFunctionInfer = () => {
        const selectedId = functionId || id;
        ApiRequest.getTestAttributeData(selectedId, (res) => {
            if (res.isSuccess) {
                setInputAttribute(res?.data?.inputAttributes)
                const names = res.data.inputAttributes.map(attr => attr.name);
                setAttributeNames(names);
                // toast.success(res.data.message ? res.data.message : "Configuration Created");
            } else {
                console.log('Error fetching data');
            }
        });
    };
    const llmFunctionInferenceBeforePublish = () => {
        setISLoadingTest(true)
        const selectedId = functionId || id;
        const updatedObj = Object?.fromEntries(
            Object?.entries(inputValues)?.map(([key, value]) => {
                try {
                    // Attempt to parse the value as JSON
                    if (value === 'true') {
                        return [`${key}`, true];
                    }
                    if (value === 'false') {
                        return [`${key}`, false];
                    }
                    const parsedValue = JSON?.parse(value);
                    // If it's an object or array after parsing, return that parsed value
                    if (parsedValue && (typeof parsedValue === 'object' || Array?.isArray(parsedValue))) {
                        return [`${key}`, parsedValue];
                    }
                } catch (error) {
                    console.log('error', error);
                }
                // Check if the value is a float
                if (!isNaN(value) && value.toString()?.includes('.')) {
                    return [`${key}`, parseFloat(value)];
                }
                // Convert value to a number if it's a numeric string, otherwise return the value as-is
                return [`${key}`, isNaN(value) ? value : Number(value)];
            })
        );
        const payload = {
            inputAttribute: updatedObj
        };
        ApiRequest.llmFunctionInference(payload, { id: selectedId }, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Response generated successfully");
                setResponseLlmInfer(res?.data)
                setISLoadingTest(false)
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Failed");
                setISLoadingTest(false)
            }
        });

    };
    const getllmdetails = async (id) => {
        let data = {}
        await ApiRequest.llmFunctionDetail({ id }, (res) => {
            if (res.isSuccess) {
                data = res.data?.['LLM Function']
            }
        })
        return data;
    }

    const getLLMFunctionDetail = async (id) => {

        const llmDetails = await getllmdetails(id)
        setFlg(llmDetails?.flag)
    };
    const llmFunctionPublish = () => {
        const selectedId = functionId || id;
        const payload = {
            flag: flg === 0 ? 1 : 0
        };
        ApiRequest.llmFunctionPublish({ id: selectedId }, payload, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Function publish successfully");
                setFlg(flg === 0 ? 1 : 0)
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Failed publish");
            }
        });

    };

    const getAccessDetails = () => {
        const selectedId = functionId || id;
        ApiRequest.getAccessDetail({ id: selectedId }, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Fetch user detail successfully");
                const data = res?.data
                setAccessDetail({ AuthenticationHeader: data["Authentication(Header)"], Note: data?.Note, Method: data?.Method, Url: data.URL, RequestPayload: data?.['Request Payload'], ResponsePayload: data?.['Response Payload'] })
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Fetch user detail failed");
            }
        });
    };
    const handleAccesDetails = () => {
        getAccessDetails()
        setModalOpen(true)
    }
    const handleInputChange = (name, value) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };
    const reconfigBtn = () => {
        navigation('/config', 1, 0, location?.state?.llmFunctionTest,);
    };

    const divider = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <div style={{ background: darkMode ? "white" : "#CCCCCC", height: "1px", width: "100%", borderRadius: "50%", margin: "30px 0px 0px 0px" }}></div>
            </div>
        )
    };
    const Icons = (icon) => {
        return (
            <div style={{ width: "30px", marginRight: "15px" }}>
                <MDBox bgColor={themeColor}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                    <MDBox component="img" textAlign="center"
                        src={icon}
                        alt="Brand" width="70%"
                        style={{ height: "14px", width: "14px" }}
                    />
                </MDBox>
            </div>
        )
    }
    return (
        <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
            {/* Request Data OPEN */}
            <div>
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.responseLLMFunction)}

                    Request
                    {<Tooltip uniqueId={"requestId"} text={`Pass the values of the input attributes that will be sent to OpenAI.`} color={getColorVlue(themeColor)} />}
                </div>
            </div>

            <TableContainer sx={{ boxShadow: "none" }}>
                <Table>
                    <TableBody>
                        {attributeNames?.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell size="small" sx={{ paddingLeft: "43px", border: "none" }} style={{ width: "50px" }} >
                                    <Typography
                                        style={{
                                            fontSize: isSmallMobile ? "9px" : isLargeMobile ? "10px" : "14px",
                                            fontWeight: "600",
                                            color: darkMode ? "white" : "black",
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "none" }} >
                                    <MDInput
                                        style={{ width: isSmallMobile ? "137px" : isLargeMobile ? "190px" : "400px", color: darkMode ? "white" : "black", }}
                                        id={`input-${index}`}
                                        type="text"
                                        value={inputValues[item] || ''}
                                        onChange={(e) => handleInputChange(item, e.target.value)}
                                        variant="standard"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <MDButton size="small" style={{ width: "max-content", minWidth: "100px", marginTop: "20px", marginLeft: isSmallMobile ? "126px" : isLargeMobile ? "225px" : "477px" }} variant="gradient" color={themeColor} onClick={llmFunctionInferenceBeforePublish} fullWidth>{isLoadingTest ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Test'}</MDButton>

            {/* Request Data CLOSE */}

            {divider()}

            {/* Response Data Open */}
            <div className="mt-[20px]">
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                    {Icons(Images.responseLLMFunction)}

                    Response
                    {<Tooltip uniqueId={"responseID"} text={`Response given by OpenAI.`} color={getColorVlue(themeColor)} />}
                </div>
                <MDBox sx={{ width: "100%", minHeight: "80px", border: "2px solid #CCCCCC", borderRadius: "10px", marginTop: "20px" }}>
                    <div className={`flex ${isSmallMobile ? "px-[4px] py-[10px]" : isLargeMobile ? "px-[5px] py-[10px]" : "px-[6px] py-[10px]"} flex-col`}>
                        {Object?.keys(responseLlmInfer).length ?
                            Object?.keys(responseLlmInfer)?.map((key) => (
                                <div className={`flex ${isSmallMobile ? "px-[4px] py-[10px]" : isLargeMobile ? "px-[5px] py-[10px]" : "px-[6px] py-[10px]"} flex-col`} >
                                    <MDTypography sx={{ fontSize: isSmallMobile ? "8px" : isLargeMobile ? "9px" : '14px', fontWeight: '600', }} style={{ minWidth: isSmallMobile ? "60px" : isLargeMobile ? "70px" : "100px" }}>
                                        {key} :
                                    </MDTypography>
                                    <MDTypography sx={{ paddingLeft: "2px", fontSize: isSmallMobile ? "9px" : isLargeMobile ? "11px" : '14px', fontWeight: '400' }}>
                                        {Array.isArray(responseLlmInfer[key])
                                            ? `[${responseLlmInfer[key].join(", ")}]` 
                                            : responseLlmInfer[key]} 
                                    </MDTypography>
                                </div>
                            ))
                            :
                            <></>
                        }
                    </div>
                    <div className="flex justify-end mr-[20px]">
                        <IconButton edge="end" onClick={() => {
                            const formattedResponse = Object?.entries(responseLlmInfer)
                                ?.map(([key, value]) => `"${key}": "${value}"`)
                                .join('\n');
                            navigator.clipboard.writeText(formattedResponse);
                            toast.success(" Response copied")

                        }}>
                            <ContentCopyIcon color={darkMode ? "white" : "black"} />
                        </IconButton>
                    </div>
                </MDBox>
            </div>
            {/* Response Data Closed */}

            {divider()}
            <MDBox mt={4} mb={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
                <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={isLoading || isLoading2}
                    onClick={() => {
                        reconfigBtn()
                    }} variant="gradient" color={themeColor} fullWidth>
                    {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Previous'}
                </MDButton>
                <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={isLoading || isLoading2}
                    onClick={() => {
                        llmFunctionPublish()
                    }} variant="gradient" color={themeColor} fullWidth>
                    {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : (flg === 1 ? 'Unpublish' : 'Publish')}
                </MDButton>
                <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={isLoading || isLoading2}
                    onClick={handleAccesDetails} variant="gradient" color={themeColor} fullWidth>
                    {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'GET ACCESS DETAILS'}
                </MDButton>
            </MDBox>
            {modalOpen &&
                <Modal open={modalOpen} setOpen={() => setModalOpen(false)} isMedium={isTablet}>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <CloseIcon className="w-[13px] h-[13px] cursor-pointer" style={{ color: darkMode ? "white" : "#000000" }} onClick={() => setModalOpen(false)} />
                    </MDBox>
                    <MDTypography style={{ display: "flex", alignItems: "center", fontWeight: "600", justifyContent: "center", color: darkMode ? "white" : "black" }}>User Access Detail</MDTypography>
                    <div className="flex flex-col w-full mt-[20px] justify-center items-start text-xl p-[20px]" style={{ overflowX: (isLargeMobile || isSmallMobile) && "scroll" }}>
                        {Object?.entries(accessDetail).length ?
                            Object?.entries(accessDetail)?.map(([key, value]) => (
                                <div key={key} className="mb-2">
                                    <strong>{key}:</strong> <span style={{ color: key === "AuthenticationHeader" && "red" }}>{JSON?.stringify(value, null, 2)}</span>
                                </div>
                            ))
                            :
                            <></>
                        }

                    </div>
                </Modal>
            }

        </div>
    )
}