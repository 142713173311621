/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { SpinnerLoader } from "examples/Loader/Loader";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { validateAndProcessFile } from "services/Utils";
import Methods from "libraries/CommonMethodsUI";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa";
import Constants from "libraries/Constants";
import MDDropdown from "components/MDDropdown";
import { FaCopyright } from "react-icons/fa6";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, FormControl } from "@mui/material";
import { Tag } from "examples/Tag/Tag";
import { AudioPlay } from "./textToSpeech/AudioPlayer";
import ApiService from "services/ApiService";
import Modal from "examples/Modal/Modal";
// import CustomSelect from "components/CustomSelect/CustomSelect"; 


function TextToSpeechInference() {
  const navigate = useNavigate()
  const location = useLocation()
  const { id, name } = useParams()

  const { configForm } = location?.state || {};
  const { agentName, description, modelType, pageSegmentation, modelName, openAIKey, systemPrompt, temperature, maxTokens } = configForm || {};
  const [uploadedFile, setUploadedFile] = useState([]);
  const [inferResult, setInferResult] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [inputMode, setInputMode] = useState('none');
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [docId, setDocId] = useState('');
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, document: {} });
  const [textAlign, setTextAlign] = useState('left');
  const objectId = location.state?.objectId
  // localStorage.setItem("objectId", objectId)
  // const objectID = localStorage.getItem("objectId")
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 426px)');

  const navigation = (route, page, step, objectId, configForm) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };
  useEffect(() => (
    navigation('/inference', 3, 1, location?.state?.objectId, location?.state?.configForm)
  ), []);

  useEffect(() => {
    getAgentDetails()
  }, [])

  const uploadedContent = (fl) => {
    let supportedFormats = {};

    supportedFormats = {
      'application/pdf': 'PDF',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'text/plain': 'TXT',
      'application/msword': 'DOCX',
    }

    const afile = fl?.[0];

    const validation = validateAndProcessFile(afile, supportedFormats)
    if (validation === true && fl) {
      setText('');
      setFile(afile);
      setFileName(afile?.name);
      setInputMode('file');
      toast.success(`${afile?.name} uploaded successfully.`);
    } else {
      toast.error(`${validation}`)
    }
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location.state?.objectId, location?.state?.configForm);
  };

  const inferenceDownload = () => {
    if (!uploadedFile?.length) {
      reconfigBtn()
    } else {
      setUploadedFile([])
    }
  };

  const saveConfig = () => {
    setIsLoading(true);
    if(!text && !file){
      toast.error("please fill required field")
      setIsLoading(false)
      return;
    }
    const id = objectId;
    const deployedURL = location?.state?.deployedURL;
    const formData = new FormData();
  
    if (text) {
      formData.append('text', text);
    }
    if (file) {
      formData.append('file', file);
    }
  
    ApiRequest.inferTextToSpeechAgent(formData, { id, deployedURL }, (res) => {
      if (res.isSuccess) {
        setAudioSrc(res.url);
        toast.success(res?.data?.message);
        setIsLoading(false);
        if (file || text.length) {
          nextInference();
        }
      } else {
        toast.error(res?.error?.message);
        setIsLoading(false);
      }
    });
  };
  const getAgentDetails = () => {
    ApiRequest.agentList(`/${objectId}`, '', (res) => {
      if (res.isSuccess) {
        setDocId(res?.data?.agentsInformation?.[0]?.outputDocumentDetails?.[0]?._id)
      } else {
        toast.error(res?.error?.message ? res?.error?.message : "Failed");
        setIsLoading(false)
      }
    })
  }
  const downloadAudioFile = () => {
    ApiRequest.inferBucketSaveFeatureAgent({
      documentId: docId,
      modelName: "",
      inputOrOutput: "output",
    },
      `${objectId}`, (res) => {
        if (res.isSuccess) {
          Methods.downloadAudio(res?.data, `${fileName}.mp3`);
          toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
        } else {
          toast.error(res?.error?.message ? res?.error?.message : "Failed")
        }
      });
  }
  const nextInference = () => {

    navigation('/inference', 4, 1, location?.state?.objectId, location?.state?.configForm);
  }
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    if (newText.length > 0) {
      setInputMode('text'); // Set mode to text
    } else {
      setInputMode('none'); // Reset mode if text is cleared
    }
    const rtlRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    if (rtlRegex.test(newText)) {
      setTextAlign('right');
    } else {
      setTextAlign('left');
    }
  };
  const deleteDocument = () => {
        setModalDelOpen({ flag: false, document: !file, })
        setFile(null)
        setFileName("")
        setInputMode('file')
  }

  return (
    <div className="flex flex-col" style={{ height: "calc(100%)", justifyContent: "space-between" }}>
      {location.state?.page === 4 ?
        <>
          {
            audioSrc && <AudioPlay audioSrc={audioSrc} isPlaying={isPlaying} handlePlayPause={handlePlayPause} setAudioDownload={downloadAudioFile} />}
          <div className="flex justify-center">
            <MDButton style={{ width: "max-content", minWidth: "140px" }}
              onClick={() => navigation('/inference', 3, 1, location?.state?.objectId, location?.state?.configForm)} variant="gradient" color={themeColor} fullWidth>
              {"Previous"}
            </MDButton>
          </div>
        </>
        :
        <>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} sx={{ paddingLeft: { xs: "0px", md: "50px !important" } }}>
              <p className={`text-xl font-semibold text-[#0F0F0F] ${darkMode ? "text-white" : "text-black"}`}>Add Text</p>

              <Box className=' border-gray-300 mt-[20px]'>
                {/* <div style={{ height: "300px" }}  className="border-2 border flex justify-center rounded-xl"> */}

                <textarea
                  // ref={textareaRef}
                  value={text}
                  placeholder="Add your text here"
                  onChange={handleTextChange}
                  style={{
                    color: darkMode ? "white" : "black",
                    borderColor: darkMode ? "white" : "lightgrey",
                    background: !darkMode ? "white" : "#202940", resize: 'none', paddingRight:textAlign==='left' && "30px",
                    width: '100%', borderRadius: "10px",
                    height: "300px", maxHeight: "300px",
                    opacity: inputMode === 'file' ? 0.5 : 1,
                    pointerEvents: inputMode === 'file' ? 'none' : 'auto',
                    textAlign: textAlign
                  }}
                  disabled={inputMode === 'file'}
                />

                {/* </div> */}
              </Box>
              <div style={{ textAlign: "right", fontSize: "14px", color: darkMode ? "white" : "black" }}>
                {text.length} / 4000 Characters
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} className="w-full">
              <p className={`text-xl font-semibold ${isMobile ? "0px" : "pl-[23px]"} ${darkMode ? "text-white" : "text-black"}`}>Document upload</p>
              <Box className='md:border-b-0 md:border-l md:pl-5 border-gray-300 md:border-gray-300 mt-[20px]'>
                <div style={{ height: "300px", opacity: inputMode === 'text' ? 0.5 : 1, pointerEvents: inputMode === 'text' ? 'none' : 'auto' }} className="border-2 border-dashed flex justify-center rounded-xl flex-col">
                  <div className="px-[15px]">
                    <TabUpload supportedFileTypes={"PDF, DOCX, TXT"} isSingleFile={true}
                      uploadedFile={uploadedContent} loader={false} />
                  </div>
                  <div className="flex justify-center w-full flex-col pb-[40px]">
                    {fileName ? (
                      <>
                        <Tag
                          key={fileName}
                          name={fileName}
                          onClick={() => console.log("")}
                          isDelete={true}
                          onDelete={(e) => {
                            e.stopPropagation();
                            setModalDelOpen({ flag: true, document: file });
                          }}
                          className="mt-6"
                          isLogo={true}
                          isWebToolTrain={true}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </Box>
            </Grid>

          </Grid>
          {modalDelOpen?.flag && (
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: file })}>
            <div style={{ textAlign: "center" }} className="flex flex-row w-full justify-center items-center text-xl font-semibold mb-4">
              Are you sure you want to delete "{fileName}"? Deleted document will be lost
            </div>

            <div className="mt-6 flex flex-row w-full justify-center items-center text-xl font-semibold" style={{ gap: "20px" }}>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: !file })} variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={deleteDocument} variant="gradient" color={themeColor} fullWidth>
                Yes
              </MDButton>
            </div>
          </Modal>
        )}

          <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <MDButton style={{ width: "max-content", minWidth: "140px" }}
              onClick={() => inferenceDownload()} variant="gradient" color={themeColor} fullWidth>
              {"Previous"}
            </MDButton>

            <MDButton style={{ width: "max-content", minWidth: "140px" }}
              onClick={saveConfig} variant="gradient" color={themeColor} fullWidth>
              {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Inference"}
            </MDButton>
          </MDBox>
        </>
      }
    </div>
  );
}

export default TextToSpeechInference;
