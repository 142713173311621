import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ApiRequest from "services/ApiRequest";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import { TabIconsUpload } from "examples/TabIconsUpload/TabUpload";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { Images } from "libraries/Images";
import { IoMdSend } from "react-icons/io";
import { getColorVlue } from 'services/Utils';
import MDInput from "components/MDInput";
import JSONPretty from 'react-json-pretty';
import { Tag } from "examples/Tag/Tag";
import ApiUrls from "services/ApiUrls";
import {CopyToClipboard} from 'react-copy-to-clipboard';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
import Modal from "examples/Modal/Modal";
import { validateAndProcessFile } from "services/Utils";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { IoMdCloseCircle, IoIosClose } from 'react-icons/io';
import { ChatbotBottomWidget } from "examples/ChatbotBottomWidget/ChatbotBottomWidget";


function WidgetDesign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [profileLoader, setProfileLoader]  = useState(false);
  const [chatTitleLoader, setChatTitleLoader]  = useState(false);
  const [chatIcon, setChatIcon]  = useState();
  const [profileIcon, setProfileIcon]  = useState();

  const { widgetDesignForm } = location?.state || {};
  const { msgPlaceHolder, displayName, footer, headerColor, msgColor, chatIconColor, bgColor, alignChatIcon } = widgetDesignForm || {};

  const [modalInfoOpen, setModalInfoOpen] = useState({flag: false});
  const [colorPickerModal, setColorPickerModal] = useState({flag: false, name: ''});

  const navigation = (route, page, step, widgetDesignForm) => {
    navigate(`/widgets/widget-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        objectId: location?.state?.objectId,
        isEditAgent: location.state?.isEditAgent,
        page: page, step: step,
        widgetDesignForm: widgetDesignForm,
        widgetConfigForm: location?.state?.widgetConfigForm,
      }
    });
  };

  const [widgetDesign, setWidgetDesign] = useState(
    {
      // chatIcon: chatIcon ?? null,  profileIcon: profileIcon ?? null, 
      msgPlaceHolder: msgPlaceHolder ?? '', footer: footer ?? '', displayName: displayName ?? '',
      headerColor: headerColor ? useColor(headerColor) : useColor('#1C3C7D'), 
      msgColor: msgColor ? useColor(msgColor) : useColor('#1C3C7D'), 
      chatIconColor: chatIconColor ? useColor(chatIconColor) : useColor('#1C3C7D'), 
      bgColor: bgColor ? useColor(bgColor) : useColor('#ffff'), 
      alignChatIcon: alignChatIcon ?? "right"
    });

  useEffect(() => {
    navigation('/design', 2, 1, widgetDesignForm);
  }, []);

  const interfaceInputFields = [
    { id: 1, type: 'text', placeholder: 'Message Placeholder', name: 'msgPlaceHolder', mendatory: false, information: "Name of the placeholder for user to write something to chatbot" },
    { id: 2, type: 'text', placeholder: 'Display Name', name: 'displayName', mendatory: false, information: "Title of the chat widget" },
    { id: 3, type: 'text', placeholder: 'Footer', name: 'footer', mendatory: false, information: "Add anything on bottom of chat widget e.g Name of the organization or resource" },
  ];

  const interfacePalleteFields = [
    { id: 1, type: 'text', placeholder: 'User Chat Color', name: 'msgColor', mendatory: false, information: "Background color of send messages to chat bot" },
    { id: 2, type: 'text', placeholder: 'Header Color', name: 'headerColor', mendatory: false, information: "Chat widget top bar background color" },
    { id: 3, type: 'text', placeholder: 'Chat Background Color', name: 'bgColor', mendatory: false, information: "Chat widget body background color" },
    { id: 4, type: 'text', placeholder: 'Chat Bubble Color', name: 'chatIconColor', mendatory: false, information: "Chat bubble icon background color" },
  ];

  const saveWidgetDesign = (flag) => {
    if(flag){
      setIsLoading(true);
    }else{
      setIsLoading2(true);
    }

    const data = {...widgetDesign, 
          agentId: location?.state?.widgetConfigForm?.agentId, msgColor: widgetDesign['msgColor']?.[0]?.hex, 
          headerColor:  widgetDesign['headerColor']?.[0]?.hex, bgColor:  widgetDesign['bgColor']?.[0]?.hex, 
          chatIconColor:  widgetDesign['chatIconColor']?.[0]?.hex}
     ApiRequest.createWidgetDesign(data, location?.state?.objectId, (res) => {
      if(flag)setIsLoading(false)  
      if(!flag){
        setIsLoading2(false);      
        setModalInfoOpen({ flag: true })      
      }
       
      if(res?.isSuccess){
        // navigation('/design', 2, 1, location?.state?.widgetDesignForm);
        if(flag){
        navigate('/widgets');
        toast.success(res?.data?.message ? res?.data?.message : "Design Configuration Created");
        }
      }else{
        toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
      }

    });
  };


  useEffect(()=> {
    getImageIcons('chat'); getImageIcons('profile');
  },[]);

  const getImageIcons = (type) => {
    ApiRequest.readWidgetIcons({imageType: type}, location?.state?.objectId, (resVal)=> {
      setProfileLoader(false); setChatTitleLoader(false);
      if(resVal?.blob){
        if(type === "chat"){
          setChatIcon(URL.createObjectURL(resVal?.blob));
        }else{
          setProfileIcon(URL.createObjectURL(resVal?.blob));
        }
      }else{
        if(type === "chat")setChatIcon(""); 
        else setProfileIcon("")
      }
    });
  };

  const deleteImageIcons = (type) => {
    ApiRequest.deleteWidgetIcons({imageType: type}, location?.state?.objectId, (res)=> {
      if(res.isSuccess){
        if(type === "chat") setChatIcon(""); 
        else setProfileIcon("");
        toast.success(res?.data?.message ? res?.data?.message : "Image successfully deleted")
      }  else{
        toast.error(res?.error?.message ? res?.error?.message : "Something went wrong")
      }
        console.log("ResVal Image", res)
    });
  };

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.widgetDesignForm);
  };


  const Icons = (icon) => {
    return (
      <div style={{ width: "30px", marginRight: "15px" }}>
        <MDBox bgColor={themeColor}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
          <MDBox component="img" textAlign="center"
            src={icon}
            alt="Brand" width="70%" />
        </MDBox>
      </div>
    )
  };

  const uploadedFile = (acceptedFiles, isProfile) => {
    const supportedFormats = {
      'image/png': 'PNG',
      'image/jpeg': 'JPEG',
      'image/svg+xml': 'SVG',
    };

    if(isProfile) { setProfileLoader(true) }
    else { setChatTitleLoader(true) } 

    acceptedFiles?.length && acceptedFiles?.map((afile, i) => {
      
      const data = {
        fileName: afile?.name,
        fileExtension: afile?.name?.split('.')?.pop(),
        imageType: isProfile  ? "profile" : "chat",
        fileSize:afile?.size
      }

      const validation = validateAndProcessFile(afile, supportedFormats)
      if (validation === true) {
        ApiRequest.uploadWidgetIcons(data, location?.state?.objectId, (resVal)=> {
          console.log("resVal", resVal)
          if(resVal?.isSuccess){
            ApiRequest.saveWidgetIcons(afile, resVal?.data?.signedUrl, (resVal2)=> {
              getImageIcons(data?.imageType);
            });
          }else{
            toast.error(resVal?.error?.message ?? "Something went wrong");
            setProfileLoader(false); setChatTitleLoader(false);
          }
       });
      }
      else { toast.error(`${validation}`) }
    });

  };

  const modalInfoPop = () => {
    return (`
  
<iframe 
id="chat-iframe"
src=${`"${ApiUrls.frontendBaseUrl}/chatbot-widget/${location?.state?.objectId}"`}
title="Chatbot"
width="100%"
allow="clipboard-read; clipboard-write"
style="height: 100%; min-height: 520px"
frameborder="0"
></iframe>
      `)
  };

  const modalInfoPop2 = () => {
    return (`

<script src=${`"${ApiUrls?.bucketBubbleUrl}"`} 
id="chat-widget" 
data-widget-id=${`"${location?.state?.objectId}"`}
>
</script>    
    `)
  }

  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
        <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.designWidget)}
          Interface Customization
        </div>

      <div className = "w-[100%] flex flex-row flex-wrap justify-between mt-6" >
          {/*  ========= Input Fields  ======== */}
          <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "40%" }} className="flex flex-col flex-wrap">
            {interfaceInputFields.map(fi => (
              <MDBox style={{ width: "100%", outline: "none", display: "flex" }}>
                  <MDInput   
                          style={{ width: "250px", paddingBottom: "20px"}}
                          id={fi.name} 
                          type={fi.type}
                          value={widgetDesign[fi.name]} 
                          onChange={(e) => {
                            setWidgetDesign({ ...widgetDesign, [fi.name]: e.target.value });
                          }}
                          label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                          variant="standard"  />
                  <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>             
              </MDBox>
            ))}  

              {/*==== Profile Icon ====*/}
            <div>
              <div style={{ fontSize: "14px", fontWeight: "450", margin: "20px 0px 0px 0px", color: darkMode ? "white" : "black" }}>
                Profile Icon
              </div>

              <div className="flex items-center">        
                {profileIcon?.length ? 
                  <div style={{ margin: "14px 0px 0px 0px", position: "relative" }}>
                  <IoMdCloseCircle
                        onClick={(e) => deleteImageIcons("profile")}
                        style={{ position: 'absolute', top: '-13px', right: '-13px' }}
                        className={`mr-4 font-semibold text-xl text-red-500 cursor-pointer`}
                    />
                    <img  style={{ width: "50px", height: "50px", marginRight: "10px"}} src={profileIcon} />
                    </div>  
                : <TabIconsUpload title={"Upload Profile Image"} 
                                  supportedFileTypes="PNG, JPEG, SVG" 
                                  uploadedFile={(e) => uploadedFile(e, true)} 
                                  loader={profileLoader} isWebToolTrain={false} /> }
                
                </div>
            </div>
            
            

            {/* <div>
              <div className="flex flex-row items-center mt-6">
                <div style={{ color: darkMode ? "white" : "black", fontSize: "14px", fontWeight: "400px" }}>
                  Align Chat Bubble Icon
                </div>
                <Tooltip uniqueId={"BubbleIcon"} text={`test`} color={getColorVlue(themeColor)}/>  
              </div>

              <div className="flex flex-row items-center mt-4">
              <FaArrowLeft style={{ fontSize: "25px", marginRight: "5px", color: darkMode ? "white" : "black" }} />
              <FaArrowRight style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />
              </div>
            </div>                   */}


          <div>
              <div style={{ fontSize: "14px", fontWeight: "450", margin: "30px 0px 0px 0px", color: darkMode ? "white" : "black" }}>
                Chat Bubble Icon
              </div>
              <div className="flex items-center">
                    {chatIcon?.length ?
                        <div style={{ position: "relative", margin: "14px 0px 0px 0px"  }}>
                          <IoMdCloseCircle
                                onClick={(e) => deleteImageIcons("chat")}
                                style={{ position: 'absolute', top: '-13px', right: '-13px' }}
                                className={`mr-4 font-semibold text-xl text-red-500 cursor-pointer`}
                            />
                            <img  style={{ width: "50px", height: "50px", marginRight: "10px" }} src={chatIcon} />
                        </div>
                    : <TabIconsUpload title={"Upload Chat Head Icon"} 
                    supportedFileTypes="PNG, JPEG, SVG" 
                    uploadedFile={(e) => uploadedFile(e, false)} 
                    loader={chatTitleLoader} 
                    isWebToolTrain={false} /> }
                   
              </div> 
          </div>

          </div>


          {/* ======= Color Pallete ========= */}
          <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", marginTop: "15px" }} className="flex flex-col flex-wrap">
            {interfacePalleteFields.map(fi => (
              <MDBox style={{paddingBottom: "40px", width: "100%", outline: "none", display: "flex" }}>
                  <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>

                      <div onClick={()=> setColorPickerModal({flag: true, name: fi?.name }) }  
                          style={{ border: darkMode ? `1px solid white` : `1px solid black`, borderRadius: "5px", 
                                   background: widgetDesign[fi?.name]?.[0]?.hex, width: "20px", height: "20px", marginRight: "20px", 
                                   cursor: "pointer" }}>
                      </div>  
                      <span style={{ color: darkMode ? "white" : "black" }}>{fi.placeholder}</span>
                    </div>
                  
                  <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>             
              </MDBox>
            ))} 
          </div>

          {/* Dummy Chat Widget */}
          <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", position: "relative" }} className="flex flex-col flex-wrap">

                <div className="flex justify-end mb-1" > 
                  <div style={{  fontSize: "10px", height: "350px", width: "250px",borderRadius: "5px" }}>
                    {/* Header */}

                      <div style={{ borderRadius: "5px 5px 0px 0px",padding: "10px",color: "white",backgroundColor: widgetDesign['headerColor']?.[0]?.hex, display: "flex",justifyContent: "space-between",alignItems: "center" }}>
                          <div className="flex flex-row items-center">
                              <img
                                  style={{ width: "20px", height: "20px" }}
                                  src={profileIcon?.length ? profileIcon : "https://storage.googleapis.com/intellixcore-frontend-develop/uploads/IntellixcoreWidgetAssets/default-icons/chatWidgetDefaultProfileIcon.png"} />

                              <span style={{ marginLeft: "10px" }}>
                                  {widgetDesign['displayName']}
                              </span>
                          </div>
                          <i className="fas fa-window-close cross-icon"></i>
                      </div> 
                      {/* Body */}
                      <div style={{backgroundColor: widgetDesign['bgColor']?.[0]?.hex, padding: "10px",border: "1px solid darkgrey"}}  >
                          <div style={{ height: "200px" }}>
                            <div className="flex justify-end">
                            <div className="p-2  rounded-bl-lg rounded-tl-lg rounded-tr-lg" style={{ backgroundColor: widgetDesign['msgColor']?.[0]?.hex, color: "white",maxWidth: "90%" }}  >Hi</div>
                            </div>
                            <div className="flex">
                            <div className="bg-gray-200 p-2 mt-1 rounded-br-lg rounded-tr-lg rounded-tl-lg" style={{ maxWidth: "90%" }}>Hello how can I assist you</div>
                            </div>
                          </div>
                          


                          <div className="relative w-full mt-2">
                            <div
                              style={{
                                  color: "grey",
                                  borderColor: "black",
                                  background: "white", resize: 'none', padding: "10px 30px 10px 10px",
                                  width: '100%', borderRadius: "10px",
                                  border: "1px solid darkgrey",
                                  height: '35px',
                                  maxHeight: "100px",
                              }}
                            >
                            {widgetDesign['msgPlaceHolder']} 
                              </div>
                            <IoMdSend
                              style={{ color: "#202940" }}
                              className={`absolute h-5 w-5 top-2 right-2 `} 
                            />
                          </div>
                          {/* Footer */}
                          <div>
                            <div className="flex justify-center mt-4" style={{ color:"#1C3C7D", fontWeight: "600"  }}>
                              {widgetDesign['footer']}
                            </div>
                            <div className="flex justify-center">
                              Powered by - <span style={{ fontWeight: "500", marginLeft: "5px" }}>
                              { "INTELLIXCORE"}
                                </span>
                            </div>

                          </div>
                      </div>
                  </div> 
                </div> 

                <div className="flex justify-end">
                    <div style={{ cursor: "pointer", backgroundColor: widgetDesign['chatIconColor']?.[0]?.hex,color: "white",border: "none",padding: "10px", borderRadius: "100%" }}>
                       <img style={{ width: "20px", height: "20px" }}
                            src={chatIcon ? chatIcon : "https://storage.googleapis.com/intellixcore-frontend-develop/uploads/IntellixcoreWidgetAssets/default-icons/chatWidgetDefaultChatIcon.png"} />
                    </div>
                </div>               
          </div>   
      </div>

      {/* Color Picker Modal */}
      {colorPickerModal?.flag &&
          <Modal open={colorPickerModal?.flag} setOpen={(fl) => setColorPickerModal({ flag: fl, name: '' })}>
            <div className="flex flex-row justify-end">
               <IoIosClose
                    onClick={(e) => setColorPickerModal({ flag: false, name: '' })}
                    style={{ color:  darkMode ? "white" : "black",fontSize: "30px", marginBottom: "10px" }}
                    className={`font-semibold cursor-pointer`}
                />
            </div>
            <ColorPicker color={widgetDesign?.[colorPickerModal?.name]?.[0]} onChange={(e)=> setWidgetDesign({...widgetDesign, [colorPickerModal.name]: [e] })} />
            
          </Modal>
      }

      {/* Copy Code Modal */}
      {modalInfoOpen?.flag &&
            <Modal open={modalInfoOpen?.flag} setOpen={(fl) => setModalInfoOpen({ flag: fl })}>
              <div className="flex flex-row justify-end">
               <IoIosClose
                    onClick={(e) => setModalInfoOpen({ flag: false})}
                    style={{ color:  darkMode ? "white" : "black",fontSize: "30px", marginBottom: "10px" }}
                    className={`font-semibold cursor-pointer`}
                />
            </div>
              <div className=" mb-4" >
              
              <div style={{ fontSize: "18px" }}>To add the chatbot any where on your website, please add this iframe to your html code</div>
                <div className="mt-8 mb-4">
                  <JSONPretty style={{fontSize: "15px"}} id="json-pretty" 
                              theme={JSONPrettyMon}
                              data={modalInfoPop()}></JSONPretty>
                 </div>

                <div className="w-full flex justify-center"> 
                  <CopyToClipboard text={modalInfoPop()} onCopy={()=>  toast.success("Code has been copied!")}>
                  <Tag
                    name={"Copy To Clipboard"}
                    onClick={(e) => {e?.stopPropagation()}}
                    onDelete={(e) => console.log("delete")}
                    className="mt-4"
                    isLogo={true}
                  />
                  </CopyToClipboard>
                </div>


                {/* Script Tag */}
              <div style={{ fontSize: "18px", marginTop: "16px" }}>To add a chat bubble to the bottom right of your website add this script tag to your html</div>
                <div className="mt-2 mb-2">
                  <JSONPretty style={{fontSize: "15px"}} id="json-pretty" 
                              theme={JSONPrettyMon}
                              data={modalInfoPop2()}></JSONPretty>
                 </div>

                <div className="w-full flex justify-center"> 
                  <CopyToClipboard text={modalInfoPop2()} onCopy={()=>  toast.success("Chat bubble code has been copied!")}>
                  <Tag
                    name={"Copy To Clipboard"}
                    onClick={(e) => {e?.stopPropagation()}}
                    onDelete={(e) => console.log("delete")}
                    className="mt-4"
                    isLogo={true}
                  />
                  </CopyToClipboard>
                </div>
              </div>
            </Modal>
      }


      {/* ======== BUTTON ======== */}

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "120px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { reconfigBtn()}} variant="gradient" color={themeColor} fullWidth>
                {'Previous'}
            </MDButton>
            
            <MDButton style={{width: "max-content", minWidth: "120px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> saveWidgetDesign(true) } variant="gradient" color={themeColor} fullWidth>
                {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
            </MDButton>  

            <MDButton style={{width: "max-content", minWidth: "120px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveWidgetDesign(false)  }} variant="gradient" color={themeColor} fullWidth>
                {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Get Code'}
            </MDButton>
         
        </MDBox>

        {/* Chat Widget */}
        {/* <ChatbotBottomWidget /> */}
    </div>

  );
};

export default WidgetDesign;

