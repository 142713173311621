import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Progressbar from "examples/Progressbar/Progressbar";
import ApiRequest from "services/ApiRequest";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
// import { Paths } from "libraries/Route";

import {useMaterialUIController} from "context";

import MDTableFeatureExtactionModels from "components/MDTable/MDTableFeatureExtactionModels";

function FeatureExtractionModel(props){
  const navigate = useNavigate();
  const location = useLocation();
  const {id, name} = useParams();
  const [timer, setTimer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null)

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const navigation = (route, page, step, deployedURL, uploadedFile, beforeTrained, modelType) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        id: location?.state?.id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: uploadedFile,
        isTrained: false,
        beforeTrained: beforeTrained ?? false,
        modelType: modelType
      }
    });
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location.state?.deployedURL, false, location?.state?.uploadedFile);
  };

  const onPrimaryBtn = () => {
    navigation('/train', 4, 2, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.beforeTrained, location?.state?.modelType);
  };

  const featureModelPop = (id, name, obj, docId) => {
    localStorage.setItem("featureModelId", id ? JSON.stringify({ id, name }) : "");
    const data = {
      sourceCol: obj ? obj?.sourceCol : '',
      featureList: obj?.featureList?.length ? JSON.parse(obj?.featureList.replace(/'/g, '"')) : [],
      modelName: name ? name : "",
      documentId: docId ? docId.filter((ac) => ac?.fileName?.includes(name))?.[0]?.documentId : '',
    };
    navigation('/model', 3, 1, location?.state?.deployedURL, location?.state?.uploadedFile, data, obj ? obj?.modelType : '');
    setSelectedRow(obj)      
  };
  

  const widthhandle = ["3", "27", "15", "15", "15", "15", "10"];
  const columns = [
     { name: 'Model Name', width: "32" },
    { name: 'Created Date', width: "15" }, { name: 'Modified Date', width: "15" },
    { name: 'Model Type', width: "15" }, { name: 'Model Status', width: "15" }, 
    { name: '', width: "10" },
  ];

  return (
    <div>
      <MDBox pt={3}>
          <MDTableFeatureExtactionModels selectedRow={selectedRow} columns={columns} widthhandle={widthhandle} onRowSelection={(i, row, docId) => {featureModelPop(`${i + 1}`, row?.modelName, row, docId)}}/>
      </MDBox>

      <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
          <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {reconfigBtn()}} variant="gradient" color={themeColor} fullWidth>
            Previous
          </MDButton>
          <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={!selectedRow} onClick={()=> {onPrimaryBtn()}} variant="gradient" color={themeColor} fullWidth>
            Next
          </MDButton>
      </MDBox>
    </div>
  );
};

export default FeatureExtractionModel;

