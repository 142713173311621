import { Paths } from "./Route";

export const ACCESS_TOKEN_KEY = 'authenticationAccessToken',
    REFRESH_TOKEN_KEY = 'authenticationRefreshToken';
export const USER_EMAIL_KEY = 'userEmail';
export const GOOGLE_TYPE = 'loginType';
export const ERROR_KEY = 'error';
export const ERROR_TYPE = 'errorType';
export const UPDATE_PASSWORD_REMOVE_KEY = 'true'
class Service {
     getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    }

     setAccessToken(token) {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

     getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

     setRefreshToken(token) {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }
    getUserEmail() {
        return localStorage.getItem(USER_EMAIL_KEY);
    }

    setUserEmail(email) {
        localStorage.setItem(USER_EMAIL_KEY, email); 
    }
    getLoginType() {
        return localStorage.getItem(GOOGLE_TYPE);
    }

    setMicorosoftLoginType(type) {
        localStorage.setItem(GOOGLE_TYPE, type); 
    }
    getMicorosoftLoginType() {
        return localStorage.getItem(GOOGLE_TYPE);
    }

    setLoginType(type) {
        localStorage.setItem(GOOGLE_TYPE, type); 
    }
    setError(error) {
        localStorage.setItem(ERROR_KEY, error); 
    }
    getError() {
        return localStorage.getItem(ERROR_KEY); 
    }
    setErrorType(type) {
        localStorage.setItem(ERROR_TYPE, type); 
    }
    getErrorType() {
        return localStorage.getItem(ERROR_TYPE); 
    }
    setMicrosoftErrorType(type) {
        localStorage.setItem(ERROR_TYPE, type); 
    }
    getMicrosoftErrorType() {
        return localStorage.getItem(ERROR_TYPE); 
    }
    setRemoveUpdatePassword(type) {
        localStorage.setItem(UPDATE_PASSWORD_REMOVE_KEY, type); 
    }
    getRemoveUpdatePassword() {
        return localStorage.getItem(UPDATE_PASSWORD_REMOVE_KEY); 
    } 
    clearTokens(BETimoutSession) {
        
        if(BETimoutSession){
            window.location.replace(`${Paths.login}?logout=true`);
        }else{
            localStorage.clear();
            sessionStorage.clear();
        }
    }

     logoutWithToken() {
        this.clearTokens();
    }
}

export const TokenService = new Service();

