/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Patterns from "layouts/patterns";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import BillingPlans from "layouts/authentication/billing-plans";
import ContactUs from "layouts/authentication/contact-us";
import ConfirmEmail from "layouts/authentication/confirm-email";
import NewPassword from "layouts/authentication/new-password";
import ForgetPassword from "layouts/authentication/forget-password";

// @mui icons
import Icon from "@mui/material/Icon";
import XAgents from "layouts/xAgents";
import Widgets from "layouts/widgets";
import PaymentHistory from "layouts/payment-history";
import UsageHistory from "layouts/usage-history";

import Pattern from "layouts/pattern";
import ChatWidget from "layouts/widget";
import CreationFlowWrapper from "layouts/agent-creation-flow/creation-flow-wrapper/CreationFlowWrapper";
import CreationWidgetFlowWrapper from "layouts/widget-creation-flow/creation-flow-wrapper/CreationFlowWrapper";
import ChatXAgent from "layouts/agent-creation-flow/ChatXAgent";
import FeatureExtractionXAgent from "layouts/agent-creation-flow/FeatureExtractionXAgent";
import RecommendationXAgent from "layouts/agent-creation-flow/RecommendationXAgent";
import PredictiveAnalysisXAgent from "layouts/agent-creation-flow/PredictiveAnalysisXAgent";
import { ChatbotBottomWidget } from "examples/ChatbotBottomWidget/ChatbotBottomWidget";
import WorkForceStrategy from "layouts/workforce-strategy/business-configuration/workForce";
import EstimatedSavings from "layouts/workforce-strategy/estimated-savings/EstimatedSavings";
import WorkForcePatterns from "layouts/workforce-strategy/workForce-patterns/WorkForcePatterns";
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import LLMFunctionsTable from "layouts/llmFunctions/LLMFunctionsTable";
import CreationLLMFunctionFlowWrapper from "layouts/llmFunctions/llm-creation-flow/CreationFlowWrapper"
import ApiKeyManagementTable from "layouts/apiKeyManagementModule/ApiKeyManagementTable";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Patterns",
    key: "patterns",
    icon: <Icon fontSize="small">patterns</Icon>,
    route: "/patterns",
    component: <Patterns />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name",
    component: <Pattern />,
  },
  {
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/chatbot-widget/:widgetId",
    component: <ChatWidget />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/config",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/tools",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/deployment",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/protect",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/model",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/train",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/result",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/inference",
    component: <CreationFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/infer/llm",
    component: <ChatXAgent />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/infer/:chatId/llm",
    component: <ChatXAgent chatDetail={true} />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/infer/feature-extraction",
    component: <FeatureExtractionXAgent />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/infer/predictive-analysis",
    component: <PredictiveAnalysisXAgent />,
  },
  {
    // type: "collapse",
    name: "Pattern",
    key: "patterns",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/patterns/:id/:name/agent-creation/infer/recommendation-system",
    component: <RecommendationXAgent />,
  },
  {
    type: "collapse",
    name: "X-Agents",
    key: "x-agent",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "x-agent",
    component: <XAgents />,
  },
  {
    type: "collapse",
    name: "Functions",
    key: "llm-function",
    icon:<Icon fontSize="small">rate_review</Icon>,
    route: "/llm-function",
    component: <LLMFunctionsTable />,
  },
  {
    // type: "collapse",
    name: "LLM Function",
    key: "llm",
    icon: <VoiceChatIcon />,
    route: "/llm-function/llm-creation/config",
    component: <CreationLLMFunctionFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "LLM Function",
    key: "llm",
    icon: <VoiceChatIcon />,
    route: "/llm-function/llm-creation/test",
    component: <CreationLLMFunctionFlowWrapper />,
  },
  {
    type: "collapse",
    name: "Chat Widgets",
    key: "widgets",
    icon: <Icon fontSize="small">chatbot</Icon>,
    route: "widgets",
    component: <Widgets />,
  },
  {
    // type: "collapse",
    name: "Chat Widget",
    key: "widgets",
    icon: <Icon fontSize="small">chatbot</Icon>,
    route: "/widgets/widget-creation/config",
    component: <CreationWidgetFlowWrapper />,
  },
  {
    // type: "collapse",
    name: "Chat Widget",
    key: "widgets",
    icon: <Icon fontSize="small">chatbot</Icon>,
    route: "/widgets/widget-creation/design",
    component: <CreationWidgetFlowWrapper />,
  },
  {
    type: "collapse",
    name: "API Keys",
    key: "api",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/api-key",
    component: <ApiKeyManagementTable />,
  },
  {
    type: "divider",
    // name: "",
    // key: "api",
    // icon: <Icon fontSize="small">key</Icon>,
    // route: "/api-key",
    // component: <ApiKeyManagementTable />,
  },
  {
    type: "collapse",
    name: "Business Planning",
    key: "workToggle",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/patterns",
    component: <Patterns />,
    collapse: [
      {
        name: "Configuration",
        key: "strategy",
        icon: <Icon fontSize="small"></Icon>,
        route: "/work-force-strategy",
        component: <WorkForceStrategy />,
      },
      {
        name: "ROI Estimate",
        key: "savings",
        icon: <Icon fontSize="small"></Icon>,
        route: "/estimated-savings",
        component: <><EstimatedSavings /></>,
      },
    ],
  },
  {
    name: "Patterns",
    key: "savings",
    icon: <Icon fontSize="small"></Icon>,
    route: "/work-force-patterns",
    component: <><WorkForcePatterns /></>,
  },
  {
    // type: "collapse",
    name: "Billing Plans",
    key: "billing-plans",
    icon: <Icon fontSize="small">billing</Icon>,
    route: "/pricing-plans",
    component: <BillingPlans />,
  },
  {
    // type: "collapse",
    name: "Contact Us",
    key: "contact-us",
    icon: <Icon fontSize="small">contact</Icon>,
    route: "/contact-us",
    component: <ContactUs />,
  },
  // {
  //   type: "collapse",
  //   name: "LLM Function",
  //   key: "llm",
  //   icon: <VoiceChatIcon />,
  //   route: "/llm-function",
  //   component: <LLMFunctionsTable />,
  // },
  // {
  //   // type: "collapse",
  //   name: "LLM Function",
  //   key: "llm",
  //   icon: <VoiceChatIcon />,
  //   route: "/llm-function/llm-creation/config",
  //   component: <CreationLLMFunctionFlowWrapper />,
  // },
 
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login/confirm-email",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/resend-confirm-email",
    component: <ConfirmEmail />,
  },
  {
    // type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/forgot-password",
    component: <ForgetPassword />,
  },
  {
    // type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login/forget-password",
    component: <NewPassword />,
  },
  {
    // type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    // type: "collapse",
    name: "Payment",
    key: "payment-history",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/payment-history",
    component: <PaymentHistory />,
  },
  {
    // type: "collapse",
    name: "Usage",
    key: "usage-history",
    icon: <Icon fontSize="small">pattern</Icon>,
    route: "/usage-history",
    component: <UsageHistory />,
  }
];

export default routes;