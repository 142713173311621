/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useParams, useLocation } from "react-router-dom";



function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);
  const location = useLocation();
  const { name} = useParams();


  console.log("location Breadcrum name", name);
  console.log("title name", title)

  const formatString = (input) => {
    if(input?.length){
      return input
      .split('-')              // Split the string by hyphens
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
      .join(' ');  
    }
  }

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {/* {routes.map((el) => ( */}
        {routes?.length ? 
          <Link to={`/${routes[0]}`} >
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {
              routes[0] === "llm-function" ? "LLM Function" 
              : routes[0] === "widgets" ? "Chat Widget" 
              : routes[0] === "patterns" ? "Pattern" 
              : routes[0]
              }
            </MDTypography>
          </Link>
          : null }
         {/* ))} */}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {/* .replace("-", " ") */}
          {/* {location.pathname.includes("creation") && location?.state?.isEditAgent ? `Update  /  `
          : location.pathname.includes("creation") && !location?.state?.isEditAgent ? `Create / ` 
          : ""
          } */}
          {name?.length && (name === "llm" ? "LLM / " : `${formatString(name)} / ` )}
           {title === "config" ? "Configure" 
           : title === "widgets" ? "Chat Widget" 
           : title === "llm-function" ? "LLM Function" 
           : title === "patterns" ? "Pattern" 
           : title === "work-force-strategy" ? "Bussiness Planning / Configuration" 
           : title === "estimated-savings" ? "Bussiness Planning / ROI Estimate" 
           : title === "work-force-patterns" ? "Bussiness Planning / Use case and Patterns" 
           : location.pathname.includes("infer") ? "Inference" : formatString(title)}
         </MDTypography>
       </MuiBreadcrumbs> 
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
