export const Images = {
  // loginBg: require("../assets/images/intellixcore/background-8.png"),
  dashboardBg: require("../assets/images/intellixcore/homepage.png"),
  contentBg: require("../assets/images/intellixcore/contentBg.png"),
  splashBg: require("../assets/images/intellixcore/splashScreenBg.png"),
  mainBg: require("../assets/images/intellixcore/background-8.png"),
  mainBgWhite: require("../assets/images/intellixcore/mainBg.png"),
  mainBgDark: require("../assets/images/intellixcore/mainBgDark.png"),
  chatbotBg: require("../assets/images/intellixcore/chatbotBg.png"),
  chatbotBg2: require("../assets/images/intellixcore/chatbotBg-1.png"),
  featureBg: require("../assets/images/intellixcore/featureBg.jpg"),
  xAgentBg: require("../assets/images/intellixcore/xAgentBg.jpg"),


  //   Logo
  tick: require("../assets/images/intellixcore/tick.png"),
  // pdfIcon: require("../assets/images/intellixcore/pdf_icon.png"),
  // excelIcon: require("../assets/images/intellixcore/excel_icon.png"),
  uploadIcon: require("../assets/images/intellixcore/uploadFile.png"),
  uploadIconWhite: require("../assets/images/intellixcore/uploadIconWhite.png"),
  uploadIconDark: require("../assets/images/intellixcore/uploadIconDark.png"),
  completeLogo: require("../assets/images/intellixcore/complete_logo.png"),
  // logoHorizontal: require("../assets/images/intellixcore/logo-horizontal.png"),
  // logoWhite: require("../assets/images/intellixcore/logo-white.png"),
  logo: require("../assets/images/intellixcore/logo.png"),
  logoIntellixcoreWhite: require("../assets/images/intellixcore/logoWhite.png"),
  logoIntellixcoreBlue: require("../assets/images/intellixcore/logoBlue.png"),


  //  Navbar
  profile: require("../assets/images/intellixcore/profile.png"),
  pattern: require("../assets/images/intellixcore/patterns.png"),
  help: require("../assets/images/intellixcore/help.png"),
  xAgent: require("../assets/images/intellixcore/xAgents.png"),
  tutorial: require("../assets/images/intellixcore/tutorials.png"),
  logout: require("../assets/images/intellixcore/logout.png"),

  //   Patterns
  languageTranslator: require("../assets/images/intellixcore/languageTranslator.png"),
  predictive: require("../assets/images/intellixcore/predictiveAnalysis.png"),
  recommendation: require("../assets/images/intellixcore/recommendationAnalysis.png"),
  chatbot: require("../assets/images/intellixcore/chatbot.png"),
  speech: require("../assets/images/intellixcore/speech.png"),
  imageToText: require("../assets/images/intellixcore/imageToText.png"),
  behavorial: require("../assets/images/intellixcore/behavorial.png"),

  //   Models
  forcast: require("../assets/images/intellixcore/forcasting.png"),
  predictCustomer: require("../assets/images/intellixcore/predictingCustomer.png"),
  predictEmplyoee: require("../assets/images/intellixcore/predictingEmployee.png"),
  recommendation1: require("../assets/images/intellixcore/recommendation-1.png"),
  recommendation2: require("../assets/images/intellixcore/recommendation-2.png"),
  recommendation3: require("../assets/images/intellixcore/recommendation-3.png"),

  // UseCases
  textToSpeech1: require("../assets/images/intellixcore/useCase-text1.png"),
  textToSpeech2: require("../assets/images/intellixcore/useCase-text2.png"),
  textToSpeech3: require("../assets/images/intellixcore/useCase-text3.png"),
  textToSpeech4: require("../assets/images/intellixcore/useCase-text4.png"),
  textToSpeech5: require("../assets/images/intellixcore/useCase-text5.png"),

  // Protect Tab
  anonymize: require("../assets/images/intellixcore/anonymize.png"),
  promptInjection: require("../assets/images/intellixcore/promptInjection.png"),
  secret: require("../assets/images/intellixcore/secret.png"),
  sensitiveTopic: require("../assets/images/intellixcore/sensitiveTopic.png"),
  global: require("../assets/images/intellixcore/global.png"),

  // Config Widget Tab
  configWidget: require("../assets/images/intellixcore/config.png"),
  rateLimitWidget: require("../assets/images/intellixcore/rateLimit.png"),
  domainWidget: require("../assets/images/intellixcore/domain.png"),
  designWidget: require("../assets/images/intellixcore/design.png"),
  
  // LLM Patterns Tab
  configLlmPattren: require("../assets/images/intellixcore/config.png"),
  inputParameterLlmPattern: require("../assets/images/intellixcore/input-parameter.png"),
  promptLlmPattern: require("../assets/images/intellixcore/prompt-icon.png"),
  outputParameterLlmPattern: require("../assets/images/intellixcore/output-parameter.png"),
  responseLLMFunction:require("../assets/images/intellixcore/response.png"),

};
