import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import MDInput from "components/MDInput";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';
import Methods from "libraries/CommonMethodsUI";

import { FaEdit, FaDownload } from "react-icons/fa";
import { MdDelete, MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatXAgent from 'layouts/agent-creation-flow/ChatXAgent';
import { Checkbox } from '@mui/material';
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';


import { SpinnerLoader } from "examples/Loader/Loader";

export default function MDTableRecommendationModels({ columns, widthhandle, selectedRow, onRowSelection }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modelFeatureList, setModelFeatureList] = useState([]);
  const [featuresLoader, setFeaturesLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const [file, setFile] = useState("")

  
  const promptPreValue = 'You are a label extraction expert. Use the context and feature list provided to extract labels from the given text. Make no mistakes.';
  const [createLoader, setCreateLoader] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [featureModelId, setFeatureModelId] = useState(
    localStorage.getItem("featureModelId") ? Number(JSON?.parse(localStorage.getItem("featureModelId") || "")?.id) : 0
  );
  const [createUpdateNewModel, setCreateUpdateNewModel] = useState({
    modelName: '', modelType: '', recommendationType: '',
    numberOfRecommendations: 0, vectorSize: 300, similarityThreshold: 0.1,
  });
  const { modelName, modelType, recommendationType, numberOfRecommendations, vectorSize, similarityThreshold } = createUpdateNewModel || {};
  const location = useLocation();
  const { state } = location;
  const updatedFilename = location.state?.uploadedFile?.filter((fl) => fl?.fileName?.split('_')?.[0] === JSON?.parse(localStorage.getItem("featureModelId") || "")?.name)?.[0]?.fileName;
  // const [activeDropdown, setActiveDropdown] = useState(true);
  const [docId, setDocId] = useState([]);

  const nameArr = ['CosineSimilarity', 'PearsonCorrelation'];
  const nameArr2 = ['Collaborative', 'ContentBased'];

  console.log("createUpdateNewModel", createUpdateNewModel);

  const fields = [
    {
      id: 1,
      placeholder: "Recommendation Model*",
      name: "modelType",
      type: "dropdown",
      arr: nameArr,
      isDropdown: true,
      isActive: true,
      mendatory: true
    },
    {
      id: 1,
      placeholder: "Recommendation Type*",
      name: "recommendationType",
      type: "dropdown",
      arr: nameArr2,
      isDropdown: true,
      isActive: true,
      mendatory: true
    },
    {
      placeholder: "Model Name", name: "modelName",
      isActive: true,
      val: modelName, type: "text", isDropdown: false,
      information: "Unique name for the model.",
      mendatory: true
    },
    {
      placeholder: "Number of recommendations*", name: "numberOfRecommendations",
      step: 1, isActive: true,
      val: numberOfRecommendations, type: "number", isDropdown: false,
      information: `The number of similar recommendation required for the given product`,
      mendatory: false
    },
    {
      placeholder: "Similarity Threshold*", name: "similarityThreshold",
      step: 1, isActive: true,
      val: similarityThreshold, type: "number", isDropdown: false,
      information: `The value between 0 and 1 that is the threshold for similarity of the products`,
      mendatory: false
    },
    // {
    //   placeholder: "Vector Size*", name: "vectorSize",
    //   step: 0.5, min: 0, max: 1,
    //   isActive: createUpdateNewModel.recommendationType?.toLowerCase() === Constants.recommendationContentBased?.toLowerCase(),
    //   val: vectorSize, type: "number", isDropdown: false,
    //   information: ``,
    //   mendatory: false
    // },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const convertBytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(3);

  const statusCheck = (status) => {
    if (status === Constants.CONFIGURED) return "#f94449"
    if (status === Constants.DEPLOYED) return "black"
    if (status === Constants.MODIFIED) return "#c30010"
    if (status === Constants.TRAINING) return "rgb(123, 128, 154)"
    if (status === Constants.DEPLOYING) return "royalblue"
    if (status === Constants.READY) return "#43a047"
    if (status === "TRAINED") return "#A020F0"
    else null
  };

  useEffect(() => {
    // Feature Extraction Model List
    featureRecommendationModel();
    // reset Selected Modal
    // localStorage.setItem("featureModelId", "");
    setFeatureModelId(0);
  }, []);

  const featureRecommendationModel = () => {
    setFeaturesLoader(true);
    ApiRequest.agentList(`/${location?.state?.objectId}`, '', (res) => {
      if (res.isSuccess) {
        setModelFeatureList(res?.data?.agentsInformation?.[0]?.models);
        setDocId(res?.data?.agentsInformation?.[0]?.documentDetails);
        const outputDetails = res?.data?.agentsInformation?.[0]?.outputDocumentDetails?.map((item) => item)
        setFile(outputDetails)
      } else {
        setModelFeatureList([]);
      }
      setFeaturesLoader(false);
    });
  };
  const deleteModal = () => {
    setDelLoader(true);

    ApiRequest.deleteModelRecommendationAgent({ modelName: modalDelOpen?.modelName }, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, modelName: '' }); setDelLoader(false);
      featureRecommendationModel();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const openNewModal = (updatingModal) => {
    var updateData = updatingModal;

    if (updatingModal?.recommendationType?.toLowerCase() === Constants.recommendationCollaborative?.toLowerCase()) {
      updateData = { ...updatingModal, vectorSize: 300 }
    } else if (updatingModal?.recommendationType?.toLowerCase() === Constants.recommendationContentBased?.toLowerCase()) {
      updateData = { ...updatingModal }
    }

    setModalOpen(true); setIsUpdateModal(updatingModal?.modelName ? true : false)
    setCreateUpdateNewModel(updateData?.dateCreated ? updateData :
      {
        modelName: '', modelType: '', recommendationType: '',
        numberOfRecommendations: 0, vectorSize: 300, similarityThreshold: 0.1,
      });
    // setDropdownValues({ modelType: updateData ? updateData?.modelType : modelType ? modelType : '' });
  };

  const createUpdateModel = () => {
    var updatedData = {};
    const data = {
      modelName: modelName,
      modelType: modelType,
      recommendationType: recommendationType,
    }

    var numericValues = {
      numberOfRecommendations: Number(numberOfRecommendations),
      similarityThreshold: Number(similarityThreshold),
    }

    if (createUpdateNewModel?.recommendationType?.toLowerCase() === Constants.recommendationContentBased?.toLowerCase()) {
      updatedData = { ...data, ...numericValues, vectorSize: 300 };
    } else if (createUpdateNewModel?.recommendationType?.toLowerCase() === Constants.recommendationCollaborative?.toLowerCase()) {
      updatedData = { ...data, ...numericValues };
    }

    if(Number(similarityThreshold) == Math.floor(Number(similarityThreshold))){
      toast.error("Similarity threshold must be a decimal value");
    }
    else if (Number(similarityThreshold) === 0 || Math.floor(Number(similarityThreshold) > 1)) {
      toast.error("Similarity threshold must be greater than 0 till 1");
    }
    else if (Number(numberOfRecommendations) !== Math.floor(Number(numberOfRecommendations))) {
      toast.error("Number of Recommendations must be an integer value");
    }
    else if (Number(numberOfRecommendations) < 1) {
      toast.error("Number of Recommendations must be greater than or equal to 1");
    }
    else if (!modelName || !createUpdateNewModel?.modelType || !createUpdateNewModel?.recommendationType
    ) {
      toast.error("Please fill all required fields");
    } else {
      setCreateLoader(true);
      ApiRequest.createUpdateModelRecommendationAgent(updatedData, location?.state?.objectId, isUpdateModal, (res) => {
        setCreateLoader(false);
        if (res.isSuccess) {
          setModalOpen(false);
          toast.success(res?.data?.message);
          featureRecommendationModel();
        } else {
          toast.error(res?.error?.message ?? "Something Went Wrong")
        }
      });
    }
  };

  const getFile = (modelName) => {
    const fileData = file?.find((file => (file.fileName?.split('-')[0]) === modelName))
    return fileData?.fileSize ? convertBytesToMB(fileData.fileSize) : null;
  }

  const saveToBucket = (fileData) => {
    const modalName = fileData?.modelName
    const fileToDownload = file?.find((file => (file.fileName?.split('-')[0]) === modalName))
    ApiRequest.inferBucketSaveFeatureAgent({
      documentId: fileToDownload?._id,
      modelName: modalName,
      inputOrOutput: "output",
    },
      `${location?.state?.objectId}`, (res) => {
        if (res.isSuccess) {
          Methods.downloadCSV(res?.data, fileToDownload?.fileName);
          toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
        } else {
          toast.error("Inference failed")
        }
      });
  }

  const handleModelNameChange = (e) => {
    const validateModelName = e.target.value.replace(/[\s-]/g, '');
    setCreateUpdateNewModel(prevState => ({
      ...prevState,
      modelName: validateModelName
    }));
  };

  const CommonIcons = (row, i) => {
    const outPutFileSizeMB = getFile(row?.modelName);
    return (
      <div className="flex flex-row" key={i} >
        {outPutFileSizeMB !== null && (
        <FaDownload
          style={{ color: darkMode ? "white" : "black" }}
          onClick={(e) => {
            e.stopPropagation();
            saveToBucket(row);
          }}
          className="cursor-pointer"
        />
      )}
        <FaEdit
          style={{ color: darkMode ? "white" : "black" }}
          onClick={(e) => {
            e.stopPropagation(); openNewModal(row)
          }}
          className="ml-4 cursor-pointer"
        />

        <MdDelete
          style={{ color: darkMode ? "white" : "black" }}
          onClick={(e) => {
            e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.modelName })
          }}
          className={`ml-4 cursor-pointer`}
        />
        <Tooltip uniqueId={`predictiveModal-${i}`}
        isPattern={true}
          color={getColorVlue(themeColor)}
          text={<>
            <div style={{ textAlign: "center" }} className="text-lg font-semibold">
              {row?.modelType === "CosineSimilarity" ? `Cosine Similarity` : 'Pearson Correlation'}
            </div>
            <div>
              {row?.numberOfRecommendations ? <><div style={{ fontWeight: "500" }}>Total Recommendations</div>
                {row?.numberOfRecommendations}</> : ''}
            </div>
            {/* <div>
              {row?.vectorSize ? <><div style={{ fontWeight: "500" }}>Vector Size</div> 
              {row?.vectorSize}</> : ''}
            </div>           */}
            <div>
              {row?.similarityThreshold ? <><div style={{ fontWeight: "500" }}> Similarity Threshold</div>
                {row?.similarityThreshold}</> : ''}
            </div>

          </>} />
      </div>
    )
  }

  return (
    <div>
      <MDBox mb={1} style={{ display: "flex", justifyContent: "end" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={false} onClick={() => { openNewModal() }} variant="gradient" color={themeColor} fullWidth>
          Create New Model
        </MDButton>
      </MDBox>

      <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table >

            <TableBody>
              {/* Header */}
              <TableRow >
                {/* {windowSize.innerWidth <= 600 && <TableCell  align={"left"} style={{ width: "30px" }}></TableCell>} */}
                <TableCell
                  align={"left"}
                  style={{ width: windowSize.innerWidth > 600 ? "20%" : "33%", fontWeight: "bold", color: darkMode ? "white" : "black", paddingLeft: windowSize.innerWidth <= 600 ? "30px" : "16px" }}
                >
                  Model
                </TableCell>
                <TableCell
                  align={"left"}
                  style={{ width: windowSize.innerWidth > 600 ? "15%" : "33%", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                >
                  Created Date
                </TableCell>
                <TableCell
                  align={"left"}
                  style={{ width: "15%", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                >
                  Modified Date
                </TableCell>

                <TableCell
                  align={"left"}
                  style={{ width: "15%", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                >
                  Type
                </TableCell>
                <TableCell
                  align={"left"}
                  style={{ width: "15%", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                >
                  Status
                </TableCell>
                <TableCell
                  align={"left"}
                  style={{ width: "15%", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                >
                  Output File Size
                </TableCell>
                {windowSize.innerWidth > 600 && <>

                  <TableCell
                    align={"left"}
                    style={{ fontWeight: "bold", color: darkMode ? "white" : "black" }}
                  >
                    Action
                  </TableCell>
                </>
                }
              </TableRow>

              {/* Body List  */}
              {featuresLoader ?
                <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                  {[1, 2, 3].map(() => (
                    <div className="w-56 mr-8">
                      <Code backgroundColor="grey" foregroundColor="darkgrey" />
                    </div>
                  ))}
                </div>
                :
                modelFeatureList?.length ?
                  <>
                    {modelFeatureList
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, i) => {
                        return (
                          <>
                            <TableRow tabIndex={-1}>
                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[0]}%` }}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                  {
                                    windowSize.innerWidth <= 600 &&
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() => { setOpen(!open); setCollapseRow(i + 1) }}
                                    >
                                      {(open && (collapseRow === (i + 1))) ?
                                        <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                        : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                    </IconButton>
                                  }
                                  {
                                    <Checkbox checked={selectedRow?.modelName === row?.modelName}
                                      color={themeColor}
                                      icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                                      checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                                      onClick={() => { onRowSelection(i, row, docId); }} />
                                  }
                                  {row?.modelName}{row?.fileSize && ` (${convertBytesToMB(row?.fileSize)} mbs)`}
                                </div>
                              </TableCell>

                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[1]}%` }}>
                                {row?.dateCreated?.split('T')?.[0]}
                              </TableCell>

                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[2]}%` }}>
                                {row?.dateModified?.split('T')?.[0]}
                              </TableCell>

                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[3]}%` }}>
                                {row?.recommendationType === "ContentBased" ? `Content Based` : 'Collaborative'}
                              </TableCell>

                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: `${widthhandle[4]}%` }}>
                                <div className="px-2 rounded-md text-center text-white w-28"
                                  style={{ background: statusCheck(row?.modelStatus?.toUpperCase()) }}>
                                  {row?.modelStatus?.charAt(0) + row?.modelStatus?.substring(1)?.toLowerCase()}
                                </div>
                              </TableCell>

                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[3]}%` }}>
                                 {getFile(row?.modelName) !== null ? `${getFile(row?.modelName)} mbs` : " --"}
                              </TableCell>
                              {windowSize.innerWidth > 600 &&
                                <>
                                  <TableCell key={i} align={"left"} style={{ width: `${widthhandle[5]}%` }}>

                                    {CommonIcons(row, i)}
                                  </TableCell>
                                </>
                              }
                            </TableRow>

                            {/* Extended Row */}
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Table>
                                      <MDBox component="thead">
                                        <TableRow >
                                          <TableCell
                                            // key={column.id}
                                            align={"left"}
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </MDBox>
                                      <TableBody >
                                        <>
                                          <TableRow tabIndex={-1}>
                                            <TableCell key={i} align={"left"}
                                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: `33%` }}>
                                              {row?.recommendationType}
                                            </TableCell>
                                            <TableCell key={i} align={"left"}
                                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: `33%` }}>
                                              <div className="px-2 rounded-md text-center text-white w-28"
                                                style={{ background: statusCheck(row?.modelStatus) }}>
                                                {row?.modelStatus?.charAt(0) + row?.modelStatus?.substring(1)?.toLowerCase()}
                                              </div>
                                            </TableCell>
                                            <TableCell key={i} align={"left"}>

                                              {CommonIcons(row, i)}
                                            </TableCell>
                                          </TableRow>
                                        </>

                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </>
                  : <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No models found</div>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          style={{ color: darkMode ? "white" : "black" }}
          count={modelFeatureList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {modalDelOpen?.flag &&
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, modelName: '' })}>
            <div className="flex flex-row w-full justify-center items-center mb-4" >
              Are you sure you want to delete? Deleted model will be lost
            </div>

            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "140px" }} disabled={delLoader}
                onClick={() => setModalDelOpen({ flag: false, modelName: '' })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "140px" }} disabled={delLoader}
                onClick={() => deleteModal()}
                variant="gradient" color={themeColor} fullWidth>
                {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
              </MDButton>
            </MDBox>
          </Modal>
        }
      </Paper>

      {/* Modals */}
      {modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
          <div style={{ zIndex: 999, minHeight: "300px", padding: "20px" }}>
            <div className="flex flex-row w-full justify-between items-center  text-xl font-semibold mb-4" >
              <div> {!isUpdateModal ? 'Creating' : 'Updating'} Model </div>

              <MDButton style={{ width: "max-content", minWidth: "140px" }}
                disabled={createLoader} onClick={() => { createUpdateModel() }} variant="gradient"
                color={themeColor} fullWidth>
                {createLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : !isUpdateModal ? 'Create Model' : 'Update Model'}
              </MDButton>

            </div>

            <div className="w-[100%] flex flex-row flex-wrap"
              style={{ gap: "20px", justifyContent: "space-between", padding: "30px 0px" }}>
              {fields?.filter((fl) => fl?.isActive).map(fi => (
                <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "45%", outline: "none", display: "flex", alignItems: "center" }}>
                  {
                    fi.type === "dropdown" ?
                      <MDDropdown
                        dropdownName={fi?.placeholder}
                        nameArr={fi?.arr} isMultiple={false}
                        backSelect={(val) => {
                          setCreateUpdateNewModel({ ...createUpdateNewModel, [fi?.name]: typeof val === 'string' ? val.split(',')?.[0] : val })
                        }}
                        personName={[createUpdateNewModel?.[fi?.name]]?.length ? createUpdateNewModel[fi?.name] : ''} />
                      : <MDInput
                        // style={{outline: "none", border: "none"}}
                        id={fi.name}
                        type={fi.type}
                        disabled={fi.name === 'modelName' && isUpdateModal}
                        value={createUpdateNewModel[fi.name]}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowDown" && fi?.name === "similarityThreshold") {
                            if (Number(createUpdateNewModel?.similarityThreshold) >= 1) {
                              setCreateUpdateNewModel({ ...createUpdateNewModel, similarityThreshold: 1 });
                            } else {
                              setCreateUpdateNewModel({ ...createUpdateNewModel, similarityThreshold: (Number(createUpdateNewModel?.similarityThreshold) + 0.1)?.toFixed(1) });
                            }
                          }
                          if (e.key === "ArrowUp" && fi?.name === "similarityThreshold") {
                            if (Number(createUpdateNewModel?.similarityThreshold) === 0) {
                              setCreateUpdateNewModel({ ...createUpdateNewModel, similarityThreshold: 0 });
                            } else {
                              setCreateUpdateNewModel({ ...createUpdateNewModel, similarityThreshold: (Number(createUpdateNewModel?.similarityThreshold) - 0.1)?.toFixed(1) });
                            }
                          }
                        }}
                        onChange={fi.name === 'modelName' ? handleModelNameChange : (e) => {
                          setCreateUpdateNewModel(prevState => ({ ...prevState, [fi.name]: e.target.value }));
                        }}
                        label={`${fi.placeholder}${fi.mendatory ? "*" : ""}`}
                        variant="standard" fullWidth />
                  }
                  {/* createUpdateNewModel?.recommendationType?.toLowerCase() === Constants.recommendationContentBased?.toLowerCase() */}
                  {
                    fi?.information &&
                    <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />
                  }
                </MDBox>
              ))}
            </div>
          </div>
        </Modal>
      }
    </div>
  );
}
