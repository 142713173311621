import React, { useEffect, useRef } from 'react';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import 'assets/styles/react-audio-player.css'
import { useMediaQuery } from '@mui/material';

export const AudioPlay = ({ audioSrc, isPlaying, setAudioDownload }) => {
    const [controller] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const audioRef = useRef(null);
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.audio.current.play();
            } else {
                audioRef.current.audio.current.pause();
            }
        }
    }, [isPlaying]);

    const handleNext = () => {
        if (audioRef.current && audioRef.current.audio.current) {
            audioRef.current.audio.current.currentTime += 10;
        }
    };


    const handlePrevious = () => {
        if (audioRef.current && audioRef.current.audio.current) {
            audioRef.current.audio.current.currentTime -= 10;
        }
    };

 
    return (
        <MDBox style={{ padding:(isLargeMobile ||isSmallMobile) ? "0px" : "0px 30px" }}>
            <div className="flex justify-end">
                <MDButton
                    style={{ width: "max-content", minWidth: "140px" }}
                    onClick={() => setAudioDownload()}
                    variant="gradient"
                    color={themeColor}
                    fullWidth
                >
                    DOWNLOAD AUDIO FILE
                </MDButton>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "150px auto"
                }}
            >
                <AudioPlayer
                    autoPlay
                    ref={audioRef}
                    src={audioSrc}
                    onPlay={() => console.log("Playing audio")}
                    showSkipControls={true}
                    showJumpControls={false}
                    onClickNext={handleNext}
                    onClickPrevious={handlePrevious}
                    className={`custom-audio-player ${darkMode ? 'dark-mode' : ''}`} 

                />
            </div>
        </MDBox>
    );
};
