/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDTable from "components/MDTable/MDTablePaymentHistory";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DateRangePicker from "examples/DatePicker/DatePicker";
import Footer from "examples/Footer";
import { Images } from "libraries/Images";
import {useMaterialUIController} from "context";
import { ChatbotBottomWidget } from "examples/ChatbotBottomWidget/ChatbotBottomWidget";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDDropdown from "components/MDDropdown";
import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from "examples/Dropdown/Dropdown";


function PaymentHistory() {
  // const [windowSize, getWindowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const stateUser = useSelector((state) => state.loginReducer);
  const [isPageChange, setPageChange] = useState(false);
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [ isLoader, setIsLoader ] = useState(true);
  const [dropdownValues, setDropdownValues] = useState({ 
    card: "", startDate: "", endDate: ""
  }); 

  useEffect(() => {
    allPaymentMethodsList();
  }, [])

  useEffect(() => {
    allPaymentsList();
  }, [dropdownValues])

  const allPaymentsList = () => {
    setIsLoader(true);

    const data = {
      paymentMethod: dropdownValues?.card,
      startDate: dropdownValues?.startDate,
      endDate: dropdownValues?.endDate
    };

    // Filter out properties with empty string values
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    )

    ApiRequest.paymentBillingHistory(filteredData, `/${stateUser?.user?.userId}`, (res) => {
      if(res?.isSuccess){
        setPaymentsList(res?.data?.paymentHistory);
      }else{
        setPaymentsList([]);
      }
      setIsLoader(false);
    });
  };

  const allPaymentMethodsList = () => {
    ApiRequest.PaymentMethodsList(`/${stateUser?.user?.userId}`, "", (res) => {
      if(res?.isSuccess && res?.data?.paymentMethod?.length){
        setPageChange(!isPageChange); setPaymentMethodsList(res?.data?.paymentMethod)
      }
    })
  };

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>
      <DashboardNavbar />

      <MDTypography sx={{ fontSize: "20px", fontWeight: "600",paddingLeft:"5px" }}>
          Payment History
      </MDTypography>

      <MDBox pt={1}>
      <div  style={{ marginTop: "15px" }} className="flex w-full justify-end flex-wrap">
        <div style={{ fontSize: "15px", marginTop: "10px", marginRight: "10px",color: darkMode && "white" }}>Filter by:</div>
        
     
        <div style={{  marginBottom: "5px" }}>
        <MDDropdown 
            dropdownName={"Payment Method"} 
            nameArr={paymentMethodsList?.length ? paymentMethodsList : ['']}
            isBgWhite={!darkMode}
            // isMultiple={true}
            backSelect={(val)=> {
              setDropdownValues({ ...dropdownValues, card: val })
            } }
            personName={dropdownValues?.card?.length ? [dropdownValues?.card]?.filter(el => el) : []} />
        </div>

        <div  style={{ paddingLeft: "10px" }}>
          <DateRangePicker backDates={(dt) => {
            setDropdownValues({...dropdownValues, 
              startDate: Constants.formatDashDates(dt?.startDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.startDate, false),
              endDate: Constants.formatDashDates(dt?.endDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.endDate, false),
            })
          }} />
        </div>
    
      </div>
      

      <MDBox pt={2} pb={3} style={{ overflowY: "auto", height: "calc(100vh - 235px)" }}>
        <Grid container spacing={6} >
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDTable  backPage={isPageChange} isLoader={isLoader} paymentsList={paymentsList} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
      </MDBox>
    </DashboardLayout>


    {/* Widget Component */}
    {/* <ChatbotBottomWidget /> */}
   
    </div>
    
  );
}

export default PaymentHistory;
