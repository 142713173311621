import React, { FC } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { NavLink } from "react-router-dom";
import {useMaterialUIController} from "context";
import MDBox from "components/MDBox";
import { FaBoxArchive } from "react-icons/fa6";
import { useMediaQuery } from '@mui/material';

export const Tag = ({ name, onClick, isDelete, isLogo, onDelete, backgroundColor, fontColor, className,isWebToolTrain }) => {
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile=useMediaQuery('(min-width:318px) and (max-width:390px)')

    return (
        <MDBox
            onClick={(e) => onClick(e)}
            bgColor={themeColor}
            color={"white"}
            style={{ fontSize: isWebToolTrain?`${isSmallMobile ? "7px" :isLargeMobile ? "8px" : (isTablet ? "10px" : "14px")}`:"14px", fontWeight: "450" 
            
            // backgroundColor: backgroundColor ?? "#E3E4E5", 
            // color: fontColor ?? "#28638e" 
            }}
            className={`mx-2 ${className} relative cursor-pointer inline-flex items-center leading-sm uppercase px-3 py-1  rounded-full`}
        >
            {isLogo &&
                   <FaBoxArchive
                    style={{ color: "white", marginRight: "10px", fontSize: "14px" }}
                    // onClick={(e) => editAgent(e,row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, '', false)}
                    className=" cursor-pointer"
                    />
                // <svg
                //     xmlns="http://www.w3.org/2000/svg"
                //     width="20"
                //     height="20"
                //     viewBox="0 0 24 24"
                //     fill="none"
                //     stroke="currentColor"
                //     strokeWidth="2"
                //     strokeLinecap="round"
                //     strokeLinejoin="round"
                //     className="feather feather-archive mr-2"
                // >
                //     <polyline points="21 8 21 21 3 21 3 8"></polyline>
                //     <rect x="1" y="3" width="22" height="5"></rect>
                //     <line x1="10" y1="12" x2="14" y2="12"></line>
                // </svg>
                }
                <div style={{ paddingTop: "3px" }}>
                {name}
                </div>

            {isDelete &&
                <IoMdCloseCircle
                    onClick={(e) => onDelete(e)}
                    style={{ position: 'absolute', top: '-13px', right: '-13px' }}
                    className={`mr-4 font-semibold text-xl text-red-500 cursor-pointer`}
                />
            }
        </MDBox>
    );

};
