import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "assets/styles/core.css";

export default function Toaster() {
   return (
      <ToastContainer
         position="top-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         className={'toast-message'}
         rtl={false}
         limit={8}
         pauseOnFocusLoss={false}
         draggable
         pauseOnHover={false}
      />
   );
}
