/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ApiKeyManagementTablePattren from "components/MDTable/MDTableApiKeyManagement";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Images } from "libraries/Images";
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';
import OutlinedInput from '@mui/material/OutlinedInput';

import ApiRequest from "services/ApiRequest";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import ApikeyModal from "./ApiKeyModel";
import { useMediaQuery } from "@mui/material";


function ApiKeyManagementTable() {
    // const [windowSize, getWindowSize] = useScreenWidth();
    const [controller] = useMaterialUIController();
    const { themeColor, darkMode } = controller;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
    const [origionalApiKey, setOrigionalApiKey] = useState('')
    const [apiKeyList, setApiKeyList] = useState();
    const [isLoader, setIsLoader] = useState(true);
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')

    const columns = [
        { id: 1, name: "XAgent" },
        { id: 2, name: "Pattern" },
        { id: 3, name: "Status" },
        { id: 4, name: "Actions" }
    ];

    const widthhandle = ["35", "35", "20", "10"];

    const getApiKeys = () => {
        ApiRequest.allApiKeys('', (res) => {
          setIsLoader(true);
          var agentArr = [];
          const apiKeys = res?.data?.apiKeys;
          agentArr = apiKeys?.map(ps => {
            return {
              id: ps?._id, name: ps?.name, secretKey: ps?.secretKey,
              dateCreated: ps?.dateCreated, lastUsed: ps?.lastUsed,
              globalPermission: ps?.globalPermission
            }
          })?.flatMap(item => item)
          if (res?.isSuccess) {
            setApiKeyList(agentArr?.length ? agentArr : []);
    
          } else {
            setApiKeyList([]);
          }
          setIsLoader(false);
        });
      }
    const handleModalClose = () => {
     setModalOpen(false);
        setModalSuccessOpen(false);
        getApiKeys()
    };

    return (
        <div style={{
            backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundPosition: "100% 100%",
            overflowY: "auto",
            height: "100vh"
        }}>
            <DashboardLayout>
                <DashboardNavbar />
                <Grid item xs={12} style={{ padding: "0px 6px", display: "flex", justifyContent: "space-between" }}>
                <MDTypography style={{fontSize:(isSmallMobile || isLargeMobile) ? "14px" : "20px",fontWeight:"600", display:"flex",alignItems:"center"}}>
                        API Key
                    </MDTypography>
                    <MDButton
                        component="a"
                        onClick={() => {
                            setModalOpen(true)
                        }
                        }
                        rel="noreferrer"
                        variant="gradient"
                        color={themeColor}
                        style={{ height: "40px", padding:`${(isSmallMobile || isLargeMobile) ? "7px" : ""}` }}
                    >
                        Create New API Key
                    </MDButton>
                </Grid>

                <MDBox pt={3} pb={3} style={{ overflowY: "auto", height: "calc(100vh - 175px)" }}>
                    <Grid container spacing={6} >
                        <Grid item xs={12}>
                            <Card>
                                {/* <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor={themeColor}
                                    borderRadius="lg"
                                    coloredShadow={themeColor}
                                >
                                    <MDTypography variant="h6" color="white"> */}
                                        {/* Widgets List */}
                                    {/* </MDTypography>
                                </MDBox> */}
                                <MDBox>
                                    <ApiKeyManagementTablePattren columns={columns} widthhandle={widthhandle} apiList ={apiKeyList} getApiKey = {getApiKeys} isLoading ={isLoader}/>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
            {modalOpen &&
                <ApikeyModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    title="Creating New API Key"
                    buttonLabel="Create API key"
                    themeColor={themeColor}
                    darkMode={darkMode}
                    isSuccessModel={false}
                    setOpen = {setModalOpen}
                />
            }
            {modalSuccessOpen &&
                <ApikeyModal
                    open={modalSuccessOpen}
                    onClose={handleModalClose}
                    title="API Key Successfully Created"
                    themeColor={themeColor}
                    darkMode={darkMode}
                    isSuccessModel={true}
                    origionalApiKey={origionalApiKey}
                    setOpen = {setModalSuccessOpen}
                />
            }
        </div>

    );
}

export default ApiKeyManagementTable;
