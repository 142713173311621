import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { SpinnerLoader } from "examples/Loader/Loader";
import { Toggle } from "examples/Toggle/Toggle";
import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";
import { useMaterialUIController } from "context";
import { Card, Grid } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Images } from "libraries/Images";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { validateAndProcessFile } from "services/Utils";
import { Tag } from "examples/Tag/Tag";
import Methods from "libraries/CommonMethodsUI";
import { toast } from "react-toastify";
import ApiUrls from "services/ApiUrls";

const PredictiveAnalysisXAgent = (props) => {
  const [loader, setLoader] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [bucketDocumentId, setBucketDocumentId] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const {id, name} = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const uploadedContent = (fl) => {
    const supportedFormats = {
      'text/csv': 'CSV',
      'application/vnd.ms-excel': 'XLSX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
      'application/octet-stream': 'PARQUET', 
      '': 'PARQUET'
    };

    const afile = fl?.[0];

    const validation = validateAndProcessFile(afile, supportedFormats)
    if(validation === true && fl){
        setUploadedFile(fl);
        toast.success("File(s) have been saved")
    }else{
        toast.error(`${validation}`)      
    }
    
  }

  const saveToDownload = () => {
    setLoader(true);
    if (uploadedFile?.length) {
      ApiRequest.inferFeatureAgent({
        file: uploadedFile?.[0],
        modelName: JSON.parse(localStorage.getItem("featureModelId") || "")?.name,
        saveToBucket: enabled ? 'True' : 'False',
      },enabled,
        `${location?.state?.deployedURL}${ApiUrls.inferPredictiveAgent}/${location?.state?.objectId}`, 
          async(res) => {
          setLoader(false);

          if (res.isSuccess) {
            if (res?.data?.documentId) setBucketDocumentId(res?.data?.documentId)
            else {
              Methods.downloadCSV(res?.data, uploadedFile?.[0]?.name);
            }
            toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
          } else {
            let json = {};
            if(!enabled){
              const text = new TextDecoder().decode(res?.error);
              json = JSON.parse(text);
            }else{
              json = res?.error;
            }
            toast.error(json?.message ? json?.message : "Inference failed")
          }
        });
    } else {
      toast("Please Upload file for inference");
      setLoader(false);
    }
  }

  const saveToBucket = () => {
    setLoader(true);
    ApiRequest.inferBucketSaveFeatureAgent({
      documentId: bucketDocumentId,
      modelName: JSON.parse(localStorage.getItem("featureModelId") || "")?.name,
      inputOrOutput: "output",
    },
      `${location?.state?.objectId}`, (res) => {
        setLoader(false);
        if (res.isSuccess) {
          Methods.downloadCSV(res?.data, uploadedFile?.[0]?.name);
          toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
        } else {
          toast.error(res?.error?.message ? res?.error?.message : "Inference failed")
        }
      });
  }

 
  const Layout = ({layoutTitle, children, style, className}) => {
      return(
        <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
          <MDBox
            variant="gradient"
            bgColor={themeColor}
            color={"white"}
            coloredShadow={themeColor}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // width="4rem"
            pl={2} pr={2}
            height="4rem"
            mt={-5}
          >
            <MDTypography style={{ color: "white" }} variant="h6">
                {layoutTitle}
            </MDTypography>
              
          </MDBox>
        </MDBox>  
        <MDBox lineHeight={1.25} px={2} py={1} className={className} style={{paddingBottom: "30px", ...style}}>
              {children}              
          </MDBox>
      </Card>
      )
  }

  return (
    <div style={{
        backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
        backgroundPosition: "100% 100%",
        overflowY: "auto",
        height: "100vh"
      }}>
      <DashboardLayout>
      <DashboardNavbar />
        
      <Grid container spacing={2} style={{paddingTop: "30px", overflowY: "auto", height: "calc(100vh - 110px)"}}>
          <Grid container xs="12" className="p-4 relative">
              <Grid container xs="12" className="flex mt-6 flex-row w-full     justify-between items-center text-primary-blue text-xl font-semibold mb-4" >
                  <div className="flex flex-row w-full" style={{ justifyContent: "end" }}>
                    <div className="mr-4 flex flex-row items-center">
                      <div style={{ color: darkMode ? "white" : "black"}} className="text-sm mr-4">Save to Bucket</div>
                      <Toggle enabled={enabled} setEnabled={(fl) => setEnabled(fl)} />
                    </div>
                    <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                    onClick={()=> {
                        navigate(`/patterns/${id}/${name}/agent-creation/config`,{ state: {...location?.state, page: 1, step: 0, isDeploying: false}})
                      }} 
                        variant="gradient" color={themeColor} fullWidth>
                        {"Modify X-Agent"}
                    </MDButton>
                  </div>
                </Grid>


              <Grid container xs="12" className="mt-8">
                  <Grid xs="12" sm="12" md={"12"} lg={"12"} className="px-2">
                  <Layout style={{paddingTop: "20px"}} layoutTitle={`Predictive Analysis X-Agent`}>
                    <div style={{ color: darkMode ? "white" : "black", overflowY: 'auto', scrollBehavior: 'smooth' }}>

                    <div style={{fontSize: "16px"}} className=" w-full pt-4 pl-2">
                      {`${bucketDocumentId ? 'Please click on download to save the infer results in your system.' 
                      : uploadedFile?.length ? 'Click Next to save the uploaded file or remove to upload a new one.'
                        : 'Please upload file for inference results of Predictive Analysis X-Agent.'}`}
                    </div>

                    <div className={`${uploadedFile?.length ? 'pt-24' : 'mt-4'}`} style={{ position: "relative" }}>
                      <div className=" flex flex-grow justify-center w-full  flex-wrap">
                        {uploadedFile?.length ? uploadedFile?.map((afl, ind, arr) => (
                          <Tag
                            name={afl?.name}
                            onClick={(e) => console.log(afl)}
                            isDelete={!bucketDocumentId}
                            onDelete={(e) => {
                              e.stopPropagation();
                              setUploadedFile([])
                            }}
                            className="mt-8"
                            isLogo={true}
                            isWebToolTrain={true}
                          />
                        )) : null}
                      </div>

                      {!uploadedFile?.length ?
                        <div>
                          <TabUpload supportedFileTypes="CSV, XLSX, PARQUET" isSingleFile={true} 
                          uploadedFile={(fl) => uploadedContent(fl)} loader={false} />
                        </div>
                        : null}
                    </div>
                  </div>

                  <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
                    {bucketDocumentId &&
                    <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                              onClick={()=> setBucketDocumentId("")} variant="gradient" 
                              color={themeColor} fullWidth>
                      Back
                    </MDButton>
                    }
                    <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={loader} 
                              onClick={()=> bucketDocumentId ? saveToBucket() : saveToDownload()} 
                              variant="gradient" color={themeColor} fullWidth>
                      {loader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : bucketDocumentId ? "Download File" : "Next"}
                    </MDButton>
                  </MDBox>            
                </Layout>
              </Grid>
            </Grid> 
          </Grid>
        </Grid> 
        </DashboardLayout>
    </div>
  );
};

export default PredictiveAnalysisXAgent;
