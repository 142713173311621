import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ApiRequest from "services/ApiRequest";

import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import MDInput from "components/MDInput";
import useScreenWidth from "libraries/ScreenSizeHook";

import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import { Paths } from "libraries/Route";

import {useMaterialUIController} from "context";

function FeatureExtractionResult(props){
  const navigate = useNavigate();
  const location = useLocation();
  const {id, name} = useParams();
  const [timer, setTimer] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
const [graghVlues, setGraghVlues] = useState({labels: [], datasets: null})

  const navigation = (route, page, step, deployedURL, uploadedFile, result) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: deployedURL,
        status: location?.state?.status,
        isDeploying: location?.state?.isDeploying,
        uploadedFile: uploadedFile,
        isTrained: false,
        result: result,
        beforeTrained: location?.state?.beforeTrained
      }
    });
  };

  const fields = [
    {
      placeholder: "Model Name",
      name: "modelName",
      val: `${location?.state?.beforeTrained?.modelName}`,
      // inputValues.modelName
      type: "text",
    },
    {
      placeholder: "Training Loss",
      name: "trainingLoss",
      val: `${location?.state?.result?.trainingLoss}`,
      // inputValues.trainingLoss?.toFixed(13)
      type: "text",
    }
  ];

  const reportsLineChartData = {
    sales: {
      labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: { label: "Training Loss", data: [50, 40, 300, 320, 500, 350, 200, 230, 500] },
    },
    tasks: {
      labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: { label: "Iteration", data: [50, 40, 300, 220, 500, 250, 400, 230, 500, 50, 40, 300, 220, 500, 250, 400, 230, 500] },
    },
  };

  const { sales, tasks } = reportsLineChartData;

  useEffect(() => {
    let labels = [] 
    let _data = []
    location?.state?.result?.graphValues?.map((value, index) => {
      labels.push(`${value?.iteration}`)
      _data.push(value?.loss)
    })

    setGraghVlues({labels: labels, datasets: {label: "Iteration", data: _data}})
  }, [])
  


  
  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  const modelSetup = () => {
    navigation('/train', 3, 2, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  return (
    <div>
        <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "30px"}}>
        	{fields.map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "45%", outline: "none" }}>
					  <MDInput   
                // style={{outline: "none", border: "none"}}
                id={fi.name} 
                type={fi.type}
                value={fi.val} 
                disabled={true}
                onChange={(e) => console.log(e)}
                label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                variant="standard" fullWidth />
  
					</MDBox>
            ))}
          </div>


          {/* Graph */}
          <div style={{ display: "flex", justifyContent: "center", width: "100%",  marginTop: "25px" }}>
            <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={4}>
                  <ReportsLineChart
                    color={themeColor}
                    title="Training Loss Vs Iteration"
                    description=""
                    date=""
                    chart={graghVlues}
                  />
                </MDBox>
            </Grid>
          </div>
          
          <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {modelSetup()}} variant="gradient" color={themeColor} fullWidth>
              Previous
            </MDButton>
            {/* <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {modelSetup()}} variant="gradient" color={themeColor} fullWidth>
              Model Setup
            </MDButton> */}
            <MDButton 
              style={{width: "max-content", minWidth: "140px"}} disabled={false} 
              onClick={()=> {navigate(`/patterns/${id}/${name}/agent-creation/infer/feature-extraction`,{ state: {...location?.state}})}} 
              variant="gradient" color={themeColor} fullWidth>
              Inference
            </MDButton>
          </MDBox>
    </div>
  );
};

export default FeatureExtractionResult;

