/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import ApiRequest from "services/ApiRequest";
import { useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { Instagram } from 'react-content-loader'
import { Images } from "libraries/Images";
import { useNavigate } from "react-router-dom";
import { Paths } from "libraries/Route";
import { useMaterialUIController, setChatbotNavClose } from "context";
import { Tabs, Tab, Card, Divider, TextField, InputAdornment, useMediaQuery } from '@mui/material';
import { getColorVlue } from "services/Utils";
import { PatternCard } from "./PatternCard";
//pattern images
import  LLMIcon  from "assets/images/patterns/LLM-Pattern.png"
import  LLMIconWhite  from "assets/images/patterns/LLM-Pattern-white.png"
import  LLMIconGreen from "assets/images/patterns/LLM-Pattern-green.png"
import  FeatureExtraction  from "assets/images/patterns/FeatureExtraction-Pattern.png"
import  FeatureExtractionWhite  from "assets/images/patterns/FeatureExtraction-Pattern-white.png"
import  FeatureExtractionGreen  from "assets/images/patterns/Feature Extraction Pattern-green.png"
import  Predictive  from "assets/images/patterns/Predictive-Pattern.png"
import  PredictiveWhite  from "assets/images/patterns/Predictive-Pattern-white.png"
import  PredictiveGreen from "assets/images/patterns/Predictive Pattern-green.png"
import  Recommendation  from "assets/images/patterns/Recommendation-Pattern.png"
import  RecommendationWhite  from "assets/images/patterns/Recommendation-Pattern-white.png"
import  RecommendationGreen from "assets/images/patterns/Recommendation Pattern-green.png"
import  ImageToText  from "assets/images/patterns/Image-to-Text-Pattern.png"
import  ImageToTextWhite  from "assets/images/patterns/Image-to-Text-Pattern-white.png"
import  ImageToTextGreen from "assets/images/patterns/Image-to-Text Pattern-green.png"
import  TextToSpeech  from "assets/images/patterns/Text-to-Speech-Pattern.png"
import  TextToSpeechWhite  from "assets/images/patterns/Text-to-Speech-Pattern-white.png"
import  TextToSpeechGreen  from "assets/images/patterns/Text-to-Speech Pattern-green.png"
import  Personalisation  from "assets/images/patterns/Personalisation-Pattern.png"
import  PersonalisationWhite  from "assets/images/patterns/Personalisation-Pattern-white.png"
import  PersonalisationGreen from "assets/images/patterns/Personalisation Pattern-green.png"
import  AnomalyDetection  from "assets/images/patterns/AnomalyDetection-Pattern.png"
import  AnomalyDetectionWhite  from "assets/images/patterns/AnomalyDetection-Pattern-white.png"
import  AnomalyDetectionGreen  from "assets/images/patterns/Anomaly Detection Pattern-green.png"
import  NaturalLanguage  from "assets/images/patterns/Natural-Language.png"
import  NaturalLanguageWhite  from "assets/images/patterns/Natural-Language-white.png"
import  NaturalLanguageGreen  from "assets/images/patterns/Natural Language-green.png"
import  ComputerVision  from "assets/images/patterns/ComputerVision-Pattern.png"
import  ComputerVisionWhite  from "assets/images/patterns/ComputerVision-Pattern-white.png"
import  ComputerVisionGreen  from "assets/images/patterns/Computer Vision Pattern-green.png"
import  ProcessAutomation  from "assets/images/patterns/Process-Automation.png"
import  ProcessAutomationWhite  from "assets/images/patterns/Process-Automation-white.png"
import  ProcessAutomationGreen  from "assets/images/patterns/Process Automation-green.png"
import  SpeechRecognition  from "assets/images/patterns/Speech-to-text-Pattern.png"
import  SpeechRecognitionWhite  from "assets/images/patterns/Speech-to-text-Pattern-white.png"
import  SpeechRecognitionGreen  from "assets/images/patterns/Speech-to-text Pattern-green.png"
import  CustomerSegmentation  from "assets/images/patterns/Customer-segmentation.png"
import  CustomerSegmentationWhite  from "assets/images/patterns/Customer-segmentation-white.png"
import  CustomerSegmentationGreen  from "assets/images/patterns/Customer-green.png"
import  VirtualAssistant  from "assets/images/patterns/VirtualAssistant-Pattern.png"
import  VirtualAssistantWhite  from "assets/images/patterns/VirtualAssistant-Pattern-white.png"
import  VirtualAssistantGreen  from "assets/images/patterns/Virtual Assistant Pattern-green.png"
import  BehaviouralAnalytics  from "assets/images/patterns/Behavioural-Analytics.png"
import  BehaviouralAnalyticsWhite  from "assets/images/patterns/Behavioural-Analytics-white.png"
import  BehaviouralAnalyticsGreen  from "assets/images/patterns/Behavioural Analytics-green.png"
import  Reinforcement  from "assets/images/patterns/Reinforcement.png"
import  ReinforcementWhite  from "assets/images/patterns/Reinforcement-white.png"
import  ReinforcementGreen from "assets/images/patterns/Reinforcement-green.png"
import  FraudDetection  from "assets/images/patterns/FraudDetection-Pattern.png"
import  FraudDetectionWhite  from "assets/images/patterns/FraudDetection-Pattern-white.png"
import  FraudDetectionGreen  from "assets/images/patterns/Fraud Detection Pattern-green.png"
import  KnowledgeGraph  from "assets/images/patterns/Knowledge-Graph.png"
import  KnowledgeGraphWhite  from "assets/images/patterns/Knowledge-Graph-white.png"
import  KnowledgeGraphGreen from "assets/images/patterns/Knowledge Graph-green.png"
import  LanguageTranslation  from "assets/images/patterns/Language.png"
import  LanguageTranslationWhite  from "assets/images/patterns/Language-white.png"
import  LanguageTranslationGreen  from "assets/images/patterns/Language-green.png"
import  AutomatedContent  from "assets/images/patterns/Automated-Content.png"
import  AutomatedContentwhite  from "assets/images/patterns/Automated-Content-white.png"
import  AutomatedContentGreen  from "assets/images/patterns/Automated Content-green.png"
import  EmotionRecognition  from "assets/images/patterns/Emotion-Recognition.png"
import  EmotionRecognitionWhite  from "assets/images/patterns/Emotion-Recognition-white.png"
import  EmotionRecognitionGreen  from "assets/images/patterns/Emotion Recognition-green.png"
import  CollaborativeFiltering  from "assets/images/patterns/Collaborative-Filtering.png"
import  CollaborativeFilteringWhite  from "assets/images/patterns/Collaborative-Filtering-white.png"
import  CollaborativeFilteringGreen  from "assets/images/patterns/Collaborative Filtering-green.png"
import  AugmentedReality  from "assets/images/patterns/Augmented-Reality.png"
import  AugmentedRealityWhite  from "assets/images/patterns/Augmented-Reality-white.png"
import  AugmentedRealityGreen  from "assets/images/patterns/Augmented Reality-green.png"
import  BlockchainIntegration  from "assets/images/patterns/Blockchain-Integration.png"
import  BlockchainIntegrationWhite  from "assets/images/patterns/Blockchain-Integration-white.png"
import  BlockchainIntegrationGreen  from "assets/images/patterns/Blockchain Integration-green.png"
import  AutomatedSpeech  from "assets/images/patterns/Automated-Speech.png"
import  AutomatedSpeechWhite  from "assets/images/patterns/Automated-Speech-white.png"
import  AutomatedSpeechGreen  from "assets/images/patterns/Automated Speech-green.png"
import  DynamicPricing  from "assets/images/patterns/DynamicPricing-Pattern.png"
import  DynamicPricingWhite  from "assets/images/patterns/DynamicPricing-Pattern-white.png"
import  DynamicPricingGreen  from "assets/images/patterns/Dynamic Pricing Pattern-green.png"
import SearchIcon from '@mui/icons-material/Search';
import MDTypography from "components/MDTypography";
import LLmGif from "assets/images/patterns/gifvideo/LLm_pattern_video.gif.gif"
import FeatureExtractionGif from "assets/images/patterns/gifvideo/Feature-extraction-pattern-min.gif"
import predictiveGif from "assets/images/patterns/gifvideo/Predictive-pattern-min.gif"
import SpeechToTextGif from "assets/images/patterns/gifvideo/Speech-to-text-pattern-min.gif"
import TextToSpeechPattern from "assets/images/patterns/gifvideo/Text-to-speech-pattern-min.gif"
import ImageToTextPattern from "assets/images/patterns/gifvideo/Image-to-text-pattern-min.gif"




function Patterns() {
  const { sales, tasks } = reportsLineChartData;
  const [patternList, setPatternList] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:399px)')
  const handleChange = (event, newValue) => {
    console.log(newValue,"val")
    setSelectedTab(newValue);
  };
  useEffect(() => {
    setIsLoader(true);
    setChatbotNavClose(dispatch, true);
        ApiRequest.patternList("?page=1", "", (res) => {
          if (res.isSuccess) {
            const patternData = res.data?.patternsList.map(pattern => {
              const matchingTabData = tabData.find(tab => tab.patternName === pattern.patternName);
      
              if (matchingTabData) {
                return {
                  ...pattern,
                  tabId:matchingTabData.id,
                  icon: matchingTabData.icon,
                  defaultIcon: matchingTabData.defaultIcon,
                  greenIcon: matchingTabData.greenIcon,
                  gifImage:matchingTabData.gifImage
                };
              }
              return pattern;
            });
      
            setPatternList(patternData);
      } else {
        setPatternList([]);
      }
      setIsLoader(false);
    });
  }, []);

  const patternDetailList = (data, id) => {
    ApiRequest.patternList(`/${id}`, '', (res) => {
        if (res.isSuccess) {
           navigate(`/patterns/${id}/${data?.patternName?.replaceAll(" ", "-").toLowerCase()}/agent-creation/config`, {
            state: { id: id, step: 0, deployedURL: res.data?.patternLearnMore?.[0]?.url, isEditAgent: false }
           });
        } 
      });
  };
  
  const handleNavigate = (data) => {
      if (data?.planName===null) {
        patternDetailList(data, data?._id)
        console.log("patternList Data", data)
      }
      if(data?.planName !== null){
        navigate("/pricing-plans")
      }
      if(data?.planName === "Unavailable"){
        navigate("/pricing-plans",{state:{pricingPlanUpdate:true}})
      }
  };

  const tabData = [
    { id:0,patternName: 'LLM', icon: LLMIcon, defaultIcon: LLMIconWhite,greenIcon:LLMIconGreen,gifImage:LLmGif },
    { id:1,patternName: 'Feature Extraction', icon: FeatureExtraction , defaultIcon: FeatureExtractionWhite,greenIcon:FeatureExtractionGreen,gifImage:FeatureExtractionGif   },
    { id:2,patternName: 'Predictive Analysis', icon: Predictive , defaultIcon: PredictiveWhite,greenIcon:PredictiveGreen,gifImage:predictiveGif   },
    { id:3,patternName: 'Recommendation System', icon: Recommendation , defaultIcon: RecommendationWhite,greenIcon:RecommendationGreen,gifImage:LLmGif   },
    { id:4,patternName: 'Image to Text', icon: ImageToText , defaultIcon: ImageToTextWhite,greenIcon:ImageToTextGreen,gifImage:ImageToTextPattern   },
    { id:5,patternName: 'Text to Speech', icon: TextToSpeech , defaultIcon: TextToSpeechWhite,greenIcon:TextToSpeechGreen,gifImage:TextToSpeechPattern   },
    { id:6,patternName: 'Speech to Text', icon: AutomatedSpeech , defaultIcon: AutomatedSpeechWhite,greenIcon:AutomatedSpeechGreen,gifImage:SpeechToTextGif   },
    { id:7,patternName: 'Anomaly Detection', icon: AnomalyDetection , defaultIcon: AnomalyDetectionWhite,greenIcon:AnomalyDetectionGreen,gifImage:LLmGif  },
    { id:8,patternName: 'Natural Language Understanding', icon: NaturalLanguage , defaultIcon: NaturalLanguageWhite,greenIcon:NaturalLanguageGreen,gifImage:LLmGif  },
    { id:9,patternName: 'Computer Vision', icon: ComputerVision , defaultIcon: ComputerVisionWhite,greenIcon:ComputerVisionGreen,gifImage:LLmGif  },
    { id:10,patternName: 'Process Automation', icon: ProcessAutomation , defaultIcon: ProcessAutomationWhite,greenIcon:ProcessAutomationGreen,gifImage:LLmGif  },
    { id:11,patternName: 'Speech Recognition', icon: SpeechRecognition , defaultIcon: SpeechRecognitionWhite ,greenIcon:SpeechRecognitionGreen,gifImage:LLmGif },
    { id:12,patternName: 'Customer Segmentation', icon: CustomerSegmentation , defaultIcon: CustomerSegmentationWhite ,greenIcon:CustomerSegmentationGreen,gifImage:LLmGif },
    { id:13,patternName: 'Virtual Assistant', icon: VirtualAssistant , defaultIcon: VirtualAssistantWhite ,greenIcon:VirtualAssistantGreen,gifImage:LLmGif },
    { id:14,patternName: 'Behavioural Analytics', icon: BehaviouralAnalytics , defaultIcon: BehaviouralAnalyticsWhite,greenIcon:BehaviouralAnalyticsGreen,gifImage:LLmGif   },
    { id:15,patternName: 'Reinforcement Learning', icon: Reinforcement , defaultIcon: ReinforcementWhite,greenIcon:ReinforcementGreen,gifImage:LLmGif   },
    { id:16,patternName: 'Fraud Detection', icon: FraudDetection , defaultIcon: FraudDetectionWhite,greenIcon:FraudDetectionGreen,gifImage:LLmGif   },
    { id:17,patternName: 'Knowledge Graph', icon: KnowledgeGraph , defaultIcon: KnowledgeGraphWhite,greenIcon:KnowledgeGraphGreen,gifImage:LLmGif   },
    { id:18,patternName: 'Language Translation', icon: LanguageTranslation , defaultIcon: LanguageTranslationWhite,greenIcon:LanguageTranslationGreen,gifImage:LLmGif   },
    { id:19,patternName: 'Automated Content Generation', icon: AutomatedContent , defaultIcon: AutomatedContentwhite,greenIcon:AutomatedContentGreen,gifImage:LLmGif   },
    { id:20,patternName: 'Emotion Recognition', icon: EmotionRecognition , defaultIcon: EmotionRecognitionWhite,greenIcon:EmotionRecognitionGreen,gifImage:LLmGif  },
    { id:21,patternName: 'Collaborative Filtering', icon: CollaborativeFiltering , defaultIcon: CollaborativeFilteringWhite,greenIcon:CollaborativeFilteringGreen,gifImage:LLmGif  },
    { id:22,patternName: 'Augmented Reality (AR)', icon: AugmentedReality , defaultIcon: AugmentedRealityWhite,greenIcon:AugmentedRealityGreen,gifImage:LLmGif   },
    { id:23,patternName: 'Blockchain Integration', icon: BlockchainIntegration , defaultIcon: BlockchainIntegrationWhite,greenIcon:BlockchainIntegrationGreen,gifImage:LLmGif   },
    { id:24,patternName: 'Personalisation', icon: Personalisation , defaultIcon: PersonalisationWhite,greenIcon:PersonalisationGreen,gifImage:LLmGif  },
    { id:25,patternName: 'Dynamic Pricing', icon: DynamicPricing , defaultIcon: DynamicPricingWhite,greenIcon:DynamicPricingGreen,gifImage:LLmGif   },
  ];

  const filteredTabs = patternList?.filter(tab => 
    tab?.patternName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  console.log(filteredTabs,"tabs")
  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>

        <DashboardNavbar />

        <MDBox style={{ overflowY: "auto", height: "calc(100vh - 130px)" }}>
          <MDTypography sx={{margin: "0px 0px 15px 10px", fontSize:"20px",fontWeight:"600", color:darkMode ? "white" : "#3B4E6D"}}>Pattern</MDTypography>
          <Grid container spacing={2} sx={{display:(isSmallMobile || isLargeMobile) ?"flex" : "", flexDirection:(isSmallMobile ||isLargeMobile) ? "column-reverse" : ""}}>
          <Grid item xs={12} lg={3} md={4} style={{height:"750px" }} >
              <Card style={{height:"100%"}}>
              <MDBox p={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Search Patterns..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon  style={{color:darkMode ? "white" : "black"}}/>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <div style={{overflowY:"scroll"}}>
                <Tabs
                  orientation="vertical"
                  value={selectedTab}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                  
                    borderRight: 1,
                    borderColor: 'divider',
                    width: '100%',
                    padding: 0,
                    background: !darkMode ? "white" :"",
                    borderRadius: "8px",
                    ".MuiTab-root": {
                      height: "90px",
                      backgroundColor: "transparent", 
                      justifyContent: "flex-start", 
                      textAlign: "left",
                      paddingLeft: "16px", 
                      borderRadius: "0",
                      "&:not(:last-child)": {
                        borderBottom: "1px solid #DAD8D8", 
                      },

                    },
                    ".Mui-selected": {
                      backgroundColor: getColorVlue(themeColor), 

                    },
                  }}
                >
                  {filteredTabs?.map((tab, index) => {

                    return (
                      <Tab
                        key={index}
                        icon={
                          <div style={{ width: '50px', height: '50px', margin: '0px',background: selectedTab === tab.tabId ? 'white' : getColorVlue(themeColor),  borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                           <img src={selectedTab === tab.tabId && themeColor === "success" ? tab.greenIcon :selectedTab === tab.tabId ? tab.icon :tab.defaultIcon} alt="img"  className="h-[25px] w-[25px]"/>
                          </div>
                        }
                        label={<p style={{ color: selectedTab === tab.tabId|| darkMode ? "white" : "#000000" ,fontSize:isMdLoptop ? "11px" :"14px", fontWeight:"500" }}>{tab.patternName}</p>}
                        sx={{ display: 'flex', gap: '15px' }}
                        value={tab.tabId}
                     />
                         
                      
                    )
                  }
                  )}
                </Tabs>
                </div>
              </Card>
            </Grid>

            {/* Content section with 9 columns */}
            {filteredTabs?.map((data, idx) => {
              return (
                <PatternCard
                  key={data._id}
                  selectedTab={selectedTab}
                  id={data.tabId}
                  llmDescription={data.description}
                  llmIcon={data.defaultIcon}
                  buttonLabel={"Create X-Agent"}
                  patternName={data.patternName}
                  gifImage={data.gifImage}
                  darkMode={darkMode}
                  themeColor={themeColor}
                  handleNavigate={() => handleNavigate(data)}
                />
              )
            })}


            {/* {isLoader ?
                <Grid item xs={4} md={4} lg={4}>
                <MDBox style={{ width: "100%", display: "flex" }}>
                  {[1, 2].map(() => (
                    <div style={{ width: "250px", marginRight: "32px" }}>
                      <Instagram backgroundColor="grey" foregroundColor="darkgrey" />
                    </div>
                  ))}
                </MDBox> 
                </Grid>
          : patternList?.length ? patternList
                // .filter((fl) => fl.isActive)
                ?.map((item, i) => (
                  <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon={item.logoUrl}
                      title=""
                      color={themeColor}
                      isPatternList={item}
                      patternName={item.patternName}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: item.description,
                      }}
                      onClick={()=>{
                       navigate(`/patterns/${item._id}/${item?.patternName?.replaceAll(" ", "-").toLowerCase()}`,
                        {state: {planName: item?.planName}  }
                      )
                      }}
                    />
                  </MDBox>
                </Grid>
                )) : <div style={{ fontSize: "14px", padding: "20px 40px", color: "red" }}>No record found</div>
         } */}
          </Grid>
        </MDBox>

        {/* <Footer /> */}
      </DashboardLayout>
    </div>

  );
}

export default Patterns;
