import { FaBullseye } from "react-icons/fa";
import ApiService from "./ApiService";
import ApiUrls from "./ApiUrls";
import { TokenService } from "libraries/TokenService";
import axios from "axios";
import { getFileType, readFileAsBinaryString } from "./Utils";
import { FaLinesLeaning } from "react-icons/fa6";

class Request {
    async signUp(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.signUpUrl, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
        };

     async login(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.loginUrl, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //  Verify Email
     async verifyEmail(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.baseUrl + param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async forgotPassword(data, param, callback) {
        try {
            const response = await ApiService.putRequest(ApiUrls.forgotPasswordlUrl, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async setNewPassword(data,searchParam, param, callback) {
        try {
            const response = await ApiService.putRequest((ApiUrls.setNewPasswordlUrl + searchParam), data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async verifyResetToken(data ,searchParam , param , callback) {
        try {
            const response = await ApiService.putRequest((ApiUrls.validResetTokenURL + searchParam), data, param, false);
            const errMsg  = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async updateNewPassword(data ,searchParam , param , callback) {
        try {
            const response = await ApiService.putRequest((ApiUrls.changePasswordlUrl +`/`+ searchParam), data, param, false);
            const errMsg  = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // resend Email Token
     async resendEmailConfirmation(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.resendEmailUrl, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async refreshToken(token, param, callback) {
        try {
            const response = await axios.post(ApiUrls.refreshTokenUrl, '', {
                headers: {
                    Authorization: `Bearer ${TokenService.getRefreshToken()}`,
                },
                params: param,
                validateStatus: (status) => status >= 200 && status <= 404,
            });

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };


    //  Pattern List
     async patternList(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.patterns + param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Dropdown patterns

    async patternDropdownList(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.patternsDropdown, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Dropdown patterns

    async agentDropdownList(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.agentsDropdown+param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Billing Plans
    async billingPlans(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async contactUs(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.contactUs, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async subscribeBilling(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.cancel+param, data, {}, false);
            // const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async cancelBilling(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.cancel+param, data, {}, false);
            // const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async downgradeBilling(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.downgrade+param, data, {}, false);
            // const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async checkoutBilling(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.checkout, data, {}, false);
            // const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async userPaymentStatus(token, data, param, callback) {
        try {
            const response = await ApiService.getParamTokenRequest(ApiUrls.userPaymentStatus+param, data, token);
            // const response = await ApiService.getRequest(ApiUrls.billing, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async userPermissionStatus(token, data, param, callback) {
        try {
            const response = await ApiService.getParamTokenRequest(ApiUrls.userPermissionStatus, data, token);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    
    async paymentBillingHistory(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.listPaymentHistory+param, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async paymentUsageHistory(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.listUsageHistory+param, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async PaymentMethodsList(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.listpaymentMehods, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //RefreshTokenExpirey

    async RefreshTokenExpirey(callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.refreshToken );
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //  Agent List
     async agentList(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.agents + param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //  Dashboard Stats
    async dashboardStats(param, data, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.dashboardStats + param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Agent Delete
     async deleteXAgent(data, param, callback) {
        try {
            let response = {};
            response = await ApiService.putRequest(`${ApiUrls.deleteXAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //   Text To Speech APIs

     async uploadFile(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.uploadFiles, data, param, true);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async generateTranscription(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.generateTranscription, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async textSummarize(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.textSummary, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async sentimentAnalysis(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.sentimentAnalysis, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Feature Extraction Pattern

     async configFeatureExtractionAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configFeatureExtractionAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configFeatureExtractionAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async deployFeatureExtractionAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.featureExtractionAgent}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async createUpdateModelFeatureExtractionAgent(data, param, isUpdate, callback) {
        try {
            let response = {};

            if (!isUpdate) {
                response = await ApiService.postRequest(`${ApiUrls.createModelFeatureExtractionAgent}/${param}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.createModelFeatureExtractionAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async deleteModelFeatureExtractionAgent(data, param, callback) {
        try {
            let response = {};
            response = await ApiService.putRequest(`${ApiUrls.deleteModelFeatureExtractionAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };


     async statusFeatureExtractionAgent(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.statusFeatureExtractionAgent}/${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async previewUploadFeatureAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.uploadPreviewFeatureExtractionAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async documentDeleteFeatureAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.deleteFeatureExtractionAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async trainFeatureAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async inferFeatureAgent(data, isSaveToBucket, param, callback) {
        try {
            let response = {};
            if(isSaveToBucket){
                 response = await ApiService.postRequest(`${param}`, data, {}, true);
            }else{
                 response = await ApiService.postRequestStream(`${param}`, data, {}, true);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async inferBucketSaveFeatureAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequestStreamWithoutFormData(`${ApiUrls.inferBucketSaveFeatureExtractionAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Recommendation Pattern

    async configRecommendationAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configRecommendationAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configRecommendationAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async deleteModelRecommendationAgent(data, param, callback) {
        try {
            let response = {};
            response = await ApiService.putRequest(`${ApiUrls.deleteModelRecommendationAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async createUpdateModelRecommendationAgent(data, param, isUpdate, callback) {
        try {
            let response = {};

            if (!isUpdate) {
                response = await ApiService.postRequest(`${ApiUrls.createModelRecommendationAgent}/${param}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.createModelRecommendationAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async inferRecommendationAgent(data,isSaveToBucket, param, callback) {
        try {
            let response = {};
            if(isSaveToBucket){
                response = await ApiService.postRequest(`${param}`, data, {}, false);
           }else{
                response = await ApiService.postRequestStreamWithJSON(`${param}`, data, {}, false);
           }
            // response = await ApiService.postRequest(`${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async inferBucketSaveRecommendationAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.inferBucketSaveFeatureExtractionAgent}/${param}`, data, {}, false);

            // const response = await ApiService.postRequestStreamWithoutFormData(`${ApiUrls.inferBucketSaveFeatureExtractionAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Chatbot Pattern

     async configChatbotAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configChatbotAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configChatbotAgent}/${param}`, data, {}, false);
            }
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async saveGuardRailsChatbotAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.saveGuardrailsAI}/${param}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.saveGuardrailsAI}/${param}`, data, {}, false);
            }
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async deployChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.deployChatbotAgent}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async statusChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.statusChatbotAgent}/${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async uploadChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(
                `${ApiUrls.uploadChatbotAgent}/${param}`, 
                {
                    fileName: data?.fileName || "",
                    fileExtension: data?.fileExtension || "",
                    modelName: data?.modelName,
                    fileSize: data?.fileSize || ""
                },
                {}, false);

            const errMsg = response?.data;
            const signedUrl = response?.data?.signedUrl
            const binaryData = await readFileAsBinaryString(data?.file)
            
            if (response?.status === 200 || response?.status === 201) {

            try {
                await ApiService.uploadFileToGCP( `${signedUrl}`, {'Content-Type': `application/octet-stream`}, binaryData);
                
                callback({ data: response.data, isSuccess: true });
            } catch (error) {
                callback({ isSuccess: false, data: undefined, error: "Upload failed. Please try again!" });
            }
                

                
               
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async trainChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async isFeedbackChatbot(param, data, callback) {
        try {
            const response = await ApiService.getRequest(param, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    }

    async retainFeedbackChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.putRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async feedbackChatbotAgent(data, param, update, callback) {
        try {
            let response = {};
            if (!update) {
                 response = await ApiService.postRequest(`${param}`, data, {}, false);
            } else {
                 response = await ApiService.putRequest(`${param}`, data, {}, false);
            }
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    

    async exposeChatbotAgent(data, param, callback) {
        try {
            // const response = await ApiService.postRequestStream(`${param}`, data, {});
            // ${TokenService.getAccessToken()}
            const response = await fetch(param, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    responseType: 'blob',
                },
                body: JSON.stringify(data),
            });
            const errMsg = response;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response, isSuccess: true });
            } else {
                const errorBlob = await response.blob();
                const errorText = await errorBlob.text();
                let errorJson;
                try {
                    errorJson = JSON.parse(errorText);
                } catch (e) {
                    errorJson = { message: 'An unknown error occurred' };
                }
                callback({ isSuccess: false, data: undefined, error: errorJson });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error: error.message });
        }
    };

     async chatChatbotAgent(data, param, callback) {
        try {
            // const response = await ApiService.postRequestStream(`${param}`, data, {});
            // ${TokenService.getAccessToken()}
            const response = await fetch(param, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TokenService.getAccessToken()}`,
                    responseType: 'blob',
                },
                body: JSON.stringify(data),
            });
            const errMsg = response;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response, isSuccess: true });
            } else {
                const errorBlob = await response.blob();
                const errorText = await errorBlob.text();
                let errorJson;
                try {
                    errorJson = JSON.parse(errorText);
                } catch (e) {
                    errorJson = { message: 'An unknown error occurred' };
                }
                callback({ isSuccess: false, data: undefined, error: errorJson });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error: error.message });
        }
    };

    async listChatbotHistory(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {

                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async viewChatbotHistoryConversation(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {

                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async deleteChatbotConversation(data , param , callback) {
        try {
            const response = await ApiService.putRequest(param, data, "", false);
            const errMsg  = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Predictive Pattern

     async configPredictiveAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configPredictiveAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configPredictiveAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async deployPredictiveAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.deployPredictiveAgent}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async statusPredictiveAgent(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.statusPredictiveAgent}/${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async deleteModelPredictiveAgent(data, param, callback) {
        try {
            let response = {};
            response = await ApiService.putRequest(`${ApiUrls.deleteModelPredictiveAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async previewUploadPredictiveAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.uploadPreviewPredictiveAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async documentDeletePredictiveAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.deletePredictiveAgent}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async trainPredictiveAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //    RECOMMENDATION PATTERN
     async configRecommendPattern(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.configRecommendAnalysis, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async deployRecommendPattern(data, param, callback) {
        try {
            const response = await ApiService.postRequest(ApiUrls.inferenceRecommendAnalysis, data, param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

     async createUpdateModelPredictiveAgent(data, param, isUpdate, callback) {
        try {
            let response = {};

            if (!isUpdate) {
                response = await ApiService.postRequest(`${ApiUrls.createModelPredictiveAgent}/${param}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.createModelPredictiveAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async searchRecommendPattern(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async websiteURls(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.getWebsiteUrls}/${param.id}`, data, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
        };
    async saveUrlsForTrain(data,param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.saveUrls}/${param.id}`, data,false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
        };
        
    async sqldatabaseTool(data,param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.chatbotSqlTool}/${param.id}`, data, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
        };

    async trainChatbotAgent(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async getSqlToolDropDownValues(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.sqlToolDropDownValues}`,data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async sqlDeleteDocumnet(param, callback) {
        try {
            const response = await ApiService.putRequest(ApiUrls.sqlDeleteDocument + param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Models

    async listModels(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.listModels}/${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Image To Text Pattern
    async configImageToTextAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configImageToTextAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configImageToTextAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async inferenceImageToTextAgent(data, param, callback) {
        try {
            let response = {};

            response = await ApiService.postRequest(`${param}`, data, {}, true);
            

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Speech To Text pattern
    async configSpeechToTextAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configSpeechToTextAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configSpeechToTextAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    

    //  Text To Speech Pattern
    async configTextToSpeechAgent(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.configTextToSpeechAgent}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.configTextToSpeechAgent}/${param}`, data, {}, false);
            }

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async dropdownTextToSpeechAgent(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.dropdownTextToSpeechAgent}${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async inferTextToSpeechAgent(data, param, callback) {
        const { id, deployedURL } = param;
        try {
          const response = await ApiService.postBlobTextToSpeech(`${deployedURL}${ApiUrls.inferenceTextToSpeechAgent}/${id}`, data,{}, false);
          const errMsg = response?.data;
    
          if (response?.status === 200 || response?.status === 201) {
            // Convert the response to a Blob URL
            const url = URL.createObjectURL(response.data);
            callback({ data: response.data, url, isSuccess: true });
          } else {
            callback({ isSuccess: false, data: undefined, error: errMsg });
          }
        } catch (error) {
          callback({ isSuccess: false, data: undefined, error });
        }
      }

    // Widgets

    async createWidgetConfig(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.createModelWidget}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.createModelWidget}/${param}`, data, {}, false);
            }
            
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async createWidgetDesign(data, param, callback) {
        try {
            let response = {};

            if (!param) {
                response = await ApiService.postRequest(`${ApiUrls.createDesignWidget}`, data, {}, false);
            } else {
                response = await ApiService.putRequest(`${ApiUrls.createDesignWidget}/${param}`, data, {}, false);
            }
            
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async listWidgets(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.listWidgets}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async editWidget(data, param, callback) {
        try {
            const response = await ApiService.getRequestWithoutToken(`${ApiUrls.editWidget}/${param}`, data);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async listReadyAgents(data, param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.listReadyAgents}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async deleteWidget(data, param, callback) {
        try {
            let response = {};
            response = await ApiService.putRequest(`${ApiUrls.deleteWidget}/${param}`, data, {}, false);

            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async readWidgetIcons(data, param, callback) {
        try {
            const response = await ApiService.postRequestImage(`${ApiUrls.readIcons}/${param}`, data, {});
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true, blob: blob });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg, blob: "" });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error, blob: "" });
        }
    };

    async uploadWidgetIcons(data, param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.uploadIcons}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async deleteWidgetIcons(data, param, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.deleteIcons}/${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async saveWidgetIcons(data, param, callback) {
        try {
            const response = await ApiService.putRequest(`${param}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //llm functions APIs
    async AttributeDefaultValues(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.llmFunctionAttributeValue}`,data, {}, false );
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async createLlmFunction(data, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.createLlmFunction}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async updateLlmFunction(data,param, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.createLlmFunction}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async llmFunctionDetail(param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.getLLMFunctionDetails}/${param.id}`, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async getTestAttributeData(functionId, callback) {
        try {
            const url = ApiUrls.getTestAttribute.replace('functionId', functionId);
            const response = await ApiService.getRequest(url);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    }
    async llmFunctionInference(data,param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.llmFunctionInferenceScreen}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async llmFunctionInferencePublish(data,param, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.llmFunctionInferenceScreenPublish}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async llmFunctionPublish(param,data, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.llmfunctionPublish}/${param.id}`,data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async llmFunctionUnPublish(param,data, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.llmfunctionUnPublish}/${param.id}`,data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async deleteLLMFunction(data,param, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.deleteLLMFunction}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async allApiKeys(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.userApisKeys}`,data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    
    async listingOfLlmFunction(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.listingLlmFunction}`,data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async getAccessDetail(param, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.getAccessDetail}/${param.id}`, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    
     // api-key management module APIS
     async createApi(data, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.createApi}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async updateApiKey(data,param, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.createApi}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async deleteApikeyModule(data,param, callback) {
        try {
            const response = await ApiService.putRequest(`${ApiUrls.deleteApiKey}/${param.id}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    // Work Force APIs

    async workForceStrategyvalues(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.workForceStrategyDefaultValue}`,data, {}, false );
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    
    async workForceStrategy(data, callback) {
        try {
            const response = await ApiService.postRequest(`${ApiUrls.workForceStrategy}`, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    async getWorkForceStartegyData(param, callback) {
        try {
            const response = await ApiService.getRequest(ApiUrls.workForceStrategy + param, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async updateWorkForceStrategy(data, callback) {
        try {
            const response = await ApiService.putRequest(ApiUrls.workForceStrategy, data, {}, false);
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };
    async estimatedCostValues(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.estimatedSavings}`,data, {}, false );
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    //dashboard storage usage
    async storageUsage(data, callback) {
        try {
            const response = await ApiService.getRequest(`${ApiUrls.storageUsage}`,data, {}, false );
            const errMsg = response?.data;

            if (response?.status === 200 || response?.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

}

const ApiRequest = new Request();

export default ApiRequest;
