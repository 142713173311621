import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';
import { IoIosClose } from 'react-icons/io';

import { FaEdit } from "react-icons/fa";
import { MdDelete, MdOutlineSettingsSuggest } from "react-icons/md";
import { BsFileEarmarkCodeFill } from "react-icons/bs";

import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SpinnerLoader } from "examples/Loader/Loader";
import { setChatbotHistoryUrl } from "context";
import JSONPretty from 'react-json-pretty';
import { Tag } from "examples/Tag/Tag";
import ApiUrls from "services/ApiUrls";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


export default function StickyHeadTable({ columns, widthhandle }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [agentList, setAgentList] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [modalInfoOpen, setModalInfoOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setChatbotHistoryUrl(dispatch, '')
    allWidgetsList();
  }, []);

  const allWidgetsList = () => {
    setIsLoader(true);

    ApiRequest.listWidgets('', '', (res) => {
      var agentArr = [];
      agentArr = res.data?.widgetAgents?.map(pl => pl?.widgetDetails?.map(ps => {
        return {
          id: ps?._id, widgetName: ps?.widgetName, agentName: pl?.agentName,
          agentId: pl?.agentId, patternId: pl?.patternId
        }
      }))?.flatMap(item => item)

      if (res.isSuccess) {
        setAgentList(agentArr?.length ? agentArr : []);
      } else {
        setAgentList([]);
      }
      setIsLoader(false);
    });
  };

  const deleteModal = () => {
    setDelLoader(true);

    ApiRequest.deleteWidget("", modalDelOpen?.id, (res) => {
      setModalDelOpen({ flag: false, agentName: '', patternId: '', id: '' }); setDelLoader(false);
      allWidgetsList();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const editAgent = (e, patternId, agentId, widgetId) => {
    e.stopPropagation();

    ApiRequest.editWidget('', widgetId, (res) => {
      if (res.isSuccess) {
        navigate('/widgets/widget-creation/config', {
          state: {
            objectId: res?.data?.widget?._id,
            page: 1, step: 0,
            isEditAgent: true,
            widgetConfigForm: res?.data?.widget,
            widgetDesignForm: res?.data?.widget?.styling,
          }
        });
      } else {
        toast(res.error?.message ? res.error?.message : "Something went wrong")
      }
    });
  }

  const modalInfoPop = () => {
    return (`
  
<iframe 
id="chat-iframe"
src=${`"${ApiUrls.frontendBaseUrl}/chatbot-widget/${modalInfoOpen?.id}"`}
title="Chatbot"
width="100%"
allow="clipboard-read; clipboard-write"
style="height: 100%; min-height: 520px"
frameborder="0"
></iframe>
      `)
  };

  const modalInfoPop2 = () => {
    return (`
    
<script src=${`"${ApiUrls?.bucketBubbleUrl}"`} 
id="chat-widget" 
data-widget-id=${`"${modalInfoOpen?.id}"`}
>
</script>    
    `)
  }

  console.log("agentList?.length", agentList?.length)
  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1D3E80";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };
  const getTooltipBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  };

  return (
    <>
     <div style={{ height: "45px", width: '100%', display: 'flex', padding: '12px 16px', backgroundColor: getBackgroundColor(), borderRadius: '8px', alignItems: 'center' }}>
        <div
          style={{border: "none", width: windowSize.innerWidth > 600 ? "40%" : "50%", fontWeight: "600", fontSize: "16px", color: "white" }}
        >
          Chat Widget
        </div>
        <div
          style={{border: "none", width: windowSize.innerWidth > 600 ? "40%" : "50%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "34px" : "29px" }}
        >
          X-Agent
        </div>
        {windowSize.innerWidth > 600 && <>
          <div
            style={{border: "none", width: "20%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "53px" : "47px" }}
          >
            Action
          </div>
        </>}
      </div>
    
    <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
     
      {/* Header */}
      <TableContainer >
        {/* sx={{ maxHeight: 440 }} */}
        <Table >

          <TableBody>

            {/* Body List  */}
            {isLoader ?
              <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                {[1, 2, 3].map(() => (
                  <div className="w-56 mr-8">
                    <Code backgroundColor="grey" foregroundColor="darkgrey" />
                  </div>
                ))}
              </div>
              :
              agentList?.length ?
                <>
                  {agentList
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, i) => {
                      return (
                        <>
                          <TableRow tabIndex={-1} style={{ backgroundColor: !darkMode ? i % 2 === 0 ? "white" : "#F1F1F1" : "" }}>
                            <TableCell key={i} align={"left"}
                              style={{paddingLeft:isLargeMobile ? "4px" : "", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "40%" }}>
                              {
                                windowSize.innerWidth <= 600 &&
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => { setOpen(!open); setCollapseRow(i + 1) }}
                                >
                                  {(open && (collapseRow === (i + 1))) ?
                                    <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                    : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                </IconButton>
                              }
                              {row?.widgetName}
                            </TableCell>
                            <TableCell key={i} align={"left"}
                              style={{ paddingLeft:isLargeMobile ? "15px": "2px", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "40%" }}>
                              {row?.agentName}
                            </TableCell>

                            {windowSize.innerWidth > 600 &&
                              <>

                                <TableCell key={i} align={"left"} style={{ width: "20%", paddingLeft: "4px", }}>
                                  <div className="flex flex-row" key={i} >
                                    <Tooltip title="Copy iframe Code" arrow
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            color: "white",
                                            backgroundColor: getTooltipBackgroundColor(),
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            '& .MuiTooltip-arrow': {
                                              color: getTooltipBackgroundColor(),
                                            },

                                          },
                                        },
                                      }}
                                    >
                                      <div>
                                        <BsFileEarmarkCodeFill
                                          className="mr-4 cursor-pointer"
                                          onClick={(e) => { e.stopPropagation(); setModalInfoOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?.id }) }}
                                          style={{ color: darkMode ? "white" : "black" }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip title="Reconfigure ChatWidget" arrow
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            color: "white",
                                            backgroundColor: getTooltipBackgroundColor(),
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            '& .MuiTooltip-arrow': {
                                              color: getTooltipBackgroundColor(),
                                            },

                                          },
                                        },
                                      }}
                                    >
                                      <div>
                                        <FaEdit
                                          style={{ color: darkMode ? "white" : "#000000" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?.agentId, row?.id)}
                                          className=" cursor-pointer"
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip title="Delete ChatWidget" arrow
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            color: "white",
                                            backgroundColor: getTooltipBackgroundColor(),
                                            fontSize: "10px",
                                            fontWeight: "400",
                                            '& .MuiTooltip-arrow': {
                                              color: getTooltipBackgroundColor(),
                                            },

                                          },
                                        },
                                      }}
                                    >
                                      <div>
                                        <MdDelete
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?.id }) }}
                                          className={`ml-4  cursor-pointer`}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </>
                            }

                          </TableRow>

                          {/* Extended Row */}
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Table>
                                    <MDBox component="thead">
                                      <TableRow >
                                        <TableCell
                                          // key={column.id}
                                          align={"left"}
                                          style={{ width: "100%", textAlign: "left", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                        >
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </MDBox>
                                    <TableBody >
                                      <>
                                        <TableRow tabIndex={-1}>

                                          <TableCell key={i} align={"left"}>
                                            <div className="flex flex-row w-full justify-start" key={i} >
                                              <Tooltip title="Copy iframe Code" arrow
                                                slotProps={{
                                                  tooltip: {
                                                    sx: {
                                                      color: "white",
                                                      backgroundColor: getTooltipBackgroundColor(),
                                                      fontSize: "10px",
                                                      fontWeight: "400",
                                                      '& .MuiTooltip-arrow': {
                                                        color: getTooltipBackgroundColor(),
                                                      },

                                                    },
                                                  },
                                                }}
                                              >
                                                <div>
                                                  <BsFileEarmarkCodeFill
                                                    className="mr-4 cursor-pointer"
                                                    onClick={(e) => { e.stopPropagation(); setModalInfoOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?.id }) }}
                                                    style={{ color: darkMode ? "white" : "black" }}
                                                  />
                                                </div>
                                              </Tooltip>
                                              <Tooltip title="Reconfigure ChatWidget" arrow
                                                slotProps={{
                                                  tooltip: {
                                                    sx: {
                                                      color: "white",
                                                      backgroundColor: getTooltipBackgroundColor(),
                                                      fontSize: "10px",
                                                      fontWeight: "400",
                                                      '& .MuiTooltip-arrow': {
                                                        color: getTooltipBackgroundColor(),
                                                      },

                                                    },
                                                  },
                                                }}
                                              >
                                                <div>
                                                  <FaEdit
                                                    style={{ color: darkMode ? "white" : "black" }}
                                                    onClick={(e) => editAgent(e, row?.patternId, row?.agentId, row?.id)}
                                                    className="cursor-pointer"
                                                  />
                                                </div>
                                              </Tooltip>
                                              <Tooltip title="Delete ChatWidget" arrow
                                                slotProps={{
                                                  tooltip: {
                                                    sx: {
                                                      color: "white",
                                                      backgroundColor: getTooltipBackgroundColor(),
                                                      fontSize: "10px",
                                                      fontWeight: "400",
                                                      '& .MuiTooltip-arrow': {
                                                        color: getTooltipBackgroundColor(),
                                                      },

                                                    },
                                                  },
                                                }}
                                              >
                                                <div>
                                                  <MdDelete
                                                    style={{ color: darkMode ? "white" : "black" }}
                                                    onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?.id }) }}
                                                    className={`ml-4  cursor-pointer`}
                                                  />
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </>

                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </>
                : <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No chat widget found</div>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        style={{ color: darkMode ? "white" : "black" }}
        count={agentList?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {modalDelOpen?.flag &&
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, agentName: '', patternId: '', id: '' })}>
          <div className="flex flex-row w-full justify-center items-center text-xl mb-4" >
            Are you sure you want to delete? Deleted Widget will be lost
          </div >

          <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <MDButton style={{ width: "140px" }} disabled={false}
              onClick={() => setModalDelOpen({ flag: false, agentName: '', patternId: '', id: '' })}
              variant="gradient" color={"error"} fullWidth>
              No
            </MDButton>

            <MDButton style={{ width: "140px" }} disabled={delLoader}
              onClick={() => deleteModal()}
              variant="gradient" color={themeColor} fullWidth>
              {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </MDBox>
        </Modal>
      }

      {modalInfoOpen?.flag &&
        <Modal open={modalInfoOpen?.flag} setOpen={(fl) => setModalInfoOpen({ flag: fl, agentName: '', patternId: '', id: '' })}>
          <div className="flex flex-row justify-end">
            <IoIosClose
              onClick={(e) => setModalInfoOpen({ flag: false, agentName: '', patternId: '', id: '' })}
              style={{ color: darkMode ? "white" : "black", fontSize: "30px", marginBottom: "10px" }}
              className={`font-semibold cursor-pointer`}
            />
          </div>
          <div className=" mb-2" >

            <div style={{ fontSize: "18px" }}>To add the chatbot any where on your website, please add this iframe to your html code</div>
            <div className="mt-2 mb-4">
              <JSONPretty style={{ fontSize: "15px" }} id="json-pretty"
                theme={JSONPrettyMon}
                data={modalInfoPop()}></JSONPretty>
            </div>

            <div className="w-full flex justify-center">
              <CopyToClipboard text={modalInfoPop()} onCopy={() => toast.success("IFrame code has been copied!")}>
                <Tag
                  name={"Copy To Clipboard"}
                  onClick={(e) => { e?.stopPropagation() }}
                  onDelete={(e) => console.log("delete")}
                  className="mt-4"
                  isLogo={true}
                />
              </CopyToClipboard>
            </div>

            {/* Script Tag */}
            <div style={{ fontSize: "18px", marginTop: "16px" }}>To add a chat bubble to the bottom right of your website add this script tag to your html</div>
            <div className="mt-2 mb-2">
              <JSONPretty style={{ fontSize: "15px" }} id="json-pretty"
                theme={JSONPrettyMon}
                data={modalInfoPop2()}></JSONPretty>
            </div>

            <div className="w-full flex justify-center">
              <CopyToClipboard text={modalInfoPop2()} onCopy={() => toast.success("Chat bubble code has been copied!")}>
                <Tag
                  name={"Copy To Clipboard"}
                  onClick={(e) => { e?.stopPropagation() }}
                  onDelete={(e) => console.log("delete")}
                  className="mt-4"
                  isLogo={true}
                />
              </CopyToClipboard>
            </div>
          </div>

        </Modal>
      }
    </Paper>
    </>
  );
}
