import React, { useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "libraries/Images";
import { useDropzone } from 'react-dropzone';
import { SpinnerLoader } from "examples/Loader/Loader";
import UploadFileIcon from 'assets/images/flow-icons/upload-icon.svg'
import { useMaterialUIController } from "context";
import { useMediaQuery } from "@mui/material";


export const TabUpload = ({supportedFileTypes, uploadedFile, loader, isSingleFile,isSql,disabled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef();
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const onDrop = useCallback((acceptedFiles) => {
    const file = new FileReader;
    file.onload = function () {
      uploadedFile(acceptedFiles);
    }
    file.readAsDataURL(acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: !isSingleFile });

  return (
    <div className="mt-4">
      <form autoComplete="off">
        <div
          style={{ height:isSql? "80px" : "200px", color: darkMode ? "white" : "black", fontSize: "16px"  }}
          className="text-sm justify-center items-center flex flex-col w-full my-2"
        >
          {loader ? (
            <div className="w-full flex justify-center bg-transparent">
              <SpinnerLoader enhance={'text-2xl'} adjustment={true} />
            </div>
            // <p><img src={newFile as string} alt="Upload preview" /></p>
          )
            :
            <div className="justify-center items-center flex flex-col w-full">
              <div {...getRootProps()}>
                {/* application/vnd.ms-excel */}
                <input type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  {...getInputProps()} />
                {
                  isDragActive ?
                    <p className="text-2xl" style={{ height: "200px" }}>Drop the file here ...</p> :
                    <div>
                      <p className={` ${isSql ? "flex justify-center gap-[20px]" : "flex flex-col justify-center"} `}>
                        <label
                          className="flex flex-row justify-center cursor-pointer"
                          htmlFor="contained-button-file"
                        >
                          {
                            
                            isSql ?(
                              <img width={ "70px"} src={darkMode ?UploadFileIcon : UploadFileIcon} style={{marginLeft:isSmallMobile && "16px"}} />
                            ) :(
                              <img width={ "70px"} src={darkMode ? Images.uploadIconWhite : Images.uploadIconDark} />
                            )
                           
                          }
                          
                        </label>
                        {
                          isSql ? (
                            <div className="flex flex-col">
                            <div className="text-xl mt-4 flex font-semibold" style={{ width: "100%", display: "flex", justifyContent: "center" }}>Drag or Upload file</div>
                            <div className="mt-4 flex justify-center text-sm">

                              {supportedFileTypes} files supported only
                            </div>
                            </div>
                          ) :(
                            <>
                            <div className="text-xl mt-4 flex font-semibold" style={{ width: "100%", display: "flex", justifyContent: "center" }}>Drag or Upload file</div>
                            <div className="mt-4 flex justify-center text-sm">
                              {/* {isSingleFile ? 'CSV' : 'txt, docs, pdf'} files supported only */}
                              {supportedFileTypes} files supported only
                            </div>
                            </>
                          )
                        }
                       
                        {/* txt, docs, pdf */}
                        {/* <FaFileCsv className="ml-2 h-6 w-6 text-primary-blue cursor-pointer" />
                            <RiFileExcel2Line className="ml-2 h-6 w-6 text-primary-blue cursor-pointer" /> */}
                      </p>

                    </div>
                }
              </div>
              {/* <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => handleChange(e)}
                style={{ display: "none" }}
                id="contained-button-file"
              />
              <label
                className="flex flex-row cursor-pointer"
                htmlFor="contained-button-file"
              >
                <img width={"70px"} src={Images.uploadIcon} />
              </label>

              <div className="text-2xl mt-4">Drag or Upload documents</div>
              <div className="mt-4">txt, docs, xlsx, pdf files supported only</div> */}
            </div>

          }

        </div>
      </form >
    </div >
  );
};
