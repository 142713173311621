/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import RouteWrapper from "routeWrapper";
import Toaster from 'examples/Toaster/Toaster';

import { persistStore } from 'redux-persist';
import { configureStore } from './redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
const store = configureStore();
const persistor = persistStore(store);

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <>
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <RouteWrapper>
          <App />
          </RouteWrapper>
        </MaterialUIControllerProvider>
      </BrowserRouter>
      <Toaster />
    </PersistGate>
  </Provider>
  </>
);
