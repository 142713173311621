import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ApiRequest from "services/ApiRequest";

import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import MDInput from "components/MDInput";
import useScreenWidth from "libraries/ScreenSizeHook";

import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import { Paths } from "libraries/Route";

import {useMaterialUIController} from "context";
import Constants from "libraries/Constants";

function PredictiveAnalysisResult(props){
  const navigate = useNavigate();
  const location = useLocation();
  const {id, name} = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [inputValues, setInputValues] = useState(
    {
      modelName: location?.state?.beforeTrained?.modelName,
      modelType: location?.state?.result?.modelType,
      precision: location?.state?.result?.metrics?.precision,
      recall: location?.state?.result?.metrics?.recall,
      testAccuracy: location?.state?.result?.metrics?.testAccuracy,
      trainAccuracy: location?.state?.result?.metrics?.trainAccuracy,
      validationAccuracy: location?.state?.result?.metrics?.validationAccuracy,
      r2: location?.state?.result?.metrics?.r2,
      rmse: location?.state?.result?.metrics?.rmse,
      mse: location?.state?.result?.metrics?.mse,
    }
  );

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const navigation = (route, page, step, deployedURL, uploadedFile, result) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: deployedURL,
        status: location?.state?.status,
        isDeploying: location?.staate?.isDeploying,
        uploadedFile: uploadedFile,
        isTrained: false,
        result: result,
        beforeTrained: location?.state?.beforeTrained
      }
    });
  };

  console.log("Location Result", location)

  const fields = [
    {
      placeholder: "Model Name",
      name: "modelName",
      val: inputValues.modelName,
      type: "text",
      active: inputValues.modelName
    },
    {
      placeholder: "Model Type",
      name: "modelType",
      val: inputValues.modelType,
      type: "text",
      active: inputValues.modelType
    },
    {
      placeholder: "Test Accuracy",
      name: "testAccuracy",
      val: `${(inputValues.testAccuracy * 100)}%`,
      type: "text",
      active: inputValues.testAccuracy && inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Train Accuracy",
      name: "trainAccuracy",
      val: `${(inputValues.trainAccuracy * 100)}%`,
      type: "text",
      active: inputValues.trainAccuracy && inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Validation Accuracy",
      name: "validationAccuracy",
      val: `${(inputValues.validationAccuracy * 100)}%`,
      type: "text",
      active: inputValues.validationAccuracy && inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Precision",
      name: "precision",
      val: `${(inputValues.precision * 100).toFixed(0)}%`,
      type: "text",
      active: inputValues.precision && inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Recall",
      name: "recall",
      val: `${(inputValues.recall * 100)}%`,
      type: "text",
      active: inputValues.recall && inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "R2",
      name: "r2",
      val: `${(inputValues.r2 * 100)}%`,
      type: "text",
      active: inputValues.r2 && inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    },
    {
      placeholder: "RMSE",
      name: "rmse",
      val: `${(inputValues.rmse * 100)}%`,
      type: "text",
      active: inputValues.rmse && inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    },
    {
      placeholder: "MSE",
      name: "mse",
      val: `${(inputValues.mse * 100)}%`,
      type: "text",
      active: inputValues.mse && inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    }
  ];

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  const previousSetup = () => {
    navigation('/train', 3, 2, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  return (
    <div>
        <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "30px"}}>
        	{fields.filter(ch => ch.active).map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "45%", outline: "none" }}>
					  <MDInput   
                // style={{outline: "none", border: "none"}}
                id={fi.name} 
                type={fi.type}
                value={fi.val}
                disabled={true} 
                onChange={(e) => console.log(e)}
                label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                variant="standard" fullWidth />
					  </MDBox>
            ))}
          </div>


          
          <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {previousSetup()}} variant="gradient" color={themeColor} fullWidth>
              Previous
            </MDButton>
            {/* <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {modelSetup()}} variant="gradient" color={themeColor} fullWidth>
              Model Setup
            </MDButton> */}
            <MDButton 
              style={{width: "max-content", minWidth: "140px"}} disabled={false} 
              onClick={()=> {navigate(`/patterns/${id}/${name}/agent-creation/infer/predictive-analysis`,{ state: {...location?.state}})}} 
              variant="gradient" color={themeColor} fullWidth>
              Inference
            </MDButton>

          </MDBox>
    </div>
  );
};

export default PredictiveAnalysisResult;

