import React, { useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "libraries/Images";
import { useDropzone } from 'react-dropzone';
import { SpinnerLoader } from "examples/Loader/Loader";
import { FaUpload } from "react-icons/fa";
import { useMaterialUIController } from "context";

export const TabIconsUpload = ({title, supportedFileTypes, uploadedFile, loader, isSingleFile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef();

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const onDrop = useCallback((acceptedFiles) => {
    const file = new FileReader;
    file.onload = function () {
      uploadedFile(acceptedFiles);
    }
    file.readAsDataURL(acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: !isSingleFile });

  return (
    <div className="mt-4">
      <form autoComplete="off">
        <div
          style={{color: darkMode ? "white" : "black", fontSize: "16px" }}
          className="text-sm items-center flex flex-col w-full my-2"
        >
          {loader ? (
            <div className="w-full flex justify-center bg-transparent">
              <SpinnerLoader enhance={'text-2xl'} adjustment={true} />
            </div>
            // <p><img src={newFile as string} alt="Upload preview" /></p>
          )
            :
            <div className="w-full">
              <div {...getRootProps()}>
                {/* application/vnd.ms-excel */}
                <input type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      {...getInputProps()} />
                {
                  isDragActive ?
                    <p className="text-2xl" style={{ height: "200px" }}>Drop the file here ...</p> :
                    <div className="flex flex-row items-center cursor-pointer" htmlFor="contained-button-file">
                        <FaUpload
                          style={{ fontSize: "25px", color: darkMode ? "white" : "black" }}
                          className="cursor-pointer"
                        /> 

                        <div className="ml-2">
                          <div className="" style={{ width: "100%"}}>{title}</div>
                          <div className="mt-2 text-gray-500 flex text-sm">
                            {supportedFileTypes} files supported
                          </div>
                        </div>
                    </div>
                }
              </div>
              {/* <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => handleChange(e)}
                style={{ display: "none" }}
                id="contained-button-file"
              />
              <label
                className="flex flex-row cursor-pointer"
                htmlFor="contained-button-file"
              >
                <img width={"70px"} src={Images.uploadIcon} />
              </label>

              <div className="text-2xl mt-4">Drag or Upload documents</div>
              <div className="mt-4">txt, docs, xlsx, pdf files supported only</div> */}
            </div>

          }

        </div>
      </form >
    </div >
  );
};
