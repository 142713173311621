/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";


// Dashboard components
import { useEffect, useState } from "react";
import { setChatbotHistoryUrl } from "context";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import { Tooltip } from "examples/Tooltip/Tooltip";


import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import Modal from "examples/Modal/Modal";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { Tag } from "examples/Tag/Tag";
import { validateAndProcessFile, getFileType } from "services/Utils";
import ApiRequest from "services/ApiRequest";
import { Box, FormControl } from "@mui/material";
import Icon from "@mui/material/Icon";
import { getColorVlue } from "services/Utils";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useSelector } from "react-redux";


//images
import deleteIcon from "assets/images/flow-icons/delete icon.svg"
import LinkIcon from '@mui/icons-material/Link';

function ChatbotTrain() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();

  const [agentList, setAgentList] = useState();
  const [loader, setLoader] = useState(false);
  const [scrapedURLs, setScrapedURLs] = useState([]);
  const [delLoader, setDelLoader] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [trainingLoader, setTrainingLoader] = useState(false);
  const [trainingPopupLoader, setTrainingPopupLoader] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [siteMapUrl, setSiteMapUrl] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderwebsiteUrl, setIsLoaderWebsiteUrl] = useState(false);
  const [isLoaderSitemap, setIsLoaderSitemap] = useState(false);
  const [urlsFetched, setUrlsFetched] = useState(false);
  const [errorURLs, setErrorURLs] = useState([]);
  const [isFeedbackPopupHandle, setIsFeedbackPopupHandle] = useState(false);
  const stateUser = useSelector((state) => state?.loginReducer?.isSqlTool);

  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, document: {} });

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 426px)');
  const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
  const isMediumLarge = useMediaQuery('(min-width: 770px) and (max-width: 1026px)');

  const navigation = (route, page, step, isDeploying, uploadedFile, isTrained) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        protect: location?.state?.protect,
        isDeploying: isDeploying,
        uploadedFile: uploadedFile,
        isEditAgent: location?.state?.isEditAgent,
        isTrained: isTrained,
      }
    });
  };

  useEffect(() => {
    feedbackPopupHandle();
    setChatbotHistoryUrl(dispatch, '')
    allAgentsList();

  }, [])
  const addUrlInput = () => {
    setScrapedURLs(["", ...scrapedURLs]);
  };

  const feedbackPopupHandle = () => {
    setIsLoader(true);
    ApiRequest.isFeedbackChatbot(`${location.state?.deployedURL}/chatbot-agent/isFeedbackEmbeddingsExist/${location.state?.objectId}`, '', (res) => {
      if (res?.isSuccess) {
        setIsFeedbackPopupHandle(res?.data?.isFeedbackExist)
      }
    });
  };

  const allAgentsList = () => {
    setIsLoader(true);
    ApiRequest.agentList(`/${location.state.objectId}`, '', (res) => {
      if (res.isSuccess) {
        setAgentList(res.data?.agentsList);
        setScrapedURLs(res.data?.agentsInformation[0]?.selectedWebsiteURLS)
        if(res.data?.agentsInformation[0]?.baseWebsiteURL.includes(".xml")){
          setSiteMapUrl(res.data?.agentsInformation[0]?.baseWebsiteURL)
        }else{
        setWebsiteUrl(res.data?.agentsInformation[0]?.baseWebsiteURL)
        }
      } else {
        setAgentList([]);
      }
      setIsLoader(false);
    });
  };

  var resIndex = 0;

  const uploadedFile = (acceptedFiles) => {

    setLoader(true);
    // baseWebsiteURL
    // selectedWebsiteURLS
    const supportedFormats = {
      'application/pdf': 'PDF',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'text/plain': 'TXT',
      'application/msword': 'DOCX',
    };

    acceptedFiles?.length && acceptedFiles?.map((afile, i) => {
      // Unexpected File Type. We only support [PDF, DOCX, TXT, CSV, XLSX, PARQUET].
      const validation = validateAndProcessFile(afile, supportedFormats)
      if (validation === true) {
        ApiRequest.uploadChatbotAgent({
          file: afile, modelName: '',
          fileName: afile?.name, fileSize:afile?.size, fileExtension: getFileType(supportedFormats, afile?.type)
        },
          location?.state?.objectId, (res) => {
            if (res.isSuccess) {
              toast.success(`"${res.data.fileName}" uploaded successfully.`);
              resIndex = resIndex + 1;
              if (resIndex === acceptedFiles?.length) {
                resIndex = 0;
                navigation('/train', 4, 3, false,
                  (location?.state?.documentNames?.length > res?.data?.documentNames?.length)
                    ? location?.state?.documentNames : res?.data?.documentNames, location?.state?.isTrained);
              }
            } else {
              toast.error(res.error ? res?.error?.message : 'Something went wrong')
            }
            //  Loop Through All Files
            if (acceptedFiles?.length === i + 1) {
              setLoader(false);
            }
            setLoader(false);
          });
      }
      else {
        toast.error(`${validation}`)
        setLoader(false);
      }

    });
  };

  const deleteDocument = () => {
    setDelLoader(true);
    const data = {
      modelName: "",
      documentId: modalDelOpen?.document?._id ?? modalDelOpen?.document?.documentId ?? ''
    }

    ApiRequest.documentDeleteFeatureAgent(data, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, document: {} }); setDelLoader(false);
      // featureExtractionModels();
      if (res.isSuccess) {
        toast.success(res?.data?.message);
        navigation('/train', 4, 3, false, res?.data?.documentNames, location?.state?.isTrained);
      }
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  }

  const TrainXAgent = async (isFeedback) => {
    // if (scrapedURLs?.length > 40) {
    //   toast.error("You may only train the LLM on maximum 40 Page URLs at a time. ")
    //   setTrainingLoader(false);
    //   return;
    // }
    const invalidUrls = scrapedURLs?.filter(url => !isUrlValid(url));
  
    if (invalidUrls?.length > 0) {
      toast.error("Please enter correct URL");
      return;
    }
    if (isFeedback) setFeedbackLoader(true);
    else setTrainingLoader(true);
    setTrainingPopupLoader(true);
    
    const saveUrlsResult = await saveAllUrls();
    if (!saveUrlsResult) {
      setTrainingLoader(false);
      setFeedbackLoader(false);
      setTrainingPopupLoader(false);
      return;
    }
  
    ApiRequest.retainFeedbackChatbotAgent({ deleteFeedback: !isFeedback }, `${location.state?.deployedURL}/chatbot-agent/deleteembeddings/${location.state?.objectId}`, (resFeedback) => {
      setTrainingLoader(false); 
      setFeedbackLoader(false); 
      setModalOpen(false);
      if (resFeedback.isSuccess) { 
        if (isFeedback) toast.success(resFeedback?.data?.message);
      } else { 
        if (isFeedback) toast.error(resFeedback?.error?.message);
      }
  
      ApiRequest.trainChatbotAgent('', `${location.state?.deployedURL}/chatbot-agent/train/${location.state?.objectId}`, (res) => {
        setTrainingPopupLoader(false);
        if (res.isSuccess) {
          toast.success(res.data.message ? res.data.message : "xAgent trained successfully");
          navigation('/train', 4, 3, false, location?.state?.uploadedFile, true);
        } else {
          toast.error(res.error.message ? res.error.message : "Training failed");
        }
      });
    });
  };

  const reconfigBtn = () => {
    navigation('/config', 1, 0, false, location?.state?.uploadedFile, location?.state?.isTrained);
  };
  const isUrlValid = (url) => {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (errorURLs.includes(url)) return false
    return urlRegex.test(url);
  };

  const deleteUrlInput = (index) => {
    const updatedURLs = [...scrapedURLs];
    updatedURLs.splice(index, 1);
    setScrapedURLs(updatedURLs);
  };

  const fetchWebsiteUrls = async (urlType) => {

    let url = '';
    if (urlType === 'sitemap') {
      url = siteMapUrl;
      setIsLoaderSitemap(true);
    } else {
      url = websiteUrl;
      setIsLoaderWebsiteUrl(true);
    }
    if (!url?.trim() || url === "") {
      toast.error("Please enter a URL");
      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false);
      return;
    } else if (!isUrlValid(url)) {
      toast.error("Please enter a valid URL");
      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false);
      return;
    }
    ApiRequest.websiteURls({ [urlType === 'sitemap' ? 'sitemapURL' : 'websiteURL']: url }, { id: location.state.objectId }, (res) => {

      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false)
      if (res.isSuccess) {
        toast.success(res.data?.message ? res.data.message : "Urls fetched successfully");
        setScrapedURLs(res.data?.scrapedURLs);
        setUrlsFetched(true);

      } else {
        toast.error(res.error?.message ? res.error.message : "Urls fetch failed")
      }
    });
  };
  const saveAllUrls = async () => {
    const invalidUrls = scrapedURLs?.filter(url => !isUrlValid(url));
  
    if (invalidUrls?.length > 0) {
      toast.error("Please enter correct URL");
      return false;
    }
  
    return new Promise((resolve) => {
      ApiRequest.saveUrlsForTrain({ baseWebsiteURL: websiteUrl ? websiteUrl : siteMapUrl || "", selectedWebsiteURLS: (scrapedURLs && scrapedURLs.length ? scrapedURLs : []) }, { id: location.state.objectId }, (res) => {
        if (res.isSuccess) {
          if (scrapedURLs?.length > 0) {
            toast.success(res.data.message ? res.data.message : "Urls fetched successfully");
          }
          setUrlsFetched(true);
          resolve(true);
        } else {
          toast.error(res.error.message ? res.error.message : "Urls fetch failed");
          resolve(false);
        }
      });
    });
  };
  

  const ChatXAgent = () => {
    navigate(`/patterns/${id}/${name}/agent-creation/infer/llm`, { state: { id: id, deployedURL: location.state?.deployedURL, objectId: location?.state?.objectId, configForm: location?.state?.configForm, ...location?.state } })
  };
  const previousBtn = () => {
    navigation('/protect', 3, 2, false, location?.state?.uploadedFile, location?.state?.isTrained);
  };
  const deleteAllLinks = () => {
    ApiRequest.saveUrlsForTrain({ baseWebsiteURL: "", selectedWebsiteURLS: [] }, { id: location.state.objectId }, (res) => {

      if (res.isSuccess) {
        if(scrapedURLs?.length > 0){
        toast.success( "Website links deleted successfully");
        setScrapedURLs([]);
        }

      } else {
        toast.error( "Website links deleted failed")
      }
    });
  
  };
  const handleUrlChange = (newValue, index) => {
    const updatedURLs = [...scrapedURLs];
    updatedURLs[index] = newValue;
    setScrapedURLs(updatedURLs);
  };

  const scrapedURLInputs = scrapedURLs?.map((url, index) => {
    console.log(url, "==")
    const inputStyled = `rounded-md h-[25px] w-full md:w-[75%] lg:w-[75%] mt-[20px]  ${isUrlValid(url) ? "text-[#0F0F0F]" : "text-red-500"}
        ${darkMode ? `bg-transparent placeholder:placeholder-gray-100 ${!isUrlValid(url) ? 'text-red-500' : 'text-white focus:border-[#ffff]'}`
        : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}`;
    return (
      <div className="flex gap-[20px] pt-20px">
        <input
          className={inputStyled}
          value={url}
          onChange={(e) => handleUrlChange(e.target.value, index)}
          style={{ borderColor: darkMode && !isUrlValid(url) ? "red" : darkMode ? "white " : (isUrlValid(url) ? "#0F0F0F" : "red") }}
        />
        <Icon sx={{ cursor: "pointer", marginTop: "20px", color: darkMode ? "white !important" : "" }} fontSize="small" onClick={() => deleteUrlInput(index)}>delete</Icon>
      </div>
    )
  });

  const sortedScrapedURLInputs = scrapedURLInputs?.sort((a, b) => {
    const urlA = scrapedURLs?.find((url, index) => !isUrlValid(url) && index === scrapedURLInputs?.indexOf(a));
    const urlB = scrapedURLs?.find((url, index) => !isUrlValid(url) && index === scrapedURLInputs?.indexOf(b));
    return urlA && !urlB ? -1 : urlB && !urlA ? 1 : 0;
  });
  const buttonStyles = {
    color: "white !important",
    borderRadius: "4px !important",
    padding: `${isMobile ? "5px 10px !important" : (isTablet ? "5px 10px !important" : "5px 18px !important")}`,
    textAlign: "center"
  };
  return (
    <div className="flex flex-col justify-between">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} sx={{
          paddingLeft: {
            xs: "0px",
            md: "50px !important"
          }
        }}>
          <p className={`text-xl font-semibold text-[#0F0F0F] ${darkMode ? "text-white" : "text-black"}`}>Website</p>

          <Box className=" mt-[10px]">
            <div className="flex items-center">
              <label className={`text-sm text-[#0F0F0F] font-medium ${darkMode ? "text-white" : "text-black"}`}>Crawl</label>
              <Tooltip text="Provide the base link to crawl all the links starting with this URL, ignoring files." uniqueId="crawlTooltip"
                color={getColorVlue(themeColor)}
              >
              </Tooltip>
            </div>
            <div className="flex xs:flex gap-[4px] sm:flex gap-[4px] md:gap-[15px] lg:gap-[15px] items-center">
              <input className={`rounded-md h-[25px] text-[#0F0F0F] w-full md:w-[70%] lg:w-[70%]
               border placeholder: flex justify-center text-[13px]
               ${darkMode ? "bg-transparent text-white border-[#ffff] focus:border-[#ffff] placeholder:placeholder-gray-100" : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}
             
               `}
                placeholder="https://www.website.com"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                disabled={siteMapUrl?.length > 0}

              />
              <MDButton color={themeColor} variant="gradient" size="small" style={{ fontSize: `${isMobile ? "6px" : (isTablet ? "10px" : isMediumLarge ? "11px" : "13px")}`, }} sx={buttonStyles} disabled={siteMapUrl?.length > 0}
                onClick={() => fetchWebsiteUrls('website')}>{isLoaderwebsiteUrl ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'FETCH LINKS'} </MDButton>
            </div>
          </Box>
          <div className="flex justify-center items-center pt-[50px] pr-[40px]">
            <div className="border-t border-gray-300 w-[30%]"></div>
            <p className={`px-2 font-medium ${darkMode ? "text-white" : "text-[#3B4E6D]"}`}>OR</p>
            <div className="border-t border-gray-300 w-[30%]"></div>
          </div>
          <Box className=" mt-[15px]">
            <div className="flex items-cnter">
              <label className={`text-sm text-[#0F0F0F] font-medium ${darkMode ? "text-white" : "text-black"}`}>Submit Sitemap</label>
              <Tooltip text="Provide the link top the sitemap of your website for accessing all links within the website."
                uniqueId="siteMapTooltip"
                color={getColorVlue(themeColor)}
              >
              </Tooltip>
            </div>
            <div className="flex xs:flex gap-[4px] sm:flex gap-[4px] md:gap-[15px] lg:gap-[15px] items-center">
              <input className={`rounded-md h-[25px] text-[#0F0F0F] w-full md:w-[70%] lg:w-[70%]
               border placeholder:flex justify-center text-[13px] 
               ${darkMode ? "bg-transparent text-white border-[#ffff] focus:border-[#ffff] placeholder:placeholder-gray-100" : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}
               `}
                placeholder="https://www.website.com/sitemap.xml"
                value={siteMapUrl}
                onChange={(e) => setSiteMapUrl(e.target.value)}
                disabled={websiteUrl?.length > 0}
              />
              <MDButton color={themeColor} variant="gradient" size="small" style={{ fontSize: `${isMobile ? "6px" : (isTablet ? "10px" : isMediumLarge ? "11px" : "13px")}`, }} sx={buttonStyles} disabled={websiteUrl?.length > 0}
                onClick={() => fetchWebsiteUrls('sitemap')}>{isLoaderSitemap ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'LOAD SITEMAP'}</MDButton>

            </div>
          </Box>
          <div className="mt-[50px] border-t border-gray-300 w-[95%]"></div>
          <Box className="mt-[10px]">
            <div className="flex justify-between w-[95%] items-center">
              <p className={`text-[14px] font-medium ${darkMode ? "text-white" : "text-black"}`}>Included Links</p>
              <div className="flex ">
                {scrapedURLs?.length > 0 && (
                  <>

                    <MDButton size="small" sx={{
                      color: darkMode ? "white !important" : "#1D3F80 !important", fontSize: "10px", display: "flex", gap: "4px", backgroundColor: darkMode ? "transparent !important" : "",
                      padding: "7px !important", fontSize: "10px !important"
                    }} onClick={() => saveAllUrls()}>
                      <LinkIcon fontSize="small" color={themeColor} sx={{ color: darkMode ? "#15369e !important" : "", height: "17px", width: "17px" }} />
                      {/* <img src={linkIcon} alt="" style={{color:darkMode?"#2459b5 !important":""}}/> */}
                      save links</MDButton>
                    <MDButton size="small" sx={{
                      color: darkMode ? "#ec1313 !important" : "#FF0000!important", fontSize: "10px", padding: "7px !important",
                      display: "flex", gap: "4px", padding: "7px !important", fontSize: "10px !important", backgroundColor: darkMode ? "transparent !important" : ""
                    }}
                      onClick={() => deleteAllLinks()}>Delete All</MDButton>
                  </>
                )}
                <AddBoxIcon color={themeColor} className=" mt-[2px] pl-[5px] cursor-pointer flex justify-center items-center" fontSize="medium" onClick={() => addUrlInput()} />
              </div>

            </div>
          </Box>
          <Box className={`mt-[10px] ${scrapedURLs?.length > 0 ? "h-[200px] overflow-y-scroll" : ""}`}
          // style={{ height: websiteUrl?.length > 0 ? "200px" : "0px", overflowY: "auto" }}
          >
            <div className="">
              {sortedScrapedURLInputs}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="w-full">
          <p className={`text-xl font-semibold ${isMobile ? "0px" : "pl-[23px]"} ${darkMode ? "text-white" : "text-black"}`}>Document upload</p>
          <Box className='md:border-b-0 md:border-l md:pl-5 border-gray-300 md:border-gray-300 mt-[20px]'>
            <div className="border-2 border-dashed flex justify-center rounded-xl">
              <div className="pt-[50px] px-[15px] pb-[15px]">
                <form autoComplete="off">
                  <div className="flex flex-row w-full flex-wrap justify-between mt-4">
                    <div className="flex flex-row justify-center my-2 w-full">
                      <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }} className="w-full">
                        {location.state?.isTrained ? (
                          <div className="text-center">
                            <div>X-Agent trained successfully. Please start interaction with LLM.</div>
                            {location.state?.deployedURL && (
                              <div className="mt-2">
                                <span className="font-semibold"> Deployed URL: </span>
                                {location.state?.deployedURL}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-center text-base">
                            <p className="text-sm font-normal">Training has not started yet. Please upload file and click <span className="font-semibold">Train.</span></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center w-full flex-col">
                    {location.state?.uploadedFile?.length ? (
                      location.state?.uploadedFile?.map((afl) => (
                        <Tag
                          key={afl.fileName}
                          name={afl?.fileName}
                          onClick={() => console.log("")}
                          isDelete={true}
                          onDelete={(e) => {
                            e.stopPropagation();
                            setModalDelOpen({ flag: true, document: afl });
                          }}
                          className="mt-6"
                          isLogo={true}
                          isWebToolTrain={true}
                        />
                      ))
                    ) : null}
                  </div>
                </form>
                <div>
                  <TabUpload supportedFileTypes="PDF, DOCX, TXT" uploadedFile={uploadedFile} loader={loader} isWebToolTrain={true} />
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="flex justify-center w-full mt-auto">
        <MDBox mt={4} mb={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
          <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={trainingPopupLoader} onClick={() => { previousBtn() }} variant="gradient" color={themeColor} fullWidth>
            Previous
          </MDButton>
          <MDButton style={{ width: "max-content", minWidth: "125px" }}
            disabled={trainingPopupLoader}
            onClick={() => { if (!isFeedbackPopupHandle) { TrainXAgent(false) } else { setModalOpen(true) } }}
            variant="gradient" color={themeColor} fullWidth>
            {trainingPopupLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'train'}
          </MDButton>
          {
            location.state?.page === 4 &&
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={trainingPopupLoader} onClick={() => { ChatXAgent() }} variant="gradient" color={themeColor} fullWidth>
              Chatbot
            </MDButton>
          }

        </MDBox>
      </div>

      {modalDelOpen?.flag && (
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: modalDelOpen.document })}>
          <div style={{ textAlign: "center" }} className="flex flex-row w-full justify-center items-center text-xl font-semibold mb-4">
            Are you sure you want to delete "{modalDelOpen?.document?.fileName}"? Deleted document will be lost
          </div>

          <div className="mt-6 flex flex-row w-full justify-center items-center text-xl font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: {} })} variant="gradient" color={"error"} fullWidth>
              No
            </MDButton>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={deleteDocument} variant="gradient" color={themeColor} fullWidth>
              Yes
            </MDButton>
          </div>
        </Modal>
      )}



      {modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
          <div className="flex flex-row w-full justify-center items-center mb-4" >
            LLM will be retrained. Do you wish to keep manual feedback previously provided?
          </div>

          <div className="mt-6 flex flex-row w-full justify-center items-center font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={trainingLoader}
              onClick={() => { TrainXAgent(false) }} variant="gradient" color={"error"} fullWidth>
              {trainingLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "No"}
            </MDButton>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={feedbackLoader} onClick={() => { TrainXAgent(true) }}
              variant="gradient" color={themeColor} fullWidth>
              {feedbackLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </div>
        </Modal>
      }
    </div>
  );
}

export default ChatbotTrain;