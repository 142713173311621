/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import ApiRequest from "services/ApiRequest";
import { useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { Facebook, Instagram , List, BulletList} from 'react-content-loader'
import { Images } from "libraries/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
import GPT35 from "../../assets/images/icons/Model Icons/gpt-3.5-turbo-instruct.svg"
import Gimini from "../../assets/images/icons/Model Icons/google-gemini.svg"
import SpacyNER from "../../assets/images/icons/Model Icons/spacy ner.svg"
import DecisionTree from "../../assets/images/icons/Model Icons/Decision Tree.svg"
import LogisticRegression from "../../assets/images/icons/Model Icons/Logistic Regression.svg"
import KNearestNeighbours  from "../../assets/images/icons/Model Icons/K Nearest Neighbours.svg"
import SupportVectorMachine  from "../../assets/images/icons/Model Icons/Support Vector Machine.svg"
import LinearRegression from "../../assets/images/icons/Model Icons/Linear Regression.svg"
import XGBoost from "../../assets/images/icons/Model Icons/XGBoost Regression.svg"
import KMeansRegression from "../../assets/images/icons/Model Icons/K-Means Regression.svg"

import { useMaterialUIController } from "context";
import  { patternTitleWithoutPattern} from "../../libraries/Enums";
import useScreenWidth from "libraries/ScreenSizeHook";
import Constants from "libraries/Constants";
import { useSelector } from "react-redux";
import { getColorVlue } from 'services/Utils';
import {ReactComponent as Basic}  from "assets/images/billing-icons/Basic.svg"; 


function Pattern() {
  const { sales, tasks } = reportsLineChartData;
  const [patternDetails, setPatternDetails] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [modelIcons, setModelIcons] = useState([]);
  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const location = useLocation();
  const  navigate = useNavigate();
  const {id, name} = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();


  useEffect(()=> {
    patternDetailList();
    allModelsList();
  },[]);

  const patternDetailList = () => {
    setIsLoader(true);
    ApiRequest.patternList(`/${id}`, '', (res) => {
        if (res.isSuccess) {
          setPatternDetails(res.data?.patternLearnMore);
          console.log("11")
        } else {
          setPatternDetails([]);
        }   
      });
  };

  const allModelsList = () => {
    ApiRequest.listModels('', id, (res)=>{
      if(res?.isSuccess){
        setModelIcons(res?.data?.patternModels?.map(pl => pl?.logoUrl))
      }else{
        setModelIcons([]);  
      }
      setIsLoader(false);
    });
  };

  const createAgent = () => {
    // navigate("/auth/forgot-password");
    if(name === Constants.chatbotRoute || name === Constants.featureExtractionRoute || name === Constants.predictiveAnalysisRoute 
        || name === Constants.recommendationRoute || name === Constants.imageToTextRoute || name === Constants.speechToTextRoute  || name === Constants.textToSpeechRoute){
        navigate(`/patterns/${id}/${name}/agent-creation/config`,
        { state: { id: id, step: 0, deployedURL: patternDetails?.[0]?.url, isEditAgent: false }}
    )}      
 };

 console.log("location?.state?.planName",location?.state?.planName)

  return (
    <div style={{
        backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
        backgroundPosition: "100% 100%",
        overflowY: "auto",
        height: "100vh",
        }}>
    <DashboardLayout>
      
    <DashboardNavbar />
      
    <Grid container spacing={2} style={{width: "100%", overflowY: "auto", height: "calc(100vh - 110px)"}}>
        <Grid item xs={12}style={{display: "flex", justifyContent: "flex-end"}}>
            {location?.state?.planName ?
            <div className="mr-2 mt-2" style={{ color: darkMode && "white", fontSize: "16px", fontWeight: "800" }}>To Create X-Agent </div> : null
            }

            <MDButton
                component="a"
                onClick={()=>{ location?.state?.planName ?  navigate("/pricing-plans")  :  createAgent() }}
                rel="noreferrer"
                variant="gradient"
                color={themeColor}
                style={{ height: "40px" }}
            >    
            {location?.state?.planName ? 
            <MDBox  className="flex flex-row items-center p-1" >
                <Basic fill={getColorVlue(themeColor)} style={{width: "20px", height: "20px", marginRight: "5px"}}/>
                <div style={{ color: "white" }}>Upgrade to {location?.state?.planName}</div>
            </MDBox>     
            : "Create X-Agent"
            }
            </MDButton>
        </Grid>
        <Grid xs={12} md={6} lg={4} style={{padding: windowSize.innerWidth < 768 ? "" : "0px 1.5rem"}}>
            {
                isLoader
                ?
                <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                :
                <Grid container xs={12} md={12} lg={12} rowGap={2} >
                    <Grid xs={12}  style={{padding: "10px"}}>
                        <MDTypography>Description</MDTypography>
                    </Grid>
                    <Grid xs={12} style={{padding: "10px"}}>
                        <SimpleStatisticsCard
                            icon={patternDetails?.[0]?.logoUrl}
                            title=""
                            IconWidth="4rem"
                            IconHeight="4rem"
                            isPatternList={true}
                            color={themeColor}
                            patternName={patternDetails?.[0]?.patternName}
                            percentage={{
                                color: themeColor,
                                amount: "",
                                label: patternDetails?.[0]?.description,
                            }}
                            onClick={()=>{}}
                        />
                    </Grid>
                </Grid>
            }
        </Grid>   
        <Grid xs={12} md={6} lg={4} style={{padding: windowSize.innerWidth < 768 ? "" : "0px 1.5rem"}}>
               {
                    isLoader
                    ?
                    <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                    :
                        
                    <Grid container xs={12} md={12} lg={12} rowGap={2} >
                        <Grid xs={12}    style={{padding: "10px"}}>
                            <MDTypography>Available models</MDTypography>
                        </Grid>
                        {
                        patternDetails?.[0]?.models?.split(',')?.map((item, i) => (
                            <>
                            <Grid xs={12}    style={{padding: "10px"}}>
                                <SimpleStatisticsCard
                                    icon={modelIcons?.length ? modelIcons[i] : null}
                                    color={themeColor}
                                    title={item}
                                    IconWidth="48px"
                                    IconHeight="48px"
                                    onClick={()=>{}}
                                />
                            </Grid>
                            </>
                            ))
                        }
                    </Grid>
               }
        </Grid>   
        <Grid  xs={12} md={6} lg={4} style={{padding: windowSize.innerWidth < 768 ? "" : "0px 1.5rem"}}>
                {
                    isLoader
                    ?
                    <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                    :
                    <Grid container xs={12} md={12} lg={12} rowGap={2} >
                        <Grid item xs={12}   style={{padding: "10px"}}>
                            <MDTypography>Use-Cases</MDTypography>
                        </Grid>
                        <Grid xs={12} md={12} lg={12}xl={12} style={{padding: "10px"}}>
                            <SimpleStatisticsCard
                                icon={patternDetails?.[0]?.useCaseOnelogoUrl}
                                title={patternDetails?.[0]?.useCaseOne}
                                color={themeColor}
                                IconWidth="48px"
                                IconHeight="48px"
                                onClick={()=>{}}
                            />
                        </Grid>
                        <Grid xs={12}    style={{padding: "10px"}}>
                            <SimpleStatisticsCard
                                icon={patternDetails?.[0]?.useCaseTwologoUrl}
                                title={patternDetails?.[0]?.useCaseTwo}
                                color={themeColor}
                                IconWidth="48px"
                                IconHeight="48px"
                                onClick={()=>{}}
                            />
                        </Grid>
                        <Grid xs={12}    style={{padding: "10px", display: "flex", justifyContent: "center"}}>
                            <SimpleStatisticsCard
                                icon={patternDetails?.[0]?.useCaseThreelogoUrl}
                                title={patternDetails?.[0]?.useCaseThree}
                                color={themeColor}
                                IconWidth="48px"
                                IconHeight="48px"
                                onClick={()=>{}}
                            />
                        </Grid>
                    </Grid>
               }
        </Grid>         
    </Grid>  
      
      {/* <Footer /> */}
    </DashboardLayout>
    </div>

  );
}

export default Pattern;
