/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useMaterialUIController} from "context";

function DashboardStatisticsCard({ color, title, count, icon, isIcon, isForDashboard }) {
  const [controller] = useMaterialUIController();
  const {  darkMode, themeColor } = controller;

  return (
    <Card style={{ marginBottom: "25px" }}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          {isIcon ? <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
          :  <img style={{ width: "30px", height: "30px" }} src={icon} />
        }
            
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} style={{display: "flex", flexDirection: "column",gap: "10px", paddingBottom: "30px"}}>
            <MDTypography style={{ color: darkMode ? "white" : "black" }} variant="h6">
            {title}
            </MDTypography>

            <MDBox style={{display: "flex", justifyContent: "end", alignItems: "center", gap: "5px"}}>
            {
                isForDashboard && 
                <MDTypography variant="button" style={{ color: darkMode ? "white" : "#0F0F0F" }}>Requests Made -</MDTypography>
            }
            <MDTypography style={{ color: darkMode ? "white" : "black" }} variant="h6">{count}</MDTypography>  
            </MDBox>
            
        </MDBox>
      </MDBox>  
    </Card>
  );
}

// Setting default values for the props of DashboardStatisticsCard
DashboardStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the DashboardStatisticsCard
DashboardStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default DashboardStatisticsCard;
