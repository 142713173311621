  /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {useMaterialUIController} from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { SpinnerLoader } from "examples/Loader/Loader";
import MediaPlayer from "examples/MediaPlayer/MediaPlayer";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { validateAndProcessFile } from "services/Utils";
import Methods from "libraries/CommonMethodsUI";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa";
import Constants from "libraries/Constants";
import MDDropdown from "components/MDDropdown";
import { FaCopyright } from "react-icons/fa6";

import ReactPlayer from 'react-player';
import { Checkbox } from '@mui/material';
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
// import CustomSelect from "components/CustomSelect/CustomSelect"; 


function SpeechToTextInference() {
  const navigate = useNavigate()
  const location = useLocation()  
  const {id, name} = useParams()

  const { configForm } = location?.state || {};
  const {  agentName, description, modelType, pageSegmentation, modelName, openAIKey, systemPrompt, temperature, maxTokens } = configForm || {};
  const [uploadedFile, setUploadedFile] = useState();
  const [isVideo, setIsVideo] = useState(false);
  const [checks, setChecks] = useState({ transcription: false, textSummarization: false, sentiment: false })
  const [inferResult, setInferResult] = useState();
  const [playbackRate, setPlaybackRate] = useState(1); // Default playback rate is 1 (normal speed)
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
   
  const navigation = (route, page, step) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };

  useEffect(()=>(
    navigation('/inference', 3, 1)
  ),[]);

  const uploadedContent = (fl) => {
    setIsLoading(true);
    let supportedFormats = {};

     supportedFormats = {
      'audio/mpeg': 'MP3',    // For MP3 files
      'audio/wav': 'WAV',     // For WAV files
      // 'audio/aac': 'AAC',     // For AAC files
      // 'audio/ogg': 'OGG',     // For OGG files
      // 'audio/flac': 'FLAC'    // For FLAC files
    };

    const videoSupportedFormat = {
      'video/mp4': 'MP4',     // For MP4 files
    }

    const afile = fl?.[0];

    const validation = validateAndProcessFile(afile, supportedFormats)
    const videoValidation = validateAndProcessFile(afile, videoSupportedFormat)
      if(videoValidation === true && fl){
        setIsLoading(false);setUploadedFile(URL.createObjectURL(fl?.[0])); setIsVideo(true);
      }else if(validation === true && fl){
          setIsLoading(false);setUploadedFile(fl); setIsVideo(false);
      }else{
          toast.error("Unexpected File Type. We only support MP3, WAV, MP4");
          setIsLoading(false);     
      }
    }

    const reconfigBtn = () => {
      navigation('/config', 1, 0);
    };

    const inferenceDownload = () => {
      if(!uploadedFile?.length){
        reconfigBtn()
      }else{
        setUploadedFile()
      }
    };
  
  const nextInference = () => {
    if(uploadedFile?.length){
      navigation('/inference', 4, 1);
    }
  }

  const arrChecks = [
    {id: 1, name: "Transcription", apiName: "transcription"},
    {id: 2, name: "Text Summarization", apiName: "textSummarization"},
    {id: 3, name: "Sentiment Analysis", apiName: "sentiment"}
  ];

  console.log("checks",checks)

  return (
      <div className="flex flex-col" style={{height: "calc(100%)", justifyContent: "space-between"}}>

        {location.state?.page === 4 ?
        <div>
            {arrChecks?.map((ar, i) => (
                <>
                <MDBox mt={2} mb={1} style={{display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "space-between"}}>
              <div style={{ color: darkMode ? "white" : "black", marginTop: "10px", fontWeight: "600" }}>{ar?.name}</div>
              {i === 0 ? 
              <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                        onClick={()=> Methods.downloadCSV(inferResult, "data.txt")} variant="gradient" color={themeColor} fullWidth>
                  {"Download File"}
              </MDButton>
              : null}
            </MDBox>

            <div style={{fontSize: "14px", padding: "20px", 
                border: darkMode ? "1px dotted white" : "1px dotted black" , borderRadius:"7px", 
                color: darkMode && "white" ,
                height: "150px", textAlign: "justify", overflowY: "auto" }}>
                {inferResult}
            </div>

            <MDBox mt={1} style={{display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "end"}}>
              <CopyToClipboard text={inferResult} onCopy={()=>  toast.success("Text has been copied!")}>
                <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} 
                          onClick={(e) => {e?.stopPropagation()}}
                          variant="gradient" color={themeColor} fullWidth>
                  {"Copy"}
              </MDButton>
              </CopyToClipboard>
            </MDBox>
                </>
            ))}
            

        </div>

        :
        <div>
        {isLoading ? 
        <div style={{ marginTop: "40px" }}>
        <SpinnerLoader adjustment={true} enhance={`${darkMode ? "text-white" : "text-black"} text-2xl`} /> 
        </div>
        : !uploadedFile?.length ?
          <div>
            <TabUpload supportedFileTypes={"MP3, WAV, MP4"} isSingleFile={true} 
            uploadedFile={(fl) => uploadedContent(fl)} loader={false} />
          </div>
          : <div>
              {!isVideo ? 
              <MediaPlayer uploadedContent={uploadedFile} />
              : <ReactPlayer
                  url={uploadedFile}
                  controls
                  width="100%"
                  height="400px"
                />
              }

              <div className="w-full flex flex-row justify-center items-center">
              {arrChecks.map((ar) => (
                <div className="m-4">
                <Checkbox style={{ paddingLeft: "0px", paddingTop: "5px" }} color={themeColor}
                  icon={<MdCheckBoxOutlineBlank style={{ fontSize: "25px", color: darkMode ? "white" : "black" }} />}
                  checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "25px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "25px" }} />}
                  checked={checks[ar?.apiName]}
                  onClick={(e) => {
                    setChecks({ ...checks, [ar.apiName]: e.target.checked});
                  }}
                />
                <span style={{ fontSize: "20px"}}>{ar?.name}</span>
                </div>
              ))}
              </div>
            </div>
            }
        </div>
        }
        
        
        

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading} 
                      onClick={()=> inferenceDownload()} variant="gradient" color={themeColor} fullWidth>
                {"Previous"}
            </MDButton>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading} 
                      onClick={()=> nextInference()} variant="gradient" color={themeColor} fullWidth>
                {"Next"}
            </MDButton>
        </MDBox>
      </div>
  );
}

export default SpeechToTextInference;
