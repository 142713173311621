import React, { useState, useEffect } from 'react';
import Modal from 'examples/Modal/Modal';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { Tooltip } from 'examples/Tooltip/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import ApiRequest from 'services/ApiRequest';
import { toast } from 'react-toastify';
import { SpinnerLoader } from "examples/Loader/Loader";
import { useMediaQuery } from '@mui/material';
import { getColorVlue } from 'services/Utils';


const ApikeyModal = ({ open, onClose, setOpen, title, buttonLabel, themeColor, darkMode, id, name }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [isSuccessModel, setIsSuccessModel] = useState(false);
    const [origionalApiKey, setOrigionalApiKey] = useState('')
    const [apiInputValue, setApiInputValue] = useState(name || '')
    const [isLoading, setIsLaoding] = useState(false)
    const [apiId, setApiId] = useState('')
    const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)')
    const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)')
    const apiKeyData = [
        {
            id: 1, type: 'text', placeholder: 'API Name', name: 'apiName', mendatory: true, information: " Name of the API Key you want to create. Note that API Key name must be unique."
        }
    ];

    useEffect(() => {
        getApiKeys()
    }, [])
    const createAPIKey = () => {
        setIsLaoding(true)
        const payload = {
            name: apiInputValue,
            globalPermission: true
        };
        ApiRequest.createApi(payload, (res) => {

            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "API Key generated successfully.");
                setOrigionalApiKey(res?.data?.keyConfiguration?.originalApiKey)
                setApiId(res?.data?.keyConfiguration?._id)
                setModalOpen(false);
                setIsSuccessModel(true);
                setIsLaoding(false)
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "failed");
                setIsLaoding(false)
            }
        });
    };
    const updateAPIKey = () => {
        const payload = {
            name: apiInputValue,
            globalPermission: true
        };
        ApiRequest.updateApiKey(payload, { id: id }, (res) => {
            console.log(res, "res")
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "API Key generated successfully.");
                setOrigionalApiKey(origionalApiKey)
                setApiId(res?.data?.updatedConfiguration?._id)
                setModalOpen(false);
                setIsSuccessModel(false);
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "failed");
            }
        });
    };
    const handleAPikeyModule = () => {
        if (!apiInputValue) {
            toast.error("Please fill require field")
            return;
        }
        if (!id) {
            createAPIKey()
        } else {
            updateAPIKey()
        }
    }
    const getApiKeys = () => {
        ApiRequest.allApiKeys('', (res) => {
            if (res?.isSuccess) {
                console.log('')
            } else {
                console.log('')
            }
        });
    }

    return (
        <Modal open={open} onClose={onClose} setOpen ={setOpen} isMedium={true} isApikey={true}>
            <MDBox sx={{ padding: "10px 0px", minHeight: "300px", backgroundColor: darkMode ? '' : '#fff' }}>
                <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CloseIcon className="w-[13px] h-[13px] cursor-pointer" style={{ color: darkMode ? "white" : "#000000" }} onClick={onClose} />
                </MDBox>
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: isSuccessModel ? "10px 20px" : (isLargeMobile || isSmallMobile) ? "40px 6px" : "40px 20px" }}>
                    <MDTypography style={{ display: "flex", fontSize: isSmallMobile ? "11px" : isLargeMobile ? "16px" : "20px", alignItems: "center", fontWeight: "600", color: darkMode ? "white" : "#3B4E6D" }}>
                        {(!isSuccessModel && id) ? "Update API Key" : !isSuccessModel ? title : "API Key Successfully Created"}
                    </MDTypography>
                    {buttonLabel && !isSuccessModel && (
                        <MDButton size={`${isSmallMobile || isLargeMobile} && "small" `} style={{ padding: (isSmallMobile || isLargeMobile) && "10px", width: "max-content", minWidth: isSmallMobile ? "80px" : "140px" }} variant="gradient" color={themeColor} onClick={handleAPikeyModule}>
                            {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> :
                                ((!isSuccessModel && id) ? "Update API Key" : buttonLabel)}
                        </MDButton>
                    )}
                </MDBox>

                <MDBox sx={{ padding: isSuccessModel && (isSmallMobile || isLargeMobile) ? "0px" : isSuccessModel ? "0px 20px 20px" : "10px 20px 20px" }}>
                    {!isSuccessModel && apiKeyData?.map((fi) => (
                        <div key={fi?.name} className="flex">
                            <MDInput
                                style={{ width: "285px" }}
                                id={fi?.name}
                                type={fi?.type}
                                value={apiInputValue}
                                onChange={(e) => {
                                    setApiInputValue(e?.target?.value)
                                }}
                                label={`${fi.placeholder}${fi.mendatory ? "*" : ""}`}
                                variant="standard"
                            />
                            <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />
                        </div>
                    ))}
                    {isSuccessModel && (
                        <MDBox>
                            <MDTypography style={{ marginTop: "10px", fontSize: isSmalMobileStyling ? "11px" : ismdMobileStyling ? "12px" : isLargeMobile ? "14px" : "16px", fontWeight: "400", color: darkMode ? "white" : "#000000" }}>
                                Please save this secret key somewhere safe and accessible. For Security reasons you won’t be able to view it again.
                                If you lose this secret key, you will need to generate a new one.
                            </MDTypography>
                            <OutlinedInput value={origionalApiKey} className={`mt-[30px]  ${isSmalMobileStyling ? "w-[255px]" : ismdMobileStyling ? "w-[305px]" : isLargeMobile ? "w-[352px]" : "w-[590px]"}  text-red-500`} style={{ fontSize: isSmalMobileStyling ? "9px" : ismdMobileStyling ? "10px" : isLargeMobile ? "12px" : "" }} sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#BFBFBF',
                                    borderWidth: '1px',
                                    borderRadius: "10px",
                                    // backgroundColor: "#FAFAFA"
                                },
                                pointerEvents:"none"
                            }} />
                            <MDBox sx={{ display: "flex", justifyContent: "flex-end", marginRight: "9px" }}>
                                <MDButton style={{ marginTop: "30px", width: "max-content", minWidth: "140px" }} variant="gradient" color={themeColor} onClick={() => { navigator.clipboard.writeText(origionalApiKey); toast.success("API Key copied to clipboard."); }}>Copy</MDButton>
                            </MDBox>
                        </MDBox>
                    )}
                </MDBox>
            </MDBox>
        </Modal>
    );
};

export default ApikeyModal;
