import React from 'react'
import { useMediaQuery } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ExpandableMenu = ({ size, options, selectedYear, setSelectedService, selectedService, currentService, showIcon, children, pattren }) => {
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = (option) => {
        setSelectedService(option);
        handleClose();
    };
    if(currentService !==selectedService) return <></>
    return (
        <>
            {children}
            {
                (isLargeMobile || isSmallMobile  || showIcon) && <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color={"white"}
                style={{
                    padding: "0px",

                }}
            >
                <UnfoldMoreIcon style={{
                    height:(isSmallMobile && pattren) ? "14px" : (isLargeMobile && pattren) ? "18px" : isTablet ? "20px" : ((!pattren && isLargeMobile) || (!pattren && isSmallMobile)) ? "12px" : "",
                    width: (isSmallMobile && pattren) ? "14px" : isTablet ? "20px" : ((!pattren && isLargeMobile) || (!pattren && isSmallMobile)) ? "12px" : ""
                }} />
            </IconButton>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{padding:"8px 2px"}}

            >
                {options.map((option, key) => (<MenuItem
                    style={{
                        padding:0,
                        cursor: "pointer",
                        background: (selectedYear || selectedService) === option ? '#E9E8E8' : 'transparent',
                        color: (selectedYear || selectedService) === option ? '#5B5B5B' : 'inherit',
                        borderRadius:"2px",
                        paddingLeft:"10px",
                        fontSize:(isSmallMobile || isLargeMobile) ? "9px" : isTablet ? "11px" :"14px"
                    }}
                
                    key={key} onClick={() => handleSelect(option)}>{option}</MenuItem>))}
            </Menu></>
    )
}

export default ExpandableMenu