  /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {useMaterialUIController} from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { SpinnerLoader } from "examples/Loader/Loader";
import Constants from "libraries/Constants";
import MDDropdown from "components/MDDropdown";
import { Images } from "libraries/Images";
import Divider from "@mui/material/Divider";
import OutlinedInput from '@mui/material/OutlinedInput';

// import CustomSelect from "components/CustomSelect/CustomSelect"; 


function ImageToTextConfig() {
  const navigate = useNavigate()
  const location = useLocation()  
  const {id, name} = useParams()

  const { configForm } = location?.state || {};
  const {  agentName, description, modelType, pageSegmentation, openAIKey, prompt, temperature, maxTokens } = configForm || {};

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const [config, setConfig] = useState( { 
    agentName: agentName ?? "", description: description ?? '',
    modelType: modelType ?? "", pageSegmentation: pageSegmentation ?? "", 
    openAIKey: openAIKey ?? "", prompt: prompt ?? "",
    temperature: temperature ?? "0.5", maxTokens: maxTokens ?? "256"
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();

  console.log("Image To text Deployed URL", location?.state)
   
  const fields = [
    { id: 3, type: 'dropdown', placeholder: 'Model Type*', name: 'modelType', isActive: true, 
    information: 'There are 2 Model types. Using OCR technology to scan PDF images and OpenAI for image processing',       
    arr: ['openAI', 'ocr'], mendatory: true },
    { id: 3, type: 'dropdown', placeholder: 'Empty', name: 'modelType', isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase(), arr: [''], mendatory: true, information: "" },
    { id: 4, type: 'dropdown', placeholder: 'Page Segmentation Mode*',name: 'pageSegmentation', isActive: config.modelType?.toLowerCase() === Constants.imageToTextOcr?.toLowerCase(), 
      arr: ['FULL_PAGE_SEGMENTATION', 'SINGLE_COLUMN_OF_TEXT', 'BLOCK_OF_VERTICALLY_ALIGNED_TEXT', 'UNIFORM_BLOCK_OF_TEXT', 'SINGLE_TEXT_LINE', 'SPARSE_TEXT'], mendatory: true, 
      information: 'test'       
    },
    { id: 1, type: 'text', placeholder: 'Agent Name', name: 'agentName', isActive: true, mendatory: true, information: "Name of the X-Agent you want to create. Note that the X-Agent name must be unique." },
    // { id: 6, type: 'text', placeholder: 'OpenAI Key', name: 'openAIKey', isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase(), mendatory: true, information: "OpenAI Key required for API usage. You can find your API key at the OpenAI website under API keys section" },
    { id: 8, type: 'number', placeholder: 'Temperature', name: 'temperature', isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase(), mendatory: true, information: "Controls randomness of model's output; lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive." },
    { id: 9, type: 'number', placeholder: 'Maximum Length', name: 'maxTokens', isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase(), mendatory: true, information: "The maximum number of tokens to generate shared between the prompt and the completion. The exact limit varies by model. (One token is rougly 4 characters for standard English text)" },
    { id: 2, type: 'textarea', placeholder: 'Agent Description*', name: 'description', 
    isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase() || config.modelType?.toLowerCase() === Constants.imageToTextOcr?.toLowerCase(), mendatory: true, information: "Describes the agent's purpose or functionality." },
    { id: 7, type: 'textarea', placeholder: 'System Prompt*', name: 'prompt', isActive: config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase(), mendatory: true, information: "Input that guides the model's behavior, providing context or setting the tone for generating responses.." }
  ];

  const isValidate = () => {
    let validate = true;
    fields.map((field, _) => {
        if((field?.type === "text" || field?.type === "textarea") && field?.mendatory && config[`${field?.name}`] === ""){
            validate = false
        }
    })
    return validate
  }

  const navigation = (route, page, step, objectId, configForm) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, location?.state?.configForm)
  ),[]);

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, 
    {...config, patternId: id})
  ),[config]);

  const saveConfig = (flg) => {
      // if(isValidate()){
        let data={}
        let isValid=false;
        if(config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase()){
          // openAIKey,
          data = {agentName, description, modelType,  prompt, temperature: Number(temperature), maxTokens: Number(maxTokens)}
        }else{
          data = {agentName, description, modelType, pageSegmentation}
        }

        if(config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase()){
          // if(!openAIKey){
          //   toast.error("Please enter openAI Key");
          //   isValid=true
          // }
           if(Number(temperature) == Math.floor(Number(temperature))){
            toast.error("Temperature must be a decimal value");
            isValid=true
          }
          else if(Number(temperature) === 0 || Math.floor(Number(temperature) > 1)){
            toast.error("Temperature must be greater than 0 till 1");
            isValid=true
          }
          else if(Number(maxTokens) !== Math.floor(Number(maxTokens))){
            toast.error("Maximum Tokens must be an integer value");
            isValid=true
          }else if(!prompt){
            isValid=true
          }
          else if(Number(maxTokens) < 256){
            toast.error("Maximum Tokens  must be greater than or equal to 256");
            isValid=true
          }else{             isValid=false          }
        } else{
           if(!pageSegmentation && modelType) {
            isValid=true          
          }else{
            isValid=false          
          }          
        }
        
        if(!agentName || !description || !modelType || isValid){
          toast.error("Please fill all required fields");
        }else{
          if(flg){setIsLoading(true)}
          else{setIsLoading2(true)}

          ApiRequest.configImageToTextAgent({...data, patternId: id}, location?.state?.objectId, (res) => {
            if(flg) setIsLoading(false);
            if (res.isSuccess) {
              if (flg) {
                navigation('/config', 1, 0, res?.data?.agent?._id, location?.state?.configForm);
              } else {
                setIsLoading(false);setIsLoading2(false);
                navigation('/inference', 3, 1, res?.data?.agent?._id, location?.state?.configForm);
              }
              toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
            } else {
              setIsLoading(false);setIsLoading2(false);
              toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
          });
        }
  };

  const Icons = (icon) => {
    return (
        <div style={{ width: "30px", marginRight: "15px" }}>
            <MDBox bgColor={themeColor}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                <MDBox component="img" textAlign="center"
                    src={icon}
                    alt="Brand" width="70%"
                    style={{ height: "14px", width: "14px" }}
                />
            </MDBox>
        </div>
    )
}

console.log("location.state", location.state)

  return (
      <div className="flex flex-col" style={{height: "calc(100%)", justifyContent: "space-between"}}>
        <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "30px"}}>
        	
          {fields?.filter((fl) => fl?.isActive).map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" 
                  : fi?.type === "textarea" ? "80%" 
                  : (config.modelType?.toLowerCase() === Constants.imageToTextOcr?.toLowerCase() && fi.name === "agentName") ? "80%"
                  : (config.modelType?.toLowerCase() === Constants.imageToTextOcr?.toLowerCase() && fi.type === "dropdown") ? "30%"
                  :  "45%", outline: "none",
                display: "flex", alignItems: fi?.type === "textarea" ? "start" : "center" }}>
            {fi.type === "dropdown" && fi.placeholder !== "Empty" ?
                <MDDropdown dropdownName={fi.placeholder} 
                      nameArr={fi?.arr}
                      isLarge={true}
                      isMultiple={false} 
                      backSelect={(val)=> {
                        setConfig({ ...config, [fi?.name]: typeof val === 'string' ? val.split(',')?.[0] : val })
                      } }
                      personName={[config?.[fi?.name] ]?.length ? config[fi?.name] : ''} />                       
                :
                fi.type === "dropdown" && fi.placeholder === "Empty" ? <div></div>
                :
              fi?.type === "textarea" ? 
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                      {Icons(fi?.name === "description" 
                                ? Images.promptLlmPattern : Images.inputParameterLlmPattern)}
                      {fi?.placeholder}
                  </div>
                <OutlinedInput 
                      placeholder={`Write ${fi?.placeholder} here`}
                      sx={{
                        width: "100%",
                        minHeight: "70px", 
                        borderRadius: "10px", '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CCCCCC',
                            borderWidth: '2px',
                        },
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        resize: 'none'     
                      }} 
                      multiline 
                      value={config[fi.name]} 
                      onChange={(e) => {
                        setConfig({ ...config, [fi.name]: e.target.value });
                        }}
                      />
                      </div>
                :
              <MDInput   
                  // style={{outline: "none", border: "none"}}
                  id={fi.name} 
                  type={fi.type}
                  value={config[fi.name]} 
                  onChange={(e) => {
                  setConfig({ ...config, [fi.name]: e.target.value });
                  // setError({ ...error, [fi.name]: '' });
                  }}
                  label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                  variant="standard" fullWidth />
              }
                <div style={{ marginTop: fi?.type === "textarea" && "20px" }}>
                  {config.modelType?.toLowerCase() === Constants.imageToTextCustom?.toLowerCase() ?
                  <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>
                  :  <Tooltip uniqueId={fi.name} text={fi.name === "pageSegmentation" ? 
                  <>
                      <div style={{ textAlign: "center"}}  className="font-semibold">
                          {'Page Segmentation Modes'}
                      </div>
                      <div>
                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Full Page Segmentation:</div>
                        Page of text with multiple words, multiple lines or multiple paragraphs
                        </>

                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Single Column of Text:</div>
                        Column data and require text to be concatenated row-wise
                        </>

                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Block of Vertically Aligned Text:</div>
                        For a single block of vertically aligned text
                        </>

                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Uniform Block of Text:</div>
                        Text in a single font face without any variation
                        </>

                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Single Text Line:</div>
                        Working with a single line of uniform text
                        </>

                        <><div style={{ fontWeight: "500", marginTop: "10px" }}>Sparse Text:</div>
                        Finds as much text as possible in no particular order
                        </>
                      </div>
                      </>
                      :
                  `${fi?.information}`} 
                  color={getColorVlue(themeColor)}/>}
                </div>
              
                </MDBox>
            ))}
        </div>

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(true)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
            </MDButton>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(false)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
            </MDButton>
        </MDBox>
      </div>
  );
}

export default ImageToTextConfig;
