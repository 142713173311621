/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// Dashboard components
import 'react-loading-skeleton/dist/skeleton.css';
import { Images } from "libraries/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import MDStepper from "components/MDStepper";
import WidgetConfig from "../widget/config";
import WidgetDesign from "../widget/design";

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import useScreenWidth from "libraries/ScreenSizeHook";
import MDTypography from "components/MDTypography";

function CreationFlowWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const {id, name} = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();

  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const isProUser = false;

  const stepsWidget = ['Configure','Design'];

  const ChatbotIcons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };


  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
    <DashboardLayout>
    <DashboardNavbar />
    <MDTypography sx={{ fontSize: "20px", fontWeight: "600",paddingLeft:"5px",paddingBottom:"15px" }}>
        {"Chat Widget"}
    </MDTypography>
	<Grid container spacing={2} style={{overflowY: "auto", height: "calc(100vh - 150px)" }}>
		<MDStepper 
      steps={stepsWidget} 
      activeStep={location?.state?.step ?? 0}
      icons = {ChatbotIcons}
      >
          { ( location?.state?.step === 0 ) ? <WidgetConfig />
            : ( location?.state?.step === 1 ) ? <WidgetDesign />
          : null
          }
		</MDStepper>
	</Grid>  
      
    </DashboardLayout>
    </div>

  );
}

export default CreationFlowWrapper;
