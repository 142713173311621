/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {useMaterialUIController} from "context";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function NewPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const {  themeColor } = controller;

  const sendEmail = () => {
    if(!password || !confirmPassword){
      toast.error("Please fill all required fields")
    }
    else if(password !== confirmPassword){
      toast.error("New Password and Confirm New Password do not match");
    }else{
      ApiRequest.setNewPassword({ newPassword: password }, location?.search, '', (res) => {
        const { isSuccess, data, error } = res;

        if (isSuccess) {
            toast.success(data.message);
            navigate('/auth/login');          ;
        } else {
            toast.error(error.message);
        }
    });
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow={themeColor}
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            New Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
             Set the new password for your account
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">

            <MDBox mb={2}>
              <MDInput 
                      type={showPassword ? 'text' : 'password'}
                      value={password} 
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=> setShowPassword((show) => !show)}
                              onMouseDown={(e)=> e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                     
                      onChange={(e) => {
                          setPassword(e.target.value)
                          // setError({ ...error, password: '' });
                      }} label="New Password" fullWidth />
            </MDBox>

            <MDBox mb={2}>
              <MDInput 
                      type={showPassword2 ? 'text' : 'password'}
                      value={confirmPassword} 
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=> setShowPassword2((show) => !show)}
                              onMouseDown={(e)=> e.preventDefault()}
                              edge="end"
                            >
                              {showPassword2 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                     
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }} label="Confirm New Password" fullWidth />
            </MDBox>



            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color={themeColor} onClick={()=> sendEmail()} fullWidth>
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default NewPassword;
