import { THEME_COLOR } from "redux/Types";

const initialState = {
    agent: undefined
};

export const ThemeReducer = (state = initialState, action)=> {

    const { payload, type } = action;

    switch (type) {

        case THEME_COLOR: {
            return {
                ...state,
                agent: payload
            };
        }

        default:
            return state;

    }

};
