/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import googleIcon from "assets/images/small-logos/google-icon.svg"
import microsoftIcon from "assets/images/small-logos/microsoft-icon.svg"
import appLogo from "assets/images/logos/Intellixcore.png";
import { useState,useCallback,useEffect } from "react";
import { toast } from "react-toastify";
import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { SpinnerLoader } from "examples/Loader/Loader";
import {useMaterialUIController} from "context";
import ApiUrls from "services/ApiUrls";
import axios from "axios"

function Cover() {
    const [controller] = useMaterialUIController();
    const theme = useTheme();
    const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 376px)');
    const isMDMobile = useMediaQuery('(min-width: 400px) and (max-width: 476px)');
    const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
    const {  themeColor, darkMode } = controller;
    const navigate = useNavigate();
    const [signupCredentials, setSignupCredentials] = useState(
      { userEmail: '', userName: '', password: '', confirmPassword: '', firstName: '', lastName: '', company: '' });
    const [isSignupLoading, setIsSignupLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [isGoogleSignupLoading, setIsGoogleSignupLoading] = useState(false);
    const [isMicrosoftSignupLoading, setIsMicrosoftSignupLoading] = useState(false);

    const fields = [
      { id: 1, type: 'text', placeholder: 'First Name*', name: 'firstName' },
      { id: 1, type: 'text', placeholder: 'Last Name*', name: 'lastName' },
      { id: 1, type: 'text', placeholder: 'Company', name: 'company' },
      { id: 2, type: 'email', placeholder: 'Email*', name: 'userEmail' },
      { id: 3, type: showPassword ? 'text' : 'password', placeholder: 'Password*', name: 'password', endorse: {
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=> setShowPassword((show) => !show)}
              onMouseDown={(e)=> e.preventDefault()}
              style={{ color: darkMode ? "white" : "black" }}
              edge="end"
            >
              {showPassword ? <VisibilityOff style={{
                height: isMobile || isMDMobile || isTablet ? "15px" : "",
                width: isMobile || isMDMobile || isTablet ? "15px" : "",
              }}/> : <Visibility style={{
                height: isMobile || isMDMobile || isTablet ? "15px" : "",
                width: isMobile || isMDMobile || isTablet ? "15px" : "",
              }}/>}
            </IconButton>
          </InputAdornment>
        )
      } },
      { id: 4, type: showPassword2 ? 'text' : 'password', placeholder: "Confirm Password*", name: 'confirmPassword',  endorse: {
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=> setShowPassword2((show) => !show)}
              onMouseDown={(e)=> e.preventDefault()}
              style={{ color: darkMode ? "white" : "black" }}
              edge="end"
            >
              {showPassword2 ? <VisibilityOff  style={{ 
                height: isMobile || isMDMobile || isTablet  ? "15px" : "",
                width: isMobile || isMDMobile || isTablet ? "15px" : "",
              }}/> : <Visibility style={{
                height: isMobile || isMDMobile || isTablet ? "15px" : "",
                width: isMobile || isMDMobile || isTablet ? "15px" : "",
              }}/>}
            </IconButton>
          </InputAdornment>
        )
      } }
    ];
    const { validateEmail, validateLength } = CommonMethodsUI;

    const signUpUser = async () => {
        const { userEmail, userAddress, password, confirmPassword, firstName, lastName, company } = signupCredentials;
        const data = { userEmail, userAddress, password, firstName, lastName, companyName: company };

        // For testing
        if(!password || !confirmPassword || !userEmail || !firstName || !lastName){
          toast.error("Please fill all required fields")
        }
        else if(password !== confirmPassword){
          toast.error("Password and Confirm Password do not match");
        }
        else {
            setIsSignupLoading(true);
            ApiRequest.signUp(data, '', (res) => {
                setIsSignupLoading(false);
                const { isSuccess, data, error } = res;
                if (isSuccess) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate("/auth/login");
                    }, 1000);
                } else {
                    toast.error(error ? error?.message : 'Error while creating user');
                }
            });
        };
    }
 
  return (
    <CoverLayout image={bgImage}>
      <Card >
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
           <MDBox 
          mt={1}
          >
            <MDTypography variant="button" display="flex" justifyContent="center">
          <img  src={appLogo} alt="" className="h-[45px]"/>
             </MDTypography>
           </MDBox>

          <MDTypography variant="h4" fontWeight="medium" color="white"  mt={1} display="flex" justifyContent="center">
          Join us today
          </MDTypography>   
          <MDTypography variant="button" color="white" my={1} display="flex" justifyContent="center">
            Please enter your details to register
          </MDTypography>
          </MDBox>
       

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {fields.map((fi) => (
                <div style={{ width: "45%" }} key={fi.id} >
                  <MDBox mb={2}>
                    <div className="signup">
                    <MDInput
                      id={fi.name}
                      type={fi.type}
                      value={signupCredentials[fi.name]}
                      onChange={(e) => {
                        setSignupCredentials({
                          ...signupCredentials,
                          [fi.name]: e.target.value,
                        });
                      }}
                      InputProps={fi?.endorse}
                      label={fi.placeholder}
                      variant="standard"
                      fullWidth
                    />
                    </div>
                  </MDBox>
                </div>
              ))}
            </div>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton disabled={isSignupLoading} onClick={()=> signUpUser()}
              variant="gradient" color={themeColor} fullWidth>
                {isSignupLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "sign up"}                
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} display="flex" gap={2}>
              <Link to={ApiUrls.googleSignupUrl} className="w-full">
              <MDButton disabled={isGoogleSignupLoading} variant="gradient" color={themeColor} fullWidth className={'flex gap-[10px]'} sx={{ padding:"10px" }}>
                {isGoogleSignupLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : <><img src={googleIcon} alt="" />SIGN UP WITH GOOGLE</>}
              </MDButton>
              </Link>
              <Link to={ApiUrls.microsoftSignupUrl} className="w-full">
              <MDButton disabled={isMicrosoftSignupLoading} variant="gradient" color={themeColor} fullWidth className={'flex gap-[10px]'} sx={{ padding:"10px" }}>
                {isMicrosoftSignupLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : <><img src={microsoftIcon} alt="" className="h-[21px] w-[19px]"/>SIGN UP WITH MICROSOFT</>}
              </MDButton>
              </Link>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color={themeColor}
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;

