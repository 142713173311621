import { IS_LOGIN, IS_LOGOUT,SQL_TOOLS, IS_PERMISSION,ENABLE_INTERVEL } from "redux/Types";

const initialState = {
    isLoggedIn: false,
    user: undefined,
    permission: undefined,
    isSqlTool:false,
    enableIntervel:false
};

export const LoginReducer = (state = initialState, action) => {

    const { payload, type } = action;

    switch(type){

        case IS_LOGIN: {
            return {
                ...state,
                isLoggedIn: true,
                user: payload,
            };
        }

        case IS_LOGOUT: {
            return {
                ...initialState
            };
        }

        case IS_PERMISSION: {
            return {
                ...state,
                permission: payload,
            };
        }

        case SQL_TOOLS: { 
            return {
                ...state,
                isSqlTool: payload,
            };
        }
        case ENABLE_INTERVEL: { 
            console.log("reducer EnableIntervel",payload)
            return {
                ...state,
                enableIntervel: payload,
            };
        }


        default:
            return state;

    }

};
