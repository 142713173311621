import React, { useState, useRef, useEffect } from 'react';
import './Dropdown.css'; // Import CSS file for styling
import { useMaterialUIController } from "context";



const Dropdown = ({ options, onSelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span style={{marginBottom: "5px", color : darkMode ? "white" : "black"}} 
        className={`dropdown-arrow ${isOpen ? 'dropdown-arrow-open' : ''}`}>&#9662;</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu" style={{ color :"black" }}>
            {options.map((option, index) => (
                <div key={index} 
                className="dropdown-item" onClick={() => {onSelection( option ); setIsOpen(false)}}>
                {option?.title}
                </div>
             ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

