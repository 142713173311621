import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';
import { FaEdit, FaRocketchat, FaRegFileAlt, FaInfoCircle } from "react-icons/fa";
import { MdDelete, MdOutlineSettingsSuggest } from "react-icons/md";
import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import { SpinnerLoader } from "examples/Loader/Loader";
import { setChatbotHistoryUrl } from "context";
import JSONPretty from 'react-json-pretty';
import { Tag } from "examples/Tag/Tag";
import ApiUrls from "services/ApiUrls";
import { CopyToClipboard } from 'react-copy-to-clipboard';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
import { useMaterialUIController, setChatbotNavClose } from "context";
import { useMediaQuery } from '@mui/material';



export default function StickyHeadTable({ columns, widthhandle }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [agentList, setAgentList] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [modalInfoOpen, setModalInfoOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)');
  const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)');
  const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const statusCheck = (status) => {
    if (status === Constants.CONFIGURED) return "#f94449"
    if (status === Constants.DEPLOYED) return "black"
    if (status === Constants.MODIFIED) return "#c30010"
    if (status === Constants.TRAINING) return "rgb(123, 128, 154)"
    if (status === Constants.DEPLOYING) return "royalblue"
    if (status === Constants.READY) return "#43a047"
  };

  useEffect(() => {
    setChatbotHistoryUrl(dispatch, '')
    allAgentsList();
  }, []);

  const allAgentsList = () => {
    setIsLoader(true);
    ApiRequest.agentList('', '', (res) => {
      if (res.isSuccess) {
        setAgentList(res.data?.agentsList);
      } else {
        setAgentList([]);
      }
      setIsLoader(false);
    });
  };

  const deleteModal = () => {
    setDelLoader(true);

    ApiRequest.deleteXAgent({ patternId: modalDelOpen?.patternId }, modalDelOpen?.id, (res) => {
      setModalDelOpen({ flag: false, agentName: '', patternId: '', id: '' }); setDelLoader(false);
      allAgentsList();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const editAgent = (e, patternId, agentId, patternName, isChat, isChatXAgent) => {
    const isProUser = false;
    e.stopPropagation();
    var customPatternName = '';
    setChatbotNavClose(dispatch, true);

    console.log("patternName oo",patternName)

    if (patternName?.toLowerCase() === Constants.llm) customPatternName = 'llm';
    if (patternName === Constants.featureExtraction) customPatternName = 'feature-extraction';
    if (patternName === Constants.predictive) customPatternName = 'predictive-analysis';
    if (patternName === Constants.recommendation) customPatternName = 'recommendation-system';
    if (patternName === Constants.imageToText) customPatternName = 'image-to-text';
    if (patternName === Constants.speechToText) customPatternName = 'speech-to-text';
    if (patternName === Constants.textToSpeech) customPatternName = 'text-to-speech';

    ApiRequest.patternList(`/${patternId}`, '', (resAgent) => {
      if (resAgent.isSuccess) {
        ApiRequest.agentList(`/${agentId}`, '', (res) => {
          if (res.isSuccess) {
            const { agentsInformation } = res.data;

            navigate(`/patterns/${patternId}/${customPatternName}/agent-creation/${isChat === "chatbot" ? "infer/llm" : isChat === 'feature' ? "model" : isChat === 'predictive' ? "model" : "config"}`
              , {
                state: {
                  edit: true,
                  previousPath: location.pathname ?? '',
                  deployedURL: agentsInformation?.[0]?.agentURL?.length ? agentsInformation?.[0]?.agentURL : resAgent.data?.patternLearnMore?.[0]?.url,
                  id: agentsInformation?.[0]?.patternId, objectId: agentsInformation?.[0]?._id,
                  page: isChat === 'chatbot' ? 5 : isChat === 'feature' ? 3 : isChat === 'predictive' ? 3 : 1,
                  step: isChat === 'chatbot' ? 5 : isChat === 'feature' ? 1 : isChat === 'predictive' ? 1 : 0,
                  configForm: {
                    agentName: agentsInformation?.[0]?.agentName,
                    description: agentsInformation?.[0]?.description,
                    prePrompt: agentsInformation?.[0]?.configuration?.prePrompt,
                    modelName: agentsInformation?.[0]?.configuration?.modelName,
                    openAIKey: agentsInformation?.[0]?.configuration?.openAIKey,
                    temperature: agentsInformation?.[0]?.configuration?.temperature,
                    topP: agentsInformation?.[0]?.configuration?.topP,
                    frequencyPenalty: agentsInformation?.[0]?.configuration?.frequencyPenalty,
                    presencePenalty: agentsInformation?.[0]?.configuration?.presencePenalty,
                    maxTokens: agentsInformation?.[0]?.configuration?.maxTokens,
                    defaultPrompt: agentsInformation?.[0]?.configuration?.defaultPrompt,
                    modelType: agentsInformation?.[0]?.configuration?.modelType,
                    pageSegmentation: agentsInformation?.[0]?.configuration?.pageSegmentation,
                    systemPrompt: agentsInformation?.[0]?.configuration?.systemPrompt,
                    prompt: agentsInformation?.[0]?.configuration?.prompt,
                    language: agentsInformation?.[0]?.configuration?.language,
                    voiceName: agentsInformation?.[0]?.configuration?.voiceName
                  },
                  protect: {
                    detectPII: agentsInformation?.[0]?.detectPII, secrets: agentsInformation?.[0]?.secrets,
                    sensitiveTopics: agentsInformation?.[0]?.sensitiveTopics, promptInjection: agentsInformation?.[0]?.promptInjection,
                    onFailForInference: agentsInformation?.[0]?.onFailForInference, onFailForTraining: agentsInformation?.[0]?.onFailForTraining
                  },
                  // deployedURL: agentsInformation?.[0]?.agentURL,
                  status: agentsInformation?.[0]?.status,
                  isDeploying: false,
                  uploadedFile: agentsInformation?.[0]?.documentDetails,
                  isTrained: false,
                  isEditAgent: true,
                  isErrorConfig: false
                }
              });
          } else {
            toast(res.error?.message ? res.error?.message : "Something went wrong")
          }
        });
      } else {
        toast("Something went wrong")
      }
    });


  };
  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1D3E80";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };

  const modalInfoPop = () => {
    return (`
  
<iframe 
id="chat-iframe"
src=${`"${ApiUrls.frontendBaseUrl}/chatbot-widget/${modalInfoOpen?.id}"`}
title="Chatbot"
width="100%"
allow="clipboard-read; clipboard-write"
style="height: 100%; min-height: 520px"
frameborder="0"
></iframe>
      `)
  };
  const getTooltipBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  };
  console.log("agentList?.length", agentList?.length)

  return (
    <>
      <div style={{ height: "45px", width: '100%', display: 'flex', padding: '12px 16px', backgroundColor: getBackgroundColor(), borderRadius: '8px', alignItems: 'center' }}>
        <div
          style={{ border: "none", width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white" }}
        >
          X-Agent
        </div>
        <div
          style={{ border: "none", width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isSmalMobileStyling ? "111px" : ismdMobileStyling ? "62px" : isTablet ? "-11px" : "29px" }}
        >
          Pattern
        </div>
        {windowSize.innerWidth > 600 && <>
          <div
            style={{ width: "20%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "-3px" : "47px" }}
          >
            Status
          </div>
          <div
            style={{ width: "10%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "0px" : "21px" }}
          >
            Action
          </div>
        </>}
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
        {/* <div style={{ position: 'relative',width: '100%' }}> */}
        {/* Header */}

        {/* </div> */}

        {/* Table */}
        <TableContainer>
          <Table sx={{ marginTop: "15px" }}>

            {/* Body List */}
            <TableBody>
              {isLoader ?
                <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                  {[1, 2, 3].map(() => (
                    <div className="w-56 mr-8">
                      <Code backgroundColor="grey" foregroundColor="darkgrey" />
                    </div>
                  ))}
                </div>
                :
                agentList?.length ?
                  <>
                    {agentList
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, i) => {
                        return (
                          <>
                            <TableRow tabIndex={-1} style={{ backgroundColor: !darkMode ? i % 2 === 0 ? "white" : "#F1F1F1" : ""}}>
                              <TableCell key={i} align={"left"}
                                style={{ paddingLeft: isSmalMobileStyling ? "3px" : (ismdMobileStyling || isLargeMobile) ? "6px" : "", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[0]}%` }}>
                                {
                                  windowSize.innerWidth <= 600 &&
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => { setOpen(!open); setCollapseRow(i + 1) }}
                                  >
                                    {(open && (collapseRow === (i + 1))) ?
                                      <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                      : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                  </IconButton>
                                }
                                {row?.agentName}
                              </TableCell>
                              <TableCell key={i} align={"left"}
                                style={{ paddingLeft: isLargeMobile ? "9px" : "0px", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[1]}%` }}>
                                {row?.patternDetails?.[0]?.patternName}
                              </TableCell>

                              {windowSize.innerWidth > 600 &&
                                <>
                                  <TableCell key={i} align={"left"}
                                    style={{ paddingLeft: "0px", color: darkMode ? "white" : "black", fontSize: "14px", width: `${widthhandle[2]}%` }}>
                                    <div className="px-2 rounded-[2px] text-center text-white w-28"
                                      style={{ background: statusCheck(row?.status) }}>
                                      {row?.status.charAt(0) + row?.status.substring(1).toLowerCase()}
                                    </div>
                                  </TableCell>
                                  <TableCell key={i} align={"left"} style={{ paddingLeft: "0px" }}>
                                    <div className="flex flex-row" key={i} >
                                      <Tooltip title="Reconfigure X-Agent" arrow
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <FaEdit
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, '', false)}
                                            className="cursor-pointer"
                                          />
                                        </div>
                                      </Tooltip>

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName?.toLowerCase() === Constants.llm &&
                                        <Tooltip title="Go to chatbot" arrow
                                          slotProps={{
                                            tooltip: {
                                              sx: {
                                                color: "white",
                                                backgroundColor: getTooltipBackgroundColor(),
                                                fontSize: "10px",
                                                fontWeight: "400",
                                                '& .MuiTooltip-arrow': {
                                                  color: getTooltipBackgroundColor(),
                                                },

                                              },
                                            },
                                          }}
                                        >
                                          <div>
                                            <FaRocketchat
                                              style={{ color: darkMode ? "white" : "black" }}
                                              onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'chatbot', true)}
                                              className="ml-4 cursor-pointer"
                                            />
                                          </div>
                                        </Tooltip>
                                      }

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.predictive &&
                                        <MdOutlineSettingsSuggest
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'predictive', false)}
                                          className="ml-4 cursor-pointer"
                                        />
                                      }

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.featureExtraction &&
                                        <FaRegFileAlt
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                          className="ml-4 cursor-pointer"
                                        />
                                      }

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.recommendation &&
                                        <FaRegFileAlt
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                          className="ml-4 cursor-pointer"
                                        />
                                      }

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.imageToText &&
                                        <FaRegFileAlt
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                          className="ml-4 cursor-pointer"
                                        />
                                      }

                                      {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.textToSpeech &&
                                        <FaRegFileAlt
                                          style={{ color: darkMode ? "white" : "black" }}
                                          onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                          className="ml-4 cursor-pointer"
                                        />
                                      }
                                      <Tooltip title="Delete X-Agent" arrow

                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <MdDelete
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?._id }) }}
                                            className={`ml-4  cursor-pointer`}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </>
                              }

                            </TableRow >

                            {/* Extended Row */}
                            < TableRow >
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Table>
                                      <MDBox component="thead">
                                        <TableRow >
                                          <TableCell
                                            align={"left"}
                                            style={{ color: darkMode ? "white" : "black", width: "50%", fontWeight: "bold", paddingLeft: isLargeMobile ? "0px" : isSmalMobileStyling ? "4px" : ismdMobileStyling ? "6px" : "" }}
                                          >
                                            Status
                                          </TableCell>
                                          <TableCell
                                            align={"left"}
                                            style={{ color: darkMode ? "white" : "black", fontWeight: "bold", paddingLeft: isLargeMobile ? "0px" : "" }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </MDBox>
                                      <TableBody >
                                        <>
                                          <TableCell key={i} align={"left"}
                                            style={{ paddingLeft: "0px", color: darkMode ? "white" : "black", fontSize: "14px", width: `${widthhandle[2]}%` }}>
                                            <div className="px-2 rounded-[2px] text-center text-white w-28"
                                              style={{ background: statusCheck(row?.status) }}>
                                              {row?.status.charAt(0) + row?.status.substring(1).toLowerCase()}
                                            </div>
                                          </TableCell>
                                          <TableCell key={i} align={"left"} style={{ paddingLeft: isSmalMobileStyling ? "15px" : ismdMobileStyling ? "16px" : "0px" }}>
                                            <div className="flex flex-row" key={i} >
                                              <Tooltip title="Reconfigure X-Agent" arrow
                                                slotProps={{
                                                  tooltip: {
                                                    sx: {
                                                      color: "white",
                                                      backgroundColor: getTooltipBackgroundColor(),
                                                      fontSize: "10px",
                                                      fontWeight: "400",
                                                      '& .MuiTooltip-arrow': {
                                                        color: getTooltipBackgroundColor(),
                                                      },
                                                    },
                                                  },
                                                }}
                                              >
                                                <div>
                                                  <FaEdit
                                                    style={{ color: darkMode ? "white" : "black" }}
                                                    onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, '', false)}
                                                    className="cursor-pointer"
                                                  />
                                                </div>
                                              </Tooltip>

                                              {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName?.toLowerCase() === Constants.llm &&
                                                <Tooltip title="Go to chatbot" arrow
                                                  slotProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "white",
                                                        backgroundColor: getTooltipBackgroundColor(),
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                        '& .MuiTooltip-arrow': {
                                                          color: getTooltipBackgroundColor(),
                                                        },

                                                      },
                                                    },
                                                  }}
                                                >
                                                  <div>
                                                    <FaRocketchat
                                                      style={{ color: darkMode ? "white" : "black" }}
                                                      onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'chatbot', true)}
                                                      className="ml-4 cursor-pointer"
                                                    />
                                                  </div>
                                                </Tooltip>
                                              }

                                              {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.predictive &&
                                                <MdOutlineSettingsSuggest
                                                  style={{ color: darkMode ? "white" : "black" }}
                                                  onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'predictive', false)}
                                                  className="ml-4 cursor-pointer"
                                                />
                                              }

                                              {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.featureExtraction &&
                                                <FaRegFileAlt
                                                  style={{ color: darkMode ? "white" : "black" }}
                                                  onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                                  className="ml-4 cursor-pointer"
                                                />
                                              }

                                              {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.recommendation &&
                                                <FaRegFileAlt
                                                  style={{ color: darkMode ? "white" : "black" }}
                                                  onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                                  className="ml-4 cursor-pointer"
                                                />
                                              }

                                              {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.imageToText &&
                                                <FaRegFileAlt
                                                  style={{ color: darkMode ? "white" : "black" }}
                                                  onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                                  className="ml-4 cursor-pointer"
                                                />
                                              }

                                          {row?.status === Constants.READY && row?.patternDetails?.[0]?.patternName === Constants.textToSpeech &&
                                            <FaRegFileAlt
                                              style={{ color: darkMode ? "white" : "black" }}
                                              onClick={(e) => editAgent(e, row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, 'feature', false)}
                                              className="ml-4 cursor-pointer"
                                            />
                                          }
                                              <Tooltip title="Delete X-Agent" arrow

                                                slotProps={{
                                                  tooltip: {
                                                    sx: {
                                                      color: "white",
                                                      backgroundColor: getTooltipBackgroundColor(),
                                                      fontSize: "10px",
                                                      fontWeight: "400",
                                                      '& .MuiTooltip-arrow': {
                                                        color: getTooltipBackgroundColor(),
                                                      },
                                                    },
                                                  },
                                                }}
                                              >
                                                <div>
                                                  <MdDelete
                                                    style={{ color: darkMode ? "white" : "black" }}
                                                    onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.agentName, patternId: row?.patternId, id: row?._id }) }}
                                                    className={`ml-4  cursor-pointer`}
                                                  />
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </TableCell>
                                        </>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        )
                      })}
                  </>
                  :
                  <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No X-Agent found</div>}
              
            </TableBody>
          </Table>
        </TableContainer >

        {/* Pagination */}
        < TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={agentList?.length}
          style={{ color: darkMode ? "white" : "black" }}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-actions': {
              color: darkMode ? 'white' : 'black',
            }
          }
          }
        />

        {
          modalDelOpen?.flag &&
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, agentName: '', patternId: '', id: '' })}>
            <div className="flex flex-row w-full justify-center items-center text-xl mb-4" >
              Are you sure you want to delete? Deleted X-Agent will be lost
            </div >

            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "140px" }} disabled={false}
                onClick={() => setModalDelOpen({ flag: false, agentName: '', patternId: '', id: '' })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "140px" }} disabled={delLoader}
                onClick={() => deleteModal()}
                variant="gradient" color={themeColor} fullWidth>
                {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
              </MDButton>
            </MDBox>
          </Modal>
        }

        {
          modalInfoOpen?.flag &&
          <Modal open={modalInfoOpen?.flag} setOpen={(fl) => setModalInfoOpen({ flag: fl, agentName: '', patternId: '', id: '' })}>
            <div className=" mb-4" >

              <div style={{ fontSize: "18px" }}>To add the chatbot any where on your website, please add this iframe to your html code</div>



              <div className="mt-8 mb-4">
                <JSONPretty style={{ fontSize: "15px" }} id="json-pretty"
                  theme={JSONPrettyMon}
                  data={modalInfoPop()}></JSONPretty>
              </div>

              <div className="w-full flex justify-center">
                <CopyToClipboard text={modalInfoPop()} onCopy={() => toast.success("Code has been copied!")}>
                  <Tag
                    name={"Copy To Clipboard"}
                    onClick={(e) => { e?.stopPropagation() }}
                    onDelete={(e) => console.log("delete")}
                    className="mt-4"
                    isLogo={true}
                  />
                </CopyToClipboard>
              </div>

            </div >


          </Modal>
        }
      </Paper >
    </>
  );
}
