/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Dashboard components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScreenWidth from "libraries/ScreenSizeHook";
import { Card, Grid, useMediaQuery } from "@mui/material";
import { useMaterialUIController } from "context";
import { Images } from "libraries/Images";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ApiRequest from "services/ApiRequest";
import { Paths } from "libraries/Route";
import Methods from "libraries/CommonMethodsUI";
import { NumericFormat } from 'react-number-format';

//images
import { ReactComponent as WorkForceIcon } from "assets/images/flow-icons/workforce-icon.svg"
import customerServiceImg from "assets/images/flow-icons/customer-service.svg"
import officeAdminSupportImg from "assets/images/flow-icons/admin-support.svg"
import salesImg from "assets/images/flow-icons/sales.svg"
import iTSupportImg from "assets/images/flow-icons/it-services.svg"
import customerServiceGreenThemeImg from "assets/images/flow-icons/customer-service-green.svg"
import officeAdminSupportGreenThemeImg from "assets/images/flow-icons/admin-support-green.svg"
import salesGreenThemeImg from "assets/images/flow-icons/sales-green.svg"
import iTSupportGreenThemeImg from "assets/images/flow-icons/it-services-green.svg"
import ExpandableMenu from "./ExpandableMenu";
import { menuOptions } from "./menuOptions";

import Modal from "examples/Modal/Modal";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


function EstimatedSavings() {

    const [windowSize] = useScreenWidth();
    const [controller] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const navigate = useNavigate();
    const [data, setData] = useState({
        operationTeams: null,
        businessImpact: null,
        customerServices: null,
        adminSupport: null,
        sales: null,
        iTServices: null,
        steadySaving: null,
        saving1Year: null,
        saving2Year: null,
        saving3Year: null,
        industryName: ""
    });
    const [selectedYear, setSelectedYear] = useState('Savings Year 1');
    const [selectedService, setSelectedService] = useState(menuOptions.CustomerService)
    const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
    const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
    const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
    const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
    const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)')
    const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)')
    const UpgradeFeature = [
        { title: 'Uncover Hidden Savings', description: 'Discover cost-cutting opportunities in crucial areas.' },
        { title: 'See the Impact', description: 'Visualize the transformative effects of strategic changes.' },
        { title: 'Get Actionable Recommendations', description: 'Optimize your workforce with expert advice.' },
        { title: 'Detailed Reports', description: 'Access comprehensive insights and propel your growth to new heights.' },
      ];

    const permissionUser = useSelector((state) => state?.loginReducer?.permission?.strategicWorkflowPermission?.permission === 1);
    // const permissionUser = JSON?.parse(localStorage.getItem("membershipPlan"))?.permission?.strategicWorkflowPermission?.permission === 1

    const [modalPermission, setModalPermission] = useState({ flag: permissionUser })

    const estimatedPotentialSavings = [
        { title: 'Industry opportunity size', type: 'text' },
        { title: 'Headcount', type: 'text' },
        { title: 'Potential headcount ', type: 'text' },
        { title: 'Average salary', type: 'text' },
        { title: 'Annual saving potential', type: 'text' },
        { title: 'Improvement in Net Profit', type: 'text' },
        { title: `Expected Costs Expected Year 3 implementation cost`, type: 'heading' },
        { title: 'Total Implementation Cost', type: 'heading' },
        { title: 'Percentage of Saving', type: 'text' }
    ];
    const estimatedValues = [
        { value: isFinite(data?.operationTeams?.industryOpportunitySize) ? `${data?.operationTeams?.industryOpportunitySize}%` : "-", label: 'Industry opportunity size' },
        { value: isFinite(data?.operationTeams?.startingHeadcount) ? `${data?.operationTeams?.startingHeadcount}` : "-", label: 'Starting Headcount' },
        { value: isFinite(data?.operationTeams?.potentialHeadcount) ? `${data?.operationTeams?.potentialHeadcount}` : "-", label: 'Potential Headcount' },
        { value: isFinite(data?.operationTeams?.avgSalary) ? `${data?.operationTeams?.avgSalary}` : "-", label: 'Average Salary' },
        { value: isFinite(data?.operationTeams?.annualSavingPotential) ? `${data?.operationTeams?.annualSavingPotential}` : "", label: 'Annual Saving Potential' },
        { value: isFinite(data?.operationTeams?.improvementNetProfit) ? `${data?.operationTeams?.improvementNetProfit}%` : "-", label: 'Improvement in Net Profit' },
        { value: "-", type: 'heading' },
        { value: "-", type: 'text' },
        { value: "-", type: 'text' }
    ]
    const customerServices = [
        { value: isFinite(data?.customerServices?.industryOpportunitySize) ? `${data?.customerServices?.industryOpportunitySize}%` : "-", label: 'Industry opportunity size' },
        { value: isFinite(data?.customerServices?.startingHeadcount) ? `${data?.customerServices?.startingHeadcount}` : "-", label: 'Starting Headcount' },
        { value: isFinite(data?.customerServices?.potentialHeadcount) ? `${data?.customerServices?.potentialHeadcount}` : "-", label: 'Potential Headcount' },
        { value: isFinite(data?.customerServices?.avgSalary) ? `${data?.customerServices?.avgSalary}` : "-", label: 'Average Salary' },
        { value: isFinite(data?.customerServices?.annualSavingPotential) ? `${data?.customerServices?.annualSavingPotential}` : "-", label: 'Annual Saving Potential' },
        { value: isFinite(data?.customerServices?.improvementNetProfit) ? `${data?.customerServices?.improvementNetProfit}%` : "-", label: 'Improvement in Net Profit' },
        { value: "-", type: 'heading' },
        { value: "-", type: 'text' },
        { value: "-", type: 'text' }
    ]
    const adminSupport = [
        { value: isFinite(data?.adminSupport?.industryOpportunitySize) ? `${data?.adminSupport?.industryOpportunitySize}%` : "-", label: 'Industry opportunity size' },
        { value: isFinite(data?.adminSupport?.startingHeadcount) ? `${data?.adminSupport?.startingHeadcount}` : "-", label: 'Starting Headcount' },
        { value: isFinite(data?.adminSupport?.potentialHeadcount) ? `${data?.adminSupport?.potentialHeadcount}` : "-", label: 'Potential Headcount' },
        { value: isFinite(data?.adminSupport?.avgSalary) ? `${data?.adminSupport?.avgSalary}` : "-", label: 'Average Salary' },
        { value: isFinite(data?.adminSupport?.annualSavingPotential) ? `${data?.adminSupport?.annualSavingPotential}` : "-", label: 'Annual Saving Potential' },
        { value: isFinite(data?.adminSupport?.improvementNetProfit) ? `${data?.adminSupport?.improvementNetProfit}%` : "-", label: 'Improvement in Net Profit' },
        { value: "-", type: 'heading' },
        { value: "-", type: 'text' },
        { value: "-", type: 'text' }
    ]
    const sales = [
        { value: isFinite(data?.sales?.industryOpportunitySize) ? `${data?.sales?.industryOpportunitySize}%` : "-", label: 'Industry opportunity size' },
        { value: isFinite(data?.sales?.startingHeadcount) ? `${data?.sales?.startingHeadcount}` : "-", label: 'Starting Headcount' },
        { value: isFinite(data?.sales?.potentialHeadcount) ? `${data?.sales?.potentialHeadcount}` : "-", label: 'Potential Headcount' },
        { value: isFinite(data?.sales?.avgSalary) ? `${data?.sales?.avgSalary}` : "-", label: 'Average Salary' },
        { value: isFinite(data?.sales?.annualSavingPotential) ? `${data?.sales?.annualSavingPotential}` : "-", label: 'Annual Saving Potential' },
        { value: isFinite(data?.sales?.improvementNetProfit) ? `${data?.sales?.improvementNetProfit}%` : "-", label: 'Improvement in Net Profit' },
        { value: "-", type: 'heading' },
        { value: "-", type: 'text' },
        { value: "-", type: 'text' }
    ]
    const iTServices = [
        { value: isFinite(data?.iTServices?.industryOpportunitySize) ? `${data?.iTServices?.industryOpportunitySize}%` : "-", label: 'Industry opportunity size' },
        { value: isFinite(data?.iTServices?.startingHeadcount) ? `${data?.iTServices?.startingHeadcount}` : "-", label: 'Starting Headcount' },
        { value: isFinite(data?.iTServices?.potentialHeadcount) ? `${data?.iTServices?.potentialHeadcount}` : "-", label: 'Potential Headcount' },
        { value: isFinite(data?.iTServices?.avgSalary) ? `${data?.iTServices?.avgSalary}` : "-", label: 'Average Salary' },
        { value: isFinite(data?.iTServices?.annualSavingPotential) ? `${data?.iTServices?.annualSavingPotential}` : "-", label: 'Annual Saving Potential' },
        { value: isFinite(data?.iTServices?.improvementNetProfit) ? `${data?.iTServices?.improvementNetProfit}%` : "-", label: 'Improvement in Net Profit' },
        { value: "-", type: 'heading' },
        { value: "-", type: 'text' },
        { value: "-", type: 'text' }
    ]
    const steadySavingSatate = [
        { value: "-" },
        { value: data?.steadySaving?.startingHeadcount, label: 'Starting Headcount' },
        { value: data?.steadySaving?.potentialHeadcount, label: 'Potential Headcount' },
        { value: "-", label: 'Average Salary' },
        { value: data?.steadySaving?.annualSavingPotential, label: 'Annual Saving Potential' },
        { value: `${data?.steadySaving?.improvementNetProfit}%`, label: 'Improvement in Net Profit' },
        { value: data?.steadySaving?.itAIMLCosts, type: 'heading' },
        { value: data?.steadySaving?.final, type: 'text' },
        { value: `${data?.steadySaving?.savingPercentage}%`, type: 'text', label: "Percentage of saving" }
    ]
    const savingsYear1 = [
        { value: "-" },
        { value: data?.saving1Year?.startingHeadcount, label: 'Starting Headcount' },
        { value: data?.saving1Year?.potentialHeadcount, label: 'Potential Headcount' },
        { value: "-", label: 'Average Salary' },
        { value: data?.saving1Year?.annualSavingPotential, label: 'Annual Saving Potential' },
        { value: `${data?.saving1Year?.improvementNetProfit}%`, label: 'Improvement in Net Profit' },
        { value: data?.saving1Year?.implementationCostYear1, type: 'heading' },
        { value: data?.saving1Year?.final, type: 'text' },
        { value: `${data?.saving1Year?.savingPercentage}%`, type: 'text', label: "Percentage of saving" }
    ]
    const savingsYear2 = [
        { value: "-" },
        { value: data?.saving2Year?.startingHeadcount, label: 'Starting Headcount' },
        { value: data?.saving2Year?.potentialHeadcount, label: 'Potential Headcount' },
        { value: "-", label: 'Average Salary' },
        { value: data?.saving2Year?.annualSavingPotential, label: 'Annual Saving Potential' },
        { value: `${data?.saving2Year?.improvementNetProfit}%`, label: 'Improvement in Net Profit' },
        { value: data?.saving2Year?.implementationCostYear2, type: 'heading' },
        { value: data?.saving2Year?.final, type: 'text' },
        { value: `${data?.saving2Year?.savingPercentage}%`, type: 'text', label: "Percentage of saving" }
    ]
    const savingsYear3 = [
        { value: "-", },
        { value: data?.saving3Year?.startingHeadcount, label: 'Starting Headcount' },
        { value: data?.saving3Year?.potentialHeadcount, label: 'Potential Headcount' },
        { value: "-", label: 'Average Salary' },
        { value: data?.saving3Year?.annualSavingPotential, label: 'Annual Saving Potential' },
        { value: `${data?.saving3Year?.improvementNetProfit}%`, label: 'Improvement in Net Profit' },
        { value: data?.saving3Year?.implementationCostYear3, type: 'heading' },
        { value: data?.saving3Year?.final, type: 'text' },
        { value: `${data?.saving3Year?.savingPercentage}%`, type: 'text', label: "Percentage of saving" }
    ]
    const businessImpact = [
        { title: "Business Impact", value: "Financial & Operating Improvement" },
        { title: "New Headcount", value: data?.businessImpact?.newHeadcount },
        { title: "New Profit Impact", value: data?.businessImpact?.netProfitImpact },
        { title: "New Profit", value: data?.businessImpact?.newProfit },
        { title: "PE Ratio", value: data?.businessImpact?.peRatio },
        { title: "New Valuation", value: data?.businessImpact?.newValuation },
    ];
    useEffect(() => {
        getEstimatedValues()
    }, [])
    const getBackgroundColor = () => {
        switch (themeColor) {
            case "info":
                return "#1E4585";
            case "success":
                return "#5DAA45";
            default:
                return themeColor;
        }
    };

    const getEstimatedValues = () => {

        ApiRequest.estimatedCostValues('', (res) => {
            if (res?.isSuccess) {
                console.log(res?.data)
                const getValues = res?.data?.results
                const getSupportFunctionValue = res?.data?.results?.supportFunctions
                setData({
                    operationTeams: getValues?.operationsTeam,
                    businessImpact: getValues?.businessImpact,
                    customerServices: getSupportFunctionValue?.customerService,
                    adminSupport: getSupportFunctionValue?.officeAdminSupport,
                    sales: getSupportFunctionValue?.sales,
                    iTServices: getSupportFunctionValue?.itServices,
                    steadySaving: getValues?.savingsSteadyState,
                    saving1Year: getValues?.savingsYear1,
                    saving2Year: getValues?.savingsYear2,
                    saving3Year: getValues?.savingsYear3,
                    industryName: getValues?.industryName

                });

            } else {
                console.log("error")
            }
        });

    }
    const handleMenuSelect = (year) => {
        setSelectedYear(year);

    };

    const convertToCSV = (dataSet) => {
        const rows = [];
        const industryName = dataSet.industryName;
        const estimatedPotentialSavingsTitles = estimatedPotentialSavings ? [' EstimatedPotentialSavings', ...Object.values(estimatedPotentialSavings).map(value => value.title || "-")] : [];
        const operationTeamsTitles = estimatedValues ? [` Operation Teams :${industryName}`, ...Object.values(estimatedValues).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const businessImpactTitles = businessImpact ? [' Business Impact', ...Object.values(businessImpact).map(value => `${value.title} : ${(value.value || value.value === 0 && value.value)}` || "-")] : [];
        const customerServicesTitles = customerServices ? [' CustomerServices', ...Object.values(customerServices).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const adminSupportTitles = adminSupport ? [' AdminSupport', ...Object.values(adminSupport).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const salesTitles = sales ? [' Sales', ...Object.values(sales).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const iTServicesTitles = iTServices ? [' ITServices', ...Object.values(iTServices).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const steadySavingStateTitles = steadySavingSatate ? [' SteadySavingSatate', ...Object.values(steadySavingSatate).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const savingsYear1Titles = savingsYear1 ? [' SavingsYear1', ...Object.values(savingsYear1).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const savingsYear2Titles = savingsYear2 ? [' SavingsYear2', ...Object.values(savingsYear2).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const savingsYear3Titles = savingsYear3 ? [' SavingsYear3', ...Object.values(savingsYear3).map(value => (value.value || value.value === 0 && value.value) || "-")] : [];
        const allTitles = [
            estimatedPotentialSavingsTitles,
            operationTeamsTitles,
            customerServicesTitles,
            adminSupportTitles,
            salesTitles,
            iTServicesTitles,
            steadySavingStateTitles,
            savingsYear1Titles,
            savingsYear2Titles,
            savingsYear3Titles,
            businessImpactTitles
        ].filter(title => title.length > 0);
        const maxLength = Math.max(...allTitles.map(arr => arr.length));
        for (let i = 0; i < maxLength; i++) {
            const row = allTitles.map(arr => arr[i] || '');
            rows.push(row.join(','));
        }
        return rows.join('\n');
    };

    const handleDownloadClick = () => {
        const csvData = convertToCSV(data);
        Methods.downloadCSV(csvData, 'estimated-savings.csv');
    };

    const formatValue = (value, label) => {
        const isPercentage = label === 'Industry opportunity size' || label === 'Improvement in Net Profit' || label === "Percentage of saving"
        console.log(isPercentage, "===")
        if (isPercentage) {
            return (
                <NumericFormat
                    value={value}
                    displayType="text"
                    thousandSeparator={true}
                    suffix="%"
                />
            );
        }
        if (typeof value === 'number' || 'text') {
            return (
                <NumericFormat
                    value={value}
                    displayType="text"
                    thousandSeparator={true}
                />
            );
        }
        return value;
    };
    return (
        <div style={{
            backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundPosition: "100% 100%",
            overflowY: "auto",
            height: "100vh"
        }}>
            <DashboardLayout>
                <DashboardNavbar />
                <Grid container spacing={2} style={{ paddingTop: "30px", overflowY: "auto", height: "calc(100vh - 110px)" }}>
                    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 10px", width: "100%" }}>
                        <Card style={{ width: "100%" }}>
                            <MDBox pt={1} pb={2} px={1} style={{ minHeight: "50vh" }}>
                                <MDBox>
                                    <div className={`flex justify-between ${isTablet ? "px-[10px]" : isLargeMobile ? "pl-[10px] pr-[21px]" : isSmallMobile ? "pl-[10px] pr-[6px]" : "pl-[10px] pr-[13px]"} `}>
                                        <div className="flex gap-[20px]">
                                            <div className="h-[58px] w-[57px] rounded-[9px] mt-[-26px] flex justify-center items-center" style={{ backgroundColor: getBackgroundColor() }}>
                                                <WorkForceIcon />
                                            </div>
                                            <MDTypography sx={{ display: "flex", alignItems: "center", fontSize: isLargeMobile || isSmallMobile || isTablet ? "12px" : "16px", fontWeight: "600" }}>Workforce Strategy</MDTypography>

                                        </div>
                                        <div>
                                            <MDButton size={isLargeMobile || isSmallMobile || isTablet ? "small" : ""} sx={{ fontSize: isLargeMobile || isSmallMobile ? "7px" : isTablet ? "9px" : "12px" }} variant="gradient" color={themeColor} onClick={handleDownloadClick}> Download CSV File </MDButton>
                                        </div>
                                    </div>
                                </MDBox>
                                <MDBox sx={{ paddingTop: "40px", display: "flex" }} >
                                    <div className={`${(isSmallMobile || isLargeMobile) ? "w-[30%]" : isTablet ? "w-[18%]" : "w-[20%]"}`}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`flex rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[55px]" : "h-[84px]"}  ml-[10px] mr-[10px] -mt-[30px] justify-center`} style={{ backgroundColor: getBackgroundColor() }}>
                                                <MDTypography style={{ color: "#FFFFFF", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "11px" : "12px", fontWeight: "600", display: "flex", textAlign: "center", alignItems: "center" }}>
                                                    Estimate of <br /> Potential Savings
                                                </MDTypography>
                                            </div>
                                            <div className="flex flex-col pt-[20px]">
                                                {estimatedPotentialSavings?.map((item, index) => (
                                                    <MDBox key={index} sx={{
                                                        height: item.title === 'Expected Costs Expected Year 3 implementation cost' ? '80px' : '45px',
                                                        display: "flex", alignItems: "center"
                                                    }}>
                                                        {item.title === 'Expected Costs Expected Year 3 implementation cost' ? (
                                                            <MDTypography
                                                                style={{ color: darkMode ? "white" : "black" }}
                                                                sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", paddingLeft: "7px", paddingRight: "7px" }}
                                                            >
                                                                <span style={{ fontWeight: '600' }}>Expected Costs</span><br />
                                                                Expected Year 3<br />
                                                                implementation cost
                                                            </MDTypography>
                                                        ) : (
                                                            <MDTypography
                                                                style={{ color: darkMode ? "white" : "black", fontWeight: item.type === 'heading' ? '600' : '400' }}
                                                                sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", paddingLeft: "7px", paddingRight: "7px" }}
                                                            >
                                                                {item.title}
                                                            </MDTypography>
                                                        )}
                                                    </MDBox>
                                                ))}
                                            </div>
                                        </Card>
                                    </div>
                                    <div className={`${(isSmallMobile || isLargeMobile) ? "w-[32%]" : isTablet ? "w-[20%]" : "w-[15%]"} ${isSmallMobile ? "pl-[5px]" : "pl-[10px]"}`}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`flex flex-col rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[55px]" : "h-[84px]"} ml-[5px] mr-[5px] -mt-[30px] justify-center `} style={{ backgroundColor: getBackgroundColor() }}>
                                                <div className="px-[5px]">
                                                    <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : isMdLoptop ? "11px" : "12px", fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        Operations teams
                                                    </MDTypography>

                                                    <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "7px" : isSmallMobile ? "6px" : "10px", fontWeight: "400", display: "flex", alignItems: "center", textAlign: "center", paddingLeft: isTablet ? "2px" : isLargeMobile ? "1px" : "", justifyContent: "center" }}>
                                                        {data?.industryName}
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className="flex flex-col pt-[20px] ">
                                                {estimatedValues?.map((item, index) => (
                                                    <MDBox key={index} sx={{ height: item.type === "heading" ? "80px" : "45px", display: "flex", alignItems: "center", justifyContent: "center", borderBottom: index < estimatedValues.length - 1 ? "1px solid gray" : "none" }}>
                                                        <MDTypography style={{ color: darkMode ? "white" : "black" }} sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "700", paddingLeft: "7px", paddingRight: "7px" }} >
                                                            {formatValue(item.value, item?.label)}

                                                        </MDTypography>
                                                    </MDBox>
                                                ))}
                                            </div>
                                        </Card>
                                    </div>
                                    <div className={`${isSmallMobile ? "w-[38%]" : isLargeMobile ? "w-[35%]" : "w-[45%]"} ${ismdMobileStyling ? "pl-[5px]" : isSmalMobileStyling ? "pl-[5px]" : "pl-[10px]"} `}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[55px]" : "h-[84px]"} ml-[10px] mr-[10px] -mt-[30px] ${isSmalMobileStyling ? "p-[0px]" : "p-[5px]"}  `} style={{ backgroundColor: getBackgroundColor() }}>
                                                {!(isTablet || isLargeMobile || isSmallMobile) && (
                                                    <MDTypography
                                                        style={{ color: "white", fontSize: isMdLoptop ? "11px" : "12px", fontWeight: "600", display: "flex", justifyContent: "center" }}
                                                    >
                                                        Support Function
                                                    </MDTypography>
                                                )}
                                                <div className={`flex pt-[10px] ${isSmalMobileStyling ? "p-[0px]" : "p-[5px] "} ${isSmalMobileStyling && "pt-[13px]"}`}>
                                                    {((selectedService === menuOptions.CustomerService) || (!isLargeMobile && !isSmallMobile)) && (
                                                        <div className={`flex flex-col gap-[2px] items-center ${isTablet ? "w-[122px]" : "w-[110px]"} `} >

                                                            <div className={`flex`}>
                                                                <div className={`flex flex-col items-center ${ismdMobileStyling && "pl-[5px]"} ${isSmalMobileStyling && "pl-[3px]"}`}>
                                                                    <img src={themeColor === "success" ? customerServiceGreenThemeImg : customerServiceImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : "w-[20px] h-[20px]"} `} />
                                                                    <MDTypography style={{
                                                                        width: isTablet ? "92px" : isSmalMobileStyling ? "59px" : isLargeMobile ? "76px" : ismdMobileStyling ? "60px" : "", color: "white",
                                                                        fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400", display: "flex",
                                                                    }}>
                                                                        Customer Service

                                                                    </MDTypography>
                                                                </div>
                                                                <div className="pt-[1px]">
                                                                    <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.CustomerService} setSelectedService={setSelectedService} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}
                                                    <div className={`flex flex-col  gap-[2px] items-center ${isTablet && "w-[130px]"} ${isMdLoptop ? "w-[144px]" : "w-[140px]"} `} >
                                                        {((selectedService === menuOptions.OfficeAndAdmin) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <div className={`flex ${isLargeMobile && "pl-[8px]"}`}>
                                                                <div className={`flex flex-col items-center ${(ismdMobileStyling || isSmalMobileStyling) && "pl-[7px]"}`}>
                                                                    <img src={themeColor === "success" ? officeAdminSupportGreenThemeImg : officeAdminSupportImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : "w-[20px] h-[20px]"} `} />
                                                                    <MDTypography style={{ width: isSmalMobileStyling ? "52px" : isLargeMobile ? "67px" : ismdMobileStyling ? "60px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                        Office & Admin
                                                                    </MDTypography>
                                                                </div>
                                                                <div className="pt-[1px]">
                                                                    <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.OfficeAndAdmin} setSelectedService={setSelectedService} />
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={`flex flex-col gap-[2px] items-center w-[100px] `} >
                                                        {((selectedService === menuOptions.Sales) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <div className={`flex ${isLargeMobile && "pl-[22px]"}`}>
                                                                <div className={`flex flex-col ${ismdMobileStyling && "pl-[12px]"} ${isSmalMobileStyling && "pl-[16px] mt-[3px]"}`}>

                                                                    <img src={themeColor === "success" ? salesGreenThemeImg : salesImg} alt="" className={`ml-[4px] ${isLargeMobile && "ml-[4px]"} ${(isLargeMobile || isSmallMobile) && "ml-[2px]"} ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : "w-[20px] h-[20px]"} `} />
                                                                    <MDTypography style={{ width: (isSmalMobileStyling || ismdMobileStyling) ? "29px" : isLargeMobile ? "35px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                        Sales
                                                                    </MDTypography>
                                                                </div>
                                                                <div className="pt-[1px]">
                                                                    <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.Sales} setSelectedService={setSelectedService} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col gap-[2px] items-center w-[100px]" >
                                                        {((selectedService === menuOptions.ItServices) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <div className={`flex ${isLargeMobile && "pl-[16px]"}`}>
                                                                <div className={` flex flex-col items-center ${ismdMobileStyling && "pl-[12px]"} ${isSmalMobileStyling && "pl-[6px]"} `}>

                                                                    <img src={themeColor === "success" ? iTSupportGreenThemeImg : iTSupportImg} alt="" className={` ${(isTablet || isSmallMobile || isLargeMobile) ? "w-[12px] h-[12px]" : "w-[20px] h-[20px]"} `} />
                                                                    <MDTypography style={{ width: isSmallMobile ? "43px" : isLargeMobile ? "54px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                        IT Services
                                                                    </MDTypography>
                                                                </div>
                                                                <div className="pt-[1px]">
                                                                    <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.ItServices} setSelectedService={setSelectedService} />
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                    {
                                                        (isLargeMobile || isSmallMobile) && <>
                                                            <div className="flex flex-col gap-[2px] items-center w-[80px]" >
                                                                {((selectedService === menuOptions.SavingsSteadyState)) && (
                                                                    <div className="flex">
                                                                        <div className={`flex ${(isSmalMobileStyling || ismdMobileStyling) && "items-center text-center"}`}>
                                                                            <MDTypography style={{ width: isSmalMobileStyling ? "51px" : (isSmallMobile || isLargeMobile) ? "60px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400", display: isLargeMobile && "flex", textAlign: isLargeMobile && "center" }}>
                                                                                Savings<br /> Steady State
                                                                            </MDTypography>
                                                                        </div>
                                                                        <div className="pt-[1px]">
                                                                            <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.SavingsSteadyState} setSelectedService={setSelectedService} />
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col gap-[2px] items-center w-[80px]" >
                                                                {selectedService === menuOptions.Saving1Year && (
                                                                    <div className="flex">
                                                                        <div className="flex items-center">
                                                                            <MDTypography style={{ width: isSmalMobileStyling ? "50px" : ismdMobileStyling ? "53px" : isLargeMobile ? "67px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                                Savings Year 1
                                                                            </MDTypography>
                                                                        </div>
                                                                        <div>
                                                                            <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.Saving1Year} setSelectedService={setSelectedService} />
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col gap-[2px] items-center w-[80px]" >
                                                                {selectedService === menuOptions.Saving2Year && (
                                                                    <div className="flex">
                                                                        <div className="flex items-center">

                                                                            <MDTypography style={{ width: isSmalMobileStyling ? "50px" : ismdMobileStyling ? "53px" : isLargeMobile ? "67px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                                Savings Year 2
                                                                            </MDTypography>
                                                                        </div>
                                                                        <div>
                                                                            <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.Saving2Year} setSelectedService={setSelectedService} />
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col gap-[2px] items-center w-[80px]" >
                                                                {selectedService === menuOptions.Saving3Year && (
                                                                    <div className="flex">
                                                                        <div className="flex items-center">
                                                                            <MDTypography style={{ width: isSmalMobileStyling ? "50px" : ismdMobileStyling ? "53px" : isLargeMobile ? "67px" : "", color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "11px", fontWeight: "400" }}>
                                                                                Savings Year 3
                                                                            </MDTypography>
                                                                        </div>
                                                                        <div>
                                                                            <ExpandableMenu options={Object.values(menuOptions)} selectedService={selectedService} currentService={menuOptions.Saving3Year} setSelectedService={setSelectedService} />
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </div></>
                                                    }
                                                </div>
                                            </div>

                                            <div className={`flex flex-col pt-[20px] ${isTablet && "pl-[17px] pr-[20px]"} ${isMdLoptop && "pl-[10px] pr-[20px]"} px-[20px] `}>

                                                {customerServices?.map((item, index) => (

                                                    <div
                                                        key={index}
                                                        className={`flex items-center`}
                                                        style={{ borderBottom: index < customerServices.length - 1 ? "1px solid gray" : "none", height: item.type === "heading" ? "80px" : "45px", }}
                                                    >
                                                        {/* Customer Service */}
                                                        {((selectedService === menuOptions.CustomerService) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <MDBox
                                                                sx={{
                                                                    height: item.type === "heading" ? "80px" : "45px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    width: "110px",
                                                                }}
                                                            >
                                                                <MDTypography
                                                                    style={{
                                                                        color: darkMode ? "white" : "black", display: isTablet ? "flex" : "block",
                                                                        justifyContent: isTablet ? "center" : "initial",
                                                                        width: isTablet ? "89px" : "auto"
                                                                    }}
                                                                    sx={{ fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "700", paddingLeft: isTablet ? "0px" : "7px" }}
                                                                >
                                                                    {formatValue(item.value, item?.label)}
                                                                </MDTypography>
                                                            </MDBox>
                                                        )}

                                                        {/* Customer Service */}

                                                        {/* Office & admin */}
                                                        {((selectedService === menuOptions.OfficeAndAdmin) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <MDBox
                                                                sx={{
                                                                    height: item.type === "heading" ? "80px" : "45px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    width: "140px",

                                                                }}
                                                            >
                                                                <MDTypography
                                                                    style={{
                                                                        color: darkMode ? "white" : "black", display: isTablet ? "flex" : "block",
                                                                        justifyContent: isTablet ? "center" : "initial",
                                                                        width: isTablet ? "80px" : "auto"
                                                                    }}
                                                                    sx={{ fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "700", paddingLeft: isTablet ? "0px" : "7px" }}
                                                                >
                                                                    {formatValue(adminSupport[index]?.value, adminSupport[index]?.label)}
                                                                </MDTypography>
                                                            </MDBox>
                                                        )}

                                                        {/* Office & admin */}

                                                        {/* Sales */}
                                                        {((selectedService === menuOptions.Sales) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <MDBox
                                                                sx={{
                                                                    height: item.type === "heading" ? "80px" : "45px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    width: "100px",

                                                                }}
                                                            >
                                                                <MDTypography
                                                                    style={{
                                                                        color: darkMode ? "white" : "black", display: isTablet ? "flex" : "block",
                                                                        justifyContent: isTablet ? "center" : "initial",
                                                                        width: isTablet ? "41px" : "auto"
                                                                    }}
                                                                    sx={{ fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "700" }}
                                                                >
                                                                    {formatValue(sales[index]?.value, sales[index]?.label)}
                                                                </MDTypography>
                                                            </MDBox>
                                                        )}

                                                        {/* Sales */}

                                                        {/* IT services */}
                                                        {((selectedService === menuOptions.ItServices) || (!isLargeMobile && !isSmallMobile)) && (
                                                            <MDBox
                                                                sx={{
                                                                    height: item.type === "heading" ? "80px" : "45px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    textAlign: "center",
                                                                    width: "100px",

                                                                }}
                                                            >
                                                                <MDTypography
                                                                    style={{
                                                                        color: darkMode ? "white" : "black", display: isTablet ? "flex" : "block",
                                                                        justifyContent: isTablet ? "center" : "initial",
                                                                        width: isTablet ? "53px" : "auto"
                                                                    }}
                                                                    sx={{ fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "700", paddingLeft: isTablet ? "0px" : "7px" }}
                                                                >
                                                                    {formatValue(iTServices[index]?.value, iTServices[index]?.label)}
                                                                </MDTypography>
                                                            </MDBox>
                                                        )}

                                                        {/* IT services */}

                                                        {
                                                            (isLargeMobile || isSmallMobile) && (
                                                                <>
                                                                    {selectedService === menuOptions.SavingsSteadyState && (
                                                                        <MDBox
                                                                            sx={{
                                                                                height: item.type === "heading" ? "80px" : "45px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                textAlign: "center",
                                                                                width: "80px",
                                                                            }}
                                                                        >
                                                                            <MDTypography
                                                                                style={{
                                                                                    color: darkMode ? "white" : "black",
                                                                                    display: isTablet ? "flex" : "block",
                                                                                    justifyContent: isTablet ? "center" : "initial",
                                                                                    width: isTablet ? "53px" : "auto",
                                                                                }}
                                                                                sx={{
                                                                                    fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px",
                                                                                    fontWeight: "700",
                                                                                    paddingLeft: isTablet ? "0px" : "7px",
                                                                                }}
                                                                            >
                                                                                {formatValue(steadySavingSatate[index]?.value, steadySavingSatate[index]?.label)}
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    )}

                                                                    {selectedService === menuOptions.Saving1Year && (
                                                                        <MDBox
                                                                            sx={{
                                                                                height: item.type === "heading" ? "80px" : "45px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                textAlign: "center",
                                                                                width: "80px",
                                                                            }}
                                                                        >
                                                                            <MDTypography
                                                                                style={{
                                                                                    color: darkMode ? "white" : "black",
                                                                                    display: isTablet ? "flex" : "block",
                                                                                    justifyContent: isTablet ? "center" : "initial",
                                                                                    width: isTablet ? "53px" : "auto",
                                                                                }}
                                                                                sx={{
                                                                                    fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px",
                                                                                    fontWeight: "700",
                                                                                    paddingLeft: isTablet ? "0px" : "7px",
                                                                                }}
                                                                            >
                                                                                {formatValue(savingsYear1[index]?.value, savingsYear1[index]?.label)}
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    )}

                                                                    {selectedService === menuOptions.Saving2Year && (
                                                                        <MDBox
                                                                            sx={{
                                                                                height: item.type === "heading" ? "80px" : "45px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                textAlign: "center",
                                                                                width: "80px",
                                                                            }}
                                                                        >
                                                                            <MDTypography
                                                                                style={{
                                                                                    color: darkMode ? "white" : "black",
                                                                                    display: isTablet ? "flex" : "block",
                                                                                    justifyContent: isTablet ? "center" : "initial",
                                                                                    width: isTablet ? "53px" : "auto",
                                                                                }}
                                                                                sx={{
                                                                                    fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px",
                                                                                    fontWeight: "700",
                                                                                    paddingLeft: isTablet ? "0px" : "7px",
                                                                                }}
                                                                            >
                                                                                {formatValue(savingsYear2[index]?.value, savingsYear2[index]?.label)}
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    )}

                                                                    {selectedService === menuOptions.Saving3Year && (
                                                                        <MDBox
                                                                            sx={{
                                                                                height: item.type === "heading" ? "80px" : "45px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                textAlign: "center",
                                                                                width: "80px",
                                                                            }}
                                                                        >
                                                                            <MDTypography
                                                                                style={{
                                                                                    color: darkMode ? "white" : "black",
                                                                                    display: isTablet ? "flex" : "block",
                                                                                    justifyContent: isTablet ? "center" : "initial",
                                                                                    width: isTablet ? "53px" : "auto",
                                                                                }}
                                                                                sx={{
                                                                                    fontSize: isTablet ? "9px" : isSmallMobile ? "7px" : "12px",
                                                                                    fontWeight: "700",
                                                                                    paddingLeft: isTablet ? "0px" : "7px",
                                                                                }}
                                                                            >
                                                                                {formatValue(savingsYear3[index]?.value, savingsYear3[index]?.label)}
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    )}
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                ))}
                                            </div>
                                        </Card>
                                    </div>
                                    {
                                        !(isSmallMobile || isLargeMobile) && (
                                            <div className={`${isTablet ? "w-[22%]" : "w-[25%] "} pl-[10px]`}>
                                                <Card sx={{ borderRadius: "6px" }}>
                                                    <div className={`flex rounded-[10px] ${isTablet ? "h-[55px]" : "h-[84px]"} ml-[10px] mr-[10px] -mt-[30px] justify-center`} style={{ backgroundColor: getBackgroundColor() }}>
                                                        <div className={`flex items-center ${isTablet ? "gap-[21px] p-[0px]" : "gap-[60px]"} p-[7px] text-center`}>
                                                            <MDTypography
                                                                style={{ width: isTablet ? "52px" : "71px", color: "white", fontSize: isTablet ? "9px" : isMdLoptop ? "11px" : "12px", fontWeight: "600", textAlign: "center", alignProperty: "center", }}
                                                            >
                                                                Savings<br /> Steady State
                                                            </MDTypography>

                                                            <MDTypography
                                                                style={{ color: "white", fontSize: isTablet ? "9px" : isMdLoptop ? "11px" : "12px", fontWeight: "600", textAlign: "center", alignProperty: "center", display: "flex" }}
                                                            >
                                                                {selectedYear.split(' ')[0]}<br /> {selectedYear.split(' ').slice(1).join(' ')}
                                                                <ExpandableMenu size="small" options={['Savings Year 1', 'Savings Year 2', 'Savings Year 3']} setSelectedService={handleMenuSelect} selectedYear={selectedYear} showIcon={true} />
                                                            </MDTypography>


                                                        </div>
                                                    </div>

                                                    <div className={`flex flex-col ${isMdLoptop ? "pl-[17px] pr-[12px]" : isTablet ? "px-[10px]" : "px-[20px]"} pt-[20px] `}>
                                                        {steadySavingSatate?.map((item, index) => (
                                                            <div
                                                                key={index}
                                                                className="flex justify-between items-center"
                                                                style={{ borderBottom: index < estimatedValues.length - 1 ? "1px solid gray" : "none", height: item.type === "heading" ? "80px" : "45px", }}
                                                            >
                                                                <MDBox
                                                                    sx={{
                                                                        height: item.type === "heading" ? "80px" : "45px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "80px",
                                                                    }}
                                                                >
                                                                    <MDTypography
                                                                        style={{ color: darkMode ? "white" : "black" }}
                                                                        sx={{ fontSize: isTablet ? "9px" : "12px", fontWeight: "700", paddingLeft: "7px", paddingRight: "7px" }}
                                                                    >
                                                                        {formatValue(item?.value, item?.label)}
                                                                    </MDTypography>
                                                                </MDBox>
                                                                <MDBox
                                                                    sx={{
                                                                        height: item.type === "heading" ? "80px" : "45px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "80px",
                                                                    }}
                                                                >
                                                                    {selectedYear === 'Savings Year 1' && (
                                                                        <MDTypography
                                                                            style={{ color: darkMode ? "white" : "black" }}
                                                                            sx={{ fontSize: isTablet ? "9px" : "12px", fontWeight: "700", paddingLeft: "7px", paddingRight: "7px" }}
                                                                        >
                                                                            {formatValue(savingsYear1[index]?.value, savingsYear1[index]?.label)}
                                                                        </MDTypography>
                                                                    )}
                                                                    {selectedYear === 'Savings Year 2' && (
                                                                        <MDTypography
                                                                            style={{ color: darkMode ? "white" : "black" }}
                                                                            sx={{ fontSize: isTablet ? "9px" : "12px", fontWeight: "700", paddingLeft: "7px", paddingRight: "7px" }}
                                                                        >
                                                                            {formatValue(savingsYear2[index]?.value, savingsYear2[index]?.value)}
                                                                        </MDTypography>
                                                                    )}
                                                                    {selectedYear === 'Savings Year 3' && (
                                                                        <MDTypography
                                                                            style={{ color: darkMode ? "white" : "black" }}
                                                                            sx={{ fontSize: isTablet ? "9px" : "12px", fontWeight: "700", paddingLeft: "7px", paddingRight: "7px" }}
                                                                        >
                                                                            {formatValue(savingsYear3[index]?.value, savingsYear3[index]?.label)}
                                                                        </MDTypography>
                                                                    )}
                                                                </MDBox>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    }

                                </MDBox>
                                <MDBox className="pt-[40px]">
                                    <Grid xs={12}>
                                        <Card sx={{ borderRadius: "6px" }}>
                                            <div className={`flex rounded-[10px] ${(isSmallMobile || isLargeMobile || isTablet) ? "h-[45px]" : "h-[84px]"} ml-[10px] mr-[10px] -mt-[30px] `} style={{ backgroundColor: getBackgroundColor() }}>
                                                <div className="flex flex-row items-center w-full">
                                                    {businessImpact.map((item, index) => (
                                                        <Grid lg={2} md={2} xs={6} sm={4} key={index}>
                                                            <MDTypography style={{ color: "white", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "600" }}
                                                                className="flex-grow text-center" >
                                                                {item.title}
                                                            </MDTypography>
                                                        </Grid>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center w-full px-[10px] mt-[20px] pb-[10px] justify-center">
                                                {businessImpact.map((item, index) => (
                                                    <Grid lg={2} md={2} xs={6} sm={4} key={index}>
                                                        <MDTypography style={{ color: darkMode ? "white" : "black", fontSize: (isLargeMobile || isTablet) ? "9px" : isSmallMobile ? "7px" : "12px", fontWeight: "600" }} className="flex-grow text-center">
                                                            {typeof item.value === 'string' && isNaN(item.value) ? item.value : formatValue(item.value, item.title)}
                                                        </MDTypography>
                                                    </Grid>
                                                ))}
                                            </div>
                                        </Card>
                                    </Grid>
                                </MDBox>
                                <MDTypography sx={{ fontSize: (isLargeMobile || isSmallMobile) ? "10px" : isTablet ? "12px" : "16px", fontWeight: "600", marginTop: "20px", paddingLeft: "10px" }}>
                                    <Link to={Paths.workForcePattrens} className="cursor-pointer">Business case and patterns</Link>
                                </MDTypography>
                            </MDBox>
                        </Card>
                    </div>
                </Grid>


                {modalPermission?.flag &&
                    <Modal open={modalPermission?.flag} setOpen={(fl) => setModalPermission({ flag: true })}>
                        <MDBox className={` ${isSmallMobile ? "pb-[15px]" : "px-[15px] pb-[15px]"}`}>
                            <div className="flex justify-end">
                                <CloseIcon fontSize="medium" className="cursor-pointer" style={{ color: darkMode ? "white" : "#000000" }} onClick={() => navigate('/dashboard')} />
                            </div>
                            <div className="flex flex-row w-full justify-between items-center text-xl font-semibold mt-5 mb-4" >
                                <div className="flex gap-[10px] items-center">
                                    <div className={`h-[58px] w-[57px] rounded-[9px] mt-[-26px] flex justify-center items-center`} style={{ backgroundColor: getBackgroundColor() }}>
                                        <WorkForceIcon />
                                    </div>
                                    <MDTypography style={{ fontSize:isSmallMobile ? "9px" :isLargeMobile ? "13px": "24px", fontWeight: "600", color: darkMode ? "white" : "#000000" }} >
                                        Upgrade to access Business Planning
                                    </MDTypography>
                                </div>
                                <MDButton style={{ width: "max-content", minWidth: windowSize.innerWidth <= 600 ? "100px" : "140px" }}
                                    disabled={false} onClick={() => navigate('/pricing-plans')} variant="gradient"
                                    color={themeColor} fullWidth>
                                    Upgrade
                                </MDButton>
                            </div>
                            <MDTypography className="my-4" style={{ marginTop: "30px", color: darkMode ? "white" : "#000000", fontSize: "16px", fontWeight: "700" }}>
                                Unlock Your full potential with our upgraded features:
                            </MDTypography>

                            <div className="mt-[20px]">
                                {UpgradeFeature.map((item, index) => (
                                    <div key={index} className="flex flex-row w-full items-center mt-2 gap-[10px]" style={{ fontSize: "14px", color: darkMode ? "white" : "black" }}>
                                        <FaCheck style={{ color: "green", height: "14px", width: "14px" }} />
                                        <MDTypography style={{ fontSize: "16px", fontWeight: "400", color: darkMode ? "white" : "#1E4081" }}>
                                            <span style={{ fontSize: "16px", fontWeight: 700, color: darkMode ? "white" : "#1E4081" }}>{item.title}:</span> {item.description}
                                        </MDTypography>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-4" style={{ marginTop: "20px", color: darkMode ? "white" : "#000000", fontSize: "16px", fontWeight: "700" }}>
                                Upgrade now and take the first step towards unparalleled success!
                            </div>

                        </MDBox>
                    </Modal>
                }
            </DashboardLayout>
        </div>

    );
};

export default EstimatedSavings;