import { IS_LOGIN, IS_PERMISSION, IS_LOGOUT, THEME_COLOR, SQL_TOOLS,ENABLE_INTERVEL  } from "redux/Types";

export const isLoginAction = (data) => {
    const loginTime = new Date().getTime();
    localStorage.setItem('loginTime', loginTime);
    return {
        type: IS_LOGIN,
        payload: { ...data, loginTime },
    };
};

export const isPermissionAction = (data) => ({
    type: IS_PERMISSION,
    payload: data,
});

export const isLogoutAction = () => ({
    type: IS_LOGOUT,
    payload: null
});

export const isThemeColorAction = (data) => ({
    type: THEME_COLOR,
    payload: data
});
export const isSqlToolsAction = (data) => ({
    type: SQL_TOOLS,
    payload: data
});
export const enableIntervel = (data) => {
        const currentTime = new Date().getTime();
        localStorage.setItem('loginTime', currentTime);
        console.log(data,"interveldata")
        return {
            type: ENABLE_INTERVEL ,
            payload: data,
        };
    };

