/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import CommonMethodsUI from "libraries/CommonMethodsUI";
import ApiRequest from "services/ApiRequest";
import { toast } from "react-toastify";

import {useMaterialUIController} from "context";
import { useNavigate } from "react-router-dom";


function ConfirmEmail() {
  const [email, setEmail] = useState('');
  const [controller] = useMaterialUIController();
  const {  themeColor } = controller;
  const navigate = useNavigate();

  const sendEmail = () => {
    ApiRequest.resendEmailConfirmation({ userEmail: email }, '', (res) => {
      const { isSuccess } = res;
      if (isSuccess) {
        toast.success(res?.data ? res?.data?.message : "Email sent for verification");
        navigate('/auth/login');
      }
      else toast.error(res?.error ? res?.error?.message : "Something went wrong");
     })
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={themeColor}
          borderRadius="lg"
          coloredShadow={themeColor}
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Resend Email
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput onChange={(e)=> setEmail(e.target.value)} 
              autoComplete="off" value={email}
              type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color={themeColor} onClick={()=> sendEmail()} fullWidth>
                Send
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ConfirmEmail;
