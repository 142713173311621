/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import ChangePassword from "layouts/authentication/change-password";
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { useDispatch, useSelector } from 'react-redux';

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setMiniChatbotSidenav,
  setChatbotNavClose,
  setOpenConfigurator,
} from "context";
import { TokenService } from "libraries/TokenService";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [openPassword, setOpenPassword] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, miniChatbotSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, chatbotNavClose } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const stateUser = useSelector((state) => state.loginReducer);
  const handleUpdatePasswordState = TokenService.getRemoveUpdatePassword('true')
  const userEmail=TokenService.getUserEmail('userEmail')
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleMiniChatbotSidenav = () => setMiniChatbotSidenav(dispatch, !miniChatbotSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem isLink={false} icon={<Icon>email</Icon>} title={stateUser?.user?.userEmail || userEmail } />
      {handleUpdatePasswordState !== "true" && (
      <NotificationItem
        isLink={true}
        icon={<Icon>lock</Icon>}
        onClick={() => {
          setOpenPassword(true);
          setOpenMenu(false);
        }}
        title={"Change Password"}
      />
      )}
      <NotificationItem isLink={false} onClick={() =>navigate("/pricing-plans")} 
      icon={<Icon>payment</Icon>} title="Change Payment Plan" />
      <NotificationItem isLink={false} onClick={()=> navigate("/payment-history")} icon={<Icon>history</Icon>} title="Payment History" />
      <NotificationItem isLink={false} onClick={()=> navigate("/usage-history")} icon={<Icon>equalizer</Icon>} title="Usage History" />
      <NotificationItem isLink={false} onClick={()=> navigate("/auth/login")} icon={<Icon>logout</Icon>} title={"Logout"} />    
     
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >

      {openPassword && <ChangePassword flag={openPassword} backFlag={(fl)=> setOpenPassword(fl)} />}
      
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        
        <MDBox style={{ display: "flex" }}>
        {window.innerWidth > 1200 && 
        <>
        {pathname?.includes('llm/agent-creation/infer') && !chatbotNavClose &&
          <FaRegArrowAltCircleLeft
            style={{ color: darkMode ? "white" : "grey" }}
            onClick={(e) => setChatbotNavClose(dispatch, true) }
            className="mr-2 cursor-pointer"
          />
        }
        {pathname?.includes('llm/agent-creation/infer') && chatbotNavClose &&
          <FaRegArrowAltCircleRight
            style={{ color: darkMode ? "white" : "grey" }}
            onClick={(e) => setChatbotNavClose(dispatch, false)}
            className="mr-2 cursor-pointer"
          />
        }
        </>}
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>

        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              {/* <MDInput label="Search here" value={''} autoComplete="off" /> */}
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/auth/login/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              
              {/* First Menu  */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>


               {/* Second Menu  */}
               {pathname?.includes('llm/agent-creation/infer') &&
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniChatbotSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniChatbotSidenav ? "chat" : "chat"}
                </Icon>
              </IconButton>
              }

              {/* Open Profile Section */}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                // aria-controls="notification-menu"
                // aria-haspopup="true"
                // variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {renderMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
