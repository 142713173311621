/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "examples/Items/NotificationItem/styles";
import { useMaterialUIController } from "context";


const NotificationItem = forwardRef(({ icon, title, isLink, ...rest }, ref) => {
  const [ controller ] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <MDBox component={isLink ? Link : false} py={0.5} sx={{ display:"flex", alignItems: "center" }} lineHeight={1}>
      {/* color="secondary" */}
        <MDTypography variant="body1"  lineHeight={0.75} style={{ 
          "&:hover": {color: darkMode ? "white" : "grey"},
          color: darkMode ? "white" : "grey" }}
        >
          {icon}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }} style={{ color: darkMode ? "white" : "grey"  }}>
          {title}
        </MDTypography>
      </MDBox>
    </MenuItem>
  )
  });

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;
