import React, { FC } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoInformationCircleOutline } from "react-icons/io5";
import {useMaterialUIController} from "context";
import { ReactComponent as InfoIcon} from "assets/images/flow-icons/info-icon.svg"


export const Tooltip = ({ text, uniqueId, color,isChatbot, width, isBilling, isAlignStart,isSqlTool,isWorkForce,isPattern }) => {
    const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  return (
    <>
      {text && (
        <div className={`flex flex-row ${isChatbot ? "pl-0": isSqlTool ?"pl-0 mt-[22px]":""} ${isAlignStart ? 'mt-3'  : isWorkForce ? '' : "items-center"} text-gray-800`}>
          {isChatbot || isSqlTool  ? ( 
            <InfoIcon
              className={`text-xl cursor-pointer`}
              data-tooltip-id={uniqueId}
            />
          ) : ( // Otherwise, render the information icon
            <IoInformationCircleOutline
              className={`${isBilling ? "text-xs" : "text-xl ml-2 "}  cursor-pointer`}
              style={{ color: darkMode ? "white" : "black", marginTop:isPattern ? "-2px" :"" }}
              data-tooltip-id={uniqueId}
            />
          )}

          <ReactTooltip
            id={uniqueId}
            content={text}
            className="cc-bg-registration"
            place="bottom"
            variant="info"
            style={{ maxWidth: width ?? "200px", zIndex: "999", background: color, fontSize: `${isChatbot?"10px":"14px"}`}}
          />
        </div>
      )}
    </>
  );
};
