/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ id, icon, name, active,delIcon,clickDel,dateModified, toggle, checked, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, miniChatbotSidenav, transparentSidenav, whiteSidenav, darkMode, themeColor } = controller;
  const textStyle = {
    // whiteSpace: 'nowrap',
    width: '50px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            themeColor,
          })
        }
      >
        {icon &&
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>
        }        

        {dateModified ? 
        <div style={{ width: "160px", paddingTop: "15px"}}>
        <ListItemText
          primary={name}
          style={{ fontWeight: "bold" }}
          sx={(theme) =>
            collapseText(theme, true,{
              miniSidenav,
              miniChatbotSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
          <div style={{ fontSize: "12px", padding: "5px 10px" }}>{dateModified?.split('T')?.[0]}</div>
        
        </div>
        :
        <>
        <ListItemText
          // style={{ overflow: "hidden" }}
          // collapse-icon
          primary={name}
          sx={(theme) =>
            collapseText(theme, false, {
              miniSidenav,
              miniChatbotSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />  
        {checked && (
          <>
          {toggle ? <ExpandLess /> : <ExpandMore />}
          </>
        )}
        
        </>
        }

        {delIcon &&
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
          style={{ display: 'flex', justifyContent: "end"}}
        >
          {typeof icon === "string" ? (
            <Icon style={{ color: active? "white" : (!whiteSidenav && !transparentSidenav) ? "white" :  (transparentSidenav && darkMode) ? "white" : (transparentSidenav || (whiteSidenav && !darkMode)) ? "black" : "black" }} onClick={(e)=> clickDel(e, id)} sx={(theme) => collapseIcon(theme, { active })}>{delIcon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>
        }
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
