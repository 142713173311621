import * as React from 'react';
import { useState, useEffect } from "react";
import { useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SpinnerLoader } from "examples/Loader/Loader";
import { setChatbotHistoryUrl } from "context";
import ApikeyModal from 'layouts/apiKeyManagementModule/ApiKeyModel';
import Tooltip from '@mui/material/Tooltip';
import Constants from "libraries/Constants";
import ApiKeyManagementTable from 'layouts/apiKeyManagementModule/ApiKeyManagementTable';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


export default function ApiKeyManagementTablePattren({ columns, widthhandle, apiList, getApiKey, isLoading }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [apiKeyList, setApiKeyList] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, name: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const [selectedName, setSelectedName] = useState(null)
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')

  const handleModalClose = () => {
    setModalOpen(false);
    setModalSuccessOpen(false);
    getApiKey()
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setChatbotHistoryUrl(dispatch, '')
    getApiKey()
  }, []);

  useEffect(() => {
    if (!(isLargeMobile || isSmallMobile) && open) {
      setOpen(false);
      setCollapseRow(0);
    }
  }, [isLargeMobile]);

  const deleteModal = () => {
    setDelLoader(true);
    ApiRequest.deleteApikeyModule("", { id: modalDelOpen?.id }, (res) => {
      setModalDelOpen({ flag: false, name: '', id: '' }); setDelLoader(false);
      getApiKey();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const editAgent = (e, id) => {
    e.stopPropagation();
  }
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#1D3E80";
      case "success":
        return "#5DAA45";
      default:
        return themeColor;
    }
  };

  const getTooltipBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  };
  return (
    <>
      <div style={{ height: "45px", width: '100%', display: 'flex', padding: '12px 16px', backgroundColor: getBackgroundColor(), borderRadius: '8px', alignItems: 'center' }}>
        <div
          style={{border: "none", width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white" }}
        >
          Name
        </div>
        <div
          style={{border: "none", width: windowSize.innerWidth > 600 ? "35%" : "50%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "8px" : "29px" }}
        >
          Secret Key
        </div>
        {windowSize.innerWidth > 600 && <>
          <div
            style={{ width: "20%", fontWeight: "600", fontSize: "16px", color: "white", marginLeft: isTablet ? "33px" : "47px" }}
          >
            Created
          </div>
          <div
            style={{ width: "10%", fontWeight: "600", fontSize: "16px", color: "white", paddingLeft: isTablet && "10px", marginLeft: isTablet ? "0px" : "21px" }}
          >
            Action
          </div>
        </>}
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
        {modalOpen &&
          <ApikeyModal
            open={modalOpen}
            onClose={handleModalClose}
            title="Creating New API Key"
            buttonLabel="Create API key"
            themeColor={themeColor}
            darkMode={darkMode}
            isSuccessModel={false}
            id={selectedId}
            name={selectedName}
            setOpen={setModalOpen}
          />
        }
        {modalSuccessOpen &&
          <ApikeyModal
            open={modalSuccessOpen}
            onClose={handleModalClose}
            title="API Key Successfully Created"
            themeColor={themeColor}
            darkMode={darkMode}
            isSuccessModel={true}
            origionalApiKey={origionalApiKey}
            setOpen={setModalSuccessOpen}
          />
        }

        <TableContainer >
          {/* sx={{ maxHeight: 440 }} */}
          <Table >
            <TableBody>
              {/* Body List  */}
              {isLoading ?
                <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                  {[1, 2, 3, 4, 5, 6]?.map(() => (
                    <div className="w-56 mr-8">
                      <Code backgroundColor="grey" foregroundColor="darkgrey" />
                    </div>
                  ))}
                </div>
                :
                apiList?.length ?
                  <>
                    {apiList
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, i) => {
                        return (
                          <>
                            <TableRow tabIndex={-1} style={{ backgroundColor: !darkMode ? i % 2 === 0 ? "white" : "#F1F1F1" : "" }}>
                              <TableCell key={i} align={"left"}
                                style={{ paddingLeft: (isLargeMobile || isSmallMobile) ? "5px" : "", color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "35%" }}>
                                {
                                  windowSize.innerWidth <= 600 &&
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      setOpen(!open)
                                      setCollapseRow(i + 1)
                                    }}
                                  >
                                    {(open && (collapseRow === (i + 1))) ?
                                      <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                      : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                  </IconButton>
                                }
                                {row?.name}
                              </TableCell>
                              <TableCell key={i} align={"left"}
                                style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "35%", paddingLeft: (isLargeMobile || isSmallMobile) ? "15px" : "0px" }}>
                                {row?.secretKey}
                              </TableCell>
                              {windowSize.innerWidth > 600 &&
                                <>
                                  <TableCell key={i} align={"left"}
                                    style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "20%", paddingLeft: "0px" }}>
                                    {Constants.formatDashDates(row?.dateCreated, false)}
                                  </TableCell>
                                  {/* <TableCell key={i} align={"left"}
                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "20%" }}>
                              { row?.lastUsed === "Never" ? row?.lastUsed : formatDate(row?.lastUsed)}
                            </TableCell> */}
                                  {/* <TableCell key={i} align={"left"}
                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "15%" }}>
                              {String(row?.globalPermission)}
                            </TableCell> */}

                                  <TableCell key={i} align={"left"} style={{ paddingLeft: "0px" }}>
                                    <div className="flex flex-row" key={i} >
                                      <Tooltip title="Reconfigure API Key" arrow
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <FaEdit
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => {
                                              editAgent(e, row?.id)
                                              setModalOpen(true)
                                              setSelectedId(row?.id)
                                              setSelectedName(row?.name)
                                            }}
                                            className=" cursor-pointer"
                                          />
                                        </div>
                                      </Tooltip>

                                      <Tooltip title="Delete API Key" arrow
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              color: "white",
                                              backgroundColor: getTooltipBackgroundColor(),
                                              fontSize: "10px",
                                              fontWeight: "400",
                                              '& .MuiTooltip-arrow': {
                                                color: getTooltipBackgroundColor(),
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <div>
                                          <MdDelete
                                            style={{ color: darkMode ? "white" : "black" }}
                                            onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.name, id: row?.id }) }}
                                            className={`ml-4  cursor-pointer`}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </>
                              }

                            </TableRow>
                            {/* Extended Row */}
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Table>
                                      <MDBox component="thead">
                                        <TableRow >
                                          <TableCell
                                            // key={column.id}
                                            align={"left"}
                                            style={{ paddingLeft: isLargeMobile ? "4px" : isSmallMobile ? "5px" : "", width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                          >
                                            Created
                                          </TableCell>
                                          <TableCell
                                            // key={column.id}
                                            align={"left"}
                                            style={{ width: "50%", color: darkMode ? "white" : "black", fontWeight: "bold" }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </MDBox>
                                      <TableBody >
                                        <>
                                          <TableRow tabIndex={-1}>
                                            <TableCell key={i} align={"left"}
                                              style={{ color: darkMode ? "white" : "black", fontSize: "14px", width: windowSize.innerWidth <= 600 ? "50%" : "20%", paddingLeft: "0px" }}>
                                              {Constants.formatDashDates(row?.dateCreated, false)}
                                            </TableCell>

                                            <TableCell key={i} align={"center"}>
                                              <div className={`flex flex-row w-full ${(isSmallMobile || isLargeMobile) ? "justify-start" : "justify-center"} `} key={i} >
                                                <Tooltip title="Reconfigure API Key" arrow
                                                  slotProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "white",
                                                        backgroundColor: getTooltipBackgroundColor(),
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                        '& .MuiTooltip-arrow': {
                                                          color: getTooltipBackgroundColor(),
                                                        },
                                                      },
                                                    },
                                                  }}
                                                >
                                                  <div>
                                                    <FaEdit
                                                      style={{ color: darkMode ? "white" : "black" }}
                                                      onClick={(e) => {
                                                        editAgent(e, row?.id)
                                                        setModalOpen(true)
                                                        setSelectedId(row?.id)
                                                        setSelectedName(row?.name)
                                                      }
                                                      }
                                                      className="cursor-pointer"
                                                    />
                                                  </div>
                                                </Tooltip>

                                                <Tooltip title="Delete API Key" arrow
                                                  slotProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "white",
                                                        backgroundColor: getTooltipBackgroundColor(),
                                                        fontSize: "10px",
                                                        fontWeight: "400",
                                                        '& .MuiTooltip-arrow': {
                                                          color: getTooltipBackgroundColor(),
                                                        },
                                                      },
                                                    },
                                                  }}
                                                >
                                                  <div>
                                                    <MdDelete
                                                      style={{ color: darkMode ? "white" : "black" }}
                                                      onClick={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.name, id: row?.id }) }}
                                                      className={`ml-4  cursor-pointer`}
                                                    />
                                                  </div>
                                                </Tooltip>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </>
                  : <div style={{ fontSize: "14px", padding: "20px", color: "red" }}>No API Key Created</div>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          style={{ color: darkMode ? "white" : "black" }}
          count={apiList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {modalDelOpen?.flag &&
          <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, name: '', id: '' })}>
            <div className="flex flex-row w-full justify-center items-center text-xl mb-4" >
              Are you sure you want to delete? Deleted API Key will be lost
            </div >

            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "140px" }} disabled={false}
                onClick={() => setModalDelOpen({ flag: false, agentName: '', id: '' })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "140px" }} disabled={delLoader}
                onClick={() => deleteModal()}
                variant="gradient" color={themeColor} fullWidth>
                {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
              </MDButton>
            </MDBox>
          </Modal>
        }
      </Paper>
    </>
  );
}
