  /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {useMaterialUIController} from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { SpinnerLoader } from "examples/Loader/Loader";
import Constants from "libraries/Constants";
import MDDropdown from "components/MDDropdown";
import { Images } from "libraries/Images";
import Divider from "@mui/material/Divider";
import OutlinedInput from '@mui/material/OutlinedInput';
import { useMediaQuery } from "@mui/material";

// import CustomSelect from "components/CustomSelect/CustomSelect"; 


function TextToSpeechConfig() {
  const navigate = useNavigate()
  const location = useLocation()  
  const {id, name} = useParams()

  const { configForm, isEditAgent } = location?.state || {};
  const {  agentName, description, modelType, language, voiceName } = configForm || {};
  const [languagesArr, setLanguagesArr]  = useState([]);
  const [languagesList, setLanguagesList]  = useState([]);
  const [voiceNamesArr, setVoiceNamesArr]  = useState([]);
  const [voiceNamesList, setVoiceNamesList]  = useState([]);
  const [modelTypeArr, setModelTypeArr]  = useState([]);
  const [modelTypeList, setModelTypeList]  = useState([]);
  const [ isEdit, setIsEdit] = useState(isEditAgent ?? false);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const [config, setConfig] = useState( { 
    agentName: agentName ?? "", description: description ?? '',
    modelType: modelType ?? "", language: language ?? "", 
    voiceName: voiceName ?? "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')


  useEffect(()=> {
    allModelsList();
  },[]);
  const allModelsList = () => {
    ApiRequest.listModels('', id, (res)=>{
      if(res?.isSuccess){
        const name = res?.data?.patternModels?.map(pl => { return pl?.permission === 0 ? pl?.modelName : `${pl?.modelName} (${pl?.planName} Plan)`});
        const completeList = res?.data?.patternModels?.map(pl => 
          {return {
            title: pl?.permission === 0 ? pl?.displayName : `${pl?.displayName} (${pl?.planName} Plan)`, 
            isLock: pl?.permission === 1,
            apiName: pl?.permission === 0 ? pl?.modelName : `${pl?.modelName} (${pl?.planName} Plan)`, 
            default: pl?.default  
          }}
        );
       
        setModelTypeArr(name); setModelTypeList(completeList)
        setConfig({...config, modelType: location?.state?.configForm?.modelType ?? completeList?.filter(pl => pl?.default)?.[0]?.apiName});
      }else{
        setModelTypeArr([]); setModelTypeList([])
      }
    });
  };

  useEffect(()=> {
    if(isEdit){
      setTimeout(()=> {
          setIsEdit(false)
      },2000)
    }
  },[isEditAgent]);


  useEffect(()=> {
    if(config?.modelType){
      if(!isEdit) setConfig({...config, voiceName: "", language: ""})
      setLanguagesArr([]); setLanguagesList([])
      allLanguagesList();
    }
  },[config?.modelType]);


  useEffect(()=> {
      if(config?.language){
        if(!isEdit) setConfig({...config, voiceName: ""})
        setVoiceNamesArr([]); setVoiceNamesList([])
        allVoiceNamesList();
      }
  },[config?.language]);
 

  const allLanguagesList = () => {
    // For Languages  
    ApiRequest.dropdownTextToSpeechAgent('', `/languages?modelType=${config?.modelType}`, (res)=>{

          if(res?.isSuccess){
            const name = res?.data?.languages?.map(pl => { return pl?.languageCode});
            const completeList = res?.data?.languages?.map(pl => 
              {return { title: pl?.displayName, apiName: pl?.languageCode }}
            );
            console.log("yyy Language 01", name)
            console.log("yyy CompleteList 01", completeList)
            console.log("yyy CONFIG Language 01", config)
            setLanguagesArr(name); setLanguagesList(completeList)
          }
          else{
            setLanguagesArr([]); setLanguagesList([])
          }
    })
  };

  const allVoiceNamesList = () => {
    // For Voice Name
    ApiRequest.dropdownTextToSpeechAgent('', `/voiceNames?modelType=${config?.modelType}&language=${config?.language}`, (res)=>{
        if(res?.isSuccess){
          const name = res?.data?.voiceNames?.map(pl => { return pl?.voiceCode});
          const completeList = res?.data?.voiceNames?.map(pl => 
            {return { title: pl?.displayName, apiName: pl?.voiceCode }}
          );
          setVoiceNamesArr(name); setVoiceNamesList(completeList)
        }
        else{
          setVoiceNamesArr([]); setVoiceNamesList([])
        }
    });
  }
   
  const fields = [
    { id: 1, type: 'text', placeholder: 'Agent Name', name: 'agentName', isActive: true, mendatory: true, information: "Name of the X-Agent you want to create. Note that the X-Agent name must be unique." },
    { id: 2, type: 'textarea', placeholder: 'Agent Description*', name: 'description',
    isActive: true, mendatory: true, information: "Describes the agent's purpose or functionality." },
    { id: 3, type: 'dropdown', placeholder: 'Model Type*', name: 'modelType', isActive: true, 
      arr: modelTypeArr?.length ? modelTypeArr : [''], mendatory: true, information: "There are two types of models Google Cloud Platform and Open Voice . Google Cloud Platform provides scalable and efficient text-to-speech and speech recognition services and Open Voice is an open-source platform, offering flexibility and customization options for speech recognition and synthesis." },
    { id: 40, type: 'dropdown', placeholder: 'Language*',name: 'language', 
      isActive: config.modelType?.toLowerCase() === Constants.textToSpeechGoogle?.toLowerCase(), 
      arr: languagesArr?.length ? languagesArr : [''], mendatory: true, information: "Displays the language supported by the selected model, such as English, Spanish, French, etc."
    },
    { id: 41, type: 'dropdown', placeholder: 'Language*',name: 'language', 
      isActive: config.modelType?.toLowerCase() === Constants.textTospeechOpen?.toLowerCase(), 
      arr: languagesArr?.length ? languagesArr : [''], mendatory: true, information: "Displays the language supported by the selected model, such as English, Spanish, French, etc."
    },
    { id: 50, type: 'dropdown', placeholder: 'Voice Name*',name: 'voiceName',
      isActive: config.modelType?.toLowerCase() === Constants.textToSpeechGoogle?.toLowerCase() , 
      arr:  voiceNamesArr?.length ? voiceNamesArr : [''], mendatory: true, information: "Indicates the selected voice, including options like gender, accent, or tone." 
    },
    { id: 51, type: 'dropdown', placeholder: 'Voice Name*',name: 'voiceName', 
      isActive: config.modelType?.toLowerCase() === Constants.textTospeechOpen?.toLowerCase() , 
      arr: voiceNamesArr?.length ? voiceNamesArr : [''], mendatory: true, information: "Indicates the selected voice, including options like gender, accent, or tone." 
    },
  ];

  const isValidate = () => {
    let validate = true;
    fields.map((field, _) => {
        if((field?.type === "text" || field?.type === "textarea")&& field?.mendatory && config[`${field?.name}`] === ""){
            validate = false
        }
    })
    return validate
  }

  const navigation = (route, page, step, objectId, configForm) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isEditAgent: location?.state?.isEditAgent,
        uploadedFile: location?.state?.uploadedFile,
        isTrained: page === 5 && route === '/train' ? true : false,
        beforeTrained: location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
      }
    });
  };

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, location?.state?.configForm)
  ),[]);

  useEffect(()=>(
    navigation('/config', 1, 0, location?.state?.objectId, 
    {...config, patternId: id})
  ),[config]);

  const saveConfig = (flg) => {
      // if(isValidate()){
        const data = {agentName, description, modelType, language, voiceName}

        
        if(!agentName || !description){
          toast.error("Please fill all required fields");
        }else if(!modelType) {
          toast.error("Please select model type");                       
        }else if(!language){
          toast.error("Please select language");                       
        }else if(!voiceName){
          toast.error("Please select voice name");                       
        }else{
          if(flg){setIsLoading(true)}
          else{setIsLoading2(true)}

          ApiRequest.configTextToSpeechAgent({...data, patternId: id}, location?.state?.objectId, (res) => {
            if(flg) setIsLoading(false);
            if (res.isSuccess) {
              if (flg) {
                navigation('/config', 1, 0, res?.data?.agent?._id, location?.state?.configForm);
              } else {
                setIsLoading(false);setIsLoading2(false);
                navigation('/inference', 3, 1, res?.data?.agent?._id, location?.state?.configForm);
              }
              toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
            } else {
              setIsLoading(false);setIsLoading2(false);
              toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
          });
        }
  };

  const Icons = (icon) => {
    return (
        <div style={{ width: "30px", marginRight: "15px" }}>
            <MDBox bgColor={themeColor}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                <MDBox component="img" textAlign="center"
                    src={icon}
                    alt="Brand" width="70%"
                    style={{ height: "14px", width: "14px" }}
                />
            </MDBox>
        </div>
    )
  }

  console.log("yyy languagesList 00005", languagesList)

  return (
      <div className="flex flex-col" style={{height: "calc(100%)", justifyContent: "space-between"}}>
        <div className="w-[100%] flex flex-row flex-wrap" 
              style={{gap: "20px", justifyContent: "space-between", padding: "0px 30px 30px 30px"}}>
        	
          {fields?.filter((fl) => fl?.isActive).map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" 
                  : windowSize.innerWidth <= 950 && fi.type === "dropdown" ? "48%" 
                  : fi?.type === "textarea" || fi?.type === "text" ? "70%" 
                  : fi.type === "dropdown" ? "30%"
                  :  "45%", outline: "none",
                display: "flex", alignItems: fi?.type === "textarea" ? "start" : "center" }}>
            {fi.type === "dropdown" ?
                <div style={{ marginTop: "20px" }}>
                <MDDropdown dropdownName={fi.placeholder} 
                      nameArr={fi?.arr}
                      isLarge={true}
                      isMultiple={false} 
                      backendArr={
                          fi?.name === "language" && languagesList?.length ? languagesList :
                          fi?.name === "voiceName" && voiceNamesList?.length ? voiceNamesList :
                          fi?.name === "modelType" && modelTypeList?.length ? modelTypeList
                          : []
                      }
                      
                      // (fi?.name === "language" || fi?.name === "voiceName") && 
                      backSelect={(val)=> {
                       console.log(fi?.name,"name")
                        setConfig({ ...config, [fi?.name]: typeof val === 'string' ? val.split(',')?.[0] : val })
                      } }
                      isTextToSpeech={true}
                      personName={[config?.[fi?.name] ]?.length ? config[fi?.name] : ''} />  
                      </div>                     
                :
              fi?.type === "textarea" ? 
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "15px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
                      {Icons(fi?.name === "description" 
                                ? Images.promptLlmPattern : Images.inputParameterLlmPattern)}
                      {fi?.placeholder}
                  </div>
                <OutlinedInput 
                      placeholder={`Write ${fi?.placeholder} here`}
                      sx={{
                        width: "100%",
                        minHeight: "70px", 
                        borderRadius: "10px", '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CCCCCC',
                            borderWidth: '2px',
                        },
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        resize: 'none'     
                      }} 
                      multiline 
                      value={config[fi.name]} 
                      onChange={(e) => {
                        setConfig({ ...config, [fi.name]: e.target.value });
                        }}
                      />
                      </div>
                :
              <MDInput   
                  // style={{outline: "none", border: "none"}}
                  id={fi.name} 
                  type={fi.type}
                  value={config[fi.name]} 
                  onChange={(e) => {
                  setConfig({ ...config, [fi.name]: e.target.value });
                  // setError({ ...error, [fi.name]: '' });
                  }}
                  label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                  variant="standard" fullWidth />
              }
                {fi?.information &&
                <div style={{ marginTop: fi?.type === "textarea" ? "20px" : fi?.type !== ("textarea" || "text") && "25px" }}>
                  <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>      
                </div>
                }
              
                </MDBox>
            ))}
        </div>

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(true)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
            </MDButton>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(false)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
            </MDButton>
        </MDBox>
      </div>
  );
}

export default TextToSpeechConfig;
