import React, { useEffect, useRef } from 'react';
import { useMaterialUIController } from 'context';

const MediaPlayer = ({ uploadedContent }) => {
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const audioContextRef = useRef(null);
  const mediaElementSourceRef = useRef(null);

  const renderFrame = (analyser, bufferLength) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const dataArray = new Uint8Array(bufferLength);
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    let barWidth = (WIDTH / bufferLength) * 2.5;
    let x = 0;

    const draw = () => {
      requestAnimationFrame(draw);
      x = 0;

      analyser.getByteFrequencyData(dataArray);
      ctx.fillStyle = darkMode ? '#000' : '#fff';
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i];
        const r = barHeight + 25 * (i / bufferLength);
        const g = 250 * (i / bufferLength);
        const b = 50;

        ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
    };

    draw();
  };

  const handleFileChange = () => {
    const audio = audioRef.current;
    const files = uploadedContent;

    if (files.length > 0) {
      const audioSrc = URL.createObjectURL(files[0]);
      audio.src = audioSrc;
      audio.load();

      if (!audioContextRef.current) {
        // Create a new AudioContext only if it doesn't exist
        const context = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = context;
      }

      if (!mediaElementSourceRef.current) {
        // Create a new MediaElementSourceNode only if it doesn't exist
        mediaElementSourceRef.current = audioContextRef.current.createMediaElementSource(audio);
      }

      const analyser = audioContextRef.current.createAnalyser();
      mediaElementSourceRef.current.connect(analyser);
      analyser.connect(audioContextRef.current.destination);
      analyser.fftSize = 256;

      const bufferLength = analyser.frequencyBinCount;
      renderFrame(analyser, bufferLength);

      // Play the audio after everything is connected
      audio.play().catch((error) => console.error('Audio play was interrupted:', error));
    }
  };

  useEffect(() => {
    if (uploadedContent?.length) {
      handleFileChange();
    }

    console.log('uploadedContent', uploadedContent);

    return () => {
      // Clean up the AudioContext when the component unmounts
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
        mediaElementSourceRef.current = null;
      }
    };
  }, [uploadedContent]);

  return (
    <div
      id="content"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <canvas ref={canvasRef} width="800px" height="200px"></canvas>
      <audio
        ref={audioRef}
        controls
        style={{ width: '80%', marginTop: '20px' }}
      ></audio>
    </div>
  );
};

export default MediaPlayer;
