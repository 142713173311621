/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {ReactComponent as Basic}  from "assets/images/billing-icons/Basic.svg"; 
import { getColorVlue } from 'services/Utils';
import { MdArrowOutward } from "react-icons/md";
import {  useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

function ComplexStatisticsCard({ color, title, count, percentage, icon, isIcon, patternName, isPatternList, onClick }) {
  const [controller] = useMaterialUIController();
  const navigate = useNavigate();
  const { darkMode, themeColor  } = controller;
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          style={{ minWidth: "64px", minHeight: "64px" }}
          mt={-3}
        >
          {isIcon ? <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
          :  <img style={{ width: "30px", height: "30px" }} src={icon} />
        }
            
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} style={{ minHeight: "50px" }}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          {isPatternList ? 
            <>
            <MDTypography style={{ height: "48px", color: darkMode ? "white" : "black" }} variant="h6">{patternName}</MDTypography>
            
            {isPatternList?.permission === 1 ? 
            <div  className="flex flex-row w-full items-center justify-end"  
                  style={{ height: "35px", fontSize: "12px", color: darkMode && "white" }}>
              {/* <div className="mr-1">Upgrade to</div>  */}

              <MDBox  className="flex flex-row items-center cursor-pointer" onClick={()=>navigate("/pricing-plans")}>
                <MDBox  className="flex flex-row items-center p-1" 
                      sx={{height: "25px", width: "max-content", backgroundColor: getColorVlue(themeColor), borderRadius: "5px"  }}>
                  <Basic fill={getColorVlue(themeColor)} style={{width: "20px", height: "20px"}}/>
                  <div style={{ color: "white", marginLeft: "5px" }}>Upgrade to {isPatternList?.planName}</div>
                </MDBox>
                {/* <MdArrowOutward style={{  color: darkMode && "white" }} /> */}
              </MDBox>
              
            </div>
            : <div style={{ height: "35px" }}></div>}
            </>
            :
            <MDTypography style={{ color: darkMode ? "white" : "black" }} variant="h6">{count}</MDTypography>  
        }
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography style={{ minHeight: "210px" }} component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </MDTypography>
          <div style={{ textAlign: "center", color: darkMode ? "white" : "black" }} className="truncateSomeLine">
              {isPatternList ? "The" : ""} <span style={{ fontWeight: "bold" }}>{isPatternList && patternName}</span>
          &nbsp;{percentage.label}
          </div>
        </MDTypography>
      </MDBox>

      {
        isPatternList &&
        <MDBox pb={2} px={2}>
        <MDButton
          component="a"
          onClick={onClick}
          rel="noreferrer"
          variant="gradient"
          color={color}
          fullWidth
        >
          Learn More
        </MDButton>
      </MDBox>
      }
    
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
