import { Grid, Card, useMediaQuery } from "@mui/material"
import { TabPanel } from "components/MDTab/TabPanel";
import MDTypography from "components/MDTypography";
import { getColorVlue } from 'services/Utils';
import MDButton from "components/MDButton";
export const PatternCard = ({
  selectedTab,
  id,
  llmDescription,
  llmIcon,
  buttonLabel,
  patternName,
  darkMode,
  themeColor,
  handleNavigate,
  gifImage
}) => {

  
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1500px)');
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:399px)')
  const iNestHub = useMediaQuery('(min-width: 1020px) and (max-width: 1030px) and (min-height: 580px) and (max-height: 600px)');
  const isMac = useMediaQuery('(min-width: 1500px) and (max-width: 1940px)');
  const iNestHubMax = useMediaQuery('(min-width: 1260px) and (max-width: 1281px) and (min-height: 780px) and (max-height: 800px)');
  const zenBokFold = useMediaQuery('(min-width: 840px) and (max-width: 854px) and (min-height: 1270px) and (max-height: 1281px)');
  const isSurface7 = useMediaQuery('(min-width: 900px) and (max-width: 920px) and (min-height: 1350px) and (max-height: 1370px)');
  
  return (
    <>
      {
        id === selectedTab && <Grid item xs={12} lg={9} md={8} style={{height:"750px"}}>
          <Card style={{height:(isSmallMobile || isLargeMobile) ? "" :"72%"}}>
          <TabPanel value={selectedTab} index={id} className="tab-panel">
            <div>
              <img src={gifImage} alt="video" style={{width:"100%", height:(isSmallMobile || isLargeMobile)?"" :"535px"}}/>
              </div>
              </TabPanel>
          </Card>
          <Card className="tab-panel" style={{ padding:(iNestHub||iNestHubMax) ? "22px":isLoptop ? "13px": '22px', height:(isSmallMobile || isLargeMobile) ? "" :"25.75%", marginTop:isTablet ? "3.1%":zenBokFold ? "2.4%" :isSurface7  ? "2.8%" :(iNestHub || isLoptop||iNestHubMax) ? "2%":"1.4%"}}>
            <TabPanel value={selectedTab} index={id}>
              <div>
              <div className="flex justify-between">
                  <div className="flex gap-[20px] items-center">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height:isSmallMobile || isLargeMobile? "50px": "40px", width:isSmallMobile || isLargeMobile? "60px": "40px", background: getColorVlue(themeColor), borderRadius: "50%" }}>
                    <img src={llmIcon} alt="" style={{height: "30px", width: "30px"}}/>
                  </div>
                  <MDTypography sx={{marginTop:"4px", fontSize:(isSmallMobile || isLargeMobile) ? "12px": "20px", fontWeight: "500", color: darkMode ? "white" : "#1C3B7C" }}>
                    {patternName}
                  </MDTypography>
                  </div>    
                  <MDButton style={{ marginTop:(isSmallMobile || isLargeMobile) ?"5px": '8px',fontSize:(isSmallMobile || isLargeMobile) ? "9px":"",  width: "max-content",padding:isSmallMobile || isLargeMobile ? "10px 10px" : "", minWidth:isSmallMobile || isLargeMobile? "100px": "140px" }} variant="gradient" color={themeColor} onClick={handleNavigate}>
                    {buttonLabel}
                  </MDButton>
                </div>
                <MDTypography sx={{ marginTop: "10px", fontWeight: "400", fontSize:(isSmallMobile || isLargeMobile) ? "10px":(isTablet || isSurface7) ? "11px" :(iNestHub || iNestHubMax)?"13px": "16px", color: darkMode ? "white" : "#1C3B7C" }}>
                  {llmDescription}
                </MDTypography>
               
              </div>
            </TabPanel>
          </Card>
        </Grid>
      }
    </>
  );
};